.adminEventFrame h3{
    font-size:1.8vw;
    font-weight:600;
    line-height:5vw;
    margin-left:5vw;
    margin-right:5vw;
    margin-bottom:2vw;
    border-bottom:1px solid #000000;
}

.eventMakingFrame{
    display:flex;
    flex-direction: row;
    margin-bottom:3vw;
    margin-left:5vw;
    margin-right:5vw;
}

.eventPeriodGroup{
    width:60%;
    height:auto;
    padding:1vw 0 2vw;
    border:1px solid #dedede;
}
.eventPeriodGroup>.DayPicker{
    width:100%;
}
.eventPeriodGroup>.DayPicker>div{
    width:100%;
}
.eventPeriodGroup>.DayPicker>div>.DayPicker-NavBar span{
    width:2vw;
    height:2vw;
    outline: none;
}
.eventPeriodGroup>.DayPicker>div>.DayPicker-NavBar span.DayPicker-NavButton--prev{
    right:2vw;
    bottom:1vw;
    top:inherit;
}
.eventPeriodGroup>.DayPicker>div>.DayPicker-NavBar span.DayPicker-NavButton--next{
    right:1vw;
    bottom:1vw;
    top:inherit;
}
.eventPeriodGroup>.DayPicker>div>.DayPicker-Months{
    display:flex;
    flex-direction:row;
    justify-content:center;
    font-size:1.2vw;
    margin-top:1vw;
    margin-bottom:2vw;
}
.eventPeriodGroup .setCpPeriodText{
    height:2.5vw;
    line-height:2.vw;
    font-size:1.2vw;
    color:#c56e6e;
    margin-left:4vw;
    word-break: keep-all;
}
.eventPeriodGroup .setCpPeriodText button{
    height:2.5vw;
    padding:0 2vw;
    margin-left:0.5vw;
    border:1px solid #000000;
    background:#ffffff;
}
.eventPeriodGroup .setCpPeriodText button:hover{
    background:#c56e6e;
    color:#ffffff;
    border:1px solid #c56e6e;
}



.eventImageUploadGroup{
    width:40%;
    padding:2vw 0 2vw 4vw;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.eventImageUploadGroup>div{
    height:9vw;
    display:flex;
    flex-direction: row;
    padding-bottom:1vw;
    border-bottom:1px solid #dedede;
}
.eventImageUploadGroup>div:nth-last-child(1){
    border:none;
}
.eventImageUploadGroup>div>div{
    display:flex;
    flex-direction:column;
    height:100%;
}
.eventImageUploadGroup>div>div>p{
    font-size:1.2vw;
    margin-bottom:0.5vw;
    font-weight:600;
}
.eventImageUploadGroup>div .bgUploadBx{
    width:95%;
    
}
.eventImageUploadGroup>div .bgUploadBx form{
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.eventImageUploadGroup>div .bgUploadBx form input{
    width:100%;
    line-height:2vw;
    font-size:0.9vw;
    border:1px solid #eeeeee;
    margin-bottom:0.5vw;
}
.eventImageUploadGroup>div .bgUploadBx form button{
    width:100%;
    height:2.5vw;
    font-size:1vw;
    line-height:2.5vw;
    border-width:1px;
}
.eventImageUploadGroup>div img{
    width:30%;
    height:100%;
    border:1px solid #000000;
}





.eventUploadBtn{
    width:100%;
    padding-bottom:5vw;
    padding-left:5vw;
    padding-right:5vw;
}
.eventUploadBtn button{
    width:100%;
    height:2.8vw;
    border-width:1px;
    font-size:1.2vw;
}





.eventPreviewGroup{
    width:100%;
    height:auto;
}
.eventPreviewGroup>p{
    font-size:1.2vw;
    padding-bottom:3vw;
    margin-left:5vw;
    margin-right:5vw;
    word-break: keep-all;
}
.eventPreviewGroup>p span{
    font-weight:600;
}



.eventLinkImageBx{
    padding-bottom:1vw;
    margin-bottom:1vw;
    margin-left:5vw;
    margin-right:5vw;
    border-bottom:2px dashed #eeeeee;
}
.eventLinkImageBx p{
    font-size:1.2vw;
    color:#b9b9b9;
    margin-bottom:0.5vw;
}
.eventLinkImageBx img{
    width:6vw;
}

.eventPcImageBx{
    width:100%;
    height:auto;
    padding-bottom:1vw;
    margin-bottom:1vw;
    border-bottom:2px dashed #eeeeee;
}
.eventPcImageBx p{
    font-size:1.2vw;
    color:#b9b9b9;
    margin-bottom:0.5vw;
    margin-left:5vw;
}
.eventPcImageBx img{
    width:100%;
}
.eventMobileImageBx{
    height:auto;
    padding-bottom:1vw;
    margin:0 5vw;
    margin-bottom:1vw;
    border-bottom:2px dashed #eeeeee;
}
.eventMobileImageBx p{
    font-size:1.2vw;
    color:#b9b9b9;
    margin-bottom:0.5vw;
}
.eventMobileImageBx img{
    width:480px;
}
.eventApplicantListTable th{
    background: #efefef;
    padding: 5px;
    height: 50px;
}
.eventApplicantListTable td{
    padding: 5px;
    height: 40px;
}
.eventApplicantListTable td:nth-child(1),.eventApplicantListTable td:nth-child(3){
    width: 70px;
    text-align: center;
}
.eventApplicantListTable td:nth-child(2),.eventApplicantListTable td:nth-child(4),.eventApplicantListTable td:nth-child(5),.eventApplicantListTable td:nth-child(7),.eventApplicantListTable td:nth-child(9),.eventApplicantListTable td:nth-child(10){
    width: 140px;
    font-size: 14px;
    text-align: center;
}
.eventApplicantListTable td:nth-child(6){
    display:block;
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap; 
    width: 200px;
    height: 50px; 
    line-height: 40px;
    font-size: 14px;
}
.eventApplicantListTable td:nth-child(6):hover, .eventApplicantListTable td:nth-child(8):hover{
    background: #000;
    color: #fff; 
}
.eventApplicantListTable td:nth-child(9) select{
    width: 100px; height: 30px;
}
.eventApplicantListTable td:nth-child(10) button{
    width: 80px; height: 30px;
}























