.reviewBg{
    width:100%;
    height:56.25vw;
    position: relative;
    
}
.reviewBgWarp{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    clip:rect(0, 100vw, 56.25vw, 0);
    overflow:hidden;
} 
.reviewImage{
    width:100%;
    height:100%;
    background:url(../../../../images/reviewSectionImage1.png) no-repeat 50% 50%;
    background-size:cover;
    /* background-attachment:fixed; */
    position:fixed;
    top:0;
    left:0;
    display:block;
    z-index:-1;
}
.reviewOutFrame{
    width:100%;
    height:100%;
}

.reviewInFrame{
    width:100%;
    height:100%;
    /* padding-left:5.2vw; */
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
.reviewInFrame .reviewTextBox{
    width:35%;
    height:100%;
    /* display:flex;
    flex-direction: column;
    justify-content:flex-start;
    overflow:hidden; */
    font-size:0;
    padding-top:16vw;
    text-align:right;
    z-index:1;
}
.reviewInFrame .reviewTextBox li.sectMenuText{
    font-size:1.8vw;
    color:#f3f3f3;
    padding-top:0.5vw;
}
.reviewInFrame .reviewTextBox li.contTitleText{
    
    display:inline-block;
    font-size:4vw;
    font-weight:600;
    line-height:4.5vw;
    text-align: right;
    color:#ffffff;
}
.reviewInFrame .ReviewContBox{
    width:65%;
    height:100%;
    overflow:hidden;
    padding-left:8vw;
}

.ReviewContBox .sectInFrame .swiperFrame{
    height:100%;
}
.reviewContFrame{
    padding:11vw 0;

}

.reviewTextGroup{
    width:100%;
    height:100%;
    padding:5vw;
    margin-left:auto;
    background:rgba(255,255,255,0.6);
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.reviewTextGroup div{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    height:85%;
}
.reviewTextGroup p{
    word-break: keep-all;
    display:block;
}
p.reviewTitle{
    font-size:1.3vw; 
    font-weight:600;
    padding-bottom:3vw;
}
p.content::-webkit-scrollbar{
    display:none;
}
p.content{
    font-size:1.1vw; 
    /* padding-bottom:2vw;  */
    line-height:1.8vw;
    overflow:auto;
    -ms-overflow-style:none;
    scrollbar-width:none;
}

p.contentMobile{display:none;
}
p.writer{
    /* height:10%; */
    font-size:1vw;
}





/* .reviewOutFrame{
    width:100%;
    height:56.25vw;
    background:url(../../../../images/reviewSectionImage1.png) no-repeat 50% 50%;
    background-size:cover;
    background-attachment:fixed;
    display:flex;
}

.reviewInFrame{
    width:100%;
    height:100%;
    padding:4.7vw 7.8vw;
    position: relative;
}

.reviewTextBox{
    width:100%;
    position:relative;
    padding-top:15vw;
    padding-right:70%;
    z-index:1;
}
.reviewTextBox h2{
    font-size:4vw;
    font-weight:500;
    color:#ffffff;
    line-height:1;
    padding-bottom:3vw;
}


.ReviewContBox{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    right:0;
}
.ReviewContBox .sectInFrame .swiperFrame{
    height:100%;
}

.reviewContFrame{
    padding:12vw 0;
}

.reviewTextGroup{
    width:54%;
    height:100%;
    padding:5vw;
    margin-left:auto;
    background:rgba(255,255,255,0.6);
}
p.reviewTitle{font-size:1.3vw; padding-bottom:3vw; font-weight:600;}
p.content{font-size:1vw; padding-bottom:2vw; line-height:1.8vw;}
p.contentMobile{display:none;}
p.writer{font-size:0.9vw;} */





