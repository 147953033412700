@media (max-width:1600px){
    .makingPageFrame .setOutput1 div.output1Frame{
        width:35vw;
        height:50vw;
    }
    .headingTextFrame{
        width:35vw;
        height:50vw;
        margin-right:36vw;
    }
    .output2Frame{
        width:35vw;
        height:50vw;
    }
    .adminPreviewBg .moPreviewFrame .bookPageFrame .mobileScene{
        height:50vw;
    }
    
    
}
@media (max-width:1400px){
    .makingPageFrame .setOutput1 div.output1Frame{
        width:40vw;
        height:55vw;
    }
    .headingTextFrame{
        width:40vw;
        height:55vw;
        margin-right:41vw;
    }
    .output2Frame{
        width:40vw;
        height:55vw;
    }
    .adminPreviewBg .pcPreviewFrame .bookPageFrame .headingTextBox{
        margin:9vw 0 5vw !important;
    }
    .adminPreviewBg .moPreviewFrame .bookPageFrame .headingTextBox{
        margin:9vw 0 5vw !important;
    }
    .adminPreviewBg .pcPreviewFrame .bookPageFrame .scene{
        margin:9vw auto 5vw;
    }
    .adminPreviewBg .moPreviewFrame .bookPageFrame .mobileScene{
        height:55vw;
        margin:9vw 0 5vw;
    }
}
@media (max-width:1024px){
    .makingPageFrame .setOutput1 div.output1Frame{
        width:43vw;
        height:60vw;
    }
    .headingTextFrame{
        width:43vw;
        height:60vw;
        margin-right:44vw;
    }
    .output2Frame{
        width:43vw;
        height:60vw;
    }
    .adminPreviewBg .moPreviewFrame .bookPageFrame .mobileScene{
        height:60vw;
    }
}
@media (max-width:834px){

    html{font-size:14px;}

    /* adminDigitalBookFrame */
    .adminDigitalBookFrame h3{
        font-size:2.5vw;
        line-height:6vw;
        margin-bottom:3vw;
    }
    .adminDigitalBookFrame h5{
        font-size:2.3vw;
        padding-bottom:2vw;
    }

    .bookIDFrame{
        margin-bottom:4vw;
    }
    .bookIDFrame>div{
        margin-bottom:1vw;
    }
    .bookIDFrame>div>p{
        font-size:2vw;
        margin-right:1.6vw;
    }
    .bookIDFrame>div>input{
        height:5vw;
        line-height:5vw;
        font-size:1.8vw;
        padding:0 1vw;
    }
    .bookIDFrame>div>div>form{
        padding:1vw 0;
    }
    .bookIDFrame>div>div>form>input{
        font-size:1.8vw;
        line-height:4vw;
    }  
    .bookIDFrame>div>div>form>button{
        height:5vw;
        line-height:5vw;
        font-size:1.8vw;
        padding:0 2vw;
    }
    .bookIDFrame>div>div:nth-last-child(1){
        height:5vw;
    }
    .bookIDFrame>div>div>img{
        margin-right:1vw;
        height:5vw;
        margin-right:1.5vw;
    }
    .bookIDFrame>div>div>span{
        font-size:1.8vw;
        height:5vw;
        line-height:5vw;
        padding:0 2vw;
    }



    /* DigitalbookPageTool */
    .makingPageFrame .setPageTitle{
        margin-bottom:1vw;
    }
    .makingPageFrame .setPageTitle p{
        width:15%;
        height:5vw;
        line-height:5vw;
        font-size:2vw;
        padding-right:0;
    }
    .makingPageFrame .setPageTitle input{
        width:85%;
        height:5vw;
        line-height:5vw;
        padding:0 1vw;
        font-size:1.8vw;
    }

    .makingPageFrame .setIndex{
        margin-bottom:3vw;
    }
    .makingPageFrame .setIndex p{
        width:15%;
        height:5vw;
        line-height:5vw;
        font-size:2vw;
        padding-right:0;
    }
    .makingPageFrame .setIndex div input{
        height:2vw;
        width:2vw;
        margin-right:1vw;
    }
    .makingPageFrame .setIndex div span{
        font-size:1.8vw;
        margin-right:2.5vw;
    }

    .makingPageFrame .setBg{
        margin-bottom:2vw;
        align-items: flex-start;
    }
    .makingPageFrame .setBg p{
        width:15%;
        font-size:2vw;
        padding-right:0;
    }
    .makingPageFrame .setBg div.setBgContent{
        width:85%;
        flex-direction:column;
        align-items:inherit;
    }
    .makingPageFrame .setBg div.setBgContent div.bgColorFrame{
        width:50vw;
        margin-right:0;
        margin-bottom:1vw;
    }
    .makingPageFrame .setBg div.setBgContent div.bgColorFrame>div>div{
        padding:1vw 2vw !important;
    }
    .makingPageFrame .setBg div.setBgContent div.bgColorFrame>div>div>span>div{
        width:3vw !important;
        height:3vw !important;
        margin:0 1vw 0 0 !important;
    }
    .makingPageFrame .setBg div.setBgContent div.bgColorFrame>div>div>div:nth-last-child(3){
        width:3vw !important;
        height:3vw !important;
    }
    .makingPageFrame .setBg div.setBgContent div.bgColorFrame>div>div>div:nth-last-child(2){
        height:3vw !important;
    }
    .makingPageFrame .setBg div.setBgContent div.bgColorFrame>div>div>div>input{
        height:3vw !important;
        width:10vw !important;
        font-size:1.8vw !important;
    }

    .makingPageFrame .setBg div.setBgContent div.bgImageFrame div form input{
        width:30vw;
        line-height:4vw;
        font-size:1.8vw;
    }
    .makingPageFrame .setBg div.setBgContent div.bgImageFrame div form button{
        font-size:1.8vw;
    }
    .makingPageFrame .setBg div.setBgContent div.bgImageFrame button{
        height:5vw;
        line-height:5vw;
        font-size:1.8vw;
        padding:0 2vw;
    }
    .bgUploadBx button{
        padding:0 2vw;
        line-height:5vw;
        margin-right:1vw;
    }
    .makingPageFrame button{
        padding:0 2vw;
        line-height:5vw;
        margin-right:1vw;
    }

    .makingPageFrame .setCont{
        margin-bottom:3vw;
        align-items:inherit;
    }
    .makingPageFrame .setCont p{
        width:15%;
        font-size:2vw;
        padding-right:0;
        line-height:5vw;
    }
    .makingPageFrame .setCont div{
        width:85%;
    }
    .makingPageFrame .setCont div button{
        height:5vw;
        line-height:5vw;
        padding:0 2vw;
        margin-right:1vw;
        margin-bottom:1vw;
        font-size:1.8vw;
    }

    .makingPageFrame .setPage{
        margin-bottom:5vw;
        align-items:inherit;
    }
    .makingPageFrame .setPage p{
        width:15%;
        font-size:2vw;
        padding-right:0;
    }
    .makingPageFrame .setPage .setPageFrame{
        width:85%;
        height:auto;
        flex-direction:column;
        align-items:inherit;
        justify-content:inherit;
    }
    .makingPageFrame .setPage .setPageContent button{
        padding:0 2vw;
        margin-right:1vw;
        margin-bottom:1vw;
        background:#f9f6ff;
        font-size:1.8vw;
        height:5vw;
        line-height:5vw;
    }
    .makingPageFrame .setPage .setPagePager{
        justify-content: flex-end;
    }
    .makingPageFrame .setPage .setPagePager span{
        font-size:2vw;
        height:5vw;
        line-height:5vw;
        padding:0 1.5vw;
    }
    .makingPageFrame .setPage .setPagePager button{
        font-size:3.5vw;
        line-height:5vw;
        padding:0 1vw;
    }

    .makingPageFrame .setCont .setContFrame{
        width:90%;
        height:auto;
    }
    .makingPageFrame .setCont .setPagePager{
        width:30%;
        justify-content: center;
    }
    .makingPageFrame .setCont .setPagePager span{
        font-size:2vw;
        padding:0 1vw;
    }
    .makingPageFrame .setCont .setPagePager button{
        padding:0 1vw;
        font-size:3vw;
        line-height:2.5vw;
    }
    /*output*/
    .makingPageFrame .setOutput1{
        flex-direction: column;
        width:100%;
        padding:0 5.2vw;
    }
    .makingPageFrame .setOutput1 div.output1Frame{
        width:100%;
        height:50vh;
    }

    .makingPageFrame .setOutput2{
        width:100%;
        height:70vh;
    }
    .makingPageFrame .setOutput2>form{
        width:100%;
        height:100%;
    }
    .makingPageFrame .setOutput2>form>div{
        width:100%;
        height:100%;
    }




    
    .makingPreviewBtn div{
        padding:0 2vw;
        height:5vw;
        line-height:5vw;
        font-size:1.8vw;
    }










    .output1Frame{
        width:100%;
        height:100%;
        margin-right:0;
        margin-bottom:3vw;
    }
    /* headingTextFrame */
    .headingTextFrame{
        width:100%;
        height:100%;
        font-size:3vw;
        padding:3vw;
    }
   




    /* DigitalbookPageList */
    .output2Frame{
        width:100%;
        height:86vh;
    }
    .setOutput2>form>div>div:nth-child(2){
        width:70vw;
        height:100%;
        border:1px solid #000000;
    }


    .makingPaperFrame{
        padding:2vw;
    }
    .bookContFrame>p{
        /* font-size:1.8vw; */
        /* line-height:2.5vw; */
        padding:1vw 0;
    }
    .bookContFrame>textarea{
        /* font-size:1.8vw; */
        /* line-height:2.5vw; */
        
        padding:1vw;
    }
    .bookContFrame>span>span{
        /* font-size:2vw; */
        width:4vw;
        height:4vw;
        line-height:4vw;
    }
    .bookContFrame>input{
        height:5vw;
        /* font-size:1.8vw; */
        line-height:5vw;
        padding:0 1vw;
    }
    .imageFrame>p{
        /* font-size:1.8vw; */
        height:auto;
    }
    .imageFrame>div>span{
        /* font-size:1.8vw; */
        line-height:4vw;
    }
    .emptySpaceFrame{
        height:4vw;
    }

    .makingPreviewBtn{
        flex-direction: row;
    }
    .adminPreviewBg .moPreviewFrame .bookPageFrame .headingTextBox{
        margin:9vw 5.2vw 17vw !important;
        -webkit-width:calc(100% - 10.4vw);
        width:calc(100% - 10.4vw) !important;
        -webkit-height:calc(100% - 17vw);
        height:calc(100% - 17vw);
    }

    .adminPreviewBg .moPreviewFrame .bookPageFrame .mobileScene{
        height:100%;
        padding:0 5.2vw 17vw;
    }
    .adminPreviewBg .utilityBtnGroup{
        bottom:1vw;
    }
    .adminPreviewBg .utilityBtnGroup .headingBtnBox{
        left:5.2vw;
    }
    .adminPreviewBg .utilityBtnGroup .bookBtnBox{
        right:5.2vw;
    }






    .managementFrameTitle{
        margin:0 10vw 3vw;
        line-height:8vw;
    }
    /* .managementFrameTitle h5{
        font-size:2.5vw;
    } */
    .managementFrameTitle>span{
        /* font-size:2.3vw; */
        line-height:8vw;
    }

    .managementFrame>div{
        margin-bottom:1.5vw;
    }

    .managementFrame>div>span>span{
        width:8vw;
        height:8vw;
        padding:1.5vw;        
        margin-right:2vw;
    }
    .managementFrame>div>span>p{
        /* font-size:2vw; */
        line-height:3vw;
    }
    .managementFrame>div>div{
        /* font-size:3vw; */
        bottom:1.5vw;
    }
















}



@media (max-width:480px){
    html{font-size:10px;}


    /* adminDigitalBookFrame */
    .adminDigitalBookFrame h3{
        font-size:4vw;
        line-height:12vw;
        margin-bottom:5vw;
    }
    .adminDigitalBookFrame h5{
        font-size:3.8vw;
        padding-bottom:4vw;
    }

    .bookIDFrame{
        margin:0 10vw;
        margin-bottom:5vw;
    }
    .bookIDFrame>div{
        width:100%;
        margin-bottom:3vw;
        flex-direction: column;
        align-items: inherit;
    }
    .bookIDFrame>div>p{
        font-size:3.5vw;
        margin-right:0;
        margin-bottom:1vw;
    }
    .bookIDFrame>div>input{
        height:8vw;
        line-height:8vw;
        font-size:3vw;
        padding:0 1.5vw;
    }
    .bookIDFrame>div>div>form{
        padding:1vw 0;
    }
    .bookIDFrame>div>div>form>input{
        font-size:3vw;
        line-height:6vw;
        width:70%;
    }  
    .bookIDFrame>div>div>form>button{
        width:30%;
        height:8vw;
        line-height:8vw;
        font-size:3vw;
    }
    .bookIDFrame>div>div:nth-last-child(1){
        height:8vw;
        justify-content: flex-end;
    }
    .bookIDFrame>div>div>img{
        margin-right:1vw;
        height:8vw;

    }
    .bookIDFrame>div>div>span{
        font-size:3vw;
        height:8vw;
        line-height:8vw;
        padding:0 8vw;
    }



    /* DigitalbookPageTool */
    .makingPageFrame .setPageTitle{
        margin-bottom:2vw;
        flex-direction:column;
        align-items:inherit;
    }
    .makingPageFrame .setPageTitle p{
        width:100%;
        height:8vw;
        line-height:8vw;
        font-size:3.5vw;
    }
    .makingPageFrame .setPageTitle input{
        width:100%;
        height:8vw;
        line-height:8vw;
        padding:0 1.5vw;
        font-size:3vw;
    }

    .makingPageFrame .setIndex{
        margin-bottom:4vw;
        flex-direction:column;
        align-items:inherit;
    }
    .makingPageFrame .setIndex p{
        width:100%;
        height:8vw;
        line-height:8vw;
        font-size:3.5vw;
    }
    .makingPageFrame .setIndex div input{
        height:4vw;
        width:4vw;
        margin-right:1.5vw;
    }
    .makingPageFrame .setIndex div span{
        font-size:3vw;
        margin-right:3.5vw;
    }

    .makingPageFrame .setBg{
        margin-bottom:2vw;
        flex-direction:column;
        align-items:inherit;
    }
    .makingPageFrame .setBg p{
        width:100%;
        font-size:3.5vw;
        line-height:8vw;
    }
    .makingPageFrame .setBg div.setBgContent{
        width:100%;
    }
    .makingPageFrame .setBg div.setBgContent div.bgColorFrame{
        width:100%;
        margin-bottom:2vw;
    }
    .makingPageFrame .setBg div.setBgContent div.bgColorFrame>div>div{
        padding:2vw 3vw !important;
    }
    .makingPageFrame .setBg div.setBgContent div.bgColorFrame>div>div>span>div{
        width:5vw !important;
        height:5vw !important;
        margin:0 1.5vw 0 0 !important;
    }
    .makingPageFrame .setBg div.setBgContent div.bgColorFrame>div>div>div:nth-last-child(3){
        width:5vw !important;
        height:5vw !important;
    }
    .makingPageFrame .setBg div.setBgContent div.bgColorFrame>div>div>div:nth-last-child(2){
        height:5vw !important;
    }
    .makingPageFrame .setBg div.setBgContent div.bgColorFrame>div>div>div>input{
        height:5vw !important;
        width:16vw !important;
        font-size:3vw !important;
    }

    .makingPageFrame .setBg div.setBgContent div.bgImageFrame div form input{
        width:70%;
        line-height:6vw;
        font-size:3vw;
    }
    .makingPageFrame .setBg div.setBgContent div.bgImageFrame div form button{
        font-size:3vw;
        width:30%;
        height:8vw;
        line-height:8vw;
    }
    .makingPageFrame .setBg div.setBgContent div.bgImageFrame>button{
        width:60%;
        height:8vw;
        line-height:8vw;
        font-size:3vw;
        padding:0 2vw;
    }

    .makingPageFrame .setCont{
        margin-bottom:3vw;
        flex-direction:column;
        align-items:inherit;
    }
    .makingPageFrame .setCont p{
        width:100%;
        font-size:3.5vw;
        line-height:8vw;
    }
    .makingPageFrame .setCont div{
        width:100%;
    }
    .makingPageFrame .setCont div button{
        height:8vw;
        line-height:8vw;
        padding:0 4vw;
        margin-right:1.5vw;
        margin-bottom:1.5vw;
        font-size:3vw;
    }

    .makingPageFrame .setPage{
        margin-bottom:6vw;
        flex-direction:column;
        align-items:inherit;
    }
    .makingPageFrame .setPage p{
        width:100%;
        font-size:3.5vw;
        line-height:8vw;
    }
    .makingPageFrame .setPage .setPageFrame{
        width:100%;
    }
    .makingPageFrame .setPage .setPageContent button{
        padding:0 4vw;
        margin-right:1.5vw;
        margin-bottom:1.5vw;
        font-size:3vw;
        height:8vw;
        line-height:8vw;
    }
    .makingPageFrame .setPage .setPagePager span{
        font-size:3.5vw;
        height:8vw;
        line-height:8vw;
        padding:0 2.5vw;
    }
    .makingPageFrame .setPage .setPagePager button{
        font-size:4vw;
        line-height:8vw;
        padding:0 2vw;
    }





    /* output */
    .makingPageFrame .setOutput1 div.output1Frame{
        height:80vh;
    }
    .makingPageFrame .setOutput2{
        height:100vh;
    }
    .makingPageFrame .setOutput2>form>div{
        flex-direction: column-reverse;
    }




    .makingPreviewBtn div{
        padding:0 3vw;
        height:8vw;
        line-height:8vw;
        font-size:3vw;
    }






    /* headingTextFrame */
    /* .headingTextFrame{
        width:100%;
        height:100%;
    }
    */
    /* DigitalbookPageList */
    .output2Frame{
        width:100%;
        height:80vh;
    }
    .setOutput2>form>div>div:nth-child(2){
        width:100%;
        height:70vh;
    }
    .bookContFrame>p{
        /* font-size:3vw; */
        /* line-height:4.5vw; */
        padding:1.5vw 0;
    }
    .bookContFrame>textarea{
        /* font-size:3vw; */
        line-height:5vw;
        padding:1.5vw;
    }
    .bookContFrame>span>span{
        font-size:3vw;
        width:7vw;
        height:7vw;
        line-height:7vw;
    }
    .bookContFrame>input{
        height:8vw;
        /* font-size:3vw; */
        /* line-height:8vw; */
        padding:0 1.5vw;
    }
    .imageFrame>p{
        font-size:3vw;
        height:2vw;
    }
    .imageFrame>div>span{
        font-size:3vw;
        line-height:4.5vw;
    }
    .emptySpaceFrame{
        height:4.5vw;
    }

    .adminPreviewBg .moPreviewFrame .bookPageFrame .headingTextBox{
        -webkit-width:calc(100% - 10.4vw);
        width:calc(100% - 10.4vw);
        -webkit-height:calc(100% - 17vw);
        height:calc(100% - 17vw);
        margin:9vw 5.2vw !important;
    }
    .adminPreviewBg .utilityBtnGroup{
        bottom:12vw;
    }








    .managementFrameTitle{
        margin:0 10vw 5vw;
        line-height:12vw;
    }
    .managementFrameTitle h5{
        font-size:6vw;
        line-height:12vw;
    }
    .managementFrameTitle>span{
        font-size:5vw;
        line-height:12vw;
    }

    .managementFrame>div{
        margin-bottom:2.5vw;
    }

    .managementFrame>div>span>span{
        width:12vw;
        height:12vw;
        padding:2vw;        
        margin-right:3vw;
    }
    .managementFrame>div>span>p{
        width:55vw;
        font-size:4vw;
        line-height:5vw;
        padding:2vw 0;
    }
    .managementFrame>div>div{
        font-size:6vw;
        bottom:2vw;
    }





}


