.authReaderFrame{
    padding:0 5.2vw 0;
    height:auto;
    width:100%;
}
.bookTop{
    width:100%;
    height:100%;
    /* padding:0 5.2vw; */
    margin:0 auto;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    margin-top:2vw;
}

.booInfoGroup{
    display:flex;
    flex-direction:row;
    align-items: center;
}
.booInfoGroup .iconFrame{
    width:3vw;
    height:3vw;
    border:1px solid #000000;
    /* border-radius:2vw */
    overflow:hidden;
}
.booInfoGroup .iconFrame img{
    height:100%;
    margin-left:50%;
    margin-top:50%;
    transform:translate(-50%, -50%);
}
.booInfoGroup span{
    font-size:1.2vw;
    padding:0 2vw 0 1vw;
    word-break: keep-all;
}




.linkGroup span{
    display:inline-block;
    font-size:1.2vw;
    text-decoration: none;
    color:#000f1e;
    padding:0 1.5vw;
    border:1px solid #000f1e;
    height:3vw;
    line-height:3vw;
}
.linkGroup span:nth-of-type(1){
    background:#000f1e;
    color:#ffffff;
}
.linkGroup span:active{
    border:1px solid rgb(8, 46, 77) !important;
    background:rgb(8, 46, 77) !important;
    color:#ffffff !important;  
}



.bookBottom{
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    height:3vw;
}
.bookBottom button{
    font-size:1.2vw;
    padding:0 2vw;
    line-height:3vw;
    height:100%;
    border:1px solid #000f1e;
    background:#ffffff;
}
.bookBottom a{
    font-size:1.2vw;
    padding:0 2vw;
    line-height:3vw;
    border:1px solid #000f1e;
    color:#ffffff;
    text-decoration: none;
    height:100%;
    background:#000f1e;
}
.bookBottom button:hover{
    background:#000f1e;
    color:#ffffff;
}

@media (max-width:834px){
    .bookTop{
        margin-top:5vw;
        align-items: inherit;
        
    }
    .booInfoGroup {
        display:flex;
        flex-direction: column;
        align-items: inherit;
    }
    .booInfoGroup .iconFrame{
        width:6vw;
        height:6vw;
    }
    .booInfoGroup span{
        font-size:2vw;
        padding:2vw 0;
    }
    .booInfoGroup button{
        font-size:2vw;
        padding:0 1.5vw;
        line-height:4.5vw;
    }

    
    .linkGroup span{
        font-size:2vw;
        padding:0 2vw;
        height:6vw;
        line-height:6vw;
    }


    .bookBottom{
        height:6vw;
        margin-top:6vw;
        position:inherit;
        bottom:inherit;
        right:inherit;
    }
    .bookBottom button{
        font-size:1.8vw;
        line-height:6vw;
    }
    .bookBottom a{
        font-size:1.8vw;
        line-height:6vw;
    }
    
}
@media (max-width:480px){
    .bookTop{
        display: inherit;
        flex-direction:inherit;
        align-items: inherit;
        position:relative;
        margin-top:5vw;
    }
    .booInfoGroup .iconFrame{
        width:8vw;
        height:8vw;
    }
    .linkGroup{
        position:absolute;
        top:0;
        right:0;
        text-align:right;
        margin-bottom:2vw;
    }
    .linkGroup span{
        font-size:3vw;
        padding:0 3vw;
        height:8vw;
        line-height:8vw;
        cursor: pointer;
    }
    .booInfoGroup{
        flex-direction:column;
        align-items: inherit;
    }
    .booInfoGroup .iconFrame{
        margin-bottom:4vw;
    }
    .booInfoGroup span{
        font-size:4vw;
        padding:0;
        padding-bottom:4vw;
    }
    .booInfoGroup button{
        font-size:4vw;
        padding:0 1.5vw;
        width:50%;
        line-height:8vw;
        display:inline-block;
        /* margin-left:auto; */
    }

    .bookBottom{
        width:100%;
        height:8vw;
        margin-top:14vw;
    }
    .bookBottom button{
        font-size:3vw;
        line-height:8vw;
    }
    .bookBottom a{
        font-size:3vw;
        line-height:8vw;
    }
}