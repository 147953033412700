.frame{
    width: 100%;
    padding:0 5.2vw;
    margin:0 auto;
}


.xrbookExplain{
    padding-top:3.6vw;
    padding-bottom:3.6vw;
}
#INTRO{
    height:100vh;
    overflow:hidden;
}
.priContFrame>section{
    /* height:56.25vw;  */
    display:block; 
}
.priContFrame>section#CONTACT{height:auto;}
.priContFrame>section:after{content:""; display:block; clear:both;}








/* .sectOutFrame{
    width:100vw;
    height:56.25vw;
} */
.sectInFrame{
    width:100%;
    height:100%;
    position:relative;
}
.sectTextBox{
    padding-top:15vw;
    padding-left:7.8vw;
    position:absolute;
    top:0;
    left:0;
    z-index:3;
}
.sectTextBox h2{
    font-size:4.2vw;
    color:#000f1e;
    line-height:1;
    padding-bottom:3vw;
}
.xrsOutFrame .sectTextBox h2{
    color:#ffffff;
}
.sectTextBox .pagerText{
    font-size:1.1vw;
    color:#000f1e;
    line-height:2vw;
    cursor: pointer;
}

.sectTextBox .btnGroup a{
    text-decoration:none;
    width:auto;
    height:3vw;
    padding:0 2vw;
    margin-top:1.5vw;
    display:inline-block;
    font-size:1.1vw;
    font-weight:500;
    line-height:3vw;
    text-align:center;
    border-radius: 5px;
    
}
.sectTextBox .btnGroup a.douwmloadBtn{
    /* border:1px solid #000f1e; */
    color:#000f1e;
    cursor: pointer;
    font-weight:600;
    box-shadow: -2px -2px 5px rgba(247, 250, 255, 0.9),
    3px 3px 5px rgba(212, 212, 212, 0.5);
}
.sectTextBox .btnGroup a.orderBtn{
    /* border:1px solid #000f1e; */
    color:#000f1e;
    cursor: pointer;
    font-weight:600;
    box-shadow: -2px -2px 5px rgba(248, 248, 248, 0.9),
    3px 3px 5px rgba(136, 136, 136, 0.5);
}
.sectTextBox .btnGroup a.contactBtn{
    /* border:1px solid #000f1e; */
    color:#ffffff;
    cursor: pointer;
    font-weight:600;
    box-shadow: -2px -2px 5px rgba(38, 64, 75, 0.9),
    3px 3px 5px rgba(0, 11, 20, 0.5);
}

.sectTextBox .btnGroup a:hover{
    color:#ffffff;
    background:#000f1e;
    
}



.swiper-button-prev{
    /* left:40% !important; */
    top:90% !important;
    color: #ffffff !important;
    outline: none;
    
}
.swiper-button-next{
    right:4.2vw !important;
    top:90% !important;
    color: #ffffff !important;
    outline: none;
}
.sectContGroup{
    width:100%;
    /* width:100vw !important; */
    height:56.25vw !important;
    margin-right:0 !important;
}
.sectConText{
    position: absolute;
    top:60%;
    left:7.8vw;
    color:#000000;
    z-index: 1;
    width:40vw;
    padding-bottom:20vw;
    font-size:1vw; 
}
.sectConTextMobile{display:none;}
.xrsOutFrame .sectConText{
    top:68%;
    color:#ffffff
}
.pcImage{
    display:block;
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: 0;
    /* border:1px solid red; */
}
.mobileImage{
    display:none;
}








#mainLoading{
    width:100vw;
    height:100vh;
    position:fixed;
    left:0;
    top:0;
    color:#ffffff;
    background: linear-gradient(45deg, #1c263e, #144a67);
    overflow:hidden;
}
/* #mainLoading>ul{
    width:100vw; 
    height:100vh;    
    display:flex;
    flex-direction: column;
    justify-content:center;
    padding:8vw 0 6vw;
    text-align: center;
}
#mainLoading ul li{
    font-weight:800;
    letter-spacing:1px;
}
#mainLoading ul li.mainTitle1{
    font-size:6vh;
    padding:2vw 0 1vw;
    letter-spacing:3px;
    word-break: keep-all;  
}
#mainLoading ul>span{
    width:25vw;
    height:2px;
    background:#ffffff;
    margin:1vw 0;  
}
#mainLoading ul li.mainTitle2{
    font-size:4vh;
    font-weight:600;
}
#mainLoading ul li.subTitle{
    font-weight:500;
    padding-top:0.5vw;
}
#mainLoading ul li.subTitle>span:nth-child(1){
    font-size:3vh;
    line-height:2vw;  
}
#mainLoading ul li.subTitle>span:nth-child(2){
    font-size:3vh;
    line-height:2vw;
} */

.loadingCont{
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    display:flex;
    flex-direction: column;
    justify-content:space-between;
    width:100%;
}
.loadTitle{
    width:100%;
    font-size:4vh;
    line-height:10vh;
    text-align:center;
}
.loadText{
    text-align:center;
    font-size:2vh;
    font-weight:300;
    line-height:5vh;
}
.imgGroup{
    position: relative;
    width:100%;
    height:25vw;
}
.moving{
    width:100%;
    height:100%;
    padding:2vw;
    background:url("../../../images/littlePrinceWhite.png") no-repeat 50% 50%;
    background-size: contain;
    position: absolute;
    left:50%;
    bottom:0;
    transform:translate(-50%);
    animation: 2s knight linear infinite;
    z-index:5;
}

.shadow {
    height: 5px;
    width: 40px;
    background: #000;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    bottom:0;
    transform: translatex(-50%);
    animation: 2s shadow linear infinite;
    display:none;
}
@keyframes knight {
    0%,100% {
      bottom: 0;
    }
    50% {
      bottom: 20px;
    }
  }
  @keyframes shadow {
    0%,100% {
      width: 40px;
    }
    50% {
      width: 10px;
    }
  }




