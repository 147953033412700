.orderFrame{
    width:100%; 
    min-height:100vh; 
    padding:15vw 5.2vw 0;
    background:#f0f0f0;
}


.linkFrame{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding:5vw;
    padding-top:0;
    height:40vw;
    width:100%;
}
.linkFrame>div{
    width:50%;
    padding:5vw;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    background:#ffffff;
    border:1px solid #959595;
}
.linkFrame>div:first-child{
    border-right:none;
}
.linkFrame>div>.linkTextGroup{
    width:100%;
}
.linkFrame>div>.linkTextGroup>h5{
    padding-bottom:2vw;
    color:#000f1e;
    font-size:2vw;
    text-align:center;
    border-bottom:1px solid #000000;
    padding-bottom:2vw;
    margin-bottom:3vw;
}
.linkFrame>div>.linkTextGroup>span{
    width:10vw;
    height:1px;
    background:#000f1e;
    margin:1vw 0;
}
.linkFrame>div>.linkTextGroup>p{
    font-size:1.2vw;;
    line-height:2vw;
    word-break: keep-all;
    text-align:center;
}
.linkFrame>div>.linkBtn{
    height:4vw;
    color:#000f1e;
    font-size:1.2vw;
    line-height:4vw;
    text-align:center;
    border-radius: 0;
    border:none;
    border:1px solid #000f1e;
    margin:0;
    cursor: pointer;
}
.linkFrame>div>.linkBtn:hover{
    background:#000f1e;
    color:#ffffff;
}
.linkFrame>div>.linkBtn:active{
    border:1px solid rgb(8, 46, 77) !important;
    background:rgb(8, 46, 77) !important;
    color:#ffffff !important;
}






.tabFrame{
    width:100%;
    height:auto;
    /* border:1px solid red; */   
}

.tabMenuFrame{
    width:100%;
    height:4.5vw;
    /* border:1px solid yellow; */
    background:rgba(255,255,255,0.5);
}
.tabMenuFrame{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
}
.tabMenuFrame li{
    padding:0 3vw;
    /* border:1px solid yellow; */
    text-align:center;
    line-height:4.5vw;
    font-size: 1.3vw;
    cursor: pointer;
    /* font-weight:600; */
}
.tabMenuFrame li.order_show{
    background:rgba(255,255,255,1);
    font-weight:600;
}


.contFrame{
    background:rgba(255,255,255,255);
    padding:5vw 4.8vw 10vw;
}





.orderIntroFrame{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    
}



.orderIntroFrame>.rightFrame{
    width:35%;
    padding-right:3vw;
}
.orderIntroFrame h5{
    font-size:1.8vw;
    color:#000f1e;
    line-height:4vw;
    padding-bottom:0.5vw;
}
.orderIntroFrame>.rightFrame>.orderMainImgBx{
    width:100%;
    height:auto;
    border:1px solid #959595;
    overflow:hidden;
}
.orderIntroFrame>.rightFrame>.orderMainImgBx>img{
    width:100%;
}
.orderIntroFrame>.rightFrame>.thumbGroup{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top:1vw;
    height:55px;

}
.orderIntroFrame>.rightFrame>.thumbGroup>.thumbBtn{
    border: 1px solid #959595;
    font-size:1vw;
    line-height:55px;
    text-align:center;
    width:32%;
    cursor: pointer;
}
.orderIntroFrame>.rightFrame>.thumbGroup>.thumbBtn:hover{
    background:#dedede;
}
.orderIntroFrame>.rightFrame>.thumbGroup:nth-child(4)>.thumbBtn:hover{
    border:1px solid rgb(8, 46, 77) !important;
    background:rgb(8, 46, 77) !important;
    color:#ffffff !important;
}
.orderIntroFrame>.leftFrame{
    width:65%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.orderIntroFrame>.leftFrame>h5{
    border-bottom:1px solid #dedede;
}
.orderIntroFrame>.leftFrame>ul>li{
    width:100%;
    height:2vw;
    line-height:2vw;
    margin-bottom:0.5vw;
    font-size:1vw;
}
.orderIntroFrame>.leftFrame>ul>li>span{
    display:inline-block;
    font-size:1vw;
   
}
.orderIntroFrame>.leftFrame>ul>li>span.cate{
    font-weight:600;
    width:18%;
    cursor: default;
    line-height:1.5vw;
}
.orderIntroFrame>.leftFrame>ul>li>span.cont{
    height:2vw;
}
.orderIntroFrame>.leftFrame>ul>li>span.cont>.productQuantity>input[type='number']{
    width:10vw;
    height:2vw;
    font-size:1vw;
    padding-right: 1vw;
    text-align: right;
    border-width:1px;
}
.orderIntroFrame>.leftFrame>.applyBtn{
    width:30%;
    height:55px;
    background:#000f1e;
    color:#ffffff;
    font-size:1.2vw;
    line-height:55px;
    text-align:center;
    border-radius: 0;
    border:none;
    margin:0;
    cursor: pointer;
}
.orderIntroFrame>.leftFrame>.applyBtn:active{
    border:1px solid rgb(8, 46, 77) !important;
    background:rgb(8, 46, 77) !important;
    color:#ffffff !important; 
}
.orderIntroFrame>.leftFrame>ul>li>span.cont>select,
.orderIntroFrame>.leftFrame>ul>li>span.cont>input{
    height:100%;
    font-size:1vw;
    padding:0 0.5vw;
    display:inline-block;
    border-radius: 0;
}
.orderIntroFrame>.leftFrame>ul>li.programPrice{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    margin-top:1.5vw;
    height:auto;
}
.orderIntroFrame>.leftFrame>ul>li.programPrice>span.cont{
    font-size:1.5vw;
}
.orderIntroFrame>.leftFrame>ul>li.programPrice>button{
    font-size:1vw;
    margin-left:4vw;
    padding:0 2vw;
    height:3vw;
    line-height:3vw;
    cursor: pointer;
    border-width:1px;
    border-radius: 0;
}
.orderIntroFrame>.leftFrame>ul>li.checkInputBx{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;    
}
/* .orderIntroFrame>.leftFrame>ul>li.rentDevice{
    margin-bottom:1vw;
} */
.orderIntroFrame>.leftFrame>ul>li.checkInputBx>span.cont{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;    
}
/* .orderIntroFrame>.leftFrame>ul>li:nth-child(4){
    height:2vw;
} */
.orderIntroFrame>.leftFrame>ul>li:nth-child(6){
    height:2vw;
}
.orderIntroFrame>.leftFrame>ul>li.guideText>span.cont{
    margin-left:15%;
    font-size:1vw;
    height:auto;
    word-break: keep-all;
    line-height:1.5vw;
    flex-direction: column;
    align-items: inherit;
}

.orderIntroFrame>.leftFrame>ul>li.checkInputBx>span.cont>span{
    padding-right:1.5vw;
    display:flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
.orderIntroFrame>.leftFrame>ul>li.checkInputBx>span.cont>span:nth-last-child(1){
    padding-right:0;
}
.orderIntroFrame>.leftFrame>ul>li.guideText>span.cont>span{
    display:inherit;
    align-items: inherit;
    background:#000f1e;
    color:#ffffff;
    padding:0 0.5vw !important;
    line-height:2vw;
    margin-top:0.5vw;
}

.orderIntroFrame>.leftFrame>ul>li.checkInputBx>span.cont>span input{
    margin:0;
}

.orderIntroFrame>.leftFrame>ul>li>ul.bookPublishing{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items:center;
}
.orderIntroFrame>.leftFrame>ul>li>span.cont>span.productQuantity[type="number"]::-webkit-outer-spin-button,
.orderIntroFrame>.leftFrame>ul>li>span.cont>span.productQuantity>input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    border-radius: 0;
}
.orderIntroFrame>.leftFrame>ul>li>ul.bookPublishing>li.productSelect{
    display:flex;
    flex-direction: row;
    align-items: center;
}
.orderIntroFrame>.leftFrame>ul>li>ul.bookPublishing>li.productSelect>span{
    display:flex;
    flex-direction: row;
    align-items: center;
}
.orderIntroFrame>.leftFrame>ul>li>ul.bookPublishing>li.productSelect>span input{
    margin:0;
}
.orderIntroFrame>.leftFrame>ul>li>ul.bookPublishing>li.productSelect>span>span{
    padding-right:2vw;
    font-size:1vw;
}
.orderIntroFrame>.leftFrame>ul>li>ul.bookPublishing>li.productQuantity{
    font-size:1vw;
    line-height:2vw;
}
.orderIntroFrame>.leftFrame>ul>li>ul.bookPublishing>li.productQuantity input{
    width:10vw;
    height:2vw;
    font-size:1vw;
    padding:0 1vw;
    text-align:right;
}
.plusMinusBtn{
    height:2vw;
    padding:0 1vw;
    border:1px solid #000000;
    display:inline-block;
    cursor: pointer;
}

.plusMinusBtn:active{
    border:1px solid rgb(8, 46, 77) !important;
    background:rgb(8, 46, 77) !important;
    color:#ffffff !important;
}
.plusMinusBtn:nth-child(2){
    margin-left:2vw;
    border-right:none;
}

.textGroup{
    display:flex;
    flex-direction:column;
    justify-content: space-between;
    height:80%;
}
.clpTextGroup{
    justify-content: center;
}




form.selectedBox{
    background: #eeeeee;
    padding:2vw;
    margin-top:2vw;
    padding-left:35%;
}
form.selectedBox>p.bxTitle{
    font-weight:600;
    font-size:1.3vw;
}
form.selectedBox>span.horiVar{
    width:100%;
    height:1px;
    background:#959595;
    display:block;
    margin:1vw 0;
}
form.selectedBox>.selProgramBx>li{
    font-size:1vw;
    padding:0.5vw 1vw;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
form.selectedBox>.selProgramBx>li>span:nth-child(2){
    cursor: pointer;
}
form.selectedBox>.orderConfirm{
    margin-left:auto;
    text-align:right;
    margin-bottom:2vw;
}
form.selectedBox>.orderConfirm>li>span{
    font-size: 1.5vw;
}
form.selectedBox>.orderConfirm>li>span.cate{
    font-weight:600;
}
form.selectedBox>.orderConfirm>li>span.cont{
    display:inline-block;
    min-width:15%;
}
form.selectedBox>p:nth-child(6){
    font-size:0.9vw;
    text-align:right;
}
form.selectedBox>.orderBtnGroup{
    margin:1vw 0;
    margin-left:auto;
    font-size:0;
    height:3vw;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
}
form.selectedBox>.orderBtnGroup>div{
    width:40%;
    height:100%;
    color:#ffffff;
    background:#222222;
    border:1px solid #000000;
    display:inline-block;
    line-height:3vw;
    text-align:center;
    text-decoration: none;
    font-size:1.2vw;
    margin-right:2vw;
}
form.selectedBox>.orderBtnGroup>div:active{
    border:1px solid rgb(8, 46, 77) !important;
    background:rgb(8, 46, 77) !important;
    color:#ffffff !important;  
}
form.selectedBox>.orderBtnGroup>button{
    width: 40%;
    height:100%;
    font-size:1.2vw;
    line-height:3vw;
    text-align:center;
    border-radius: 0;
    /* border:none; */
    border-width:1px;
    margin:0;
    cursor: pointer;
}





.orderTabGroup{
    width:100%;
    height:6vw;
    margin-bottom:2vw;
    margin-top:8vw;
    display: flex;
    font-size:0;
}
.orderTabGroup>li{
    font-size: 1.5vw;
    width:33.3%;
    height:100%;
    /* display:inline-block; */
    text-align:center;
    border:1px solid #ebebeb;
    background:#dedede;
    color:#ffffff;
    cursor: pointer;
    line-height:6vw;
}
.orderTabGroup>li.activeBtn{
    background:#ffffff;
    border-bottom:none;
    color:#000000;
    font-weight:600;
}

.orderTabCont{
    width:100%;
    height:auto;
    /* position:relative; */
}
.orderTabCont>div{
    /* position:absolute;
    top:0;
    left:0; */
    width:100%;
    height:100%;  
}
.orderTabCont>div.orderTab01{
    width:100%;
    padding-top:3vw;

}
.orderTabCont>div.orderTab01>img{
    width:100%;
}
.orderTabCont>div.orderTab01>img.contMobileImage{
    display:none;
}
.orderTabCont>.orderTab01>textarea{
    resize: none; 
}
.orderTabCont>.orderTab01>.se-resizing-bar{
    display: none; 
}
/* .orderTabCont>div.orderTab02{
    background:#c56e6e;
} */

.orderTab02{
    padding:2vw;
    
}
.orderTab02>ul{
   width:100%; 
   height:100%;

}
.orderTab02>ul>li{
    padding-bottom:2vw;
}
.orderTab02>ul>li>h6{
    font-size:1.6vw;
    padding:3vw 0 1vw;
}
.orderTab02>ul>li>p.queText{
    font-size:1.2vw;
    font-weight:600;
    padding-bottom:0.5vw;
}
.orderTab02>ul>li>p.ansText{
    font-size:1vw;
    line-height:1.5vw;
    padding:0 1.5vw 0;
}

.orderPFrame{
    width:100%;
    height:100%;
    /* border:1px solid red; */
}










.orderPFrame>form{
    padding:1vw 0;
    /* border:1px solid yellow; */
}
.orderPFrame>form>ul{
    width:80%;
    /* border:1px solid orange; */
    font-size:0;
}
.orderPFrame>form>ul>p{
    font-size:0.9vw;
    color:#c56e6e;
    margin-left:30%;
    margin-top:1vw;
}


.orderPFrame>form>ul>li{
    width:100%;
    height:auto;
    /* border:1px solid green; */
    margin-bottom:1vw;
    display:flex;
    flex-direction: row;
    font-size:1vw;
    line-height:2.6vw;
}


.orderPFrame>form>ul>li.checkInputBx{
 display:flex;
 flex-direction: row;
 justify-content: flex-start;
 align-items: center;
}

.orderPFrame>form>ul>li.checkInputBx.tabletRental{margin-top:-1.5vw;}
.bottomBtnGroup{margin-top:3vw;}

.orderPFrame>form>ul>li>span{
    padding:0 1vw;
}
.orderPFrame>form>ul>li.checkInputBx>span{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.orderPFrame>form>ul>li.checkInputBx>span>input{
    margin-right:1vw;
    cursor: pointer;
}
.orderPFrame>form>ul>li.checkInputBx>span>span{
    margin-right:3vw;
    display:flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
.orderPFrame>form>ul>li.checkInputBx>span>span>input{
    margin-right:1vw;
    cursor: pointer;
}

.orderPFrame>form>ul>li>span.cate{
    width:30%;
    height:2.6vw;
    /* border:1px solid pink; */
    display:inline-block;
    text-align:right;
    line-height:2.6vw;
    padding-right:1vw;
    font-size:1vw;
    font-weight:600;
}
.orderPFrame>form>ul>li>span>.phoneNumber{
    margin-left:2vw;
    height:2.6vw;
    padding:0 1vw;
    border-width:1px;
}


.orderPFrame>form>ul>li>select{
    cursor: pointer;

}


.orderPFrame>form>ul>li>select,
.orderPFrame>form>ul>li>input{
    width:70%;
    height:2.6vw;
    /* outline: none; */
    border:none;
    border-bottom:1px solid #000000;
    font-size:1vw;
    padding:0 1vw;
    border-radius: 0;
    /* border:1px solid orange; */
    
}
.orderPFrame>form>ul>li>select>option{
    width:100%;
    height:100%;
 
}
.orderPFrame>form>ul>li>input[type=checkbox]{
    width:0.8vw;
    height:0.8vw;
    margin-right:0.5vw;
    display: block;
    margin-left:0.5vw;
}
.orderPFrame>form>ul>li>span.checkBxName{
    padding:0;
    padding-right:1vw;
}
.orderPFrame>form>ul>.salesInquiryBtn{
    justify-content: center;
    padding: 1vw;
}
.orderPFrame>form>ul>li>button{
    width:360px;
    height:2.9vw;
    line-height:2.9vw;
    background:#000f1e;
    color:#ffffff;
    font-size:1.2vw;
    border-radius: 0;
    border:none;
    
}
.orderPFrame>form>ul>li>textarea{
    font-size:1vw;
    width:70%;
    height:18.2vw;
    display: block;
    resize: none;
    padding:0.5vw 1vw; 
    line-height:1.8vw;
}
.orderPFrame>form>ul>li>textarea::-webkit-scrollbar{
    width:5px;
  }
  .orderPFrame>form>ul>li>textarea::-webkit-scrollbar-thumb{
    background-color:#b3c7db;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
  }
  .orderPFrame>form>ul>li>textarea::-webkit-scrollbar-track{
    background:transparent;
    border-radius:5px;
    box-shadow:inset 0px 0px 3px #ffffff;
  }

.orderPFrame>form>ul>li.policiesCate>span{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.orderPFrame>form>ul>li.policiesCate>span.cate{
    display:inline-block;
}
.orderPFrame>form>ul>li.policiesCate>span>input{
    margin-right:1vw;
}
.orderPFrame>form>ul>li.policiesCate>span>span:nth-child(2){
    margin-right:3vw;
}
.orderPFrame>form>ul>li>span>span.policiesLink a{
    text-decoration: underline;
    color:#000000;
    

}


.bottomBtnGroup>div{
    margin-left:30%;
    height:2.9vw;
    line-height:2.9vw;
    border:1px solid #000f1e;
    width:200px;
    text-align: center;
    cursor: pointer;
    font-size:1.2vw;
}
.bottomBtnGroup>div:active{
    border:1px solid rgb(8, 46, 77) !important;
    background:rgb(8, 46, 77) !important;
    color:#ffffff !important; 
}






.orderPFrame>.elearningApplyForm{
    border:1px solid #dedede;
    margin-top:8vw;
    font-size:1vw;
}
.orderPFrame>.elearningApplyForm>h6{
    text-align:center;
    padding:10vw 0 6vw;
    font-size:1.5vw;
}
.orderPFrame>.elearningApplyForm>ul{
    width:60%;
    margin:0 auto;
}
.orderPFrame>.elearningApplyForm>ul>li{
    width:auto;
}
.orderPFrame>.elearningApplyForm>ul>li:nth-child(6){
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: inherit;
    align-content: center ;
}
.orderPFrame>.elearningApplyForm>ul>li>span{
    padding:0;
}
.orderPFrame>.elearningApplyForm>ul>li>span.cate{
    width:20%;
    
}
.orderPFrame>.elearningApplyForm>ul>li:nth-child(6)>span>input{
    height:40px;
}

.orderPFrame>.elearningApplyForm>ul>li>select, 
.orderPFrame>.elearningApplyForm>ul>li>input,
.orderPFrame>.elearningApplyForm>ul>li>textarea{
    width:70%;
}
.orderPFrame>.elearningApplyForm>ul>li>button{
    width:40%;
    border-radius: 0;
}
.orderPFrame>.elearningApplyForm>ul>li>ul.bookPublishing>li{
    display: flex;
    flex-direction:row;
    align-items:center;
    font-size:1vw;
    line-height:2.6vw;
}
.orderPFrame>.elearningApplyForm>ul>li>ul.bookPublishing>li.productSelect>input{
    margin-right:1vw;
    cursor: pointer;
}
.orderPFrame>.elearningApplyForm>ul>li>ul.bookPublishing>li.productSelect>span{
    padding-right:3vw;
    cursor: pointer;
}
li.productQuantity>input[type="number"]::-webkit-outer-spin-button,
li.productQuantity>input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    border-radius: 0;
}
.orderPFrame>.elearningApplyForm>ul>li>ul.bookPublishing>li.productQuantity input{
    padding:0 1vw;
    height:2vw;
    text-align:right;
    width:10vw;
    font-size:1vw;
    border-width:1px;
}
.orderPFrame>.elearningApplyForm>ul>li>ul.bookPublishing>li.productQuantity>span{
    line-height:2vw;
    height:100%;
    padding:0 1vw;
    font-size:1.5vw;
    display:inline-block;
}
/* .orderPFrame>.elearningApplyForm>ul>li:nth-child(6)>span.productQuantity>span.plusMinusBtn{
    display:inline-block;
    height:40px;
    line-height:40px;
    padding:0 1vw;
    margin-right:0;
} */
.orderPFrame>.elearningApplyForm>ul>li:nth-child(10){
  margin-left:20%;
  margin-top:3vw;
  margin-bottom:10vw;
}





















.paymentContFrame{
    width:100%; 
    height:100%;
    
}
.paymentContFrame .authInfo,
.paymentContFrame .programInfo,
.paymentContFrame .orderInfo{
    padding:2vw 0;
    border-bottom:1px solid #dedede;
    
}
.paymentContFrame .orderInfo{
    margin-bottom:2vw;
}
.paymentContFrame>ul>li{
    width:100%;
    height:auto;
    margin-bottom:1vw;
    display:flex;
    flex-direction: row;
    line-height:2.9vw;
    /* border:1px solid green; */
    font-size:1vw;
}
.paymentContFrame ul li input, 
.paymentContFrame>ul>li button{
    height:2.9vw;
    line-height:2.9vw;
    margin-right:0.5vw;
    font-size:1vw;
    border:1px solid #000000;
    border-radius: 0;
}
.paymentContFrame ul li input{padding:0 0.5vw;}
.paymentContFrame ul li button{padding:0 1vw; border-radius: 0; }

.paymentContFrame span{
    line-height: 2.9vw;
    font-size: 1vw;
}
.paymentContFrame span.cate{
    width: 20%;
    height: 2.9vw;
    /* border: 1px solid pink; */
    display: inline-block;
    text-align: right;
    line-height: 2.9vw;
    padding: 0 1vw;
    padding-right: 1vw;
    font-size: 1vw;
    font-weight: 600;
}


.paymentContFrame ul li .guideText{
    display:flex; 
    flex-direction: row;
}
.uploadInputGroup{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
}
.uploadInputGroup li{
    height:2.9vw;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size:1vw;
    margin-bottom:0.5vw;
}
.uploadInputGroup>li.guideTextGroup{
    display:flex;
    flex-direction: column;
    align-items: inherit;
    align-content: inherit;
    line-height: 1.5vw;
    height:auto;
    /* border:1px solid yellow; */
    color: #c56e6e;
    font-size:0.8vw;
    word-break: keep-all;
}
.uploadInputGroup>li.guideTextGroup p:nth-last-child(1){
    color:#000000;
}
.uploadInputGroup>li.guideTextGroup p:nth-last-child(1) span{
    color:#007bff;
    font-size:0.8vw;
    text-decoration:underline;
    cursor: pointer;
    word-break: keep-all;
}
.uploadInputGroup>li.guideTextGroup p:nth-last-child(1) span:hover{
    color:#c56e6e;
}
.uploadInputGroup>li>div{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.uploadInputGroup>li>div>span{
    font-size:0.9vw;
    margin-right:0.5vw;
    line-height:2.9vw;
}
.uploadInputGroup>li form input[type=file]{
    border:0;
    border-radius: 0;
    font-size:0.8vw;
    background:#ffffff;
    line-height:2vw;
    outline: none;
    /* cursor: pointer; */
    width:16vw;
}
/* .uploadInputGroup>li form{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
} */
.uploadInputGroup>li form input{
    height:2.6vw;
    font-size:1vw;
    line-height:2.6vw;
    padding:0;
}

.uploadInputGroup>li form button{
    height:2vw;
    border:1px solid #959595;
    font-size:1vw;
    line-height:2vw;
    padding:0 2vw;
    margin-right:1vw;;
    border-radius: 0;
}

.uploadInputGroup>li>span{
    font-size:0.8vw;
}

.uploadInputGroup>li>span.fileKinds{
    width:5vw;
    line-height:2.9vw;
    margin-left:0;
    font-size:1vw;
}

/* rentalPolicyBg,eventPopBg */
.rentalPolicyBg,
.eventPopBg,
.modalPopBg{
    width: 100vw;
    height:100vh;
    background:rgba(0,0,0,0.9);
    position:fixed;
    top:0;
    left:0;
    z-index:2000;
}
.rentalPolicyFrame,
.eventPopFrame,
.modalPopFrame{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    background:#ffffff;
    border:1px solid #000000;
    width:80vw;
    height:80vh;
    padding:2vw 3vw;
    overflow:auto; 
}
.modalPopFrame{
    width:50vw;
}
.eventPopFrame, .modalPopFrame{
    max-height:80vh; 
    height:auto;
    /* background: linear-gradient(0deg, #1c263e, #144a67); */
    border:none;
    /* color:#ffffff; */
}
.rentalPolicyFrame::-webkit-scrollbar,
.eventPopFrame::-webkit-scrollbar,
.modalPopFrame::-webkit-scrollbar{
    width:5px;
}
.rentalPolicyFrame::-webkit-scrollbar-thumb,
.eventPopFrame::-webkit-scrollbar-thumb,
.modalPopFrame::-webkit-scrollbar-thumb{
    background-color:#b3c7db;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
}
.rentalPolicyFrame::-webkit-scrollbar-track,
.eventPopFrame::-webkit-scrollbar-track,
.modalPopFrame::-webkit-scrollbar-track{
    background:transparent;
    border-radius:5px;
    box-shadow:inset 0px 0px 3px #ffffff;
}
.eventPopFrame>img{
    width:100%;
}
.rentalPolicyFrame .rentalPolicyPopTitle,
.eventPopFrame .eventPopTitle,
.modalPopFrame .modalPopTitle{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: space-between;
    padding-bottom:1vw;
    border-bottom:1px solid #000000;
    margin-bottom:1vw;
}
.eventPopFrame .eventPopTitle,
.modalPopFrame .modalPopTitle{
    margin-bottom:0;
}
.rentalPolicyFrame .rentalPolicyPopTitle h5,
.eventPopFrame .eventPopTitle h5,
.modalPopFrame .modalPopTitle h5{
    font-size:1.5vw;
    font-weight:600;
}
/* .eventPopFrame .eventPopTitle h5{color:#ffffff;} */
.rentalPolicyFrame .rentalPolicyPopTitle div,
.eventPopFrame .eventPopTitle div,
.modalPopFrame .modalPopTitle div{
    font-size:2vw;
    font-weight:600;
    cursor: pointer;
}
.rentalPolicyFrame .rentalPolicyPopCont li{
    margin-bottom:3vw;
}
.rentalPolicyFrame .rentalPolicyPopCont li h6{
    font-size:1.2vw;
    padding-bottom:0.8vw;
    font-weight:600;
}
.rentalPolicyFrame .rentalPolicyPopCont li span{
    font-size:1vw;
    line-height:1.8vw;
}
.eventPopFrame>ul, .modalPopFrame>ul{
    padding-top:3vw;
    display:flex;
    flex-direction: column;
    justify-content: center;
}
.eventPopFrame>ul>li, .modalPopFrame>ul>li{
    font-size:1.2vw;
    padding-bottom:2vw;
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    width:100%;
}
.eventPopFrame>ul>li:nth-child(1){
    font-size:1.4vw;
    padding-bottom:3vw;

}
.eventPopFrame>ul>li>span:nth-child(1){
    color:#687594;
    font-weight:600;
    width:20%;
    /* border:1px solid pink; */
    text-align:right;
    padding-right:1vw;
}
.eventPopFrame>ul>li>span:nth-child(2){
    width:60%;
    /* border:1px solid greenyellow; */

}
.eventPopFrame>ul>li:nth-last-child(2){
    align-items: flex-start;
}
.eventPopFrame>ul>li>ul, .modalPopFrame>ul>li>ul{
    width:60%;
}
.eventPopFrame>ul>li>ul>li, .modalPopFrame>ul>li>ul>li{
    word-break: keep-all;
    line-height:1.8vw;
    padding-bottom:1vw;
}
.eventPopFrame>ul>li:nth-last-child(1){
    padding-bottom:0;
    padding-top:1.5vw;
}
.eventPopFrame>ul>li:nth-last-child(1)>span{
    color:#c56e6e;
    font-size:1vw;
    line-height:1.5vw;
    text-align:left;
    width:100%;
}
.modalPopBodyWrapper{
    margin-top: 1vw;
    margin-bottom: 1vw;
}
.modalPopBody{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}
.modalPopBodyBtn{
    justify-content: flex-end;

}
.modalPopBody>span{
    margin-bottom: 1vw;
    margin-top: 1vw;
    font-size: 1.2vw;
}

.modalPopBody>span:nth-child(1){
    text-align: center;
    margin-left: 2vw;
    width: 25%;
}
.modalPopBody>span:nth-child(2){
    text-align: center;
    /* border : 1px solid blue; */
    width: 35%;
}
.modalPopBody>span:nth-child(2)>input{
    /* border : 1px solid orange; */
    width: 100%;
    height: 100%;
    padding-left: 1vw;
    border: 1px solid black;
}
.modalPopBody>span:nth-child(3){
    font-size: 1.2vw;
    line-height: 2vw;
    padding-left: 1vw;
    width: 40%;
    /* color:#c56e6e; */
}
.modalPopBody>button{
    margin-top: 1.5vw;
    margin-bottom: 1vw;
    width: 50%;
    margin-right: 2vw;
}
.modalPopBody>span>input.notSame,
.modalPopBody>span>input.notSame:focus{
    border:none;
    outline:1px solid #c56e6e
}





.paymentContFrame .authInfo>li:nth-last-child(1)>ul>li:nth-child(1){
    display:flex;
    flex-direction: row;
    align-items: center;
}



.paymentContFrame>div.programInfo{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom:1vw;
}
.paymentContFrame>div.programInfo>ul>li{
    margin-bottom:0.5vw;
    font-size:0.9vw;
    height:auto;
    line-height:2.6vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.paymentContFrame>div.programInfo>ul>li.guideTextGroup{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: inherit;
    height:auto;
    line-height:2vw;
    color: #000000;
    font-size:1vw;
    margin-bottom:1vw;
    margin-top:0.5vw;
    word-break: keep-all;
}
.paymentContFrame>div.programInfo>ul>li.guideTextGroup>p:nth-child(1){
    color: #c56e6e;
    font-weight:600;
}

.paymentContFrame>div.programInfo>ul>li>span.contentOrder{
    font-weight:600;
    margin-right:0.5vw;
    width:20vw;
    padding:0 0.5vw;
    height:2.9vw;
    line-height:2.9vw;
    color:#000000;
    cursor:pointer;
}
.paymentContFrame>div.programInfo>ul>li>span.contentInput>input{
    height:2.9vw;
}
.paymentContFrame>div.programInfo>ul>li>span.contentInput>input.empty:focus{
    border:none;
    outline:2px solid black
}
.paymentContFrame>div.programInfo>ul>li>span.contentInput>input.texting:focus{
    border:none;
    outline:2px solid red
}
.paymentContFrame>div.programInfo>ul>li>span.contentInput>input.texting{
    border:none;
    outline:1px solid red
}
.paymentContFrame>div.programInfo>ul>li>span.contentInput>input.finishing:focus{
    border:none;
    outline:2px solid green
}
.paymentContFrame>div.programInfo>ul>li>span.contentInput>input.finishing{
    border:none;
    outline:1px solid green
}
.paymentContFrame>div.programInfo>ul>li>span.contentInput>input:nth-child(1){width:5vw;}
.paymentContFrame>div.programInfo>ul>li>span.contentInput>input:nth-child(2){width:8vw;}
.paymentContFrame>div.programInfo>ul>li>span.contentInput>input:nth-child(3){width:11vw;}
.paymentContFrame>div.programInfo>ul>li>span.contentInput>span:nth-last-child(1){
    border:1px solid #000000;
    padding:0 1vw;
    height:2.9vw;
    cursor: pointer;
    margin-right:0.5vw;
    font-size:1vw;
    line-height:2.9vw;
    display:inline-block;
    background:#eeeeee;
}
.paymentContFrame>div.programInfo>ul>li>span.contentInput>span:nth-last-child(1):hover{
    background:#dddddd;
}


.orderInfo>li{
    display:flex;
    flex-direction: row;
    align-items: center;
}
.orderInfo>li:nth-child(1){
    align-items: flex-start;
}

.orderInfo>li>ul>li:nth-child(1){
    font-weight:600;
}


.orderInfo>li.orderCouponApply>div{
    display:flex;
    flex-direction:row;
}
.orderInfo>li.orderCouponApply>div>span{
    display:flex;
    flex-direction:row;
}
.orderInfo>li.orderCouponApply>div>span>p{
    margin-right:1vw;
    line-height:2.9vw;
} 
.orderInfo>li.orderCouponApply>div>p>span:nth-child(2){
    color:#c56e6e;
}
.orderInfo>li.orderCouponApplyList{
    align-items: flex-start;
}
.orderInfo>li.orderCouponApplyList>div{
    flex-direction:column;
    width:80%;
}
.orderInfo>li.orderCouponApplyList>div>div{
    min-width:11vw;
}
.orderInfo>li.orderCouponApplyList>div>div>div{
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    border-bottom:1px solid #dedede;
}
.orderInfo>li.orderCouponApplyList>div>div>div:nth-last-child(1){
    margin-bottom:1vw;
}
.orderInfo>li.orderCouponApplyList>div>div>div>span{
    cursor: pointer;
}
.orderInfo>li.orderCouponApplyList>div>div>div>span:hover{
    font-weight:600;
}

.paymentContFrame>.confirmInfo{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
}

.paymentContFrame>.confirmInfo>div{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    
}
.paymentContFrame>.confirmInfo>div>span{
    height:2.6vw; 
    line-height: 2.6vw;
    font-size:0.9vw;
}
.paymentContFrame>.confirmInfo>div>span:nth-child(2){
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.paymentContFrame>.confirmInfo>div>span:nth-child(2)>input{
    margin-right:0.5vw;
    cursor: pointer;
}
.paymentContFrame>.confirmInfo>div>span>span{cursor: pointer;}



.paymentContFrame>.bottomBtnGroup{
    margin-left:30%;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    font-family: 'NanumSquare', 'Nanum Gothic', sans-serif;
    
}
.paymentContFrame>.bottomBtnGroup>button{
    border:none;
    outline: none;
    border:1px solid #000f1e;
    background:#ffffff;
    width:200px;
    height:2.9vw;
    text-align: center;
    cursor: pointer;
    font-size:1.2vw;

}
.paymentContFrame>.bottomBtnGroup>div{
    width:auto;
    margin-left:0;
    border:0;
}
.paymentContFrame>.bottomBtnGroup>div>button:nth-child(1){
    width:360px;
    height:2.9vw;
    background:#000f1e;
    border:none;
    color:#fffffF;
    font-size:1.2vw;
}
.paymentContFrame>.bottomBtnGroup>div>button:nth-child(2){
    width:100px;
    height:55px;
    border:none;
    border:1px solid #000f1e;
    background:#ffffff;
    font-size:1.2vw;
}













