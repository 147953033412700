.invalidBtn{
    color:gray;
    border : 1px solid gray
}
.validBtn{
    color : black;
    border: 1px solid black
}



@media (max-width:834px){
    .adminAppImagesFrame .PageGalleryItem .bookContentFrame{
        height:80vh;
    }
}

