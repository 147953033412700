.previewFrame{
    position: relative;
    margin:0 auto;
    width:auto;
}

/* .pcPreviewFrame{
    width:auto;
    width:88.7vw;
    height:100vh;
} */






.bookPageFrame{

    width:1150px;
    /* height:50vw; */
    margin:0 auto;
    /* display:flex;
    flex-direction: row; */
    position: relative;
    overflow:hidden; 
    /* width:100%; */
}
/* .pcPreviewFrame .bookPageFrame{
    width:100%;
    height:95%;
} */

.headingTextBox{
    width:50%;
    height:50vw;
    margin:10vw auto;
    background:#ffffff;
    position: absolute;
    top:0;
    left:-100%;
    padding:3vw 5vw;
    border:1px solid #dedede;
    z-index:10;
    -webkit-transition:left 1s;
    transition:left 1s;
    display:inline-flex;
    flex-direction: column;

}
.pcPreviewFrame .bookPageFrame .headingTextBox{
    height:781px;
    width:50%;
    overflow:auto;
}
.pcPreviewFrame .bookPageFrame .headingTextBox::-webkit-scrollbar{
    width:5px;
}
.pcPreviewFrame .bookPageFrame .headingTextBox::-webkit-scrollbar-thumb{
    background-color:#b3c7db;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
}
.pcPreviewFrame .bookPageFrame .headingTextBox::-webkit-scrollbar-track{
    background:transparent;
    border-radius:5px;
    box-shadow:inset 0px 0px 3px #ffffff;
}
.headingTextBox.showHeading{
    left:0;
}
.headingTextBox div{
    display:inline-block;
    cursor: pointer;
    line-height:1;
    
}
.headingTextBox .heading1{
    font-size:1.2vw;
    font-weight:600;
    color:#5068b8;
    padding:1vw 0 0.5vw;
}
.headingTextBox .heading2{
    font-size:1.1vw;
    /* font-weight:600; */
    color:#000f1e;
    margin-left:1vw;
    padding:0.3vw 0 0.3vw;
}
.headingTextBox .heading3{
    font-size:1vw;
    font-weight:500;
    color:#3e4955;
    margin-left:2vw;
}

.headingTextBox div:hover{
    color:orange;
}



/*spinner*/
.bookPageFrame>.spinnerFrame{
    min-height:100vh;
    display:flex;
    flex-direction:column;
    justify-content: center;
}



/* PC Preview */
.scene{
    width:70.2vw;
    height:50vw;
    margin:10vw auto;
    -webkit-perspective: 2000px;
    perspective: 2000px;
}
.pcPreviewFrame .bookPageFrame .scene{
    /* width:88.7vw; */
    /* width:70vw;
    height:45vw; */
    width:1150px;
    height:780px;
}
.bookPageGroup{
    position: relative;
    width: 100%;
    height: 100%;
    margin-left:50%;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.bookPage{
    position:absolute;
    width: 100%;
    height: 100%;
    /* display:flex;
    flex-direction: row; */
    -webkit-transition: 1.2s -webkit-transform ;
    transition: 1.2s transform;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;  
    -webkit-transform-origin: left center;  
    -ms-transform-origin: left center;  
    transform-origin: left center;
    z-index:-1;
    display:none;
    width:0;
    height:0;
}
.bookPagePC{
    position:absolute;
    width: 100%;
    height: 100%;
    /* display:flex;
    flex-direction: row; */
    -webkit-transition: 1.2s -webkit-transform, 2s -webkit-z-index;
    transition: 1.2s transform, 2s z-index;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;  
    -webkit-transform-origin: left center;  
    -ms-transform-origin: left center;  
    transform-origin: left center;
    /* -webkit-transition: 2s -webkit-z-index;
    transition: 2s z-index; */
    z-index:-1;
    display:none;
    width:0;
    height:0;
    /* -webkit-transition-property: z-index;
    transition-property: z-index;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transition-delay:2s;
    transition-delay:2s; */
}

.frontPageBox,
.backPageBox{
    position: absolute;
    top:0;
    left:0;
    width: 50%;
    height: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}
.backPageBox{
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}
.bookPage.ready{
    z-index:0;
    display:block;
    width:100%;
    height:100%;
}
.bookPage.active{
    z-index:1;
    display:block;
    width:100%;
    height:100%;
}
.bookPage.flipped{
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    z-index:2;
    display:none;
    width:0;
    height:0;
}
.bookPage.flipped:last-of-type {
    z-index: 3;
}
.bookPage.pageShow{
    display:block !important;
    width:100% !important;
    height:100% !important;
}


.bookPagePC.ready{
    z-index:0;
    display:block;
    width:100%;
    height:100%;
}
.bookPagePC.ready + .bookPagePC{
    display:block;
    width:100%;
    height:100%;
}
.bookPagePC.active{
    z-index:1;
    display:block;
    width:100%;
    height:100%;
}
.bookPagePC.flipped {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    z-index:2;
    display:block;
    width:100%;
    height:100%;

    
}
.bookPagePC.flipped:last-of-type {
    z-index: 1;
}
/* .bookPagePC.zIndex0{z-index:0; transition-property: z-index; transition-delay: 2s;}
.bookPagePC.zIndex1{z-index:1; transition-property: z-index; transition-delay: 2s;}
.bookPagePC.zIndex2{z-index:2; transition-property: z-index; transition-delay: 2s;} */

/* .bookPagePC.flipped:last-of-type {
    z-index: 3;
    display:block;
    width:100%;
    height:100%;
} */
.flipped>.frontPageBox{
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    pointer-events: none; 
}

.pointerEventsNone{
    pointer-events:none;
    display:none !important;
    width:0 !important;
    height:0 !important;
}
.bookPagePC.pageShow{
    display:block !important;
    width:100% !important;
    height:100% !important;
    z-index:0;
}
.pcPreviewFrame .bookPageFrame .scene .bookContentFrame{
    width:100%;
    height:100%;
}

/* mobile Preview */
.mobileScene{
    width:35.1vw;
    height:50vw;
    
    margin:10vw auto;
    -webkit-perspective: 2000px;
    perspective: 2000px;
}
.mobileScene .bookPageGroup{
    margin-left:0;
}

.curPageBox{
    width:100%;
    height:100%;
}










.headingBtnBox{
    position:absolute;
    bottom:0;
    left:0;
    z-index:100;
    width:auto;
    height:auto;
    display:block;
}
.headingBtnBox span{
    height:3vw;
    display:block;
    padding:0 2vw;
    border:1px solid #000000;
    background:#ffffff;
    line-height:3vw;
    font-size:1.2vw;
    cursor: pointer;
}
.headingBtnBox span:hover{
    background:#000000;
    color:#ffffff;
}
.headingBtnBox span:active{
    border:1px solid rgb(8, 46, 77) !important;
    background:rgb(8, 46, 77) !important;
    color:#ffffff !important;  
}


.bookBtnBox{
    position:absolute;
    bottom:0;
    right:0;
    width:auto;
    height:3vw;
    line-height:3vw;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index:5;
}
.bookBtnBox span{
    font-size:1.2vw;
    height:100%;
    display:block;
}
.bookBtnBox span:active{
    border:1px solid rgb(8, 46, 77) !important;
    background:rgb(8, 46, 77) !important;
    color:#ffffff !important;   
}
.bookBtnBox .curPageSign{
    margin-right:1vw;
}
.bookBtnBox span.prevBtn{
    padding:0 1.5vw;
    border-right:none;
    color:#000000;
    cursor: pointer;
    background:#ffffff;
}
.bookBtnBox span.nextBtn{
    padding:0 1.5vw;
    color:#000000;
    cursor: pointer;
    background:#ffffff;
}
.bookBtnBox span.prevBtn:hover,
.bookBtnBox span.nextBtn:hover{
    background:#000000;
    color:#ffffff;
}
















