.mainImagePopup{
    position: fixed;
    width: 100%; height: 100%;
    background-color: rgba(0,0,0,0.4);
    z-index:20000;
    /* right:5.2vw;
    width:6vw; */
}
.borderRed {
    border:2px solid red
}
.mainImagePopup .mainImagePopupContents{
    position: absolute;
    /* width:30vw; height: 50vh; */
    /* top:0;  bottom:0;  right: 0; left:0; margin: 25vh auto; */
    top:10%;
  right: 10%;
}
.mainImagePopup .mainImagePopupContents img{
    max-height: 70vh;
    max-width: 60vw;
}
.mainImagePopup .mainImagePopupContents div{
    position: relative; /*Btn 기준점*/

}
.mainImagePopup .mainImagePopupContents div.CloseBtn{
    /* position: absolute; */
    right: 0;
    /* margin: 1vw; */
    text-align: right; 
    text-shadow: 2px 2px 2px #000; font-size: 2vw; color: #fff;
}
.mainImagePopup .mainImagePopupContents div.todayCloseBtn{
    /* position: absolute; */
    bottom:0; width: 100%;
    /* padding: 0.5vw 1vw; */
    text-align: right; 
    /* background-color:rgba(0,0,0,0.4); */
    font-size: 1vw; color: #fff;
}
@media (max-width:834px){
    .mainImagePopup .mainImagePopupContents{
        right: 5%;
    }
    .mainImagePopup .mainImagePopupContents img{
        max-height: 60vh;
        max-width: 80vw;
    }
    .mainImagePopup .mainImagePopupContents div.CloseBtn{
     font-size: 5vw;
     /* margin : 1vw; */
    }
    .mainImagePopup .mainImagePopupContents div.todayCloseBtn{
    font-size: 3vw;
    }
}
@media (orientation: portrait) and (min-width: 835px) { /* 가로형 */

    .mainImagePopup .mainImagePopupContents{
        top:2%;
    }
    
    .mainImagePopup .mainImagePopupContents img{
        max-height: 80vh;
        width: auto;
        
    }
    .mainImagePopup .mainImagePopupContents div.CloseBtn{
        font-size: 5vw;
        margin: 0;
    }
    .mainImagePopup .mainImagePopupContents div.todayCloseBtn{
        font-size: 3vw; 
        /* border:2px solid green */
    }
}
@media (orientation: landscape) and (max-width: 834px) { /* 가로형 */

    .mainImagePopup .mainImagePopupContents{
        top:2%;
    }
    
    .mainImagePopup .mainImagePopupContents img{
        max-height: 80vh;
        width: auto;
        
    }
    .mainImagePopup .mainImagePopupContents div.CloseBtn{
        font-size: 2vw;
        margin: 0;
    }
    .mainImagePopup .mainImagePopupContents div.todayCloseBtn{
        font-size: 1vw; 
    }
} 
@media (max-width:480px){
    .mainImagePopup .mainImagePopupContents{
        right: 5%;
    }
    .mainImagePopup .mainImagePopupContents img{
        max-height: 90vh;
        width: 90vw;
    }
       
}