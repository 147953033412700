.signBg{
    width:100vw;
    min-height:100vh;
    padding:0 5.2vw 15vw;

}


.signFrame{
    width:460px;
    height:100%;
    padding-top:15vw;
    margin-left:10vw;
    /* border:1px solid orange; */
}

.signFrame h1{
    font-size:1.6vw;
    margin-bottom:3.1vw;

}



.inputGroup{
    display:flex;
    flex-direction: column;

}

.inputGroup>input{
    text-decoration: none;
    outline:none;
    width:100%;
    height:50px;
    margin-bottom:1.6vw;
    padding:0 1vw;
    border:none;
    border-bottom:1px solid #000000;
    font-size:1vw;
    border-radius: 0;
    
}


.inputGroup a.termsLink{
    font-size:0.9vw;
    color:#000000;
    text-decoration: none;
    text-decoration: underline;
    margin-left:auto;
    text-align:right;
    display:block;
    padding-top:1vw;
    
}

.inputGroup .checkBx{
    display:flex;
    flex-direction: row;
    align-items: flex-start;
    padding:1.3vw 0 0;
   
}
.inputGroup>.checkBx>input{
    height:1.2vw;
    margin:0;
    margin-right:0.5vw;
    display: block;
}

.inputGroup .checkBx span{
    font-size:0.9vw;
    color: #000000;;
    display: inline-block;
    line-height:1.2vw;

}

.inputGroup p{
    color:#c56e6e;
    font-size:1vw;   
}


.inputGroup button{
    margin-top:3vw;
    width:100%;
    height:55px;
    background:#000f1e;
    color:#ffffff;
    font-size:1vw;
    border-radius: 0;
    border:none;
}


.signUpLink>a{
    text-decoration: none;
    color:#000000;
    text-decoration: underline;
    font-size:1vw;
}


.signConfirmBg{
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    background:rgba(0,0,0,0.9);
    z-index:999;
}
.signConfirmBox{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    background:#ffffff;
    padding:3vw;
}

.signConfirmBox ul li.signTiteTxt{
    font-size:1.4vw;
    font-weight:600;
    padding-bottom:2vw;
}
.signConfirmBox ul li.signInfoTxt{
    font-size:1vw;
    line-height:2vw;
}
.signConfirmBox ul li:nth-child(3){
    padding-bottom:2vw;
}
.signConfirmBox ul li.signGuideTxt{
    font-size:0.9vw;
    color:#aaaaaa;
    line-height:1.2vw;
}
.signConBtnGroup{
    margin-top:1vw;
    display:flex;
    flex-direction:row;
    align-items: center;
}
.signConBtnGroup span{
    font-size:1vw;
    font-weight:600;
    line-height:55px;
    height:55px;
    width:50%;
    text-align:center;
    border:1px solid #000f1e;
    cursor: pointer;
}
.signConBtnGroup span:hover{
    border:1px solid rgb(8, 46, 77) !important;
    background:rgb(8, 46, 77) !important;
    color:#ffffff !important;
}
.signConBtnGroup span:active{
    border:1px solid rgb(8, 46, 77) !important;
    background:rgb(8, 46, 77) !important;
    color:#ffffff !important;
}
.signConBtnGroup span:nth-child(1){
    font-weight:400;
    background:#000f1e;
    color:#ffffff;
}




@media (max-width:834px){
    .signFrame{
        width:auto;
        height:100%;
        padding-top:15vw;
        margin-left:10vw;
        /* border:1px solid orange; */
    }
        
    .signFrame h1{
        font-size:4vw;
        margin-bottom:10vw;
    }
    .inputGroup{
        width:70%;
    }
    .inputGroup>input{
 
        font-size:2vw;    
    }
    .inputGroup p{
        font-size:2vw;   
    }
    .inputGroup>button{
        font-size:2vw;  
    }
    .linkGoup{
        width:70%;
        padding-top:5vw;
    }
    .inputGroup a.termsLink{
        font-size:2.5vw;
        padding-top:8vw;
    }
    .inputGroup .checkBx{
        padding-top:3vw;    
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .inputGroup>.checkBx>input{
        height:3vw;   
        margin-right:1vw; 
        line-height:3vw;
    }
    .inputGroup .checkBx span{
        font-size:2.5vw;
        line-height:3vw;
        word-break: keep-all;
    }
    .inputGroup button{
        margin-top:5vw;

    }
    .signUpLink>a{
        font-size:2.5vw;
    }



    .signConfirmBox{
        padding:5vw;
        width:70vw;
    }
    .signConfirmBox ul li.signTiteTxt{
        font-size:3vw;
        padding-bottom:4vw;
    }
    .signConfirmBox ul li.signInfoTxt{
        font-size:2.5vw;
        line-height:4vw;
    }
    .signConfirmBox ul li:nth-child(3){
        padding-bottom:4vw;
    }
    .signConfirmBox ul li.signGuideTxt{
        font-size:2vw;
        line-height:3vw;
    }
    .signConBtnGroup{
        margin-top:2vw;
    }
    .signConBtnGroup span{
        font-size:2.5vw;
    }
}

@media (max-width:480px){
    .signFrame{
        width:auto;
        padding-top:30vw;
        margin-left:0;
        
    }
    .signFrame h1{
        font-size:6vw;
    }
    .inputGroup{
        width:100%;
    }
    .inputGroup>input{
        font-size:4vw;    
    }
    .inputGroup p{
        font-size:4vw;   
    }
    .inputGroup>button{
        font-size:4vw;  
    }
    .linkGoup{
        width:100%;
    }
    .inputGroup a.termsLink{
        font-size:3.5vw;
        padding-top:20vw;
    }
    .inputGroup .checkBx{
        padding-top:5vw;    
    }
    .inputGroup>.checkBx>input{
        height:4.5vw;   
        margin-right:2vw; 
    }
    .inputGroup .checkBx span{
        font-size:3.5vw;
        line-height:4.5vw;
    }
    .inputGroup button{
        margin-top:10vw;

    }
    .signUpLink>a{
        font-size:4vw;
    }

    .signConfirmBox{
        padding:8vw;
        width:90vw;
    }
    .signConfirmBox ul li.signTiteTxt{
        font-size:5.5vw;
        line-height:7.5vw;
        padding-bottom:8vw;
        word-break: keep-all;
    }
    .signConfirmBox ul li.signInfoTxt{
        font-size:4.5vw;
        line-height:7vw;
    }
    .signConfirmBox ul li:nth-child(3){
        padding-bottom:8vw;
    }
    .signConfirmBox ul li.signGuideTxt{
        font-size:3.5vw;
        line-height:5vw;
    }
    .signConBtnGroup{
        margin-top:4vw;
    }
    .signConBtnGroup span{
        font-size:4.5vw;
        height:15vw;
        line-height:15vw;
    }
}

