.eventBg{
    width:100vw;
    min-height:100vh;
    padding:0 5.2vw;
    /* border:1px solid red; */
}

.eventFrame{
    width:100%;
    height:100%;
    padding-top:15vw;
    padding-bottom:10vw;
    /* border:1px solid orange; */
    /* display:flex; */
    justify-content: center;
}

.eventFrame img{
    width:100%;
}
.eventFrame>img.contPcImage{
    display:block;
}
.eventFrame>img.contMobileImage{
    display:none;
}
.eventFrame>div{
    width:100%;
    padding-top:3vw;
    display:inline-flex;
    flex-direction: row;
    justify-content: center;
}
/* .eventFrame div a{
    height:2.8vw;
    line-height:2.8vw;
    font-size:1.2vw;
    background:#000f1e;
    color:#ffffff;
} */

.eventFrame>div{
    width:100%;
    padding:1vw 0;
    border:1px solid #000f1e;
}
.eventFrame>div>ul{
    width:80%;
    margin: 1vw 0;
    padding: 5vw 0;
    /* border:1px solid #000f1e; */
    font-size:0;
}
.eventFrame>div>ul>p{
    font-size:0.9vw;
    color:#c56e6e;
    margin-left:30%;
    margin-top:1vw;
}

.eventFrame>div>ul>li{
    width:100%;
    height:auto;
    /* border:1px solid green; */
    margin-bottom:2.5vw;
    display:flex;
    flex-direction: row;
    font-size:1.5vw;
    line-height:2.6vw;
    padding:0 2vw;
}

.eventFrame>div>ul>li.checkInputBx{
 display:flex;
 flex-direction: row;
 justify-content: flex-start;
 align-items: center;
}

.eventFrame>div>ul>li.checkInputBx.tabletRental{margin-top:-1.5vw;}
.eventFrame>div>ul>li.eventBtnGroup>div>.divBtn{
    /* margin-right:1vw; */
    height:4vw;
    line-height:4vw;
    border:1px solid #000f1e;
    width:200px;
    text-align: center;
    cursor: pointer;
    font-size:1.8vw;
}
.eventFrame>div>ul>li.eventBtnGroup>div>.divBtn:active{
    border:1px solid rgb(8, 46, 77) !important;
    background:rgb(8, 46, 77) !important;
    color:#ffffff !important; 
}
/* .eventFrame>div>ul>li.eventBtnGroup{

} */
.eventFrame>div>ul>li.eventBtnGroup>div{
    /* margin-left: 0; */
    width: 100%;
    display:flex;
    /* flex-direction: row; */
    justify-content: flex-end;

}
/* .eventFrame>div>ul>li>span{
    padding:0 1vw;
} */
.eventFrame>div>ul>li.checkInputBx>span{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.eventFrame>div>ul>li.checkInputBx>span>input{
    margin-right:1vw;
    cursor: pointer;
}
.eventFrame>div>ul>li.checkInputBx>span>span{
    margin-right:3vw;
    display:flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
.eventFrame>div>ul>li.checkInputBx>span>span>input{
    margin-right:1vw;

    cursor: pointer;
}
.eventFrame>div>ul>li>span>button{
    width: 13vw;
    height: 2.7vw;
    margin-left: 1vw;
    padding: 0;
    border: none;
    font-size: 1.25vw;
    color: #666;
}
.eventFrame>div>ul>li>span.cate{
    width:30%;
    height:2.6vw;
    /* border:1px solid pink; */
    display:inline-block;
    text-align:right;
    line-height:2.6vw;
    padding-right:2vw;
    font-size:1.7vw;
    font-weight:600;
}

.eventFrame>div>ul>li .phoneNumber{
    width: 33vw;
    margin-left:0vw;
    height:2.6vw;
    padding:0 1vw;
    border-width:1px;
    font-size: 1.6vw;
}

.eventFrame>div>ul>li>select{
    cursor: pointer;

}

.eventFrame>div>ul>li>select,
.eventFrame>div>ul>li>input{
    width:70%;
    height:2.6vw;
    /* outline: none; */
    border:none;
    border-bottom:1px solid #000000;
    font-size:1vw;
    padding:1vw;
    border-radius: 0;
    font-size: 1.5vw;
    /* border:1px solid orange; */
    
}
.eventFrame>div>ul>li>select>option{
    width:100%;
    height:100%;
 
}
.eventFrame>div>ul>li>span>span>input[type=checkbox]{
    width:1.2vw;
    height:1.2vw;
    margin-right:1vw;
    display: block;
    /* margin-left:0.5vw; */
}
.eventFrame>div>ul>li>span.checkBxName{
    padding:0;
    padding-right:1vw;
}
.eventFrame>div>ul>li.policiesCate:nth-child(6) span.mobile{
    display: none;
}
.eventFrame>div>ul>li>div>button{
    width:320px;
    height:4vw;
    line-height:4vw;
    background:#000f1e;
    color:#ffffff;
    font-size:1.8vw;
    border-radius: 0;
    border:none;
    
}
.eventFrame>div>ul>li>textarea{
    font-size:1vw;
    width:70%;
    height:18.2vw;
    display: block;
    resize: none;
    padding:0.5vw 1vw; 
    line-height:1.8vw;
}
.eventFrame>div>ul>li>textarea::-webkit-scrollbar{
    width:5px;
  }
  .eventFrame>div>ul>li>textarea::-webkit-scrollbar-thumb{
    background-color:#b3c7db;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
  }
  .eventFrame>div>ul>li>textarea::-webkit-scrollbar-track{
    background:transparent;
    border-radius:5px;
    box-shadow:inset 0px 0px 3px #ffffff;
  }

.eventFrame>div>ul>li.policiesCate>span>span:nth-child(1){
    display: flex;
    flex-direction: row;
    align-items: center;
}
.eventFrame>div>ul>li.policiesCate:nth-child(6){
    margin-bottom: 0;
} 
.eventFrame>div>ul>li.policiesCate>span{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width:70%;
}
.eventFrame>div>ul>li>span.smallText{
    font-size: 1vw;
    color: #c56e6e;
}
.eventFrame>div>ul>li.policiesCate>span.cate{
    display:inline-block;
    /* margin-right:1vw; */
    width:30%;
}
/* .eventFrame>div>ul>li.policiesCate>span:nth-child(2){
    margin-left:0;
} */
.eventFrame>div>ul>li.policiesCate>span>input{
    margin-right:1vw;
}
.eventFrame>div>ul>li>span>span.policiesLink a{
    text-decoration: underline;
    color:#000000;
    font-size: 1.1vw;
}

.eventFrame .noEvent{
    border: none;
    flex-direction: column;
    text-align: center;
}
.eventFrame .noEvent .noEventImg{
    width: 10vw;
    align-self: center;
    margin-bottom: 1vw;
}
.eventFrame .noEvent p{
    font-size: 2vw;
    line-height: 3.2vw;
}
.eventFrame .noEvent p span{
    font-size: 3vw;
    font-weight: bold;
    color: rgb(8, 46, 77);
}
@media (max-width:1024px){
    .eventFrame .noEvent{
        min-height: 70vh;
    }
    .eventFrame .noEvent .noEventImg{
        width: 40vw;
    }
    .eventFrame .noEvent p{
        font-size: 5vw;
        line-height: 7vw;
    }
    .eventFrame .noEvent p span{
        font-size: 6vw;
    }
}
@media (max-width:834px){
    .eventBg{
        padding: 0;
    }
    .eventFrame{
        padding-top:20vw;
        padding-bottom:15vw;
    }
    .eventFrame>div{
        width: 95%;
        margin: 0 2vw;
        border: none;
    }
    
    .eventFrame>div>ul>li.eventBtnGroup>div>.divBtn{
        width: 20vw;
    }
    .eventFrame>div>ul>li>span>button{
        width: 17vw;
        background: #e5e5e5;
    }
    .eventFrame>div>ul>li>div>button{
        width: 30vw;
    }
}

@media (max-width:480px){
    .eventFrame{
        padding-top:30vw;
        padding-bottom:20vw;
    }
    .eventFrame>img.contPcImage{
        display:none;
    }
    .eventFrame>img.contMobileImage{
        display:block;
    }
    .eventFrame>div>ul{
        width: 93%;
    }
    .eventFrame>div>ul>li{
        display: flex;
        flex-direction: column;
    }
    
    .eventFrame>div>ul>li>span.cate{
        font-size: 6vw;
        height: 5vw;
        width: 100%;
        text-align: left;
        margin-bottom: 2vw;
    }
    .eventFrame>div>ul>li>input{
        width: 100%;
        height: 10vw;
        margin-bottom: 10vw;
        background-color: #efefef;
    }
    .eventFrame>div>ul>li .phoneNumber{
        width: 100%;
        height: 10vw;
    }
    .eventFrame>div>ul>li.policiesCate>span.cate{
        width: 100%;
    }
    .eventFrame>div>ul>li.policiesCate>span:nth-child(2){
        width: 100%;
        line-height: 7vw;
        height: 7vw;
        font-size: 5vw;
        margin-bottom: 5vw;
    }
    .eventFrame>div>ul>li input{
        font-size: 4vw;
        border-bottom: 1px solid #999999;
    } 
    .eventFrame>div>ul>li.policiesCate>span:nth-child(2) input{
        width: 13px; height: 13px;
    }
    .eventFrame>div>ul>li.policiesCate>span:nth-child(2){
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .eventFrame>div>ul>li:nth-child(3){
        position: relative;
    }
    .eventFrame>div>ul>li:nth-child(3)>span>button{
        position: absolute;
        bottom: 0; right: 0;
        margin-bottom: 10vw;
        width: 30vw;
        height: 10vw;
        font-size: 5vw;
    }
    .eventFrame>div>ul>li>div>button{
        width: 100%;
        height: 10vw;
        font-size: 5vw;
    }
    .eventFrame>div>ul>li:nth-child(7)>span.smallText{
        width: 100%;
        font-size: 4vw;
        text-align: center;
        margin-bottom: 10vw;;
    
    }
    .eventFrame>div>ul>li.policiesCate>span:nth-child(2){
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .eventFrame>div>ul>li.policiesCate>span:nth-child(2)>span{
        font-size: 5vw;
        line-height: 8vw;
        margin-bottom: 2vw;
    }
    .eventFrame>div>ul>li.policiesCate:nth-child(6)>span:nth-child(2){
        margin-bottom: 0vw;
    }
    .eventFrame>div>ul>li:nth-child(7)> span.cate{
        display: none;
    }
    .eventFrame>div>ul>li>span>span.policiesLink{
        align-self: flex-end;
    }
    .eventFrame>div>ul>li>span>span.policiesLink a{
        font-size: 4.5vw;
        color: darkgray;
    }
}
@media (max-width:280px){
}