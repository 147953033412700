.createCouponsFrame{
    margin-bottom:5vw;
    padding:0 5vw;
}
.createCouponsFrame h3{
    font-size:1.8vw;
    font-weight:600;
    line-height:5vw;
    margin-bottom:2vw;
    border-bottom:1px solid #000000;
}
.makingCouponBxGroup{
    margin-bottom:3vw;

}
.makingCouponBxGroup>div{
    display:flex;
    margin-bottom:0.5vw;
}
.makingCouponBxGroup>div p{
    width:8vw;
    font-size:1.2vw;
    margin-right:1.5vw;
    line-height:2.5vw;
    font-weight:600;
}

.couponNameBx{
    flex-direction: row;
}
.couponNameBx input{
    width:30vw;
    height:2.5vw;
    line-height:2.5vw;
    font-size:1vw;
    padding:0 0.5vw;
    border:1px solid #000000;
}
.couponNameBx>button{
    height:2.5vw;
    line-height:2.5vw;
    font-size:1vw;
    padding:0 1vw;
    border-width:1px;
    margin-right:0.5vw;
}
.couponNameBx>button.randomcodeBtn{
    margin-left: 1vw;
}
.couponConditionBx{
    flex-direction: row;
}
.couponConditionBx>div{
    display:flex;
    flex-direction:row;
    align-items:center;
    height:2.5vw;
}
.couponConditionBx>div>div{
    display:flex;
    flex-direction:row;
    align-items:center;
}
.couponConditionBx>div>div input{
    width:1.2vw;
    height:1.2vw;
}
.couponConditionBx>div>div span{
    font-size:1vw;
    padding:0 1.5vw 0 0.5vw;
    line-height:2.5vw;
}

.couponPriceBx{
    height:auto;
    flex-direction: column;
}
.couponPriceBx>div{
    display:flex;
    flex-direction:row;
    align-items: center;
    height:2.5vw;
}
.couponPriceBx>div:nth-last-child(2){
    margin-bottom:0.5vw;
}
.couponPriceBx>div>span{
    font-size:1vw;
    margin-right:0.5vw;
    line-height:2.5vw;
}
.couponPriceBx>div>input{
    height:2.5vw;
    line-height:2.5vw;
    font-size:1vw;
    padding:0 0.5vw;
    border:1px solid #000000;
    margin-right:0.5vw;
    text-align:right;
}
.couponPriceBx>div>input::-webkit-outer-spin-button,
.couponPriceBx>div>input::-webkit-inner-spin-button{
    -webkit-appearance:none;
    margin:0;
}

.couponPeriodBx>div{
    display:flex;
    flex-direction: row;
    align-items: center;
}
.couponPeriodBx>div>span{
    font-size:1vw;
    margin-right:0.5vw;
    line-height:2.5vw;
}
.couponPeriodBx>div>input{
    height:2.5vw;
    line-height:2.5vw;
    font-size:1vw;
    padding:0 0.5vw;
    border:1px solid #000000;
    margin-right:0.5vw;
    text-align:right;
}
.couponPeriodBx>div>input::-webkit-outer-spin-button,
.couponPeriodBx>div>input::-webkit-inner-spin-button{
    -webkit-appearance:none;
    margin:0;
}

.couponCompleteBx{
    flex-direction: row;
}
.couponCompleteBx>div{
    display:flex;
    flex-direction: row;
    align-items: center;
}
.couponCompleteBx>div>span{
    font-size:1vw;
    margin-right:0.5vw;
    line-height:2.5vw;
}
.couponCompleteBx>div>button{
    height:2.5vw;
    line-height:2.5vw;
    font-size:1vw;
    padding:0 1vw;
    border-width:1px;
    margin-right:0.5vw;
}

.couponGuideText{
    font-size:1vw;
    color:#c56e6e;
    margin-left:9.5vw;
    padding-top:0.5vw;
}






.madeCouponGroup{
    width:100%;
    height:auto;
    padding-bottom:5vw;
}
.madeCouponGroup>span{
    font-size:1vw;
    text-align:right;
    display:block;
    margin-bottom:0.5vw;
}
.madeCouponGroup table{
    width:100%;
    height:auto;
    font-size:1vw;
}
.madeCouponGroup table th{
    height:2.5vw;
    line-height:2.5vw;
    padding:0 0.5vw;
    border:1px solid #dedede;
    border-bottom:2px solid #aaaaaa;
    font-size:1.2vw;
}
.madeCouponGroup table td{
    padding:0 0.5vw;
    height:2.5vw;
    border:1px solid #dedede;
}

.madeCouponGroup table td button{
    border-width:1px;
}

.madeCouponGroup table tbody tr td:nth-child(5):hover{
    cursor: pointer;
    color:orange;
}