@media (max-width:1600px){
    .bookPageFrame{
        width:70vw;
    }
    .pcPreviewFrame .bookPageFrame .scene{
        width:70vw;
        height:50vw;
    }
    .headingTextBox{
        width:50%;
    }
    .pcPreviewFrame .bookPageFrame .headingTextBox{
        height:50.1vw;
        
    }
}
@media (max-width:1400px){
    /* .previewFrame{
        width:auto;
        height:auto;
    }
    .headingTextBox{
        width:35.1vw;
        height:49.65vw;
    }
    .headingBtnBox, .bookBtnBox{
        bottom:6vw;
    } */
    .bookPageFrame{
        width:80vw;
    }
    .pcPreviewFrame .bookPageFrame .scene{
        width:80vw;
        height:55.1vw;
    }
    .pcPreviewFrame .bookPageFrame .headingTextBox{
        height:55.2vw;
    }
}
@media (max-width:1024px){
    .bookPageFrame{
        width:86vw;
    }
    .pcPreviewFrame .bookPageFrame .scene{
        width:86vw;
        height:60vw;
    }
    .pcPreviewFrame .bookPageFrame .headingTextBox{
        height:60.1vw;
    }
}
@media (max-width:834px){



    .headingTextBox{
        padding:5vw 7vw;
        margin:7vw 0;
    }
    .headingTextBox div{
        padding:2vw 0 1.5vw;
    }
    .headingTextBox.showHeading{
        left:0;
    }
    .headingTextBox .heading1{
        font-size:2.2vw;
    }
    .headingTextBox .heading2{
        font-size:2vw;
        margin-left:2vw;
    }
    .headingTextBox .heading3{
        font-size:1.8vw;
        margin-left:4vw;
    }





    /* mobile Preview */
    /* .moPreviewFrame{
        width:100%;
        min-height:100vh;
        height:auto;
    } */
    .moPreviewFrame .headingTextBox{
        width:100%;
        /* height:89.5%; */
        -webkit-height:calc(100% - 13.9vw);
        height:calc(100% - 13.9vw);
        overflow:auto;
    }
    .moPreviewFrame .headingTextBox::-webkit-scrollbar{
        width:5px;
    }
    .moPreviewFrame .headingTextBox::-webkit-scrollbar-thumb{
        background-color:#b3c7db;
        border-radius:5px;
        background-clip:padding-box;
        border:1px solid transparent;
    }
    .moPreviewFrame .headingTextBox::-webkit-scrollbar-track{
        background:transparent;
        border-radius:5px;
        box-shadow:inset 0px 0px 3px #ffffff;
    }
    .bookPageFrame{
        width:100%;
        height:auto;
        min-height:100vh;
        /* border:1px solid red; */
    }
    .mobileScene{
        width:100%;
        height:100%;
        padding:7vw 0;
        margin:0;
        -webkit-perspective: 2000px;
        perspective: 2000px;
    }







    .headingBtnBox span{
        height:6vw;
        padding:0 4vw;
        line-height:6vw;
        font-size:2vw;
    }
    .headingBtnBox span:active{
        border:1px solid rgb(8, 46, 77) !important;
        background:rgb(8, 46, 77) !important;
        color:#ffffff !important;
    }
    .bookBtnBox{
        height:6vw;
        line-height:6vw;
    }
    .bookBtnBox span{
        font-size:2vw;
    }
    .bookBtnBox .curPageSign{
        margin-right:3vw;
    }
    .bookBtnBox span.prevBtn{
        padding:0 3vw;
    }
    .bookBtnBox span.nextBtn{
        padding:0 3vw;
    }
}






@media (max-width:480px){


    .headingTextBox{
        padding:6vw 8vw;
        margin:10vw 0;
    }
    .headingTextBox div{
        padding:3vw 0 2.5vw;
    }
    .headingTextBox .heading1{
        font-size:4vw;
    }
    .headingTextBox .heading2{
        font-size:3.8vw;
        margin-left:3vw;
    }
    .headingTextBox .heading3{
        font-size:3.5vw;
        margin-left:6vw;
    }





    .mobileScene{
        padding:10vw 0;
    }
    .moPreviewFrame .headingTextBox{
        width:100%;
        /* height:89%; */
        -webkit-height:calc(100% - 19.5vw);
        height:calc(100% - 19.5vw);
    }

    .headingBtnBox{
        bottom:-4vw;
    }
    .headingBtnBox span{
        height:12vw;
        padding:0 8vw;
        line-height:12vw;
        font-size:4vw;
    }
    .bookBtnBox{
        height:12vw;
        line-height:12vw;
        bottom:-4vw;
    }    
    .bookBtnBox span{
        font-size:4vw;
    }
    .bookBtnBox .curPageSign{
        margin-right:4vw;
    }
    .bookBtnBox span.prevBtn{
        padding:0 3vw;
    }
    .bookBtnBox span.nextBtn{
        padding:0 3vw;
    }


}