.adminStaticsFrame {
    padding: 0 5vw;
  }
  .adminStaticsFrame > h3 {
    font-size: 1.8vw;
    font-weight: 600;
    line-height: 5vw;
    margin-bottom: 2vw;
    border-bottom: 1px solid #000000;
  }
    
/* statisticsTabBtnGourp */
.adminStaticsFrame .statisticsTabBtnGourp {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.adminStaticsFrame .statisticsTabBtnGourp div {
    font-size: 1.2vw;
    text-align: center;
    border: 1px solid #dedede;
    width: 50%;
    height: 4vw;
    line-height: 4vw;
    cursor: pointer;
    color: #aaaaaa;
    border-right: 0;
}
.adminStaticsFrame .statisticsTabBtnGourp div:hover {
    background: #eeeeee;
    color: #000000;
}
.adminStaticsFrame .statisticsTabBtnGourp div:nth-child(1) {
    border-right: 0;
    border-bottom: 0;
    color: #000000;
}
.adminStaticsFrame .statisticsTabBtnGourp div:nth-last-child(1) {
    border-right: 1px solid #dedede;
}


/* statisticsTabContGroup */
.adminStaticsFrame .statisticsTabContGroup {
    padding: 3vw 2vw;
    border: 1px solid #dedede;
    border-top: none;
    font-size: 1vw;
    position: relative;
  }


/* .dataAnalysisFrame */
.dataAnalysisFrame{
    border-width:1px;
}
.dataAnalysisFrame table{
    width:100%;
    height:auto;
    font-size:1vw;
}
.dataAnalysisFrame table th{
    height:2.5vw;
    line-height:2.5vw;
    padding:0 0.5vw;
    border:1px solid #dedede;
    background:#eeeeee;
    border-bottom:2px solid #aaaaaa;
    font-size:1vw;
}
.dataAnalysisFrame table td{
    height:2.5vw;
    border:1px solid #dedede;
}
.dataAnalysisFrame table tr td:nth-child(1){
    background:#eeeeee;
    font-weight:600;
}
/* .dataAnalysisFrame table tr td:nth-child(1){
    background:rgb(82, 95, 127, 0.3);
} */
.dataAnalysisFrame table tr td:nth-child(2){
    background:rgba(94,114,228,0.1);
}

.dataAnalysisFrame table tr:nth-child(1) td:nth-child(1),
.dataAnalysisFrame table tr:nth-child(2) td:nth-child(1){
    background:#eeeeee;
}
.dataAnalysisFrame table tr:nth-child(1) td:nth-child(2),
.dataAnalysisFrame table tr:nth-child(2) td:nth-child(2){
    background:#ffffff;
}



.dataAnalysisFrame table td span{
    display:block;
    text-align:center;
}
.dataAnalysisFrame table td a{
    width:100%;
    height:100%;
    line-height:2.5vw;
    text-align:center;
    display:block;
    color:#000000;
    display:inline-block;
    border:1px solire red;
}
.dataAnalysisFrame table td a:hover{
    color:#ffffff;
    background:rgba(94,114,228,1);
}
.dataAnalysisFrame .dataAnalysisGuide{
    padding-top:2vw;
}
.dataAnalysisFrame .dataAnalysisGuide p{
    font-size:1vw;
    padding-bottom:0.5vw;
}
.dataAnalysisFrame .dataAnalysisGuide ul{
    font-size:0;
}
.dataAnalysisFrame .dataAnalysisGuide ul li{
    font-size:1vw;
    display:inline-block;
    margin-right:1vw;
}
.dataAnalysisFrame .dataAnalysisGuide ul li span{
    font-weight:600;
}





/* userDataListFrame */
.userDataListFrame .userDataTitleBox{
    width:100%;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-between;
    padding-bottom:1vw;
}
.userDataListFrame .userDataTitleBox div{
    display:flex;
    flex-direction: row;
    align-items: center;
}
.userDataListFrame .userDataTitleBox div .listDownloadBtn{
    margin-left:1vw;
    color:#1e6e42;
    text-decoration: underline;
    cursor: pointer;
}
.userDataListFrame .userDataTitleBox div .listDownloadBtn:hover{
    font-weight:600;
}
.userDataListFrame .userDataTitleBox ul{
    display:flex;
    flex-direction: row;
    align-items: center;
}
.userDataListFrame .userDataTitleBox ul li{
    margin-left:0.5vw;
}
.userDataListFrame .userDataTitleBox ul li span{
    font-weight:600;
}
.userDataListFrame table{
    width:100%;
    height:auto;
    font-size:1vw;
}
.userDataListFrame table th{
    height:2.5vw;
    line-height:1.5vw;
    padding:0 0.5vw;
    border:1px solid #dedede;
    background:#eeeeee;
    border-bottom:2px solid #aaaaaa;
    font-size:1vw;
}
.userDataListFrame table td{
    height:2.5vw;
    border:1px solid #dedede;
    word-break: keep-all;
    padding:0 0.5vw;
    line-height:1.5vw;
}





/* nonUserDataListFrame */
.nonUserDataListFrame .userDataTitleBox{
    padding-bottom:1vw;
    display:flex;
    flex-direction: row;
    align-items: center;
}
.nonUserDataListFrame .userDataTitleBox .listDownloadBtn{
    margin-left:1vw;
    color:#1e6e42;
    text-decoration: underline;
    cursor: pointer;
}
.nonUserDataListFrame .userDataTitleBox .listDownloadBtn:hover{
    font-weight:600;
}
.nonUserDataListFrame table{
    width:100%;
    height:auto;
    font-size:1vw;
}
.nonUserDataListFrame table th{
    height:2.5vw;
    line-height:1.5vw;
    padding:0 0.5vw;
    border:1px solid #dedede;
    background:#eeeeee;
    border-bottom:2px solid #aaaaaa;
    font-size:1vw;
}
.nonUserDataListFrame table td{
    height:2.5vw;
    border:1px solid #dedede;
    word-break: keep-all;
    padding:0 0.5vw;
    line-height:1.5vw;
}