.invalidBtn{
    color:gray;
    border : 1px solid gray
}
.validBtn{
    color : black;
    border: 1px solid black
}


/* AuthReader/NonAuthReader  */
.bookMark{
    width:5vw;
    height:2.5vw;
    border:1px solid #dedede;
    border-bottom:none;
    cursor: pointer;
}
.shareMark{
    width:auto;
    height:2.5vw;
    border:1px solid #dedede;
    border-bottom:none;
    cursor: pointer;
    font-size:1.3vw;
    line-height:2.5vw;
    text-align:center;
    padding:0 0.5vw;
    margin:0 0.5vw;
    font-weight:300;
}
.bookContentFrame{
    width:35.1vw;
    height:49.65vw;
    border:1px solid #000000;
}
.textareaFrame>.lineLimit{
    color:#c56e6e;
    top:inherit;
    bottom:0.5vw;
    right:0.8vw;
    font-size:0.8vw;
}






/* user check Page */
.userCheckFrame .bookContentFrame .bookContFrame>p{
    font-size:1.3rem;
    font-weight:600;
    line-height:1.5vw;
}
.userCheckFrame .bookContentFrame .bookContFrame>div{
    font-size:1.2rem;
    line-height:1.4vw;
}
.userCheckFrame .bookContentFrame .inputTextFrame>div,
.userCheckFrame .bookContentFrame .textareaFrame>div,
.userCheckFrame .bookContentFrame .drawingToolFrame>div{
    padding:1vw 0 2vw;
}
.userCheckFrame .bookContentFrame .drawPaperBx{
    border:none;
}
.userCheckFrame .bookContentFrame .videoFrame{
    display:table;
    border:1px solid #c56e6e;
    opacity:0.6;
}
.userCheckFrame .bookContentFrame .videoFrame>p{
    font-size:1.2vw;
    font-weight:400;
    display:table-cell;
    text-align:center;
    vertical-align: middle;
}

/* admin check Page */
.adminCheckFrame .bookContentFrame .bookContFrame>p{
    font-size:1.3rem;
    font-weight:600;
    line-height:1.5vw;
    
}
.adminCheckFrame .bookContentFrame .bookContFrame>div{
    font-size:1.2rem;
    line-height:1.4vw;
}
.adminCheckFrame .bookContentFrame .inputTextFrame>div,
.adminCheckFrame .bookContentFrame .textareaFrame>div,
.adminCheckFrame .bookContentFrame .drawingToolFrame>div{
    padding:0;
    border:1px dashed #dedede;
    min-height:2vw;
    line-height:2vw;
}

.adminCheckFrame .bookContentFrame .drawPaperBx{
    border:none;
}

.adminCheckFrame .bookContentFrame .bookContFrame>a.imgDownloadBtnIcon{
    color:#c56e6e;
    position:absolute;
    right:9vw;
    bottom:0;
    font-size:1vw;
    line-height:1;
    cursor: pointer;
    z-index:5;
    line-height:2vw;
    display:flex;
    flex-direction: row;
    align-items: center;
}
.adminCheckFrame .bookContentFrame .bookContFrame>div.copyBtnIcon{
    color:#22608a;
    position:absolute;
    right:1vw;
    bottom:0;
    border:none;
    font-size:1vw;
    padding:0;
    line-height:1;
    cursor: pointer;
    z-index:5;
    line-height:2vw;
    display:flex;
    flex-direction: row;
    align-items: center;
}
.adminCheckFrame .bookContentFrame .bookContFrame>div.copyBtnIcon:hover span{
    text-decoration: underline;
}
.adminCheckFrame .bookContentFrame .bookContFrame>a.imgDownloadBtnIcon svg,
.adminCheckFrame .bookContentFrame .bookContFrame>div.copyBtnIcon svg{
    margin-right:0.5vw;
}
.adminCheckFrame .bookContentFrame .videoFrame{
    display:table;
    border:1px solid #c56e6e;
    opacity:0.6;
}
.adminCheckFrame .bookContentFrame .videoFrame>p{
    font-size:1.2vw;
    font-weight:400;
    display:table-cell;
    text-align:center;
    vertical-align: middle;
    /* color:rgb(46, 90, 141); */
}



@media (max-width:834px){
/* AuthReader/NonAuthReader  */       
    .bookContentFrame{
        width:100%;
        height:100%;
        border:1px solid #000000;
    }
    .textareaFrame>span{
        bottom:1vw;
        right:1.5vw;
        font-size:1.8vw;
    }

    .bookMark{
        width:12vw;
        height:5vw;
    }
    .shareMark{
        height:5vw;
        font-size:3vw;
        line-height:5vw;
        padding:0 1vw;
        margin:0 1vw;
    }


        
    /* user check Page */
    .userCheckFrame .bookContentFrame .bookContFrame>p{
        line-height:3.5vw;
    }
    .userCheckFrame .bookContentFrame .bookContFrame>div{
        line-height:3.5vw;
    }
    .userCheckFrame .bookContentFrame .inputTextFrame>div,
    .userCheckFrame .bookContentFrame .textareaFrame>div,
    .userCheckFrame .bookContentFrame .drawingToolFrame>div{
        padding:2vw 0 3vw;
    }
    .userCheckFrame .bookContentFrame .videoFrame>p{
        font-size:2vw;
    }   
    /* admin check Page */
    .adminCheckFrame .bookContentFrame .bookContFrame>p{
        line-height:3.5vw;
    }
    .adminCheckFrame .bookContentFrame .bookContFrame>div{
        line-height:3.5vw;
    }
    .adminCheckFrame .bookContentFrame .inputTextFrame>div,
    .adminCheckFrame .bookContentFrame .textareaFrame>div,
    .adminCheckFrame .bookContentFrame .drawingToolFrame>div{
        padding:0;
        min-height:4vw;
        line-height:4vw;
        border:1px dashed #dedede;
    }

    .adminCheckFrame .bookContentFrame .bookContFrame>a.imgDownloadBtnIcon{
        right:18vw;
        bottom:0;
        font-size:2vw;
        line-height:4vw;
    }
    .adminCheckFrame .bookContentFrame .bookContFrame>div.copyBtnIcon{
        right:2vw;
        bottom:0;
        font-size:2vw;
        line-height:4vw;
    }

    .adminCheckFrame .bookContentFrame .bookContFrame>a.imgDownloadBtnIcon svg,
    .adminCheckFrame .bookContentFrame .bookContFrame>div.copyBtnIcon svg{
        margin-right:1vw;
    }
    .adminCheckFrame .bookContentFrame .videoFrame>p{
        font-size:3vw;
    }
}

@media (max-width:480px){
/* AuthReader/NonAuthReader  */
    .bookMark{
        width:22vw;
        height:8vw;
    }
    .shareMark{
        height:8vw;
        font-size:5vw;
        line-height:8vw;
        padding:0 2vw;
        margin:0 2vw;
    }

        
    /* user check Page */
    .userCheckFrame .bookContentFrame .bookContFrame>p{
        line-height:5.5vw;
    }
    .userCheckFrame .bookContentFrame .bookContFrame>div{
        line-height:5vw;
    }
    .userCheckFrame .bookContentFrame .inputTextFrame>div,
    .userCheckFrame .bookContentFrame .textareaFrame>div,
    .userCheckFrame .bookContentFrame .drawingToolFrame>div{
        padding:2vw 0 3vw;
    }
    .userCheckFrame .bookContentFrame .videoFrame>p{
        font-size:3.5vw;
    } 
    /* admin check Page */
    .adminCheckFrame .bookContentFrame .bookContFrame>p{
        line-height:5.5vw;
    }
    .adminCheckFrame .bookContentFrame .bookContFrame>div{
        line-height:5vw;
    }
    .adminCheckFrame .bookContentFrame .inputTextFrame>div,
    .adminCheckFrame .bookContentFrame .textareaFrame>div,
    .adminCheckFrame .bookContentFrame .drawingToolFrame>div{
        min-height:8vw;
        line-height:8vw;
    }

    .adminCheckFrame .bookContentFrame .bookContFrame>a.imgDownloadBtnIcon{
        right:27vw;
        bottom:0;
        font-size:3.5vw;
        line-height:8vw;
    }
    .adminCheckFrame .bookContentFrame .bookContFrame>div.copyBtnIcon{
        right:3vw;
        bottom:0;
        font-size:3.5vw;
        line-height:8vw;
    }

    .adminCheckFrame .bookContentFrame .bookContFrame>a.imgDownloadBtnIcon svg,
    .adminCheckFrame .bookContentFrame .bookContFrame>div.copyBtnIcon svg{
        margin-right:1.5vw;
    }
    .adminCheckFrame .bookContentFrame .videoFrame>p{
        font-size:5vw;
    }
}




