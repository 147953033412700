@media (max-width:1600px){
    .userGallPopImage{
        width:35vw !important;
        height:50vw !important;
    }
}
@media (max-width:1400px){
    .userGallPopImage{
        width:40vw !important;
        height:55vw !important;
    }
}
@media (max-width:1024px){
    .userGallPopImage{
        width:60vw !important;
        height:80vh !important;
    }
    .userGallPopImage .bookContentFrame{
        height:100%;
    }
}
@media (max-width:834px){
    .userMypageFrame{
        padding:20vw 9vw 0;

    }
    .userMypageFrame>h3{
        font-size:3vw;
        margin-bottom:1vw;
    }
    .userMypageFrame>p{
        font-size:2.5vw;
        margin-bottom:6vw;
    }
    .tab{
        height:8vw;
        line-height:8vw;
        padding:0 2vw;
        font-size:2.6vw;
    }

    .contents h6{
        margin-bottom:2vw;
        font-size:2vw;
    }
    .libraryBg{
        padding:2vw;
    }
    .libraryBg>p{
        font-size:2vw;
        line-height:2.8vw;
        flex-direction:column;
        justify-content: flex-end;
        text-align: right;
    }
    .libraryBg>p>span{
        margin-top: 0vw;
        margin-left: 6vw;
        text-align: left;
    }
    .libraryFrame div{
        font-size:2vw;
        padding-bottom:2vw;
        margin-bottom: 2vw;
    }
    .libraryFrame div a{
        align-items: inherit;
        line-height:4vw;
        width:100%;
    }
    .libraryFrame div a span{
        width:5vw;
        height:5vw;
        margin-right:0;
    }
    .libraryFrame div a p{
        width: 90%;
        padding-left:2vw;
        font-size:2.2vw;
    }
    
    .bookRegistBtn{
        width:60vw;
        height:5vw;
        line-height:5vw;
        background:#000000;
        display: inline-block;
        color:#ffffff;
        padding:0 2vw;
        cursor: pointer;
        font-size:2.2vw;
    }
    .libraryFrame div a{
        height: 11vw;
    }
    .libraryFrame div .extensionBtn, .libraryFrame div .oculusVrAppBtn{
        width:25vw;
        cursor: pointer;
        padding: 0.5vw 2vw;
        font-size: 2vw;
        line-height: 2.8vw;
        height: auto;
    }
    .libraryFrame div span.columnMoblie{
        flex-direction: column;
    }
    .libraryFrame span.btnArea{
        width: 78%;
        justify-content: flex-start;
    }
    .codeRegiBx{
        width:75%;
        padding:3vw 5vw 5vw;
    }
    .codeRegiBx .titleBox{
        height:8vw;
        margin-bottom:5vw;
    }
    .codeRegiBx .titleBox>h5{
        font-size: 3vw;
        height:8vw;
        line-height:8vw;
    }
    .codeRegiBx .titleBox>div{
        font-size:5.2vw;
        line-height:1;
        margin-bottom:0;
    }
    .codeRegiBx .subTextBox>h6{
        font-size:3vw;
        margin-bottom:1vw;
    }
    .codeRegiBx .subTextBox>p{
        font-size:2vw;
        line-height:4vw;
        margin-bottom:1vw;
    }
    .codeRegiBx .subTextBox>ul.codeRegiBxinfoPc>li{
        display: none;
    }
    .codeRegiBx .subTextBox>ul.codeRegiBxinfoMobile>li{
        display: block;
        font-size: 3.2vw;
        margin-bottom: 2.5vw;
    }
    .codeRegiBx .codeRegiBxoculusCode{ 
        font-size: 2.5vw;
    }
    .codeRegiBx .codeRegiBxoculusCodeNone p{ 
        font-size: 3vw;
        margin: 3vw 0;
        height: 5vw;
    }
    .codeRegiBx .codeRegiBxoculusCodeCopy{
        font-size: 2vw;
        margin-bottom: 0.3vw;
    }
    .codeRegiBx .codeRegiBxoculusCodeCopy1{
        display: inline;
        font-size: 2vw;
    }
    .codeRegiBx .codeRegiBxoculusCodeNone button{
        width:65vw;
        height:10vw;
        line-height:10vw;
        padding:0 10vw;
        font-size:3.5vw; 
    }
    .codeInputFrame{
        margin-bottom:3vw;
    }
    .codeInputFrame p{
        margin-right:2vw;
        height:6vw;
        width:15vw;
        line-height:6vw;
        font-size:2.5vw;
    }
    .codeInputFrame span span{
        font-size: 3vw;
        top: 0.7vw;
    }
    .codeInputFrame input{
        height:6vw;
        width:20vw;
        line-height:6vw;
        font-size:2.5vw;
    }
    .codeRegiBx form>p{
        font-size:2.5vw;
    }
    .codeRegiBx form p.errMsg{
        font-size:2.2vw;
        padding-bottom:1.6vw;
    }
    .codeRegiBx form .codeRegiBtn{
        padding:0 5vw;
        line-height:6vw;
        margin:5vw 0 1vw;
        font-size:2.5vw;
    }

    .addressRegiBx{
        width:75%;
        padding:3vw 5vw 5vw;
    }
    .addressRegiBx .titleBox{
        height:8vw;
        margin-bottom:5vw;
    }
    .addressRegiBx .titleBox>h5{
        font-size: 3vw;
        height:8vw;
        line-height:8vw;
    }
    .addressRegiBx .titleBox>div{
        font-size:5.2vw;
        line-height:1;
        margin-bottom:0;
    }
    .addressInputFrame{
        margin-bottom:3vw;
    }
    .addressInputFrame input{
        height:6vw;
        line-height:6vw;
        font-size:2.5vw;
    }
    .addressInputFrame span{
        height:6vw;
        line-height:6vw;
        font-size:2.5vw;
    }
    .addressRegiCont p{
        font-size:2.5vw;
    }
    
    .addressRegiCont .addressRegiBtn{
        padding:0 5vw;
        line-height:6vw;
        margin:5vw 0 1vw;
        font-size:2.5vw;
    }



    .noDataText{
        font-size:2vw;
        line-height:3vw;
        padding-bottom:5vw;
    }

    .myProgramFrame{
        height:auto;
        line-height:3vw;
        flex-direction:column;
        width:100%;
        padding:2vw;
        border-bottom:1px solid #dedede;
    }
    .myProgramFrame:nth-last-child(1){
        border-bottom:0;
    }
    .myProgramFrame li{
        width:100% !important;
        font-size:2vw;
    }
    .myProgramFrame>li span{
        width:24%;
        margin-right:2vw;
        font-size:2.2vw;
    }
    .myProgramFrame>li span:nth-child(2){
        margin-left:1.5vw;
        padding-left:1.5vw;
    }

    .myBookFrame{
        line-height:3vw;
        padding:2vw;
    }
    .myBookFrame li{
        width:100% !important;
        font-size:2vw;
    }
    .myBookFrame>li span{
        width:24%;
        margin-right:2vw;
        font-size:2.2vw
    }






    .payHistoryFrame, 
    .slpStatusFrame{
        height:auto;
        line-height:3vw;
        flex-direction:column;
        padding:2vw;
        border-bottom:1px solid #dedede;
    
    }
    .payHistoryFrame:nth-last-child(1), 
    .slpStatusFrame:nth-last-child(1){
        border-bottom:0;
    }
    .payHistoryFrame,
    .slpStatusFrame{
        margin-bottom:3vw;
        padding-bottom:4vw;
    }
    .payHistoryFrame>li, 
    .slpStatusFrame>li{
        width:100% !important;
        font-size:2vw;
        align-items:flex-start;
        height:auto;
    }
    .payHistoryFrame>li:nth-last-child(1), 
    .slpStatusFrame>li:nth-last-child(1){
        margin-top:1vw;
        align-items: flex-start;
    }
    .payHistoryFrame>li span,
    .slpStatusFrame>li span{
        width:24%;
        margin-right:0;
        line-height:4vw;
    }
    .payHistoryFrame>li span:nth-child(1), 
    .slpStatusFrame>li span:nth-child(1){
        font-size:2.2vw;
    }
    .payHistoryFrame>li span:nth-child(2), 
    .slpStatusFrame>li span:nth-child(2){
        line-height:4vw;
        font-size:2vw;
        width:76%;

    }
    .payHistoryFrame>li span button{
        padding:0.5vw 2vw;
        font-size:2vw;
        line-height:2.8vw;
        height:auto;
    }
    .slpStatusFrame>li span button{
        padding:0 2vw;
        font-size:2vw;
        line-height:4vw;
        height:4vw;
    }

        
    /* orderCancle */
    .orderCancleFrame{
        width:80vw;
        padding:4vw 5vw;
    }
    .orderCancleFrame .canclePopTitle{
        margin-bottom:4vw;
    }
    .orderCancleFrame .canclePopTitle h5{
        font-size:2.5vw;
    }
    .orderCancleFrame .canclePopTitle div{
        font-size:4vw;
    }
    .orderCancleFrame .canclePopList{
        margin-bottom:5vw;
    }
    .orderCancleFrame .canclePopList h6{
        font-size:2.2vw;
    }
    .orderCancleFrame .canclePopList .payHistoryFrame{
        margin-bottom:0;
        padding:1vw;
    }
    .orderCancleFrame .canclePopList .payHistoryFrame:nth-child(2){
        padding:0 1vw;
    }
    .orderCancleFrame .canclePopList .payHistoryFrame li{
        font-size:1.8vw;
        line-height:2.5vw;
        margin-top:0;
        padding-right:0;
    }
    .orderCancleFrame .canclePopList .payHistoryFrame li:nth-child(1){width:30% !important;}
    .orderCancleFrame .canclePopList .payHistoryFrame li:nth-child(2){width:20% !important;}
    .orderCancleFrame .canclePopList .payHistoryFrame li:nth-child(3){width:15% !important;}
    .orderCancleFrame .canclePopList .payHistoryFrame li:nth-child(4){width:15% !important;}
    .orderCancleFrame .canclePopList .payHistoryFrame li:nth-child(5){width:10% !important;}
    .orderCancleFrame .canclePopList .payHistoryFrame li:nth-child(6){width:10% !important;}
    .orderCancleFrame .canclePopList .payHistoryFrame li.payCancleBtn button{
        padding:0 1vw;
        height:5vw;
        line-height:5vw;
    }
    .orderCancleFrame .canclePopReason h6{
        font-size:2.2vw;
    }
    .orderCancleFrame .canclePopReason ul li span{
        font-size:2vw;
        margin:0 2.5vw 0 1.5vw;
    }
    .orderCancleFrame .canclePopAccount h6{
        font-size:2.2vw;
    }
    .orderCancleFrame .cancleAccounInput select{
        height:4vw;
        margin-right:1vw;
        font-size:2vw;
    }
    .orderCancleFrame .cancleAccounInput input{
        padding:0 1vw;
        height:4vw;
        line-height:4vw;
        font-size:2vw;
        width:30vw;   
    }
    .orderCancleFrame .canclePopAccount p{
        font-size:2vw;
    }

    .orderCancleFrame .canclePopGuide{
        padding-bottom:5vw;
    }
    .orderCancleFrame .canclePopGuide p{
        font-size:2vw;
        line-height:2.5vw;
        word-break: keep-all;
        
    }
    .orderCancleFrame .canclePopBtn span{
        font-size:2vw;
    }
    .orderCancleFrame .canclePopBtn button{
        font-size:2vw;
        padding:0 2vw;
        line-height:5vw;  
    }

    



    /* bookPublishBg */
    .bookPublishFrame{
        width:80vw;
        padding:4vw 5vw;
    }
    .bookPublishFrame .publPopTitle{
        margin-bottom:4vw;
    }
    .bookPublishFrame .publPopTitle h5{
        font-size:2.5vw;
    }
    .bookPublishFrame .publPopTitle div{
        font-size:4vw;
    }
    .bookPublishFrame>p{
        font-size:2vw;
        padding-bottom:3vw;
        word-break: keep-all;
    }
    .bookPublishFrame .publPopInfo{
        margin-bottom:5vw;
        max-height:60vw;
    }
    .bookPublishFrame .publPopInfo li span{
        font-size:1.8vw;
        line-height:5vw;
        padding:0 1vw;
    }
    .bookPublishFrame .publPopAddress{
        margin-bottom:2.2vw;
    }
    .bookPublishFrame .publPopAddress p{
        font-size:2vw;
        padding-bottom:1vw;
    }
    .bookPublishFrame .publPopAddress>div{
        display:inherit;
        flex-direction:inherit;
        justify-content: inherit;
        align-items: inherit;
    }
    .bookPublishFrame .publPopAddress>div>p{
        line-height:3.5vw;
        padding-bottom:1vw;
        word-break: keep-all;
    }
    .bookPublishFrame .publPopAddress>div>span{
        font-size:2vw;
        padding:0 2vw;
        height:5vw;
        line-height:5vw;
        display:inline-block;
        
    } 
    .bookPublishFrame .PublPopChangeAddress .PublPopChAddrBtn button{
        font-size:2vw;
        padding:0 4vw;
        height:5vw;
        line-height:5vw;
    }
    .addressPopupOut{
        width: 75%;
    }
    .addressPopupOut .addressPopupIn>button{
        font-size:2vw;
        padding:0 4vw;
        height:5vw;
        line-height:5vw;
    }
    


    /* deviceRental */
    .deviceRentFrame{
        width:80vw;
        padding:4vw 5vw;
    }
    .deviceRentFrame .rentPopTitle{
        margin-bottom:4vw;
    }
    .deviceRentFrame .rentPopTitle h5{
        font-size:2.5vw;
    }
    .deviceRentFrame .rentPopTitle div{
        font-size:4vw;
    }
    .deviceRentFrame .rentPopList{
        margin-bottom:2vw;

    }
    .deviceRentFrame .rentPopList p{
        font-size:1.8vw;
        margin-bottom:3vw;
    }
    .deviceRentFrame .rentPopList h6{
        font-size:2.2vw;
    }
    .deviceRentFrame .rentPopList .payHistoryFrame{
        margin-bottom:0;
        padding:0 1vw;
    }
    .deviceRentFrame .rentPopList .payHistoryFrame li{
        font-size:1.8vw;
        line-height:5vw;
        margin-top:0;    
        word-break: keep-all;
    }
    .deviceRentFrame .rentPopList .payHistoryFrame li:nth-child(1){width:10% !important;}
    .deviceRentFrame .rentPopList .payHistoryFrame li:nth-child(2){width:40% !important;}
    .deviceRentFrame .rentPopList .payHistoryFrame li:nth-child(3){width:30% !important;}
    .deviceRentFrame .rentPopList .payHistoryFrame li:nth-child(4){width:20% !important;}
    
    .deviceRentFrame>p{
        font-size:2vw;
        padding-bottom:3vw;
    }
    .deviceRentFrame .rentPopInfo{
        margin-bottom:3vw;
    }
    .deviceRentFrame .rentPopInfo li{
        margin-bottom:1vw;
    }
    .deviceRentFrame .rentPopInfo li span{
        font-size:1.8vw;
    }
    .deviceRentFrame .rentPopInfo li>span:nth-child(1){
        font-size:2vw;
        width:12vw;
    }
    .deviceRentFrame .rentPopGuide{
        font-size:2vw;
        line-height:3vw;
        margin-bottom:4vw;
    } 
    .deviceRentFrame .rentPopBtn button{
        font-size:2vw;
        padding:0 2vw;
        line-height:5vw;
        margin-bottom:2vw;
    }




    /* programUserManage */

    .deviceRentFrame .programUserManage h6 {
        font-size: 2.2vw;
    }
    .deviceRentFrame .programUserManage .payHistoryFrame{
        padding:1vw 0;
    }
    .deviceRentFrame .programUserManage .payHistoryFrame li {
        font-size: 1.8vw;
        line-height: 2.5vw;
        word-break: break-all;
        padding:0.2vw 0;
    }
    .deviceRentFrame .programUserManage .payHistoryFrame li:nth-child(1) {width: 15% !important;}
    .deviceRentFrame .programUserManage .payHistoryFrame li:nth-child(2) {width: 25% !important;}
    .deviceRentFrame .programUserManage .payHistoryFrame li:nth-child(3) {width: 30% !important;}
    .deviceRentFrame .programUserManage .payHistoryFrame li:nth-child(4) {width: 30% !important;}
    .deviceRentFrame .programUserManage .payHistoryFrame li:nth-child(4) {margin-top:0;}
    .deviceRentFrame .programUserManage .payHistoryFrame li input{
        height:4vw;
        line-height:4vw;
        font-size:1.8vw;
    }
    .deviceRentFrame .programUserManage .payHistoryFrame li button{
        height:4vw;
        line-height:4vw;
        font-size:1.8vw;
    }



    p.myShareTxt{
        height:auto;
        line-height:1;
        padding:2vw 0;
        font-size:2.2vw;
        border-bottom:0;
        
    }
    .myShareFrame{
        height:5vw;
        line-height:5vw;
        align-content: center;
        font-size:2vw;
        padding-left:2vw;
        padding-right:2vw;
    }
    .myShareFrame:nth-last-child(1){
        border-bottom:0;
    }
    .myShareFrame>li:nth-child(1){
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .myShareFrame>li>button{
        width:100%;
        height:100%;
        font-size:1.8vw;
        line-height:5vw;
    }


    /* userGallPopFrame */
    .userGallPopBox{
        padding:9vw 5.2vw;
    }
    .userGallPopImage{
        width:70vw !important;
        height:100% !important;
        /* margin:7vw 5.2vw; */
    }





    .myInfoFrame>ul>li{
        height:auto;
        margin-bottom:1vw;
        /* border:1px solid red; */
        line-height:5vw;
        align-items: flex-start;
    }
    .myInfoFrame>ul>li>span{
        font-size:2vw;
    }
    .myInfoFrame>ul>li>span.infoCate{
        width:12vw;
        line-height:5vw;
        padding:0 1vw;
        font-weight:600;
        font-size:2vw;
    }
    .myInfoFrame>ul>li>input{
        width:25vw;
        height:5vw;
        font-size:2vw;
        padding:0 1vw;
        line-height:5vw;
    }
    .myInfoFrame>ul>li>button{
        font-size:2vw;
        padding:0 2vw;
        width:auto;
        height:5vw;
        line-height:5vw;
    }
    .myInfoFrame>ul>li>span:nth-child(2){
        height:5vw;
    }
    .myInfoFrame>ul>li>span>input{
        width:25vw;
        height:5vw;
        font-size:2vw;
        padding:0 1vw;
        line-height:5vw;
    }
    .myInfoFrame>ul>li>span>button{
        font-size:2vw;
        padding:0 2vw;
        width:auto;
        height:5vw;
        line-height:5vw;
    }

    .myInfoFrame>ul>li>button.infoChangeBtn{
        margin-left:13vw;
        width:20vw;
        font-size:2vw;
    }
    .pwChangeGroup{ 
        flex-direction: column;
        align-items: flex-start;
    }
    .pwChangeGroup>input{
        width:25vw;
        height:5vw;
        font-size:2vw;
        padding:0 1vw;
        margin-right:0;
        margin-bottom: 1vw;  
    }
    .pwChangeGroup>button{
        width:25vw;
        height:5vw;
        line-height:5vw;
        font-size:2vw;
        padding:0 2vw;
    }

    .myInfoFrame>ul>li>span.infoDelLink{
        font-size:1.8vw;
        padding-right:2vw;
    }
    .infoDelBtnGroup{
       
        margin-top:8%;
    }
    .infoDelBtnGroup>button{
        width:auto;
        height:5vw;
        padding:0 4vw;
        font-size:2.3vw;
    }

    .secessionAlertBx{
        width:75%;
        padding:3vw 5vw 5vw;
    }
    .secessionAlertBx>.secessionAlertTitle{
        height:8vw;
        margin-bottom:5vw;
    }
    .secessionAlertBx>.secessionAlertTitle>h5{
        font-size:3vw;
        height:8vw;
        line-height:8vw;
    }
    .secessionAlertBx>.secessionAlertTitle>button{
        font-size:5vw;
        margin-bottom:-0.5vw;
    }
    .secessionAlertCont>h6{
        font-size:3vw;
    }
    .secessionAlertCont>ul{
        margin-bottom:5vw;
    }
    .secessionAlertCont>ul>li{
        font-size:2.5vw;
        line-height:3.5vw;
    }

}



@media (max-width:480px){
    .userMypageFrame{
        padding:30vw 2vw 0;

    }
    .userMypageFrame>h3{
        font-size:5vw;
        margin-bottom:2vw;
    }
    .userMypageFrame>p{
        font-size:4vw;
        margin-bottom:10vw;
    }
    .tab{
        height:15vw;
        line-height:15vw;
        padding:0 2vw;
        font-size:4vw;
    }
    .contents{
        padding:3vw;
    }
    .contents h6{
        margin-bottom:3vw;
        font-size:3.5vw;
    }
    .libraryBg>p{
        margin: 1.5vw 0;
        font-size:3vw;
        line-height:4.5vw;
    }
    .libraryFrame div{
        font-size:3vw;
        padding-bottom:3vw;
        margin-bottom:3vw;
    }
    .libraryFrame div a span{
        width:8vw;
        height:8vw;
    }
    .libraryFrame div a{
        height: 18vw;
    }
    .libraryFrame div a p{
        display: block;
        width:85%;
        height: 15vw;
        padding-left:3vw;
        font-size:3.5vw;
        line-height:8vw;
    }
    .libraryFrame div span.btnArea{
        flex-direction: column;
    }
    .libraryFrame div .extensionBtn,  .libraryFrame div .oculusVrAppBtn{
        margin-top: 1vw;
        width: 55vw;
        padding: 0;
        line-height: 7vw;
        height: 7vw;
        font-size: 3vw;
        text-align: center;
    }
    .libraryFrame span.btnArea{
        width: auto;
        justify-content: flex-end;
    }
    .bookRegistBtn{
        width:75vw;
        height:10vw;
        line-height:10vw;
        padding:0 10vw;
        font-size:3.5vw;
    }



    .codeRegiBx .titleBox{
        height:12vw;
        margin-bottom:5vw;
    }
    .codeRegiBx .titleBox>h5{
        font-size:4.5vw;
        height:12vw;
        line-height:12vw;
    }
    .codeRegiBx .titleBox>div{
        font-size:6.5vw;
    }
    .codeRegiBx .subTextBox>h6{
        font-size: 4vw;
        margin-bottom: 2vw;
    }
    .codeRegiBx .codeRegiBxoculusCode{
        font-size: 3.5vw;
    }
    .codeRegiBx .codeRegiBxoculusCodeNone p{
        font-size: 3.5vw;
    }
    
    .codeInputFrame{
        flex-direction: column;
        align-items: inherit;
        margin-bottom:5vw;
    }
    .codeInputFrame p{
        margin-right:1vw;
        height:6vw;
        line-height:6vw;
        font-size:3.7vw;
        width:100%;
        margin-bottom:2vw;
    }
    .codeInputFrame span span{
        font-size: 6vw;
        top: 1vw;
    }
    .codeInputFrame input{
        height:8vw;
        width:45%;
        line-height:6vw;
        font-size:3.5vw;
    }
    .codeRegiBx form>p{
        font-size:3.7vw;
    }
    .codeRegiBx form p.errMsg{
        font-size:3.2vw;
        padding-bottom:1.6vw;
    }
    .codeRegiBx form .codeRegiBtn{
        line-height:12vw;
        margin:15vw 0 1vw;
        font-size:4vw;
        width:100%;
        text-align: center;
    }


    .addressRegiBx .titleBox{
        height:12vw;
        margin-bottom:5vw;
    }
    .addressRegiBx .titleBox>h5{
        font-size:4.5vw;
        height:12vw;
        line-height:12vw;
    }
    .addressRegiBx .titleBox>div{
        font-size:6.5vw;
    }
    .addressRegiBx p{
        margin-right:1vw;
        height:auto;
        line-height:6vw;
        font-size:3.7vw;
        width:100%;
        margin-bottom:2vw;
    }
    .addressInputFrame{
        margin-bottom:5vw;
    }
    
    .addressInputFrame input{
        height:10vw;
        line-height:10vw;
        font-size:3.5vw;
        margin-bottom:2vw;
        padding:0 2vw;
    }
    .addressInputFrame input:nth-child(1){width:45%;}
    .addressInputFrame span{
        width:52%;
        height:10vw;
        margin-left:3%;
        line-height:10vw;
        font-size:3.5vw;
    }
    .addressRegiCont .addressRegiBtn{
        line-height:12vw;
        margin:5vw 0 1vw;
        font-size:4vw;
        width:100%;
        text-align: center;
    }




    .noDataText{
        font-size:3vw;
        line-height:5vw;
        padding-bottom:5vw;
    }
    .myProgramFrame{
        line-height:8vw;
        font-size:3.5vw;
        padding:2vw;
    }
    .myProgramFrame li{
        font-size:3.3vw;
    }
    .myProgramFrame>li span{
        width:28%;
        margin-right:3vw;
        font-size:3.5vw;
    }
    .myProgramFrame>li span:nth-child(2){
        margin-left:28%;
        padding-left:3vw;
        border-left:none;
    }

    
    .myBookFrame{
        line-height:8vw;
        font-size:3.5vw;
        padding:2vw;
    }
    .myBookFrame li{
        font-size:3.3vw;
    }
    .myBookFrame>li span{
        width:28%;
        margin-right:3vw;
        font-size:3.5vw;
    }


    .payHistoryFrame, 
    .slpStatusFrame{
        line-height:8vw;
        font-size:3.3vw;  
        padding-bottom:5vw;  
    }
    .payHistoryFrame>li{
        font-size:3.3vw;
    }
    .payHistoryFrame>li:nth-last-child(1), .slpStatusFrame>li:nth-last-child(1){
        align-items: inherit;
    }
    .payHistoryFrame>li span,
    .slpStatusFrame>li span{
        width:30%;
        line-height:8vw;
    }
    .payHistoryFrame>li span:nth-child(1), 
    .slpStatusFrame>li span:nth-child(1){
        font-size:3.5vw;
    }
    .payHistoryFrame>li span:nth-child(2), 
    .slpStatusFrame>li span:nth-child(2){
        font-size:3vw;
        width:70%;
        line-height:8vw;
        display:inline-flex;
        flex-direction:column;
        justify-content: flex-start;
        align-items: inherit;
        text-align:left;

    }
    .payHistoryFrame>li span button, 
    .slpStatusFrame>li span button{
        width:100%;
        padding:0 ;
        line-height:10vw;
        height:10vw;
        font-size:3vw;
        text-align:center;
    }
    .payHistoryFrame>li span button:nth-child(2),
    .payHistoryFrame>li span button:nth-child(3), 
    .slpStatusFrame>li span button:nth-child(2),
    .slpStatusFrame>li span button:nth-child(3){
        border-top:none !important;
        border-left-width:1px !important;
    }


   
    /* orderCancle */
    .orderCancleFrame{
        width:90vw;
        padding:2vw 4vw 5vw;
        
    }
    .orderCancleFrame .canclePopTitle{
        margin-bottom:5vw;
    }
    .orderCancleFrame .canclePopTitle h5{
        font-size:4vw;
    }
    .orderCancleFrame .canclePopTitle div{
        font-size:7vw;
    }
    .orderCancleFrame .canclePopList{
        margin-bottom:8vw;
        max-height:60vw;
    }
    .orderCancleFrame .canclePopList .payHistoryFrame li.payCancleBtn button{
        padding:0 1.5vw;
        height:auto;
        line-height:8vw;
    }
    .orderCancleFrame .canclePopList h6{
        font-size:3.5vw;
    }
    .orderCancleFrame .canclePopList .payHistoryFrame li{
        font-size:3vw;
        line-height:4vw;
    }
    .orderCancleFrame .canclePopReason{
        margin-bottom:8vw;
    }
    .orderCancleFrame .canclePopReason h6{
        font-size:3.5vw;
    }
    .orderCancleFrame .canclePopReason ul{
        flex-direction: column;
    }
    .orderCancleFrame .canclePopReason ul li{
        margin-bottom:2vw;
    }
    .orderCancleFrame .canclePopReason ul li span{
        font-size:3vw;
    }
    .orderCancleFrame .canclePopGuide p{
        font-size:3.5vw;
        line-height:4.5vw;
    }
    .orderCancleFrame .canclePopBtn a{
        font-size:3.5vw;
    }
    .orderCancleFrame .canclePopBtn button{
        font-size:3.5vw;
        padding:0 4vw;
        line-height:8vw;  
    }

    .orderCancleFrame .cancleAccounInput select{
        height:8vw;
        font-size:3.5vw;
    }
    .orderCancleFrame .cancleAccounInput input{
        padding:0 1.5vw;
        height:8vw;
        line-height:8vw;
        font-size:3.5vw;
        width:50vw;
    }

    /* bookPublishBg */
    .bookPublishFrame{
        width:90vw;
        padding:2vw 4vw 5vw;
    }
    .bookPublishFrame .publPopTitle{
        margin-bottom:5vw;
    }
    .bookPublishFrame .publPopTitle h5{
        font-size:4vw;
    }
    .bookPublishFrame .publPopTitle div{
        font-size:7vw;
    }
    .bookPublishFrame>p{
        font-size:3vw;
        padding-bottom:5vw;
    }
    .bookPublishFrame .publPopInfo{
        margin-bottom:10vw;
    }
    .bookPublishFrame .publPopInfo li span{
        font-size:3vw;
        line-height:8vw;
    }
    .bookPublishFrame .publPopAddress{
        margin-bottom:3vw;
    }
    .bookPublishFrame .publPopAddress p{
        font-size:3.5vw;
        padding-bottom:2vw;
    }
    .bookPublishFrame .publPopAddress>div>p{
        line-height:4.5vw;
        padding-bottom:2vw;
    }
    .bookPublishFrame .publPopAddress>div>span{
        font-size:3.5vw;
        padding:0 3vw;
        height:8vw;
        line-height:8vw;        
    } 
    .bookPublishFrame .PublPopChangeAddress .PublPopChAddrBtn button{
        font-size:3.5vw;
        padding:0 5vw;
        height:8vw;
        line-height:8vw;
    }
    .addressPopupOut .addressPopupIn>button{
        font-size:3.5vw;
        padding:0 5vw;
        height:8vw;
        line-height:8vw;
    }
    .addressPopupOut{
        width: 100%;
        height: 70%;
    }

    /* deviceRental */
    .deviceRentFrame{
        width:90vw;
        padding:2vw 4vw 5vw;
    }
    .deviceRentFrame .rentPopTitle{
        margin-bottom:5vw;
    }
    .deviceRentFrame .rentPopTitle h5{
        font-size:4vw;
    }
    .deviceRentFrame .rentPopTitle div{
        font-size:7vw;
    }
    .deviceRentFrame .rentPopList{
        margin-bottom:3vw;
        max-height:70vw;
    }
    .deviceRentFrame .rentPopList p{
        font-size:3vw;
        display:inherit;
        flex-direction: inherit;
        margin-bottom:7vw;
    }
    .deviceRentFrame .rentPopList p>span:nth-child(2){
        margin-left:1vw;
    }
    .deviceRentFrame .rentPopList h6{
        font-size:3.5vw;
        margin-bottom:3vw;
    }
    .deviceRentFrame .rentPopList .payHistoryFrame{
        margin-bottom:0;
    }
    
    .deviceRentFrame .rentPopList .payHistoryFrame li{
        font-size:3vw;
        line-height:6vw;
    }
    .deviceRentFrame .rentPopList .payHistoryFrame:nth-child(2) li{
        line-height:8vw;
    }
    .deviceRentFrame .rentPopList .payHistoryFrame li:nth-child(1){width:10% !important;}
    .deviceRentFrame .rentPopList .payHistoryFrame li:nth-child(2){width:40% !important;}
    .deviceRentFrame .rentPopList .payHistoryFrame li:nth-child(3){width:30% !important;}
    .deviceRentFrame .rentPopList .payHistoryFrame li:nth-child(4){width:20% !important;}
    .deviceRentFrame>p{
        font-size:3vw;
        padding-bottom:8vw;
    }
    .deviceRentFrame .rentPopInfo{
        margin-bottom:8vw;
    }
    .deviceRentFrame .rentPopInfo li{
        flex-direction: column;
        align-items: inherit;
    }
    .deviceRentFrame .rentPopInfo li span{
        font-size:3vw;
    }
    .deviceRentFrame .rentPopInfo li>span:nth-child(1){
        font-size:3.2vw;
        width:100%;
    }
    .deviceRentFrame .rentPopGuide{
        font-size:3vw;
        line-height:4.5vw;
    } 
    .deviceRentFrame .rentPopBtn button{
        font-size:3vw;
        padding:0 3vw;
        line-height:8vw;
    }




    /* programUserManage */

    .deviceRentFrame .programUserManage h6 {
        font-size: 3.5vw;
    }
    .deviceRentFrame .programUserManage .payHistoryFrame{
        padding:1vw 0;
    }
    .deviceRentFrame .programUserManage .payHistoryFrame li {
        font-size: 3vw;
        line-height: 4vw;
        word-break: break-all;
        padding:0.2vw 0;
    }
    .deviceRentFrame .programUserManage .payHistoryFrame li:nth-child(1) {width: 15% !important;}
    .deviceRentFrame .programUserManage .payHistoryFrame li:nth-child(2) {width: 20% !important; padding-left:1vw; padding-right:1vw;}
    .deviceRentFrame .programUserManage .payHistoryFrame li:nth-child(3) {width: 40% !important; padding:0 1vw;padding-right:3vw; }
    .deviceRentFrame .programUserManage .payHistoryFrame li:nth-child(4) {width: 25% !important; word-break: keep-all;}
    .deviceRentFrame .programUserManage .payHistoryFrame li input{
        border:1px solid #000000;
        height:8vw;
        line-height:8vw;
        width:100%;
        font-size:3vw;
    }
    .deviceRentFrame .programUserManage .payHistoryFrame li button{
        border-width:1px;
        height:auto;
        line-height:4vw;
        font-size:3vw;
    }









    p.myShareTxt{
        line-height:1;
        padding-top:2vw;
        padding-bottom:5vw;
        font-size:3.5vw;
    }
    .myShareFrame{
        height:10vw;
        line-height:10vw;
        font-size:3.5vw;
        padding:0;
        margin-left:2vw;
        margin-right:2vw;
    }
    .myShareFrame:nth-last-child(1){
        border-bottom:0;
    }
    .myShareFrame>li{
        height:10vw;
        line-height:10vw;
    }
    .myShareFrame>li:nth-child(1){width:60%;}
    .myShareFrame>li:nth-child(2){width:25%;}
    .myShareFrame>li:nth-child(3){width:auto;}
    .myShareFrame>li>button{
        width:100%;
        height:10vw;
        font-size:3.5vw;
        line-height:10vw;
        text-align:right;
        padding:0;
    }


    /* userGallPopFrame */
    .userGallPopBox{
        padding:10vw 5.2vw;
    }





    .myInfoFrame>ul>li{
        margin-bottom:3vw;
        line-height:10vw;
    }
    .myInfoFrame>ul>li>span{
        font-size:3.5vw;
    }
    .myInfoFrame>ul>li>span.infoCate{
        width:23vw;
        line-height:10vw;
        padding:0 2vw;
        font-size:3.5vw;
    }
    .myInfoFrame>ul>li>input{
        width:40vw;
        height:10vw;
        font-size:3.5vw;
        padding:0 2vw;
        line-height:10vw;
    }
    .myInfoFrame>ul>li>span:nth-child(2){
        height:auto;
        flex-direction: column;
    }
    .myInfoFrame>ul>li>span>input{
        width:40vw;
        height:10vw;
        font-size:3.5vw;
        padding:0 2vw;
        line-height:10vw;
        margin-bottom:2vw;
    }
    .myInfoFrame>ul>li>span>button{
        font-size:3.5vw;
        width:40vw;
        height:10vw;
        line-height:10vw;
    }

    .myInfoFrame>ul>li>button.infoChangeBtn{
        margin-left:24vw;
        width:40vw;
        font-size:3.6vw;
        line-height:10vw;
    }
    .pwChangeGroup>input{
        width:40vw;
        height:10vw;
        font-size:3.5vw;
        padding:0 2vw;
        margin-right:0;
        margin-bottom:2vw;  
    }
    .pwChangeGroup>button{
        width:40vw;
        height:10vw;
        line-height:10vw;
        font-size:3.5vw;
        padding:0 4vw;
    }

    .myInfoFrame>ul>li>span.infoDelLink{
        font-size:3.5vw;
        padding-right:7vw;
    }
    .infoDelBtnGroup{
        margin-top:5%;
        margin-bottom:5%;
    }
    .infoDelBtnGroup>button{
        height:10vw;
        padding:0 8vw;
        font-size:4vw;
    }

    
    .secessionAlertBx>.secessionAlertTitle{
        height:auto;
        align-items: flex-start;
    }
    .secessionAlertBx>.secessionAlertTitle>h5{
        font-size:4vw;
        height:auto;
        text-align: left;
        margin-bottom:2vw;
        display: flex;
        flex-direction: column;
    }
    .secessionAlertBx>.secessionAlertTitle>button{
        font-size:7vw;
        margin-bottom:0;
        width:auto;
        margin-top:0.5vw;
    }
    .secessionAlertCont>h6{
        font-size:3.5vw;
    }
    .secessionAlertCont>ul{
        margin-bottom:5vw;
    }
    .secessionAlertCont>ul>li{
        font-size:3.2vw;
        line-height:4.5vw;
    }




}
@media (max-width:280px){
    .codeRegiBx .subTextBox>ul.codeRegiBxinfoMobile>li{
        font-size: 4vw;
        margin-bottom: 2.5vw;
    }
}
