/* div {
    border: 1px solid white
} */
.contactBg{
    width:100%; 
    height:auto;
    background:#eeeeee;

}

.contactFrame{
    width:100%;
    height:100%;
    margin:0 auto;
    padding:7.8vw 5.2vw;
    display:flex;
    flex-direction: column;
}

.contactFrame h3{
    font-size:3vw;
    line-height:1;
    color:#000000;
    padding-bottom:5vw;
    font-weight:500;
}

.contactFrame>.inputGroup{
    width:100%;
    height:100%;
    /* border:1px solid red; */
    display:flex;
    flex-direction: row-reverse;
    
}
.contGroup{
    width:60%;
    height:100%;
    /* border:1px solid yellow; */
    padding:0 0 0 3.1vw;
}
.contGroup .radioGroup{
    display: flex;
    align-items: baseline;
    margin-top:2.1vw;
}
.contGroup .radioGroup .radioTitle{
    font-size: 1.1vw;
    font-weight: bold;
    margin-right:1vw;
}
.contGroup .radioGroup .radioBtn{
    /* margin-bottom:2.1vw; */
}


.contGroup .radioBtn div{
    /* width:20vw; */
    display:inline-block;
    margin-right:1vw;
    margin-bottom:0.5vw;
    display:inline-flex;
    flex-direction:row;
    align-content: stretch;
    align-items: center;
}

.contGroup .radioBtn div input{
    height:0.8vw;
    cursor: pointer;
    margin:0;
}

.contGroup .radioBtn div span{
    color:#000000;
    font-size:1vw;
    text-align:center;
    line-height:1;
    padding-left:0.5vw; 
    
}



.contGroup .textareaBx{
    width:100%; 
    height:23vw;
    margin: 2vw 0;
    /* padding:1vw; */
    /* border:1px solid #ffffff; */
    font-size:1vw;
    
    color:#000000;
    /* word-break:keep-all;
    box-shadow: inset -2px -2px 5px rgb(63, 63, 63, 0.9),
    inset 3px 3px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px; */
    outline: none;
}

.contGroup .textareaBx textarea{
    width:100%; 
    height:100%;
    background:none; 
    padding:1vw; 
    border-radius: 0;
    border:1px solid #000000;
    outline:none;
    box-shadow: none;
    color:#000000;
    /* word-break:keep-all;
    word-wrap: break-word; */
    resize:none;
    word-break: keep-all;
    font-size:1vw;
}
.contGroup .textareaBx textarea::-webkit-scrollbar{
    width:5px;
  }
  .contGroup .textareaBx textarea::-webkit-scrollbar-thumb{
    background-color:#b3c7db;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
  }
  .contGroup .textareaBx textarea::-webkit-scrollbar-track{
    background:transparent;
    border-radius:5px;
    box-shadow:inset 0px 0px 3px #ffffff;
  }

  
.centerLine{
    width:2px;
    /* height:100%; */
    display:block;
    background:#000000;
    /* background:#282828;
    box-shadow:  -2px -2px 5px rgb(63, 63, 63, 0.9),
     3px 3px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px; */
}

.infoGroup{
    width:40%;
    height:100%;
    
    padding-right:3.1vw;
    /* border-right:3px solid #2c2c2c; */
}


.emphasizeText{
    font-size:1vw;
    color:#c06060;
    padding-bottom:1vw;
}

.infoGroup>input{
    width:100%; 
    height:3vw;
    background-color:transparent !important; 
    border:1px solid #000000;
    /* border-radius:0; */
    margin-bottom:1vw;
    font-size:1vw;
    /* box-shadow: inset -2px -2px 10px rgb(63, 63, 63, 0.9),
              inset 3px 3px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px; */
    /* border:1px solid #ffffff; */
    color:#000000;
    padding:0 1vw;
    outline: none;
    border-radius:0;
}

.infoGroup .textareaBx{
    width:100%; 
    height:21vw;
    /* padding:1vw; */
    /* border:1px solid #ffffff; */
    font-size:1vw;
    
    color:#000000;
    /* word-break:keep-all;
    box-shadow: inset -2px -2px 5px rgb(63, 63, 63, 0.9),
    inset 3px 3px 5px rgba(0, 0, 0, 0.5);
    border-radius: 5px; */
    outline: none;
}

.infoGroup .textareaBx textarea{
    width:100%; 
    height:100%;
    background:none; 
    padding:1vw;
    border-radius: 0;
    border:1px solid #000000;
    outline:none;
    box-shadow: none;
    color:#000000;
    /* word-break:keep-all;
    word-wrap: break-word; */
    resize:none;
    word-break: keep-all;
    font-size:1vw;
}
.infoGroup .textareaBx textarea::-webkit-scrollbar{
    width:5px;
  }
  .infoGroup .textareaBx textarea::-webkit-scrollbar-thumb{
    background-color:#b3c7db;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
  }
  .infoGroup .textareaBx textarea::-webkit-scrollbar-track{
    background:transparent;
    border-radius:5px;
    box-shadow:inset 0px 0px 3px #ffffff;
  }




.infoGroup a.termsLink{
    font-size:1vw;
    color:#000000;
    text-decoration: none;
    text-decoration: underline;
    margin-left:auto;
    text-align:right;
    display:block;
    
}

.infoGroup .checkBx{
    display:flex;
    padding-top:1.3vw;
    padding-bottom:3.1vw;
    flex-direction: row;
    align-items: center;
}
.errorMsg{
    font-size:1vw;
    color:#c06060;
}
.infoGroup>.checkBx>input{
    height:0.8vw;
    height:0.8vw;
    display: block;
    margin-right:0.7vw;
    /* box-shadow: inset -2px -2px 5px rgb(63, 63, 63, 0.9),
              inset 3px 3px 5px rgba(0, 0, 0, 0.5); */
    background:#eeeeee;
}

.infoGroup .checkBx span{
    font-size:1vw;
    color: #000000;
    display: inline-block;
    line-height:0.8vw;
   
}

.infoGroup button{
    width:100%; 
    height:4vw;
    background:#000f1e;
    color:#ffffff;
    font-size:1.2vw;
    line-height:4vw;
}



