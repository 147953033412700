
@media (max-width:1600px){
    .bookGuideFrame{
        width:70vw;
    }
    .bookGuideFrame>ul>li .bookGuideGroup li:nth-child(1){
        padding:2vw;
        height:50vh;
    }
    .bookGuideFrame>ul>li .bookGuideGroup li:nth-child(2){
        font-size:1.8vw;
        padding:2vw 0 1vw;
    }
    .bookGuideFrame>ul>li .bookGuideGroup li:nth-child(3){
        font-size:1.2vw;
        line-height:2.5vw;
    }
    .bookGuideBtn div{
        font-size:1.3vw;
        padding:0 2vw;
        height:3vw;
        line-height:3vw;
    }




}
@media (max-width:1300px){
    .bookGuideFrame>ul>li{
        padding:0;
    }
    .bookGuidePager{
        padding:0 8vw;
    }

}
@media (max-width:834px){
    .bookGuideFrame{
        width:90vw;
    }
    .bookGuideFrame>ul>li{
        padding:0 12vw;
    }
    .bookGuideFrame>ul>li .bookGuideGroup li:nth-child(1){
        padding:3vw;
        height:50vh;
    }
    .bookGuideFrame>ul>li .bookGuideGroup li:nth-child(2){
        font-size:2.5vw;
        padding:4vw 0 3.5vw;
    }
    .bookGuideFrame>ul>li .bookGuideGroup li:nth-child(3){
        font-size:2vw;
        line-height:4.5vw;
    }
    .bookGuideFrame>ul>li .bookGuideGroup li:nth-child(1) img.dbookPcImage{
        display:none;
    }
    .bookGuideFrame>ul>li .bookGuideGroup li:nth-child(1) img.dbookTaMoImage{
        height:100%;
        display:block;
    }
    .bookGuideFrame>ul>li .bookGuideGroup li:nth-child(3) span.textBorder{
        padding:0 1.5vw;
        height:4vw;
        line-height:4vw;
        margin-right:1vw;
    }
    .bookGuideFrame>ul>li.bookGuide1 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(2),
    .bookGuideFrame>ul>li.bookGuide1 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(3),
    .bookGuideFrame>ul>li.bookGuide2 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(2),
    .bookGuideFrame>ul>li.bookGuide3 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(2),
    .bookGuideFrame>ul>li.bookGuide4 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(1){
        margin:0 1vw;
    }


    .bookGuidePager{
        padding:0 8vw;
    }
    .bookGuidePager div{
        font-size:10vw;
        font-weight:300;
    }
    .bookGuideBtn{
        bottom:6%;
    }
    .bookGuideBtn div{
        font-size:2vw;
        padding:0 4vw;
        height:5vw;
        line-height:5vw;
    }



    
    .manualBg .manualDelBtn{
        font-size:5vw;
        right:8%;
    }





    .bookTop{
        margin-top:5vw;
    }
    .booInfoGroup {
        display:flex;
        flex-direction: column;
        align-items: inherit;
    }
    .booInfoGroup .iconFrame{
        width:6vw;
        height:6vw;
    }
    .booInfoGroup span{
        font-size:2vw;
        padding:2vw 0;
    }
    .booInfoGroup button{
        font-size:2vw;
        padding:0 1.5vw;
        line-height:4.5vw;
    }
    .linkGroup{
        position: absolute;
        top: 5vw;
        right:5vw;
        text-align: right;
        margin-bottom: 2vw;
    }
    .linkGroup div{
        font-size:2vw;
        height:6vw;
        line-height:6vw;
        padding:0 2vw;
    } 
    .linkGroup span{
        font-size:2vw;
        padding:0 2vw;
        height:6vw;
        line-height:6vw;
    }


    .bookMiddle{
        top:18vw;
        left:5.2vw;
    }
    .bookMiddle p{
        font-size:3vw;
    }
    


    .bookBottom{
        height:6vw;
        margin-top:6vw;
        position:inherit;
        bottom:inherit;
        right:inherit;
    }
    .bookBottom button{
        font-size:2vw;
        line-height:6vw;
        padding:0 3vw;
    }
    .bookBottom a,
    .bookBottom span{
        font-size:2vw;
        line-height:6vw;
        padding:0 3vw;
    }
    
}
@media (max-width:480px){

    .bookGuideFrame>ul>li{
        padding:0 6vw;
    }
    .bookGuideFrame>ul>li .bookGuideGroup li:nth-child(1){
        padding:5vw;
        height:45vh;
    }
    .bookGuideFrame>ul>li .bookGuideGroup li:nth-child(2){
        font-size:3.5vw;
        padding:6vw 0 5vw;
    }
    .bookGuideFrame>ul>li .bookGuideGroup li:nth-child(3){
        font-size:3vw;
        line-height:6vw;
    }
    .bookGuideFrame>ul>li .bookGuideGroup li:nth-child(3) span.textBorder{
        padding:0 2vw;
        height:6vw;
        line-height:6vw;
        margin-right:2vw;
    }
    .bookGuideFrame>ul>li.bookGuide1 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(2),
    .bookGuideFrame>ul>li.bookGuide1 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(3),
    .bookGuideFrame>ul>li.bookGuide2 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(2),
    .bookGuideFrame>ul>li.bookGuide3 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(2),
    .bookGuideFrame>ul>li.bookGuide4 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(1){
        margin:0 2vw;
    }


    .bookGuidePager{
        padding:0 2vw;
    }
    .bookGuidePager div{
        font-size:12vw;
        font-weight:300;
    }
    .bookGuideBtn div{
        font-size:3vw;
        padding:0 6vw;
        height:8vw;
        line-height:8vw;
    }





    .manualBg .deviceMenualGuideFrame{
        width:80vw;
        height:85vh;
        padding:6vw;
    }
    .manualBg .manualDelBtn{
        font-size:8vw;
        top:1%;
        right:9%;
    }





    .bookTop{
        display: inherit;
        flex-direction:inherit;
        align-items: inherit;
        position:relative;
        margin-top:5vw;
    }
    .booInfoGroup .iconFrame{
        width:8vw;
        height:8vw;
    }
    .linkGroup{
        position:absolute;
        top:0;
        right:0;
        text-align:right;
        margin-bottom:2vw;
    }
    .linkGroup div{
        font-size:3.5vw;
        height:8vw;
        line-height:8vw;
        padding:0 3vw;
    } 
    .linkGroup div.delinkBtnPc{
        display:inline-block;
        margin-right:2vw;
    }
    .linkGroup div.infoLinkBtnPc{
        display:none;
    }
    .linkGroup div.infoLinkBtnMobile{
        display:inline-block;
        border:1px solid #c56e6e;
        margin-right:4vw;
    }
    .linkGroup span{
        font-size:3.5vw;
        padding:0 4vw;
        height:8vw;
        line-height:8vw;
    }
    .booInfoGroup{
        flex-direction:column;
        align-items: inherit;
    }
    .booInfoGroup .iconFrame{
        margin-bottom:4vw;
    }
    .booInfoGroup span{
        font-size:4vw;
        padding:0;
        padding-bottom:4vw;
    }
    .booInfoGroup button{
        font-size:4vw;
        padding:0 1.5vw;
        width:50%;
        line-height:8vw;
        display:inline-block;
        /* margin-left:auto; */
    }


    .bookMiddle{
        top:30vw;
        left:5.2vw;
    }
    .bookMiddle p{
        font-size:6vw;
    }



    .bookBottom{
        width:100%;
        height:12vw;
        margin-top:14vw;
    }
    .bookBottom button{
        font-size:3.5vw;
        line-height:8vw;
        padding:0 4vw;
    }
    .bookBottom a,
    .bookBottom span{
        font-size:3.5vw;
        line-height:8vw;
        padding:0 4vw;
    }
}