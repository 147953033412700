@media (max-width:834px){


    .landingFrame{
        width:100%;
        height:50vw;
        padding:30vw 7.8vw;
        margin-left:0;
    }
    .landingFrame li{
        width:100%;
        height:auto;
        line-height:4vw;
    }
    .landingFrame .pwResetTitle{
        font-size:3vw;
        margin-bottom:5vw;
        font-weight:600;
    }
    .landingFrame .landingTxt{
        font-size:2vw; 
        line-height:4vw;
    }
    .landingFrame .landingBtn{
        width:auto;
        height:6vw;
    }
    .landingFrame .landingBtn a{
        line-height:6vw;
        font-size:2.5vw;
        font-weight:600; 
    }
    .landingBtnGroup>a{
        font-size:2.5vw;
        line-height:6vw;  
        height:6vw;
        padding:0 2vw;
    }



    .landingFrame .emailVeriTitle{
        font-size:3vw;
        margin-bottom:5vw;
        line-height:7vw;
    }
    .landingFrame .emailVeriTxt{
        font-size:2vw; 
        line-height:4vw;
    }
    
    .emailVerBtnGroup{
        width:100%; 
        margin-top:5vw;
    }
    
    .emailVerBtnGroup button{
        padding:0 2vw;
        height:6vw;
        font-size:2.5vw;
    }
    
}





@media (max-width:480px){

    .landingFrame{
        width:100%;
        height:50vw;
        padding:50vw 7.8vw;
        margin-left:0;
    }
    .landingFramePC{display:none;}
    .landingFrameMo{display:block;}
    .landingFrame li{
        width:100%;
        height:auto;
        line-height:4vw;
    }
    .landingFrame .pwResetTitle{
        font-size:6vw;
        margin-bottom:8vw;
        font-weight:600;
        line-height:8vw;
    }
    .landingFrame .landingTxt{
        font-size:4.5vw; 
        line-height:7vw;
        height:auto;
        display:flex;
        flex-direction: column;
        
    }
    
    .landingFrame .landingBtn{
        width:auto;
        height:10vw;
    }
    .landingFrame .landingBtn a{
        line-height:10vw;
        font-size:3.5vw;
        font-weight:600; 
    }
    .landingBtnGroup>a{
        font-size:3.5vw;
        line-height:10vw;  
        height:10vw;
        padding:0 4vw;
    }


    .landingFrame ul.emailVeriBoxPC{
        display:none;
    }
    .landingFrame ul.emailVeriBoxMo{
        display:block;
    }
    .landingFrame .emailVeriTitle{
        font-size:6vw;
        margin-bottom:5vw;
        line-height:8vw;
    }
    .landingFrame .emailVeriTxt{
        font-size:4vw; 
        line-height:6vw;
    }
    
    .emailVerBtnGroup{
        width:100%; 
        margin-top:10vw;
        flex-direction: column;
    }
    
    .emailVerBtnGroup button{
        height:12vw;
        font-size:3.5vw;
        padding:0 4vw;
    }
}