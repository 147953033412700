#signUpLoading{
    width:100%;
    height:100%;
    color:#000f1e;
    background:#ffffff;
    overflow:hidden;
}

.loadingContS{
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    display:flex;
    flex-direction: column;
    justify-content:space-between;
    width:100%;
}
.loadTitleS{
    width:100%;
    font-size:4vh;
    line-height:10vh;
    text-align:center;
}
.loadTextS{
    text-align:center;
    font-size:2vh;
    font-weight:300;
    line-height:5vh;
    margin-bottom:2vw;
}
.imgGroupS{
    position: relative;
    width:100%;
    height:25vw;
    
}
.movingS.movingSnake{
    width:10%;
    height:100%;
    padding:2vw;
    background:url("../../../images/signUpSpinner2.png") no-repeat 50% 50%;
    background-size: contain;
    position: absolute;
    left:20%;
    bottom:0;
    transform:translate(-50%);
    /* animation: 2s knight linear infinite; */
    animation-name:knight;
    animation-duration: 2s;
    animation-timing-function:linear;
    animation-delay:0;
    animation-iteration-count: infinite;
    animation-direction:alternate;
    z-index:5;
    border:1px solid red;
}
.movingS.movingPrince{
  width:auto;
  height:100%;
  padding:0;
  background:url("../../../images/signUpSpinner1.png") no-repeat 50% 50%;
  background-size: contain;
  position: absolute;
  left:70%;
  bottom:0;
  transform:translate(-50%);
  border:1px solid orange;
  z-index:5;
}

.shadowS{
    height: 5px;
    width: 40px;
    background: #000;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    bottom:0;
    transform: translatex(-50%);
    animation: 2s shadow linear infinite;
    display:none;
}
@keyframes knight {
    0% {
      left:0;
    }
    100% {
      left:100%;
    }
  }
  /* @keyframes shadow {
    0%,100% {
      width: 40px;
    }
    50% {
      width: 10px;
    }
  } */


