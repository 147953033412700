.invalidBtn{
    color:gray;
    border : 1px solid gray
}
.validBtn{
    color : black;
    border: 1px solid black
}

.headingTextBox{
    margin:12.5vw auto !important;
}



.frontPageBox>div,
.backPageBox>div{
    width:100%;
    height:100%;
}

.utilityBtnGroup{
    display:flex;
    flex-direction: row;
    /* border:1px solid yellowgreen; */
    justify-content: center;
    padding:0 10vw;
    margin-bottom:10vw;
    position:absolute;
    bottom:-8vw;
    left:0;
    width:100%;
}

.utilityBtnGroup .headingBtnBox{
    /* position:inherit;
    bottom:inherit;
    left:inherit; */
    left:10vw;
}
/* .showHeading{left:0}
.showHeadingText{left:0}
.hiddenHeadingText{left:-100%;} */


.utilityBtnGroup .bookMarkListBox{
    width:20vw;
    height:auto;
    position:relative;
    font-size:1.2vw;
    line-height:3vw;
    margin:0 auto;
    /* position:absolute;
    bottom:0vw;
    left:50%;
    -webkit-transform:translate(-50% 0);
    transform:translate(-50%); */
    background:#ffffff;
} 
.listHidden{
    display:none;
}
.listShow{
    border:1px solid #000000;
    border-bottom:none;
}
.bookMarkList{
    max-height:60vh;
    overflow:auto;
    border-bottom:none;
}
.bookMarkList::-webkit-scrollbar{
  width:5px;
}
.bookMarkList::-webkit-scrollbar-thumb{
  background-color:#b3c7db;
  border-radius:5px;
  background-clip:padding-box;
  border:1px solid transparent;
}
.bookMarkList::-webkit-scrollbar-track{
  background:transparent;
  border-radius:5px;
  box-shadow:inset 0px 0px 3px #ffffff;
}
/* 
.bookMarkList::-webkit-scrollbar{
  display:none;
}
.bookMarkList{
  -ms-overflow-style:none;
  scrollbar-width:none;
} */
.bookMarkList>span{
    padding:2vw;
    border:none;
    
}
.bookMarkList ul{
    font-size:0;
    padding:0.8vw 0;
    border-bottom:1px solid #dedede;
    font-size:0;
    /* border:1px solid yellowgreen; */
    min-height:1.5vw;
    height:auto;
}
.bookMarkList ul:after{
    content:"";
    display:block;
    clear:both;
}
.bookMarkList ul:nth-last-child(1){
    border:none;
}
.bookMarkList ul li{
    font-size:1vw;
    line-height:1.5vw;
    word-break: keep-all;
    float:left;
}
.bookMarkList ul li:nth-child(1){
    font-weight:600;
    cursor: pointer;
    display:inline-block;
    /* border:1px solid red; */
}
.bookMarkList ul li:nth-child(1):hover{
    color:rgb(255, 166, 0);
}
.bookMarkList ul li:nth-child(2){
    display:inline-flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    font-size:0.8vw;
    float:right;
    /* border:1px solid blue; */
}
.bookMarkList ul li:nth-child(2) span{
    margin-left:1vw;
}
.bookMarkList ul li:nth-child(2) span:nth-child(3){
    padding-left:0.6vw;
    /* line-height:1.8vw;
    height:1.8vw; */
    font-size:1vw;
    /* border:1px solid #dedede; */
    cursor: pointer;
}
.bookMarkList ul li:nth-child(2) span:nth-child(3):hover{
    color:red;
}
.bookMarkListBtn{
    width:100%;
    height:3vw;
    border:1px solid #000000;
    line-height:3vw;
    text-align:center;
    cursor: pointer;
}
.bookMarkListBtn:active{
    border:1px solid rgb(8, 46, 77) !important;
    background:rgb(8, 46, 77) !important;
    color:#ffffff !important;
}


.utilityBtnGroup .bookBtnBox{
    /* position:inherit;
    bottom:inherit;
    right:inherit; */
    right:10vw;
    width:auto;
    height:3vw;
    line-height:3vw;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    z-index:5;
}


.utilityBtnGroup .bookBtnBox div{
    height:3vw;
    display:flex;
    flex-direction: row;
}
.utilityBtnGroup .bookBtnBox .curPageSign{
    margin-right:1vw;
}
.utilityBtnGroup .bookBtnBox span.prevBtn{
    padding:0 1.5vw;
    border-right:none;
    color:#000000;
    cursor: pointer;
    background:#ffffff;
}
.utilityBtnGroup .bookBtnBox span.nextBtn{
    padding:0 1.5vw;
    color:#000000;
    cursor: pointer;
    background:#ffffff;
}
.utilityBtnGroup .bookBtnBox span.prevBtn:hover,
.utilityBtnGroup .bookBtnBox span.nextBtn:hover{
    background:#000000;
    color:#ffffff;
}



.userReviewFrame{
    width:50vw;
    height:auto;
    margin:0 auto;
    padding:2% 0;

}
.userReviewFrame ul{
    width:100%;
    height:100%;
    font-size:0;
    border:1px solid #000000;
    padding:5vw;
}
.userReviewFrame ul li{
    width:100%;
    height:auto;
    font-size:1.2vw;
    padding-bottom:1vw;
    word-break: keep-all;
}
.userReviewFrame ul li:nth-child(1){
    padding-bottom:3vw;
}
.userReviewFrame ul li:nth-child(1) span{
    font-size:1.5vw;
}
.userReviewFrame ul li input{
    font-size:1.2vw;
    width:100%;
    height:3vw;
    padding:0 1vw;
    border:1px solid #000000;
    border-radius:0;
    word-break: keep-all;
}
.userReviewFrame ul li textarea{
    font-size:1.2vw;
    width:100%;
    height:20vw;
    padding:1vw;
    line-height:2vw;
    resize: none;
    word-break: keep-all;
    border:1px solid #000000;
}
.userReviewFrame ul li textarea::-webkit-scrollbar{
    width:5px;
}
.userReviewFrame ul li textarea::-webkit-scrollbar-thumb{
    background-color:#b3c7db;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
}
.userReviewFrame ul li textarea::-webkit-scrollbar-track{
    background:transparent;
    border-radius:5px;
    box-shadow:inset 0px 0px 3px #ffffff;
}
.userReviewFrame ul li:nth-child(5){
    text-align:right;
}


.userReviewFrame ul li button{
    padding:0 2vw;
    height:3vw;
    line-height:3vw;
    font-size:1.2vw;
    border:1px solid #000000;
}







