.userGuidesFrame{
    padding:13vw 5vw 0;
}
/* userGuidesTabBtnGroup */
.userGuidesFrame > div > .userGuidesTabBtnGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.userGuidesFrame > div > .userGuidesTabBtnGroup div {
    font-size: 1.2vw;
    text-align: center;
    border: 1px solid #dedede;
    width: 34%;
    height: 4vw;
    line-height: 4vw;
    cursor: pointer;
    color: #aaaaaa;
    border-right: 0;
}
.userGuidesFrame > div > .userGuidesTabBtnGroup div:hover {
    background: #eeeeee;
    color: #000000;
}
.userGuidesFrame > div > .userGuidesTabBtnGroup div:nth-child(1) {
    border-right: 0;
    border-bottom: 0;
    color: #000000;
}
.userGuidesFrame > div > .userGuidesTabBtnGroup div:nth-last-child(1) {
    border-right: 1px solid #dedede;
}
.userGuidesFrame > div > .userGuidesTabBtnGroup div:nth-child(3) span{
    display:inline-block; 
    margin-right:0.5vw;
}

/* userGuidesTabContGroup */
.userGuidesFrame > div > .userGuidesTabContGroup {
    padding: 3vw 2vw;
    border: 1px solid #dedede;
    border-top: none;
    font-size: 1vw;
    min-height:90vh;
}
.userGuidesFrame > div > .userGuidesTabContGroup>div{
    padding:3vw;
}
.userGuidesFrame > div > .userGuidesTabContGroup>div>h5{
    font-size:2vw;
    font-weight:600;
    line-height:1;
    padding-bottom:2vw;
    border-bottom:1px solid #000000;
    margin-bottom:2vw;
}






/* onLearnGuideFrame */
.onLearnGuideFrame ul li ul{
    display:flex;
    flex-direction:row;
    margin-bottom:2vw;
    min-height:10vw;
}
.onLearnGuideFrame>ul{
    padding:2vw 0 3vw;
}
.onLearnGuideFrame ul li ul li.onlineGuideImg{
    border:1px solid orange;
    width:20%;
    padding:0 5vw;
    border:1px solid #dedede;
}
.onLearnGuideFrame ul li ul li.onlineGuideImg span{
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size:contain;
    display:block;
    height:100%;
    /* opacity:0.5; */
}
.onLearnGuideFrame ul li:nth-child(1) ul li.onlineGuideImg span{background-image:url("../../images/online1.png");}
.onLearnGuideFrame ul li:nth-child(2) ul li.onlineGuideImg span{background-image:url("../../images/online2.png");}
.onLearnGuideFrame ul li:nth-child(3) ul li.onlineGuideImg span{background-image:url("../../images/online3.png");}
.onLearnGuideFrame ul li:nth-child(4) ul li.onlineGuideImg span{background-image:url("../../images/online4.png");}
.onLearnGuideFrame ul li:nth-child(5) ul li.onlineGuideImg span{background-image:url("../../images/online5.png");}
.onLearnGuideFrame ul li:nth-child(6) ul li.onlineGuideImg span{background-image:url("../../images/online6.png");}


.onLearnGuideFrame ul li ul li.onlineGuideText{
    width:80%;
    padding-left:2vw;
    display: flex;
    flex-direction: column;
}
.onLearnGuideFrame ul li ul li.onlineGuideText span{
    word-break: keep-all;
    font-size:1.2vw;
    line-height:2vw;
}
.onLearnGuideFrame ul li ul li.onlineGuideText span:nth-child(1){
    font-size:1.5vw;
    line-height:1;
    padding-bottom:1vw;
    padding-top:0.5vw;
    font-weight:600;
}
.onLearnGuideFrame ul li ul li.onlineGuideText span:nth-child(2){
    color:#bbbbbb;
}

/* offLearnGuideFrame */
.offLearnGuideFrame ul li ul{
    display:flex;
    flex-direction:row;
    margin-bottom:2vw;
    min-height:10vw;
}
.offLearnGuideFrame>ul{
    padding:2vw 0 3vw;
}
.offLearnGuideFrame ul li ul li.offlineGuideImg{
    width:20%;
    padding:0 5vw;
    border:1px solid #dedede;
}
.offLearnGuideFrame ul li ul li.offlineGuideImg span{
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size:contain;
    display:block;
    height:100%;
    /* opacity:0.5; */
}
.offLearnGuideFrame ul li:nth-child(1) ul li.offlineGuideImg span{background-image:url("../../images/offline1.png");}
.offLearnGuideFrame ul li:nth-child(2) ul li.offlineGuideImg span{background-image:url("../../images/offline2.png");}
.offLearnGuideFrame ul li:nth-child(3) ul li.offlineGuideImg span{background-image:url("../../images/offline3.png");}
.offLearnGuideFrame ul li:nth-child(4) ul li.offlineGuideImg span{background-image:url("../../images/offline4.png");}


.offLearnGuideFrame ul li ul li.offlineGuideText{
    width:80%;
    padding-left:2vw;
    display: flex;
    flex-direction: column;
}
.offLearnGuideFrame ul li ul li.offlineGuideText span{
    word-break: keep-all;
    font-size:1.2vw;
    line-height:2vw;
}
.offLearnGuideFrame ul li ul li.offlineGuideText span:nth-child(1){
    font-size:1.5vw;
    line-height:1;
    padding-bottom:1vw;
    padding-top:0.5vw;
    font-weight:600;
}
.offLearnGuideFrame ul li ul li.offlineGuideText span:nth-child(2){
    color:#bbbbbb;
}




/* deviceMenualGuideFrame */
.deviceMenualGuideFrame>h5{
    font-size:2vw;
    font-weight:600;
    line-height:1;
    padding-bottom:2vw;
    border-bottom:1px solid #000000;
    margin-bottom:2vw;
}
.deviceMenualGuideFrame>ul{
    width:100%;
    height:auto;
    margin-bottom:8vw;
}
.deviceMenualGuideFrame a{
    text-decoration: none;
}
.deviceMenualGuideFrame a:link{
    color : #c56e6e;
}
.deviceMenualGuideFrame a:visited{
    color : #c56e6e;
}
.deviceMenualGuideFrame a:hover{
    color : #c56e6e;
}
.deviceMenualGuideFrame a:active{
    color : #c56e6e;
}
.deviceMenualGuideFrame>ul>li{
    width:100%;
}
.deviceMenualGuideFrame>ul:nth-of-type(1)>li:nth-child(1),
.deviceMenualGuideFrame>ul:nth-of-type(1)>li:nth-child(3),
.deviceMenualGuideFrame>ul:nth-of-type(1)>li:nth-child(6){
    margin-bottom:2vw;
}
.deviceMenualGuideFrame>ul:nth-of-type(2)>li:nth-child(1){
    margin-bottom:2vw;
}
.deviceMenualGuideFrame>ul:nth-of-type(1)>li:nth-child(5){
    border-bottom:1px solid #dedede;
    margin-bottom:2vw;
}

.deviceMenualGuideFrame>ul>li>ul{
    display:flex;
    flex-direction:row;
    justify-content: flex-start;
    width:100%;
    margin-bottom:2vw;
}
.deviceMenualGuideFrame>ul>li>ul>li.deviceGuideImage{
    width:70%;
    height:auto;
    overflow:hidden;
}
.deviceMenualGuideFrame>ul>li>ul>li.deviceGuideImage img{
    width:100%;
    border:1px solid #dedede;
}
.deviceMenualGuideFrame>ul>li>ul>li.deviceGuideText{
    width:30%;
    padding-left:2vw;
    display:flex;
    flex-direction:column;
}

.deviceMenualGuideFrame>ul>li>ul>li.deviceGuideText>span{
    word-break:keep-all;
    font-size:1.2vw;
    line-height:2vw;
}
.deviceMenualGuideFrame>ul>li>ul>li.deviceGuideText>span>img{
    width: 3.5vw;
    margin-left: 1vw;
    margin-right: 1vw;
}
.deviceMenualGuideFrame>ul>li>ul>li.deviceGuideText>span.deviceGuideTextsmall{
    font-size:0.9vw;

}
.deviceMenualGuideFrame>ul>li>ul>li.deviceGuideText span.deviceGuideTextPoint{
    padding: 0.2vw 0.5vw;
    background-color: #000;
    color: #fff;

}
.deviceMenualGuideFrame>ul>li>ul>li.deviceGuideText>span:nth-child(1){
    font-size: 1.5vw;
    line-height: 1;
    padding-bottom: 1vw;
    padding-top: 0.5vw;
    font-weight: 600; 
}

/* rentalPolicyPopCont */
.RentalGuideFrame .rentalPolicyPopCont li{
    margin-bottom:3vw;
}
.RentalGuideFrame .rentalPolicyPopCont li h6{
    font-size:1.5vw;
    padding-bottom:0.8vw;
    font-weight:600; 
}
.RentalGuideFrame .rentalPolicyPopCont li span{
    font-size:1.2vw;
    line-height:1.8vw;
    word-break: keep-all;
}
.RentalGuideFrame .rentalPolicyPopCont li span span{
    font-size: 1vw;
    color: #c56e6e;
}