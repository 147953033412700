.downloadheight{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    min-height: 800px;
    margin-top: 160px;
}
.downloadheight h3{
    display: block;
    width: 100vw;
    color: #38639a;
    padding-left: 5vw;
}
.downloadheight h4{
    font-size: 2.5vw;
    margin: 1.5vw auto;
}
.downloadAR{
    width: 40vw;
}
.downloadVR{
    width: 40vw;
}
.container{
    display: flex;
    justify-content: space-around;
}
.containerline{
    content:'';
    width: 90vw;
    position: relative;
    margin: 2vw auto;
    height: 1px;
    background-color: #a4a4a4;
}
.downloadleft{
    display: flex;
    align-self: center;
    margin-top: 1.2vw;
}
.downloadright{
    align-items: center;
}

.container p{
    display: block;
    margin-bottom: 1vw;
}

.downloadmethod{
    display: block;
    width: 100vw;
    text-align: center;
    text-decoration: underline;
    color: #38639a;
}
@media screen and (min-width: 1024px){/* 데스크탑 */
    .downloadheight{flex-direction: row;}
    .downloadheight h3{font-size: 2vw;}
    .downloadVR, .downloadAR{ margin: 0 2vw;}
    .downloadAR{margin-right: 4vw;}
    .downloadheight h4{margin-left: 3vw;}
    .container{width: 40vw; margin: 0 4vw;}
    .containerline{display: none;}
    .container .downloadleft{ margin-right: 2vw;}
    .container p{font-size: 1.6vw; margin-top: 1.5vw; }
    .container img{width: 20vw;}
    .containerline{ border-bottom:none;}
    .downloadmethod.onlymobile{display: none;}
    .downloadmethod.pc{ font-size: 2vw; margin: 4vw 0;}
}
@media screen and (max-width: 1023px){ /*타블렛*/
    .downloadheight{min-height: 1000px; margin-top: 17vw}
    .downloadheight h3{font-size: 2.7vw;}
    .downloadVR,.downloadAR{margin: 0;}
    .downloadheight h4{padding-left: 5vw;}
    .container{width: 90vw; margin: 0 2vw;}
    .downloadright{padding: 2vw 0;}
    .container img{ width: 32vw;}
    .container p{font-size: 3vw; margin-top: 1vw;}
    .downloadmethod.onlymobile{font-size: 3vw; margin: 3vw 0;}
    .downloadmethod.pc{display: none;} 
}
@media screen and (max-width: 767px){ /* 모바일 */
    .downloadheight{min-height: 500px;}
    .downloadheight h3{font-size: 4vw;  margin: 3vw 0;}
    .downloadVR, .downloadAR{width: 100vw;}
    .downloadheight h4{font-size: 4vw; padding-left: 10vw; width: 100%;}
    .container{width: 100vw; margin: 0;}
    .container .downloadleft{height: 42vw;}
    .containerline{margin: 3vw auto;}
    .container img{width: 40vw;}
    .container p{font-size: 3.3vw;}
    .downloadmethod.onlymobile{font-size: 4vw; margin: 6vw 0;}
}
@media (orientation: landscape) and (max-width: 823px) { /* 가로형 */
    .downloadheight{min-height: 1000px;}
    .downloadheight h3{margin: 3.5vw 0; font-size: 3vw; padding-left: 6vw;}
}  
@media (orientation: landscape) and (max-width: 540px){/* 가로형 */
    .downloadheight{height: 280vh; padding-top: 5vw;}
    .downloadheight h3{margin: 0;}
}
@media screen and (max-width: 281px) { /* 갤럭시 폴드*/
    .downloadheight{min-height: 380px;}
    .downloadheight h3{ font-size: 3.8vw; height: 30px;}
    .container .downloadleft{height: 37vw;}
    .container img{width: 38vw;}
    .containerline{margin: 0 auto;}
    .container p{margin-bottom: 0.3vh;}
 } 