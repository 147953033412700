.xrsOutFrame{
    width:100%; 
    height:56.25vw;
    padding:0 0 0 5.2vw;
    margin-bottom:10vw;
}
.xrsOutFrame:nth-child(2){
    height:auto;
}
.xrsInFrame{
    width:100%;
    height:100%;
    padding-right:5.2vw;
    display:flex;
    flex-direction: row;
}

.xrs1stInFrame .contBox{
    width:55%;
    height:100%;
    background:url("../../../../images/xrbookSectionImage.png") no-repeat 50% 50%;
    background-size:contain;
}

.xrs1stInFrame .TitleBox{
    /* border:1px solid yellowgreen; */
    width:45%;
    height:100%;
    padding-left:5vw;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.xrs1stInFrame .TitleBox ul{
    font-size:0;
}
.xrs1stInFrame .TitleBox ul li.sectMenuText{
    font-size:1.5vw;
    font-weight:600;
}
.xrs1stInFrame .TitleBox ul li.contTitleText{
    font-size:4vw;
    font-weight:600;
    line-height:4.5vw;
    display:flex;
    flex-direction: column;
    padding:1vw 0 2vw;
}
.xrs1stInFrame .TitleBox ul li.contTitleText span:nth-child(1){
    font-size:2.5vw;
    line-height:1;
    /* color:#dedede; */
}
.xrs1stInFrame .TitleBox ul li.contentText{
    font-size:1.1vw;
    line-height:1.8vw;
    display:flex;
    flex-direction: column;
    padding-bottom:3vw;
    word-break: keep-all;
}
.xrs1stInFrame .TitleBox ul li.contentText span:nth-last-child(1){
    font-weight:600;
    font-size:2vw;
    padding-top:2vw;
    color:#1d4f81;
}
.xrs1stInFrame .TitleBox ul li.buttonText a{
    font-size:1.2vw;
    height:4vw;
    line-height:4vw;
    text-decoration: none;
    color:#000000;
    padding:0 2vw;
    display:inline-block;
    border:1px solid #000000;
}

.xrs1stInFrame .TitleBox ul li.buttonText a:hover{
    border:1px solid rgb(8, 46, 77);
    background:rgb(8, 46, 77);
    color:#ffffff;
}
.xrs1stInFrame .TitleBox ul li.buttonText a:active{
    border:1px solid rgb(8, 46, 77);
    background:rgb(8, 46, 77);
    color:#ffffff;
}




.xrs2ndInFrame{
    padding-right:5.2vw;
    height:auto;
    padding-bottom:10vw;
}

.xrs2ndInFrame .contBox{
    width:30%;
    padding-left:10%;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
}
.xrs2ndInFrame .contBox div{
    width:8vw;
    height:8vw;
    background:url("../../../images/XRBOOKLogo.png") no-repeat 50% 50%;
    background-size:contain;
}
.xrs2ndInFrame .TitleBox{
    width:70%;
    height:100%;
    padding-left:5vw;
    display:flex;
    flex-direction: column;
    justify-content: center;
}
.xrs2ndInFrame .TitleBox ul li.contTitleText{
    font-size:4vw;
    font-weight:600;
    line-height:4.5vw;
    display:flex;
    flex-direction: column;
    padding:1vw 0 2vw;
}
.xrs2ndInFrame .TitleBox ul li.contTitleText span:nth-child(1){
    font-size:2.5vw;
    line-height:1;
}
.xrs2ndInFrame .TitleBox ul li.contentText{
    font-size:1.1vw;
    line-height:1.8vw;
    display:flex;
    flex-direction: column;
    padding-bottom:3vw;
}




.xrs3thInFrame{
    background:#ebebeb;
}
.xrs3thInFrame .contBox{
    width:55%;
    height:100%;
    padding:8vw 0;
    padding-right:1vw;
}
.xrs3thInFrame .contBox>ul{
    width:100%;
    height:100%;
    font-size:0;
    display:flex;
    flex-direction: row;
    align-content: center;
}
.xrs3thInFrame .contBox>ul>li{
    font-size:1vw;
    -webkit-transition:width 1s;
    transition:width 1s;
    height:100%;
    overflow: hidden;
}
.xrs3thInFrame .contBox>ul>li:nth-child(1){width:10%;}
.xrs3thInFrame .contBox>ul>li:nth-child(2){width:10%;}
.xrs3thInFrame .contBox>ul>li:nth-child(3){width:80%;}
.xrs3thInFrame .contBox>ul>li>span{
    width:100%;
    height:100%;
    display:block;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.xrs3thInFrame .contBox>ul>li:nth-child(1)>span{
    background-image:url("../../../../images/xbblpSectionImage1.png");
}
.xrs3thInFrame .contBox>ul>li:nth-child(2)>span{
    background-image:url("../../../../images/xbblpSectionImage2.png");
}
.xrs3thInFrame .contBox>ul>li:nth-child(3)>span{
    background-image:url("../../../../images/xbblpSectionImage3.png");
}
.xrs3thInFrame .contBox>ul>li>span>ul{
    width:100%;
    height:100%;
    font-size:0;
    display:block;
    background:rgba(0,0,0,0.5);
    transition:background-color 1s;
    padding:4vw;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.xrs3thInFrame .contBox>ul>li:nth-child(3)>span>ul{
    background:rgba(0,0,0,0.5);
}
.xrs3thInFrame .contBox>ul>li:nth-child(3)>span>ul>li{
    opacity:1;
}
.xrs3thInFrame .contBox>ul>li>span>ul>li{
    word-break: keep-all;
    color:#ffffff;
    opacity:0;
    /* display:none; */
    /* opacity:0;
    -webkit-transition: opacity 1s, visibility 1s;
    transition: opacity 1s, visibility 1s; */
}

.xrs3thInFrame .contBox>ul>li>span>ul>li:nth-child(1){
    font-size:3vw;
    font-weight:600;
}
.xrs3thInFrame .contBox>ul>li>span>ul>li:nth-child(2){
    font-size:2vw;
    text-align:center;
}
.xrs3thInFrame .contBox>ul>li>span>ul>li:nth-child(2) span{
    word-break: keep-all;
    display:inline-block;
    margin:0 0.4vw;
}
.xrs3thInFrame .contBox>ul>li>span>ul>li:nth-child(3){
    text-align:right;
    display:flex;
    flex-direction: column;
    font-size:1.6vw;
    line-height:2.5vw;
    font-weight:600;
}

.xrs3thInFrame .TitleBox{
    width:45%;
    height:100%;
    padding-left:2vw;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.xrs3thInFrame .TitleBox ul{
    font-size:0;
}
.xrs3thInFrame .TitleBox ul li.sectMenuText{
    font-size:1.5vw;
    font-weight:600;
}
.xrs3thInFrame .TitleBox ul li.contTitleText{
    font-size:4vw;
    font-weight:600;
    line-height:4.5vw;
    display:flex;
    flex-direction: column;
    padding:1vw 0 2vw;
}
.xrs3thInFrame .TitleBox ul li.contentText{
    font-size:1.1vw;
    line-height:1.8vw;
    display:flex;
    flex-direction: column;
    padding-bottom:3vw;
}
.xrs3thInFrame .TitleBox ul li.buttonText a{
    font-size:1.2vw;
    height:4vw;
    line-height:4vw;
    text-decoration: none;
    color:#000000;
    padding:0 2vw;
    display:inline-block;
    border:1px solid #000000;
}
.xrs3thInFrame .TitleBox ul li.buttonText a:nth-child(1){
    background:#000f1e;
    color:#ffffff;
}
.xrs3thInFrame .TitleBox ul li.buttonText a:hover{
    border:1px solid rgb(8, 46, 77);
    background:rgb(8, 46, 77);
    color:#ffffff;
}
.xrs3thInFrame .TitleBox ul li.buttonText a:active{
    border:1px solid rgb(8, 46, 77);
    background:rgb(8, 46, 77);
    color:#ffffff;
}






.xrs4thInFrame .contBox{
    width:55%;
    height:100%;
    padding-top:15vw;
    padding-right:1vw;
}
.xrs4thInFrame .contBox>ul{
    width:100%;
    height:100%;
    font-size:0;
    display:flex;
    flex-direction: row;
    align-content: center;
}
.xrs4thInFrame .contBox>ul>li{
    font-size:1vw;
    -webkit-transition:width 1s;
    transition:width 1s;
    height:100%;
    overflow: hidden;
}
.xrs4thInFrame .contBox>ul>li:nth-child(1){width:80%;}
.xrs4thInFrame .contBox>ul>li:nth-child(2){width:10%;}
.xrs4thInFrame .contBox>ul>li:nth-child(3){width:10%;}
.xrs4thInFrame .contBox>ul>li>span{
    width:100%;
    height:100%;
    display:block;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.xrs4thInFrame .contBox>ul>li:nth-child(1)>span{
    background-image:url("../../../../images/xbmsSectionImage1.png");
}
.xrs4thInFrame .contBox>ul>li:nth-child(2)>span{
    background-image:url("../../../../images/xbmsSectionImage2.png");
}
.xrs4thInFrame .contBox>ul>li:nth-child(3)>span{
    background-image:url("../../../../images/xbmsSectionImage3.png");
}
.xrs4thInFrame .contBox>ul>li>span>ul{
    width:100%;
    height:100%;
    font-size:0;
    display:block;
    background:rgba(0,0,0,0.5);
    transition:background-color 1s;
    padding:4vw;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.xrs4thInFrame .contBox>ul>li:nth-child(1)>span>ul{
    background:rgba(0,0,0,0.2);
}
.xrs4thInFrame .contBox>ul>li>span>ul>li{
    word-break: keep-all;
    color:#ffffff;
    /* display:none; */
    opacity:0;
    -webkit-transition: opacity 1s, visibility 1s;
    transition: opacity 1s, visibility 1s;
}
.xrs4thInFrame .contBox>ul>li:nth-child(1)>span>ul>li{
    opacity: 1;
}
.xrs4thInFrame .contBox>ul>li>span>ul>li:nth-child(1){
    font-size:3vw;
    font-weight:600;  
}
.xrs4thInFrame .contBox>ul>li>span>ul>li:nth-child(2){
    font-size:2vw;
    text-align:center;
    
}
.xrs4thInFrame .contBox>ul>li>span>ul>li:nth-child(2) span{
    word-break: keep-all;
    display:inline-block;
    margin:0 0.4vw;
}
.xrs4thInFrame .contBox>ul>li>span>ul>li:nth-child(3){
    text-align:right;
    display:flex;
    flex-direction: column;
    font-size:1.6vw;
    line-height:2.5vw;
    font-weight:600;
}

.xrs4thInFrame .TitleBox{
    width:45%;
    height:100%;
    padding-left:2vw;
    padding-top:15vw;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.xrs4thInFrame .TitleBox ul{
    font-size:0;
}
.xrs4thInFrame .TitleBox ul li.sectMenuText{
    font-size:1.5vw;
    font-weight:600;
}
.xrs4thInFrame .TitleBox ul li.contTitleText{
    font-size:4vw;
    font-weight:600;
    line-height:4.5vw;
    display:flex;
    flex-direction: column;
    padding:1vw 0 2vw;
}
.xrs4thInFrame .TitleBox ul li.contentText{
    font-size:1.1vw;
    line-height:1.8vw;
    display:flex;
    flex-direction: column;
    padding-bottom:3vw;
}
.xrs4thInFrame .TitleBox ul li.buttonText a{
    font-size:1.2vw;
    height:4vw;
    line-height:4vw;
    text-decoration: none;
    color:#000000;
    padding:0 2vw;
    display:inline-block;
    border:1px solid #000000;
}
.xrs4thInFrame .TitleBox ul li.buttonText a:nth-child(1){
    background:#000f1e;
    color:#ffffff;
}
.xrs4thInFrame .TitleBox ul li.buttonText a:hover{
    border:1px solid rgb(8, 46, 77);
    background:rgb(8, 46, 77);
    color:#ffffff;
}
.xrs4thInFrame .TitleBox ul li.buttonText a:active{
    border:1px solid rgb(8, 46, 77);
    background:rgb(8, 46, 77);
    color:#ffffff;
}



/* .xrsOutFrame{
    width:100%; 
    height:100%;
    font-size:0;
    display:flex;
    background:#ffffff;
    


}


.xrsInFrame{
    width:100%;
    height:100%;
    padding:4.7vw 7.8vw;
    position:relative;
}



.xrsTextBox{
    width:100%;
    height:100%;
    position: relative;
    padding-top:15vw;
    z-index:1;
} 
.xrsTextBox h2{
    font-size:4.2vw;
    color:#000f1e;
    line-height:1;
    padding-bottom:3vw;
}
.xrspagerGroup{
    padding-bottom:2vw;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
}
.xrspagerGroup li{
    font-size:1.1vw;
    color:#000f1e;
    line-height:2vw;
    cursor: pointer;
}
.xrspagerGroup li.active{
    color:#60c0bf;
}
.xrspagerGroup li:hover{
    font-weight:600;
}

.xrsTextBox .btnGroup a{
    text-decoration:none;
    width:auto;
    height:3vw;
    padding:0 2vw;
    display:inline-block;
    font-size:1.1vw;
    font-weight:500;
    line-height:3vw;
    text-align:center;
}
.xrsTextBox .btnGroup a.contactBtn{
    border:1px solid #000f1e;
    color:#000f1e;
    cursor: pointer;
    font-weight:600;
}
.xrsTextBox .btnGroup a:hover{
    color:#ffffff;
    background:#000f1e;
    
}





.xrsContBox{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    background:#ffffff;
    overflow:hidden;

}
.xrsContBox ul.slider{
    width:200%; 
    height:100%;
    font-size:0;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
}
.xrsContBox ul.slider li{
    width:100%;
    height:100%;
}
.xrsContBox ul.slider li img.pcImage{
    width:100%;
    margin-left:auto;
    display:block;
}
.xrsContBox ul.slider li img.mobileImage{
    display:none;
}
 */
