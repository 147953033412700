/* res css */
/* @media (max-width:1920px){
    .bgVideoFrame>iframe{
        min-height:inherit;
        height:-webkit-calc(100vh - 5.2vw);   
        height:-moz-calc(100vh - 5.2vw);
        height:-o-calc(100vh - 5.2vw);   
        height:calc(100vh - 5.2vw); 
    }
} */
@media (max-width:834px){
    .introOutFrame{
        width:auto;
        height:100vh;
        background:rgba(0, 15, 30, 0.99);
    }
    .bgVideoFrame{
        width:100vw;
    }
    /* .bgVideoFrame{
        top:10vw;
        height:-webkit-calc(100% - 10vw);   
        height:-moz-calc(100% - 10vw);
        height:-o-calc(100% - 10vw);   
        height:calc(100% - 10vw); 
    }
    .bgVideoFrame>iframe{
        min-height:inherit;
        height:-webkit-calc(100vh - 10vw);   
        height:-moz-calc(100vh - 10vw);
        height:-o-calc(100vh - 10vw);   
        height:calc(100vh - 10vw); 
    } */
   
    .titleTextGroup ul{
        padding-bottom:10vw;
    }
    .titleTextGroup ul li span{
        font-size:3.5vw;
        padding:1.8vw 3.5vw;
        border-radius:1.5vw;
    }


    







    .xrbookExplain{
        height:auto;
        display:none;
    }
    .titleBox{
        display:flex;
        flex-direction:column;
        justify-content: flex-start;
        margin-right:0;
    }
    .titleBox h3{
        font-size:1.6vw; 
        font-weight:500;
        padding-left:1.1vw;
    }
    .titleBox h2{
        font-size:3.1vw;
        font-weight:500;
        line-height:1;
    }

    .textBox{
        width:100%;
        display:flex;
        flex-direction:row;
        padding-top:1.6vw;
    }
    
        
}





@media (max-width:480px){
    /* .bgVideoFrame{
        top:15vw;
        height:-webkit-calc(100% - 15vw);   
        height:-moz-calc(100% - 15vw);
        height:-o-calc(100% - 15vw);   
        height:calc(100% - 15vw); 
    } */
    .PCbgVideoFrame{
        display:none;
    }
    .MobgVideoFrame{
        display:block;
    }
    .bgVideoFrame>iframe{
        height:auto;
        min-height:235vw;
        min-width:220vw;
    }
    /* .bgVideoFrame>iframe{
        min-height:inherit; */
        /* height:-webkit-calc(100vh - 15vw);   
        height:-moz-calc(100vh - 15vw);
        height:-o-calc(100vh - 15vw);    */
        /* height:100vh; 
    } */
    .titleTextGroup ul{
        padding-bottom:12vw;
    }
    .titleTextGroup ul li span{
        font-size:6vw;
        padding:2.5vw 5vw;
        border-radius:2vw;
    }


    .actVideoOutFrame{
        padding:0 0 30vw;
    }
}
/* @media (max-width:480px) and (max-height:850px){
    .bgVideoFrame>iframe{
        min-height:110vh;
    }
}
@media (max-width:480px) and (max-height:800px){
    .bgVideoFrame>iframe{
        min-height:180vw;
    }
}
@media (max-width:340px){
    .bgVideoFrame>iframe{
        min-height:120vh;
    }
} */
@media (max-width:280px){
    .bgVideoFrame>iframe{
        min-height:100vh;
    }
}
@media (max-width:280px) and (max-height:500px){

    .bgVideoFrame>iframe{
        min-height:180vw;
    }
}