@media (max-width:834px){
    .userBookContentFrame{
        width:100%;
        height:100%;
    }
    .headingTextBox{
        margin:12vw auto !important;
    }
    
    .utilityBtnGroup{
        padding:0;
    }
    .utilityBtnGroup .headingBtnBox{
        left:0;
    }
    .utilityBtnGroup .headingBtnBox span.headingIconText{
        display:none;
    }
    
    
    .utilityBtnGroup .bookMarkListBox{
        width:45vw;
        font-size:2vw;
        left:-4vw;
    }

    .bookMarkList ul{
        padding:1.5vw 0;
        min-height:2vw;
    }
    .bookMarkList ul li{
        font-size:1.8vw;
        line-height:3vw;
    }
    .bookMarkList ul li:nth-child(2){
        font-size:1.5vw;
    }
    .bookMarkList ul li:nth-child(2) span:nth-child(3){
        padding-left:1vw;
        font-size:2vw;
    }
    .bookMarkListBtn{
        height:6vw;
        line-height:6vw;
    }
    .bookMarkListBtn.BtnIcon{
        display:none;
    }

    .utilityBtnGroup{
        bottom:-15vw;
    }
    .utilityBtnGroup .bookBtnBox{
        right:0;
        height:6vw;
        line-height:6vw;
    }
    .utilityBtnGroup .bookBtnBox div{
        height:6vw;
    }
    .utilityBtnGroup .bookBtnBox .curPageSign{
        margin-right:1.6vw;
    }
    .utilityBtnGroup .bookBtnBox span.prevBtn{
        padding:0 2vw;
    }
    .utilityBtnGroup .bookBtnBox span.nextBtn{
        padding:0 2vw;
    }

    .userReviewFrame{
        width:70vw;
        padding-top:30vw;
    
    }
    .userReviewFrame ul li{
        font-size:1.8vw;
        padding-bottom:2vw;
    }
    .userReviewFrame ul li:nth-child(1){
        padding-bottom:5vw;
    }
    .userReviewFrame ul li:nth-child(1) span{
        font-size:2.5vw;
    }
    .userReviewFrame ul li input{
        font-size:1.8vw;
        height:4vw;
        padding:0 2vw;
    }
    .userReviewFrame ul li textarea{
        font-size:1.8vw;
        height:30vw;
        padding:2vw;
        line-height:3vw;
    }

    .userReviewFrame ul li button{
        padding:0 3vw;
        height:4vw;
        line-height:4vw;
        font-size:1.8vw;
    }
    




}
@media (max-width:480px){
    .headingTextBox{
        margin:18vw auto !important;
    }
    .utilityBtnGroup .headingBtnBox{
        bottom:-7vw;
    }
    .utilityBtnGroup .headingBtnBox span.headingBtnText{
        display:none;
    }
    .utilityBtnGroup .headingBtnBox span.headingIconText{
        display:block;
        font-size:6vw;
        padding:0 5vw;
    }
    .utilityBtnGroup .bookMarkListBox{
        bottom:-7vw;
        left:0;
        margin:0;
        width:100%;
        font-size:5vw;
    }
    .bookMarkList>span{
        padding:4vw;
    }
    .bookMarkList ul{
        padding:3vw 0;
        min-height:5vw;
    }
    .bookMarkList ul li{
        font-size:3.5vw;
        line-height:5vw;
    }
    .bookMarkList ul li:nth-child(2){
        font-size:3vw;
    }
    .bookMarkList ul li:nth-child(2) span:nth-child(3){
        padding-left:1vw;
        font-size:5vw;
    }
    .bookMarkListBtn.BtnText{
        display:none;
    }
    .bookMarkListBtn.BtnIcon{
        display:block;
        height:12vw;
        line-height:12vw;
        padding:0;
        font-size:4vw;
        border:1px solid #000000;
        width:12vw;
        margin-left:20vw;
    }
    .utilityBtnGroup{
        bottom:-30vw;
        margin-bottom:25vw;
    }
    .utilityBtnGroup .bookBtnBox{
        height:12vw;
        line-height:12vw;
        bottom:-7vw;
    }
    .utilityBtnGroup .bookBtnBox div{
        height:12vw;
    }
    .utilityBtnGroup .bookBtnBox .curPageSign{
        margin-right:7vw;
    }
    .utilityBtnGroup .bookBtnBox span.prevBtn{
        padding:0 4vw;
    }
    .utilityBtnGroup .bookBtnBox span.nextBtn{
        padding:0 4vw;
    }



    .userReviewFrame{
        width:90vw;
        padding-top:15vw;
    
    }
    .userReviewFrame ul li{
        font-size:3.5vw;
        padding-bottom:3vw;
    }
    .userReviewFrame ul li:nth-child(1){
        padding-bottom:5vw;
        display:flex;
        flex-direction: column;

    }
    .userReviewFrame ul li:nth-child(1) span{
        font-size:4.5vw;
        line-height:7vw;
    }
    .userReviewFrame ul li input{
        font-size:3.5vw;
        height:8vw;
    }
    .userReviewFrame ul li textarea{
        font-size:3.5vw;
        height:80vw;
        line-height:5vw;
    }
    .userReviewFrame ul li:nth-child(5){
        margin-top:5vw;
    }
    
    .userReviewFrame ul li button{
        padding:0 4vw;
        height:8vw;
        line-height:8vw;
        font-size:3.5vw;
    }




    .userCoverFrame{
        padding:5.2vw;
    }






}