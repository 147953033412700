.animationPage{
  /* margin-top: 10vh; */
}
.animationWrapper{
  min-height:80vh
}
.animationWrapper>.spinnerFrame{
  padding: 30vh 0;
}
.animationWrapper>.spinnerFrame>.spinnerMsg{
  font-size: 2vw;
}
.animationWrapper>.animationHeadTitle{
  padding: 30vh 0;
}
.animationHeadTitle{
  display: block;
  font-size: 2vw;
  text-align: center;
  margin: auto 0;
}

.animationBtnWrapper{
  position: absolute;
  top: -40vh;
  /* border : 1px solid white; */
  width: 100vw;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}


.animationVideoOutFrame {
    width: 100vw;
    /* padding: 1vw; */
    /* height:100vh; */
    /* border:1px solid orange; */
    overflow: hidden;
    display: flex;
    justify-content: space-around;
  }
  /* .animationVideoOutFrame:after {
    display: block;
    content: "";
    clear: both;
  } */
  .animationVideoBlock{
    width: 16vw;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    padding: 5%;
    /* border : 1px solid lightgrey; */
    background-color: #d1d1c3;
    border-radius: 20px;
  }
  .animationVideoIcon{
    padding-left: 1vw;
  }
  .animationVideoIcon:hover{
    color:darkslategray
  }
  .animationVideoTitle{
    padding: 0.5vw 1.5vw;
    font-size: 1.5vw;
  }
  .animationVideoInFrame {
    width: 100%;
    height: 100%;
    padding: 0 1vw;
    /* position: relative; */
  }
  .animationVideoInFrame > iframe {
    width: 100%;
    height: auto;
  }


.animationPageWrapper {
  height:100vh;
  background-image: url('./images/배경.png');
  background-size : cover;
  background-repeat : no-repeat;
  background-position : bottom, left bottom;
}
.animationBottomWrapper {
  width: 100vw;
  height: 50vh;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.animationPagelogo{
  margin-left: 3vw;
  margin-bottom: 3vh;
}



  @media (max-width:834px){
    .animationPageWrapper{
      height: auto;     
    }
    .animationPagetlp, .animationPageplanet, .animationPagelogo{
      display: none;
    }
    .animationBtnWrapper{
      position: static;
      display: flex;
      flex-direction: column;
      /* justify-content: space-around; */
      /* align-items: center; */
      height: auto;
      }
    .animationHeadTitle{
      font-size: 3vw;
    }
    .animationWrapper>.spinnerFrame>.spinnerMsg{
      font-size: 3vw;
    }
    .animationVideoBlock{
      width: auto;
      margin: 2% auto;
      min-width: none;
    }
    .animationVideoOutFrame{
      flex-direction: column;
    }
    .animationVideoTitle{
      font-size: 4vw;
      margin: 2vw 1vw;
    }
    .animationVideoIcon{
      padding-left: 2vw;
    }
  }
  @media (max-width:480px){
    .animationHeadTitle{
      font-size: 5vw;
    }
    .animationWrapper>.spinnerFrame>.spinnerMsg{
      font-size: 5vw;
    }
    
  }