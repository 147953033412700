/* res css */

@media (max-width:834px){
    .introOutFrame{
        width:auto;
        height:100vh;
        background:rgba(0, 15, 30, 0.99);
    }
    
    .bgVideoFrame{
        position:relative;
        width:auto;
        height:100%;   
        overflow:hidden;
        /* border:1px solid yellow; */
    }
    
   
    /* .titleTextGroup ul{
        padding-bottom:10vw;
    }
    .titleTextGroup ul li.mainTitle1{
        font-size:10vw; 
        padding:18vw 0 1vw; 
        letter-spacing:3px;
        
    }
    .titleTextGroup ul li.mainTitle2{
        font-size:5vw;
        display: flex;
        flex-direction: column; 
    }
    .titleTextGroup ul>span{
        margin:2vw 0;
    
    }
    .titleTextGroup ul li.subTitle>span:nth-child(1){
        font-size:5vw; line-height:8vw;  
    }
    .titleTextGroup ul li.subTitle>span:nth-child(2){
        font-size:5vw;  line-height:8vw;
    } */

    







    .xrbookExplain{
        height:auto;
        display:none;
    }
    .titleBox{
        display:flex;
        flex-direction:column;
        justify-content: flex-start;
        margin-right:0;
    }
    .titleBox h3{
        font-size:1.6vw; 
        font-weight:500;
        padding-left:1.1vw;
    }
    .titleBox h2{
        font-size:3.1vw;
        font-weight:500;
        line-height:1;
    }

    .textBox{
        width:100%;
        display:flex;
        flex-direction:row;
        padding-top:1.6vw;
    }
    
        
}





@media (max-width:480px){
    /* .titleTextGroup ul{
        padding-bottom:20vw;
    }
    .titleTextGroup ul li{
        font-weight:800;
        letter-spacing:0.5px;
      }
    .titleTextGroup ul li.mainTitle1{
        font-size:12vw; 
        padding:18vw 0 1vw; 
        letter-spacing:3px;
        
    }
    .titleTextGroup ul li.mainTitle2{
        font-size:5.5vw;

    }
    .titleTextGroup ul>span{
        height:1px;
        margin:3vw 0;
    
    } */

}