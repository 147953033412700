.adminReviewsFrame{
    margin-bottom:5vw;
    padding:0 5vw;
}
.adminReviewsFrame h3{
    font-size:1.8vw;
    font-weight:600;
    line-height:5vw;
    margin-bottom:2vw;
    border-bottom:1px solid #000000;
}
.adminReviewsFrame>p {
    font-size: 1vw;
    display: block;
    margin-bottom: 1vw;
}
.adminReviewsBxGroup{
    width:100%;
    height:auto;
}
.adminReviewsBxGroup table{
    width:100%;
    height:auto;
    font-size:1vw;
}
.adminReviewsBxGroup table th{
    height:2.5vw;
    line-height:2.5vw;
    padding:0 0.5vw;
    border:1px solid #dedede;
    border-bottom:2px solid #aaaaaa;
    font-size:1.2vw;
}
.adminReviewsBxGroup table td{
    padding:0 0.5vw;
    height:2.5vw;
    border:1px solid #dedede;
}
.adminReviewsBxGroup table td div.adminReviewsBtnBx{
    display:flex;
    flex-direction: row;
    width:100%;
}
.adminReviewsBxGroup table td div.adminReviewsBtnBx div{
    padding:0 1vw;
    border:1px solid #000000;
    height:2vw;
    line-height:2vw;
    cursor: pointer;
}

.adminReviewsBxGroup table td div.adminReviewsBtnBx div:nth-child(1){
    margin-right:0.5vw;
    background:#dedede;
}
.adminReviewsBxGroup table td div.adminReviewsBtnBx div:hover{
    background:#000000;
    color:#ffffff;
}
.adminReviewsBxGroup table td div.adminReviewsBtnBx div:nth-child(2):hover{
    border:1px solid #a02121;
    background:#a02121;
    color:#ffffff;
}
.adminReviewsBxGroup table td span.adminReviewsContText{
    border-width:1px;
    display:block;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    cursor: pointer;
}
.adminReviewsBxGroup table td span.adminReviewsContText:hover{
    color:orange;
}



.adminReviewDetailsBg{
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    display:block;
    background:rgba(0,0,0,0.8);
    z-index:99;
}

.adminReviewDetailsBg .adminReviewDetailsBx{
    border:1px solid #000000;
    background:#ffffff;
    width:50vw;
    height:auto;
    padding:3vw;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
}
.adminReviewDetailsBg .adminReviewDetailsBx>button{
    position:absolute;
    top:-4vw;
    right:-3vw;
    background:transparent;
    border:0;
    font-size:3vw;
    color:rgba(255,255,255,0.8);
    outline: none;
    cursor: pointer;
}
.adminReviewDetailsBg .adminReviewDetailsBx>button:hover{
    color:rgba(255,255,255,1);
    background:transparent !important;
    border:none !important;
}

.adminReviewDetailsBg .adminReviewDetailsBx .adminReviewTextGroup p.writer{
    margin-top:4vw;
    text-align:right;
}


