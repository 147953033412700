.adminContentsFrame{
    padding:0 5vw;
}
.adminContentsFrame h3{
    font-size:1.8vw;
    font-weight:600;
    line-height:5vw;
    margin-bottom:2vw;
    border-bottom:1px solid #000000;
}
.adminContentsFrame>div>h5{
    font-size: 1.5vw;
    font-weight: 600;
    padding-top:1vw;
    padding-bottom:1.5vw;
    
}
/* VrCodeInput */
.vrCodeInputGroup>div{
    padding:0 1vw;
}
.vrCodeInputGroup p{
    display: block;
    padding:0.3vw 0.5vw;
    font-size:1vw;
}
.vrCodeInputGroup>div img{
    width: 35vw;
}
.vrCodeInputGroup span{
    display: block;
    font-size: 0.8vw;
    width: 100%;
    padding: 0.2vw 0.8vw;
    color: #666666;
}
.vrCodeInputGroupflex{
    display:flex;
    margin-top: 0.5vw;
}
.vrCodeInputGroupflex>div{
    position: relative;
    border:1px solid #dedede;
    padding:0.3vw 0.5vw;
    font-size:1vw;
}
.vrCodeInputGroupflex>div input{
    margin-left: 0.5vw;
    width:11vw; 
}
.vrCodeInputGroupflex textarea{
    padding:0.5vw;
    width:15vw; 
    height:14vw; 
    resize:none;
}
.vrCodeInputGroupflex .vrCodeInputList{
    background-color: #dedede;
    padding:0.5vw;
    width:30vw;
}  
.vrCodeInputGroupflex .vrCodeInputListScroll{
    width: 22vw;
    height: 15vw;
    overflow-y: scroll;
} 
.vrCodeInputGroupflex .vrCodeInputListScroll>div{
    width: 20vw;
    /* border:1px solid red */
} 
/* .vrCodeInputGroupflex .vrCodeInputList .vrCodeInputListone{
    width:30vw; 
   
} */
.vrCodeInputGroupflex>div button{
    position: absolute;
    bottom: 1vw; right: 1vw;
    display: block;
    border-width:1px;
    width: 10vw;
    height:2.5vw;
    line-height:2.5vw;
    padding:0 1vw;
    font-size:1vw;
}
/* fileUploadGroup */
.adminContentsFrame>div>.fileUploadGroup{
    display:flex;
    flex-direction:column;
    margin-bottom:5vw;
}
.adminContentsFrame>div>.fileUploadGroup>div{
    display:flex;
    flex-direction: row;
    align-items:center;
    border:1px solid #dedede;
    height:4vw;
    line-height:4vw;
    padding:0 1vw;
}
.adminContentsFrame>div>.fileUploadGroup>div:nth-child(1){
    border-bottom:none;
}
.adminContentsFrame>div>.fileUploadGroup>div>p{
    font-size:1.2vw;
    width:20vw;
}
.adminContentsFrame>div>.fileUploadGroup>div>a{
    font-size:1vw;
    text-align:right;
    margin-left:auto;
    color:#c56e6e;
}
.adminContentsFrame>div>.fileUploadGroup>div>div>form{
    display:flex;
    flex-direction:row;
    align-items: center;;
}
.adminContentsFrame>div>.fileUploadGroup>div>div>form>input{
    line-height:2vw;
    font-size:1vw;
}
.adminContentsFrame>div>.fileUploadGroup>div>div>form>button{
    border-width:1px;
    height:2.5vw;
    line-height:2.5vw;
    padding:0 3vw;
    font-size:1vw;
}



/* dataManageGroup */
.adminContentsFrame>div>.dataTabBtnGroup{
    display:flex;
    flex-direction: row;
    align-items: center;
}
.adminContentsFrame>div>.dataTabBtnGroup div{
    font-size:1.2vw;
    text-align:center;
    border:1px solid #dedede;
    width:34%;
    height:4vw;
    line-height:4vw;
    cursor: pointer;
    color:#aaaaaa;
}
.adminContentsFrame>div>.dataTabBtnGroup div:hover{
    background:#eeeeee;
    color:#000000;
}
.adminContentsFrame>div>.dataTabBtnGroup div:nth-child(1){
    border-right:0;
    border-bottom:0;
    color:#000000;
}
.adminContentsFrame>div>.dataTabBtnGroup div:nth-child(2){
    border-right:none;
}
.adminContentsFrame>div>.dataTabContGroup{
    padding:3vw 2vw;
    border:1px solid #dedede;
    border-top:none;
    font-size:1vw;
}

.contentsDataGroup{
    /* border-bottom:1px solid #dedede; */
    padding:1vw;
    /* padding-bottom:2vw; */
    margin-bottom:3vw;
}
.contentsDataGroup:nth-last-child(1){
    border:none;
}
.contentsInfoUpload ul{
    width:100%;
    font-size:0;
    border:1px solid #dedede;
    border-top:2px solid #dedede;
    border-bottom:0;
}
.contentsInfoUpload ul li{
    padding:0 0.5vw;
    border:1px solid #dedede;
    border-top:0;
    display:inline-block;
}
.contentsInfoUpload ul li:nth-child(1){width:35%}
.contentsInfoUpload ul li:nth-child(2){width:15%}
.contentsInfoUpload ul li:nth-child(3){width:30%}
.contentsInfoUpload ul li:nth-child(4){width:10%}
.contentsInfoUpload ul li:nth-child(5){width:10%}
.contentsInfoUpload .contentsInfoCate{
    background:#eeeeee;
    border-bottom:2px solid #dedede;
}
.contentsInfoUpload .contentsInfoCate li{
    font-size:1.2vw;
    font-weight:600;
    height:2.5vw;
    line-height:2.5vw;
}
.contentsInfoUpload .contentsInfoInput{
    border-top:0;
}
.contentsInfoUpload .contentsInfoInput li{
    border:none;
    padding:1vw;
    vertical-align: middle;
    border-right:1px solid #dedede;
}

.contentsInfoUpload .contentsInfoInput li:nth-last-child(1){
    border-right:0;
}
.contentsInfoUpload .contentsInfoInput li input{
    width:100%;
    height:2.5vw;
    font-size:1vw;
    border:none;
    outline:none;
    overflow:hidden;
}
.contentsInfoUpload .contentsInfoInput li select{
    width:100%;
    height:2.5vw;
    font-size:1vw;  
    cursor: pointer; 
}
.contentsInfoUpload .contentsInfoInput li:nth-last-child(1) input{
    height:1vw;
    line-height:3vw;
    border:none;
}
.selfContData .contentsInfoUpload ul:nth-child(1) li:nth-child(1),
.selfContData .contentsInfoUpload ul:nth-child(2) li:nth-child(1){width:40%}
.selfContData .contentsInfoUpload ul:nth-child(1) li:nth-child(2),
.selfContData .contentsInfoUpload ul:nth-child(2) li:nth-child(2){width:20%}
.selfContData .contentsInfoUpload ul:nth-child(1) li:nth-child(3),
.selfContData .contentsInfoUpload ul:nth-child(2) li:nth-child(3){width:30%}
.selfContData .contentsInfoUpload ul:nth-child(1) li:nth-child(4),
.selfContData .contentsInfoUpload ul:nth-child(2) li:nth-child(4){width:10%}

.selfContData .contentsInfoUpload:nth-child(2) ul:nth-child(2) li{
    display:inline-flex;
    flex-direction: row;
    align-items: center;
}
.selfContData .contentsInfoUpload:nth-child(2) ul:nth-child(1) li:nth-child(1),
.selfContData .contentsInfoUpload:nth-child(2) ul:nth-child(2) li:nth-child(1){width:20%;}
.selfContData .contentsInfoUpload:nth-child(2) ul:nth-child(1) li:nth-child(2),
.selfContData .contentsInfoUpload:nth-child(2) ul:nth-child(2) li:nth-child(2){width:20%;}
.selfContData .contentsInfoUpload:nth-child(2) ul:nth-child(1) li:nth-child(3),
.selfContData .contentsInfoUpload:nth-child(2) ul:nth-child(2) li:nth-child(3){width:20%}
.selfContData .contentsInfoUpload:nth-child(2) ul:nth-child(1) li:nth-child(4),
.selfContData .contentsInfoUpload:nth-child(2) ul:nth-child(2) li:nth-child(4){width:20%;}
.selfContData .contentsInfoUpload:nth-child(2) ul:nth-child(1) li:nth-child(5),
.selfContData .contentsInfoUpload:nth-child(2) ul:nth-child(2) li:nth-child(5){width:20%;}
.selfContData .contentsInfoUpload:nth-child(2) ul:nth-child(2) li:nth-child(3) input:nth-child(2){
    height:1vw;
    line-height:3vw;
    width:auto;
}
.selfContData .contentsInfoUpload:nth-child(2) ul:nth-child(2) li:nth-child(4) input:nth-child(2){
    height:1vw;
    line-height:3vw;
    width:auto;
}

.contentsImgUpload{
    display:flex;
    flex-direction:column;
}
.contentsImgUpload .contentsImgCate{
    width:100%;
    font-size:0;
    border:1px solid #dedede;
    border-top:2px solid #dedede;
    border-bottom:2px solid #dedede;
    background:#eeeeee;
}
.contentsImgUpload .contentsImgCate li{
    font-size:1.2vw;
    font-weight:600;
    width:33.33%;
    height:2.5vw;
    line-height:2.5vw;
    padding:0 0.5vw;
    border:1px solid #dedede;
    border-top:0;
    display:inline-block;
}
.contentsImgUpload .contentsImgInput{
    width:100%;
    display:flex;
    flex-direction:row;
    border:1px solid #dedede;
    border-top:0;
}
.contentsImgUpload .contentsImgInput>div{
    width:33.33%;
    height:auto;
    padding:1vw;
    border-right:1px solid #dedede;
}
.contentsImgUpload .contentsImgInput>div:nth-last-child(1){
    border-right:0;
}
.contentsImgUpload .contentsImgInput>div>.bgUploadBx{
    width:100%;
}
.contentsImgUpload .contentsImgInput>div>.bgUploadBx>form{
    width:100%;
    display:flex;
    flex-direction:row;
    align-items: center;
    margin-bottom:0.5vw;
}
.contentsImgUpload .contentsImgInput>div>.bgUploadBx>form input{
    width:70%;
    height:2vw;
    border:1px solid #dedede;
}
.contentsImgUpload .contentsImgInput>div>.bgUploadBx>form button{
    width:30%;
    height:2vw;
    line-height:2vw;
    margin-right:0;
}
.contentsImgUpload .contentsImgInput>div>div:nth-last-child(1){
    height:50vw;
    overflow:auto;
}
.contentsImgUpload .contentsImgInput>div>div:nth-last-child(1)::-webkit-scrollbar{
    width:5px;
}
.contentsImgUpload .contentsImgInput>div>div:nth-last-child(1)::-webkit-scrollbar-thumb{
    background-color:#dedede;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
}
.contentsImgUpload .contentsImgInput>div>div:nth-last-child(1)::-webkit-scrollbar-track{
    background:transparent;
    border-radius:5px;
    box-shadow:inset 0px 0px 3px #ffffff;
}
.contentsImgUpload .contentsImgInput>div>div img{
    height:auto;
    width:100%;
}
.contentsUploadBtn{
    width:100%;
    height:5vw;
    padding:1vw;
    border:1px solid #dedede;
    text-align:center;
    background:#eeeeee;
}
.contentsUploadBtn button{
    font-size:1.2vw;
    width:50%;
    height:3vw;
    border-width:1px;
}
