@media (max-width:834px){
    .priContFrame>section{height:auto;}
/* 
    .sectOutFrame{
        width:100vw;
        height:auto;
        display:block;
    }
    .clpOutFrame{background:#ffffff;}
    .slpOutFrame{background:#e4e5e6;}
    .xrsOutFrame{background:#0b263c;}
    .sectTextBox{
        position:inherit;
        top:inherit;
        left:inherit;
    }
    .sectTextBox h2{
        font-size:5vw; 
        padding-bottom:4vw; 
    }
 
    .sectTextBox .pagerText{
        font-size:2.4vw;
        line-height:4vw;
    }      
    .xrsOutFrame .sectTextBox .pagerText{
        color:#ffffff;
    }
    
    .sectTextBox .btnGroup a{
        height:5vw;
        font-size:2vw;
        line-height:5vw;
        padding:0 3vw;
        color:#ffffff;
    }
    .sectTextBox .btnGroup a.contactBtn{
        color:#ffffff;
        font-weight:00;
    }
    .swiperFrame{
        min-height:100vw;
        width:100%;
    }
    .swiper-button-prev{
        left:4.2vw !important;
        top:60% !important;
    }
    .swiper-button-next{
        right:4.2vw !important;
        top:60% !important;
    }
    .sectContGroup{
        
        position:unset !important;
        height:auto !important;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .sectConText{
        position:inherit;
        width:100%;
        padding:5vw 7.8vw 5vw; 
        font-size:1.9vw; 
        word-break: keep-all; 
    }
    .sectConTextPc{display:none;}
    .sectConTextMobile{
        display:block;
        width:100%;
    }
    .sectConText>span{
        font-size:1.6vw;
        line-height:1;
        margin:1.5vw 0;
    }
    .pcImage{
        display:none;
        position: inherit;
    }
    .mobileImage{
        display:block;
        position: inherit;
        width:100vw;
        z-index: 0;
    } */


    /* #mainLoading ul li.mainTitle1{
        font-size:7.5vw; 
        padding:2vw 0 1vw;
        letter-spacing:3px;
    }
    #mainLoading ul li.mainTitle2{
        font-size:3.5vw;
        font-weight:600;
    }
 
    #mainLoading ul li.subTitle>span:nth-child(1){
        font-size:3vw; line-height:2vw;  }
    #mainLoading ul li.subTitle>span:nth-child(2){
        font-size:3vw;  line-height:2vw;} */
    .loadTitle{
        font-size:4vh;
        width:100vw;
        line-height:1;
    }
    .loadText{
        font-size:2.5vh;
        line-height:8vh;
    }
    .imgGroup{
        height:40vw;
    }
    
}




@media (max-width:480px){
    /* .priContFrame>section#INTRO{height:100vh;} */


    .sectTextBox h2{
        font-size:10vw;  
        padding-bottom:6vw;
    }
    
    .sectTextBox .pagerText{
        font-size:5vw;
        line-height:8vw;
    }      
    .sectTextBox .btnGroup{
        margin:5vw 0;
    }
    .sectTextBox .btnGroup a{
        height:10vw;
        font-size:4.5vw;
        line-height:10vw;
        padding: 0 6vw;
    }
    .sectConText{
        font-size:4vw; 
        line-height:6.5vw;
    }
    /* #mainLoading ul li.mainTitle1{
        font-size:7vw;         
    }
    #mainLoading ul li.mainTitle2{
        font-size:5vw;
        font-weight:600;
        display:flex;
        flex-direction: column;
        line-height:9vw;
        padding-top:2vw;
    }
    #mainLoading ul li.subTitle{
        padding-bottom:2vw;
    }
    #mainLoading ul li.subTitle>span:nth-child(1){
        font-size:3.5vw; line-height:5vw;  }
    #mainLoading ul li.subTitle>span:nth-child(2){
        font-size:3.5vw;  line-height:5vw;} */


    .loadTitle{
        font-size:7vw;
        width:100vw;
        line-height:1;
    }    
    .loadText{
        width:100%;
        line-height:10vh;
    }
    .imgGroup{
        height:50vw;
    }
}