.introOutFrame {
  width: 100vw;
  height: 100%;
  background: rgba(0, 15, 30, 0.99);
}

.bgVideoFrame {
  position: relative;
  /* top:5.2vw; */
  width: 100%;
  height: 100%;
  /* height:-webkit-calc(100% - 5.2vw);   
    height:-moz-calc(100% - 5.2vw);
    height:-o-calc(100% - 5.2vw);   
    height:calc(100% - 5.2vw);    */
}
.MobgVideoFrame {
  display: none;
}
.bgVideoFrame > iframe {
  width: 100vw;
  height: 56.25vw;
  min-height: 100vh;
  min-width: 177.77vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  /* display:block;
    border:none; */
  /* pointer-events:none; */
}
/* .player .vp-unmute{
    border:10px solid red;
    top:50%;
    left:50%;
}
.player .vp-unmute-button{
    max-width:100%;
}
.player .vp-unmute-button>span{
    color:red;
} */

.titleTextGroup {
  position: absolute;
  bottom: 0;
  right: 0;
  left: inherit;
  color: #f1f1f1;
  width: auto;
  height: auto;
  /* background:rgba(0,0,0,0.1); */
  background: transparent;
  padding: 0 5.2vw;
  /* border:1px solid blue; */
}
.titleTextGroup ul {
  width: auto;
  height: 100%;
  /* border:1px solid red; */
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 4vw;
}
.titleTextGroup ul li span {
  font-size: 1.8vw;
  cursor: pointer;
  padding: 1vw 2vw;
  border-radius: 1vw;
  background: rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.5);
}
.titleTextGroup ul li span.volumeIcon {
  color: rgba(255, 187, 0, 0.9);
}
.titleTextGroup ul li span:hover {
  background: rgba(0, 0, 0, 0.5);
}
.titleTextGroup ul li span:active {
  color: rgba(255, 255, 255, 1);
}

.xrbookExplain {
  width: 80vw;
  margin: 0 auto;
  height: auto;
  background: #ffffff;
}

.titleBox {
  /* display:flex;
    flex-direction:row-reverse; */
  width: 100%;
  /* align-items: flex-end;
    justify-content: flex-end; */
  margin-right: auto;
}
.titleBox h3 {
  font-size: 1.6vw;
  font-weight: 500;
}

.titleBox h2 {
  font-size: 3.1vw;
  font-weight: 500;
  line-height: 1;
}

.textBox {
  width: 100%;
  display: none;
  flex-direction: row;
  padding-top: 1.6vw;
}

.textBox p {
  font-size: 0.9vw;
  font-weight: 600;
  justify-content: flex-start;
}
.textBox a {
  font-size: 0.8vw;
  font-weight: 600;
  width: 8.6vw;
  height: 2.9vw;
  border: 1px solid #000000;
  line-height: 2.9vw;
  text-decoration: none;
  text-align: center;
  color: #000f1e;
  margin-left: auto;
  justify-content: flex-end;
}

.textBox a:hover {
  background: rgba(0, 15, 30, 0.8);
  color: #ffffff;
}

.actVideoOutFrame {
  width: 100%;
  padding: 10vw 0;
  /* height:100vh; */
  /* border:1px solid orange; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.actVideoOutFrame:after {
  display: block;
  content: "";
  clear: both;
}
.actVideoInFrame {
  width: 100%;
  height: 56.25vw;
  /* overflow:hidden; */
  position: relative;
}
.actVideoInFrame > iframe {
  width: 100vw;
  height: 56.25vw;
  /* min-height:50vh; */
  /* min-width:177.77vh; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}
.actVideoInFrame > span.blackCover {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
