/* res css */
@media (min-width:835px){
    .infoGroup .textareaBx{
        display: none;
    }
}
@media (max-width:834px){
    .contactBg{
        width:100%; 
        height:auto;
    }
    .contactFrame{
        padding-top:0;
    }
    .contactFrame h3{
        font-size:4.5vw;
        line-height:9vw;
        padding-bottom:3vw;
        padding-top:8vw;
    }
        
    .contactFrame>.inputGroup{
        flex-direction:column;
    }
    .contGroup{
        width:100%;
        height:50%;
        padding:0;

    }
    .contGroup .radioGroup{
        display: flex;
        align-items: baseline;
        margin-top:2.1vw;
        margin-bottom:4vw;
    }
    .contGroup .radioGroup .radioTitle{
        font-size: 2.1vw;
        font-weight: bold;
        margin-right:5vw;
        margin-left: 1vw;
    }
    .contGroup .radioBtn{
        /* padding-bottom:3vw; */
        /* display:none; */
        margin: auto 0;
    }
    .contGroup .radioBtn div{
        width:12vw;
        /* display: ; */
        /* margin-bottom:1vw; */
    }
    .contGroup .radioBtn div input{
        height:2vw;
    }
    .contGroup .radioBtn div span{
        font-size:2vw;
        padding-left:0.5vw; 
    }
    .contGroup .textareaBx textarea{
        /* font-size:2vw;
        padding:2vw 2vw; */
        display: none;
    }
    .contGroup .textareaBx{
        /* height:40vw;
        font-size:2vw; */
        display: none;
    }
    .centerLine{display:none;}

    .infoGroup{
        width:100%;
        padding-right:0;
        border-right:none;
    }
    .emphasizeText{
        padding-bottom:2vw;
        /* padding-top:3vw; */
        font-size:1.8vw;
    }
    
    .infoGroup>input{
        width:100%; 
        height:7vw;
        font-size:2vw;
        margin-bottom:3vw;
    }
    .infoGroup a.termsLink{
        font-size:1.9vw;    
    }

    .infoGroup .checkBx{
        display:flex;
        padding-top:1.5vw;
    }
    .errorMsg{
        font-size:1.9vw;
    }
    .infoGroup>.checkBx>input{
        height:6vw;
        height:6vw;
        margin-right:2vw;
        align-items: center;
    }
    .infoGroup>.textareaBx textarea{
        font-size:2.2vw;
        padding:2vw 2vw;
        margin-top: 3vw;
        line-height: 3vw;
    }
    .infoGroup>.textareaBx{
        height:40vw;
        font-size:2vw;
    }
    .infoGroup .checkBx span{
        font-size:1.9vw;
        
    }
  
    .infoGroup button{  
        height:7vw;
        font-size:2vw;
        padding-bottom:3vw;
        line-height:5vw;
        line-height:7vw;
    }
    .inputGroup button {
        margin-top: 0;
    }


}


@media (max-width:480px){
    .contactFrame{
        padding-top:5vw;
        padding-bottom: 13vw;
    }
    .contactFrame h3{
        font-size:8vw;
        line-height:9vw;
        padding-bottom:10vw;
        padding-top:10vw;
    }
    .contGroup .radioGroup{
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 2vw;
    }
    .contGroup .radioGroup .radioTitle{
        /* font-size: 4vw;
        font-weight: bold;
        margin-bottom: 5vw; */
        display: none;
    }
    .contGroup .radioBtn{
        padding-bottom:0;
        margin: auto 0;
        display: flex;
    }
    .contGroup .radioBtn div{
        width:100%;
        margin-right:0;
        margin-bottom:4vw;
    }
    .contGroup .radioBtn div input{
        height:3vw;
    }
    .contGroup .radioBtn div span{
        font-size:3.7vw;
        /* padding-left:2vw; */
        padding-right:3vw; 
    }
    .contGroup .textareaBx textarea{
        /* font-size:4vw;
        padding:3vw 4vw; */
        display: none;
    }
    .emphasizeText {
        font-size:4vw;
    }
    .infoGroup>input{
        height:10vw;
        font-size:4vw;
        padding:0 4vw;
    }
    .infoGroup>div.textareaBx>textarea{
        font-size:4vw;
        padding:2vw 3vw;
        line-height: 6vw;
    }
    .infoGroup a.termsLink{
        font-size:4vw;
        padding-top: 2vw;
    }

    .infoGroup .checkBx{
        display:flex;
        padding-top:3vw;
        align-items: flex-start;
    }
    .errorMsg{
        font-size:4vw;
        margin: 5vw 0;
    }
    .inputGroup p{
        font-size: 3vw;
    }
    .infoGroup>.checkBx>input{
        margin-right:2vw;
    }
    .infoGroup .checkBx span{
        font-size:4vw;   
        line-height:5.8vw;
    }
    .infoGroup button{  
        height:10vw;
        font-size:5vw;
        padding-bottom:6vw;
        line-height:5vw;
        line-height:10vw;
    }
}