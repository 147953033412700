
@media (max-width:834px){
    .userGuidesFrame{
        padding:15vw 5vw 0;
    }
    .userGuidesFrame > div > .userGuidesTabBtnGroup div {
        font-size: 1.8vw;
        width: 34%;
        height: 6vw;
        line-height: 6vw;
    }
    /* .userGuidesFrame > div > .userGuidesTabBtnGroup div:nth-child(3){width:30%;} */
    .userGuidesFrame > div > .userGuidesTabBtnGroup div:nth-child(4){width:20%;}
    .userGuidesFrame > div > .userGuidesTabContGroup>div>h5{
        font-size:2.5vw;
        padding-bottom:2.5vw;
        margin-bottom:2.5vw;
    }
    /* onLearnGuideFrame */
    .onLearnGuideFrame ul li ul{
        margin-bottom:4vw;
    }
    .onLearnGuideFrame ul li ul li.onlineGuideImg{
        padding:0 4vw;
    }
    .onLearnGuideFrame ul li ul li.onlineGuideText span{
        font-size:1.6vw;
        line-height:2.5vw;
    }
    .onLearnGuideFrame ul li ul li.onlineGuideText span:nth-child(1){
        font-size:2vw;
        padding-bottom:1.2vw;
        padding-top:1vw;
    }
    
    /* offLearnGuideFrame */
    .offLearnGuideFrame ul li ul{
        margin-bottom:4vw;
    }
    .offLearnGuideFrame ul li ul li.offlineGuideImg{
        padding:0 4vw;
    }
    .offLearnGuideFrame ul li ul li.offlineGuideText span{
        font-size:1.6vw;
        line-height:2.5vw;
    }
    .offLearnGuideFrame ul li ul li.offlineGuideText span:nth-child(1){
        font-size:2vw;
        padding-bottom:1.2vw;
        padding-top:1vw;
    }

    /* deviceMenualGuideFrame */
    .deviceMenualGuideFrame>h5{
        font-size:2.5vw;
        padding-bottom:2.5vw;
        margin-bottom:2.5vw;
    }
    .deviceMenualGuideFrame>ul:nth-of-type(1)>li:nth-child(1),
    .deviceMenualGuideFrame>ul:nth-of-type(1)>li:nth-child(3),
    .deviceMenualGuideFrame>ul:nth-of-type(1)>li:nth-child(6){
        margin-bottom:4vw;
    }
    .deviceMenualGuideFrame>ul:nth-of-type(2)>li:nth-child(1){
        margin-bottom:4vw;
    }
    .deviceMenualGuideFrame>ul>li>ul{
        margin-bottom:4vw;
    }
    .deviceMenualGuideFrame>ul>li>ul>li.deviceGuideImage{
        width:60%;
    }
    .deviceMenualGuideFrame>ul>li>ul>li.deviceGuideText{
        width:40%;
    }
    .deviceMenualGuideFrame>ul>li>ul>li.deviceGuideText>span{
        font-size:1.6vw;
        line-height:2.5vw;
    }
    .deviceMenualGuideFrame>ul>li>ul>li.deviceGuideText>span:nth-child(1){
        font-size:2vw;
        padding-bottom:1.2vw;
        padding-top:1vw;
    }


        
    /* rentalPolicyPopCont */
    .RentalGuideFrame .rentalPolicyPopCont li{
        margin-bottom:4vw;
    }
    .RentalGuideFrame .rentalPolicyPopCont li h6{
        font-size:2vw;
        padding-bottom:1.2vw;
    }
    .RentalGuideFrame .rentalPolicyPopCont li span{
        font-size:1.6vw;
        line-height:2.5vw;
    }
    .RentalGuideFrame .rentalPolicyPopCont li span span{
        font-size: 1.4vw;
    }

}





@media (max-width:480px){
    .userGuidesFrame{
        padding:32vw 5vw 0;
    }
    .userGuidesFrame > div > .userGuidesTabBtnGroup div {
        font-size: 3vw;
        width: 34%;
        height:auto;
        padding:2vw 0;
        line-height:4vw;
        word-break: keep-all;
        display:flex;
        flex-direction: row;
        align-items: center;
    }
    
    .userGuidesFrame > div > .userGuidesTabBtnGroup div:nth-child(1),
    .userGuidesFrame > div > .userGuidesTabBtnGroup div:nth-child(2){
        padding:2vw 4vw;
    }
    .userGuidesFrame > div > .userGuidesTabBtnGroup div:nth-child(3){width:32%;
        display:flex;
        flex-direction:column;
    }
    .userGuidesFrame > div > .userGuidesTabBtnGroup div:nth-child(4){width:18%;
        display:flex;
        flex-direction:column;
    }
    .userGuidesFrame > div > .userGuidesTabContGroup>div>h5{
        font-size:4vw;
        padding-bottom:2.5vw;
        margin-bottom:2.5vw;
    }

    /* onLearnGuideFrame */
    .onLearnGuideFrame ul li ul{
        margin-bottom:7vw;
    }
    .onLearnGuideFrame ul li ul li.onlineGuideImg{
        padding:0 3vw;
    }
    .onLearnGuideFrame ul li ul li.onlineGuideText span{
        font-size:3vw;
        line-height:4.5vw;
    }
    .onLearnGuideFrame ul li ul li.onlineGuideText span:nth-child(1){
        font-size:3.5vw;
        padding-bottom:3vw;
        padding-top:2vw;
    }

    /* offLearnGuideFrame */
    .offLearnGuideFrame ul li ul{
        margin-bottom:7vw;
    }
    .offLearnGuideFrame ul li ul li.offlineGuideImg{
        padding:0 3vw;
    }
    .offLearnGuideFrame ul li ul li.offlineGuideText span{
        font-size:3vw;
        line-height:4.5vw;
    }
    .offLearnGuideFrame ul li ul li.offlineGuideText span:nth-child(1){
        font-size:3.5vw;
        padding-bottom:3vw;
        padding-top:2vw;
    }
    
    



     /* deviceMenualGuideFrame */
     .deviceMenualGuideFrame>h5{
        font-size:4vw;
        padding-bottom:2.5vw;
        margin-bottom:2.5vw;
    }
    .deviceMenualGuideFrame>ul:nth-of-type(1)>li:nth-child(1),
    .deviceMenualGuideFrame>ul:nth-of-type(1)>li:nth-child(3),
    .deviceMenualGuideFrame>ul:nth-of-type(1)>li:nth-child(6){
        margin-bottom:5vw;
    }
    .deviceMenualGuideFrame>ul:nth-of-type(2)>li:nth-child(1){
        margin-bottom:5vw;
    }
     .deviceMenualGuideFrame>ul>li>ul{
         flex-direction:column;
         margin-bottom:5vw;
     }
     .deviceMenualGuideFrame>ul>li>ul>li.deviceGuideImage{
         width:100%;
         margin-bottom:1vw;
     }
     .deviceMenualGuideFrame>ul>li>ul>li.deviceGuideText{
        width:100%;
        padding-left:0;
    }
    .deviceMenualGuideFrame>ul>li>ul>li.deviceGuideText>span{
        font-size:3vw;
        line-height:4.5vw;
    }
    .deviceMenualGuideFrame>ul>li>ul>li.deviceGuideText>span:nth-child(1){
        font-size:3.5vw;
        padding-bottom:3vw;
        padding-top:2vw;
    }
    .deviceMenualGuideFrame>ul>li>ul>li.deviceGuideText>span>img{
        width: 10vw;
    }

        
    /* rentalPolicyPopCont */
    .RentalGuideFrame .rentalPolicyPopCont li{
        margin-bottom:7vw;
    }
    .RentalGuideFrame .rentalPolicyPopCont li h6{
        font-size:5vw;
        padding-bottom:2vw;
    }
    .RentalGuideFrame .rentalPolicyPopCont li span{
        font-size:4vw;
        line-height:6vw;
    }
    .RentalGuideFrame .rentalPolicyPopCont li span span{
        font-size: 3.5vw;
    }
}