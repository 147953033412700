.none {
  display: none;
}
.show {
  display: block;
}


.corporateCardLink{
  margin-left:0.5vw;
  cursor: pointer;
  text-decoration: underline;
  color:rgb(8, 89, 165);
  
}
.corporateCardLink:hover{
  color:rgb(0, 132, 255);
}

.corporateCardBg{
  width:100vw;
  height:100vh;
  background:rgba(0,0,0,0.9);
  position:fixed;
  top:0;
  left:0;
  z-index:100;
}
.corporateCardFrame{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  width:50vw;
  height:auto;
  background:#ffffff;
  padding:2vw 3vw;
}
.corporateCardFrame .corporateCardTitle{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1vw;
  border-bottom: 1px solid #000000;
  margin-bottom: 3vw;
}
.corporateCardFrame .corporateCardTitle h5{
  font-size: 1.5vw;
  font-weight: 600;
}
.corporateCardFrame .corporateCardTitle div{
  font-size: 2vw;
  cursor: pointer;
}
.corporateCardFrame .corporateCardTitle div:hover{
  font-weight:600;
}
.corporateCardFrame .corporateCardCont{
  font-size:1vw;
  margin-bottom:2vw;
}
.corporateCardFrame .corporateCardCont ul li{
  margin-bottom:0.5vw;
  font-size:1.1vw;
  line-height:2vw;
}
.corporateCardFrame .corporateCardCont ul li:nth-last-child(1){
  padding-top:1vw;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}
.corporateCardFrame .corporateCardCont ul li:nth-last-child(2){
  padding-top:1vw;
  color:#c56e6e;
  word-break: keep-all;
}
.corporateCardFrame .corporateCardCont ul li span:nth-child(1){
  font-weight:600;
  width:30%;
  display:inline-block;
}
.corporateCardFrame .corporateCardCont ul li span button{
  border-width:1px;
  padding:0 1vw;
  line-height:2.5vw;
  height:2.5vw;
}











 .userInfoUloadContFrame{
   width:90%;
   margin-bottom:1vw;
 }

.dBookUserInfoGroup{
  padding:1vw;
  border:1px solid #dedede;
  width:100%;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}
.dBookUserInfoGroup form{
  margin-bottom:1vw;
}
.dBookUserInfoGroup form .excelUploadGroup{
  width:100%;
  display:flex;
  flex-direction: row;
  align-items: center;
}
.dBookUserInfoGroup form .excelUploadGroup input{
  line-height:1.6vw !important;
  padding:0 !important;
  border:1px solid #dedede !important;
}
.dBookUserInfoGroup form .excelUploadGroup div{
  padding: 0 0.5vw;
  border:1px solid #aaaaaa;
  color:#aaaaaa;
  margin-left:auto;
  height:2vw;
  line-height:2vw;
  cursor: pointer;
}
.dBookUserInfoGroup form .excelUploadGroup div:hover{
  color:#000000;
  border:1px solid #000000;
}
.dBookUserInfoGroup form iframe{
  height:3vw;
  line-height:3vw;
  width:100%;
  padding:0;
  border:1px solid #dedede;
}
.dBookUserInfoGroup>div{
  margin-bottom:1vw;
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
}
.dBookUserInfoGroup>div input{
  width:12vw !important;
}
.dBookUserInfoGroup>div>span{
  border:1px solid #000000;
  height:2vw;
  line-height:2vw;
  display: inline-block;
  padding:0 1vw;
  cursor: pointer;
}
.dBookUserInfoGroup>div>span:hover{
  background:#000f1e;
  color:#ffffff;
}
.dBookUserInfoGroup>div>p{
  margin-left:1vw;
  line-height:2vw;
}

.dBookUserInfoGroup>div>table{
  border:1px solid #959595;
  width:60%;
}
.dBookUserInfoGroup>div>table th{
  height:2vw;
  line-height:2vw;
  padding:0 0.5vw;
  background:#eeeeee;
  font-weight:300;
}
.dBookUserInfoGroup>div>table th:nth-last-child(1){
  text-align:center;
}
.dBookUserInfoGroup>div>table td{
  height:2vw;
  line-height:2vw;
  padding:0 0.5vw;
}
.dBookUserInfoGroup>div>table td:nth-last-child(1){
  /* padding:0; */
}
.dBookUserInfoGroup>div>table td span{
  display:block;
  text-align:center;
  width:100%;
  cursor: pointer;
}
.dBookUserInfoGroup>div>table td span:hover{
  font-size:2vw;
  color:#680a0a;
}
.refreshExcelBtn{
  border:1px solid #000000;
  margin-left:0.5vw;
  padding:0 1vw;
  height:2vw;
  line-height:2vw;
  cursor: pointer;
}
.refreshExcelBtn:hover{
  background:#000f1e;
  color:#ffffff;
}

.createCodeBtn{
  border-width:1px;
  height:2vw;
  line-height:2vw;
  padding:0 0.5vw;
}






.detailBox > li:nth-last-child(1){
  margin-top:1vw;
}
.detailBox > li:nth-last-child(1)>button{
  width:100%;
  height: 2.5vw;
  line-height: 2.5vw;
  cursor: pointer;
  border: 1px solid #959595;
}




