.introOutFrame{
    width:100vw;
    height:100%;
    background:rgba(0, 15, 30, 0.99);
}

.bgVideoFrame{
    position:relative;
    width:100%;
    height:100%;
}

.bgVideoFrame>iframe{
    width:100vw;
    height:56.25vw;
    min-height:100vh;
    min-width:177.77vh;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    z-index:0;
    /* display:block;
    border:none; */
}

/* 
.titleTextGroup{
    position: absolute;
    bottom:0;
    left:0;
    color:#f1f1f1;
    width: auto;
    height: 100%;
    background:rgba(0,0,0,0.1);
    padding:0 5.2vw; 
}
.titleTextGroup ul{
    width:100%; 
    height:100%;    
    display:flex;
    flex-direction: column;
    justify-content:flex-end;
    padding:8vw 0 6vw;
}
.titleTextGroup ul li{
  font-weight:800;
  letter-spacing:1px;
}

.titleTextGroup ul li.mainTitle1{
    font-size:8vh; 
    letter-spacing:3px;
    
}
.titleTextGroup ul>span{
    width:100%;
    height:2px;
    background:#ffffff;
    margin:1vw 0;
    display:block;
}

.titleTextGroup ul li.mainTitle2{
    font-size:3.5vh;
    font-weight:600; 
    
}
.titleTextGroup ul li.subTitle{
    display:flex;
    flex-direction: column;
    padding-bottom:0vw;
    font-weight:300;    
}
.titleTextGroup ul li.subTitle>span:nth-child(1){
    font-size:3vh; line-height:5vh;  
}
.titleTextGroup ul li.subTitle>span:nth-child(2){
    font-size:3vh;  line-height:5vh;} */






.xrbookExplain{
    width:80vw;
    margin:0 auto;
    height:auto;
    background:#ffffff;    
    display:none;
}


.titleBox{
    /* display:flex;
    flex-direction:row-reverse; */
    width:100%; 
    /* align-items: flex-end;
    justify-content: flex-end; */
    margin-right:auto;
 
}
.titleBox h3{
    font-size:1.6vw; 
    font-weight:500;

}

.titleBox h2{
    font-size:3.1vw;
    font-weight:500;
    line-height:1;
}


.textBox{
    width:100%;
    display:none;
    flex-direction:row;
    padding-top:1.6vw;
}

.textBox p{
    font-size:0.9vw;
    font-weight:600;
    justify-content: flex-start;
}
.textBox a{
    font-size:0.8vw;
    font-weight:600;
    width:8.6vw;
    height:2.9vw;
    border:1px solid #000000;
    line-height:2.9vw;
    text-decoration:none;
    text-align:center;
    color:#000f1e;
    margin-left:auto;
    justify-content: flex-end;
}

.textBox a:hover{
    background:rgba(0, 15, 30, 0.8);
    color:#ffffff;
}



