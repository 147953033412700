/* res css */


@media (max-width:834px){
    .xrsOutFrame{
        height:auto;
        /* padding-top:25vh;
        padding-bottom:25vh; */
    }
    .xrsInFrame{
        height:50vh;
        padding-bottom:10vw;
        padding-top:0;
    }
    .xrs1stInFrame{
        padding-top:10vw;
    }
    .xrs1stInFrame .TitleBox{
        padding-left:3vw;
        width:50%;
    }
    .xrs1stInFrame .TitleBox ul li.sectMenuText{
        font-size:2vw;
    }
    .xrs1stInFrame .TitleBox ul li.contTitleText{
        font-size:4.5vw;
        line-height:5.5vw;
        padding:2vw 0 3vw;
    }
    .xrs1stInFrame .TitleBox ul li.contentText{
        font-size:1.8vw;
        padding-bottom:5vw;
        line-height:2.5vw;
    }
    .xrs1stInFrame .TitleBox ul li.contentText span:nth-child(3){
        width:70%;
    }
    .xrs1stInFrame .TitleBox ul li.contentText span:nth-last-child(1){
        font-size:2.5vw;
    }
    .xrs1stInFrame .TitleBox ul li.buttonText a{
        font-size:1.6vw;
        height:5vw;
        line-height:5vw;
        padding:0 3vw;
    }


    .xrs1stInFrame .contBox{
        padding:1vw 0 0;
        width:50%;
    }


    .xrs1stInFrame .contBox .contText{
        padding-left:3vw;
    }
    .xrs1stInFrame .contBox .contText ul li ul li{
        font-size:1.5vw;
        line-height:2.5vw;
    }
    .xrs1stInFrame .contBox .contText ul li ul li:nth-child(1){
        font-size:2.5vw;
        line-height:3vw;
        padding-bottom:1vw;;
    }
   

    .xrs2ndInFrame{
        height:auto;
    }
    .xrs2ndInFrame .TitleBox{
        width:80%;
    }
    .xrs2ndInFrame .TitleBox ul li.contTitleText{
        font-size:4.5vw;
        line-height:5.5vw;
    }
    .xrs2ndInFrame .TitleBox ul li.contentText{
        font-size:1.8vw;
        line-height:2.5vw;
    }
    .xrs2ndInFrame .contBox{
        width:20%;
        
    }
    .xrs2ndInFrame .contBox div{
        padding-top:30vw;
        
    }


    

    .xrs3thInFrame{
        padding-top:8vw;
        padding-bottom:0;
    }
    .xrs3thInFrame .contBox{
        padding:1vw 0 0;
        width:45%
    }
    .xrs3thInFrame .contBox>ul>li:nth-child(1){width:10%;}
    .xrs3thInFrame .contBox>ul>li:nth-child(2){width:20%;}
    .xrs3thInFrame .contBox>ul>li:nth-child(3){width:80%;}
    .xrs3thInFrame .contBox>ul>li>span>ul{
        padding:3vw;
    }
    .xrs3thInFrame .contBox>ul>li>span>ul>li:nth-child(1){
        font-size:2vw;
        font-weight:300;
    }
    .xrs3thInFrame .contBox>ul>li>span>ul>li:nth-child(2){
        font-size:3vw;
        line-height:5vw;
    }
    .xrs3thInFrame .contBox>ul>li>span>ul>li:nth-child(3){
        font-size:2vw;
        line-height:3vw;
        font-weight:200;
    }
    .xrs3thInFrame .TitleBox{
        width:55%;
        padding-left:3vw;
    }
    .xrs3thInFrame .TitleBox ul li.sectMenuText{
        font-size:2vw;
    }
    .xrs3thInFrame .TitleBox ul li.contTitleText{
        font-size:4.5vw;
        line-height:5.5vw;
        padding:1vw 0 3vw;
    }
    .xrs3thInFrame .TitleBox ul li.contentText{
        font-size:1.8vw;
        padding-bottom:5vw;
        line-height:2.5vw;
    }
    .xrs3thInFrame .TitleBox ul li.buttonText a{
        font-size:1.6vw;
        height:5vw;
        line-height:5vw;
        padding:0 3vw;
    }
    
    .xrs4thInFrame .contBox{
        width:50%
    }
    .xrs4thInFrame .contBox>ul>li:nth-child(1){width:80%;}
    .xrs4thInFrame .contBox>ul>li:nth-child(2){width:20%;}
    .xrs4thInFrame .contBox>ul>li:nth-child(3){width:10%;}
    .xrs4thInFrame .contBox>ul>li>span>ul{
        padding:3vw;
    }
    .xrs4thInFrame .contBox>ul>li>span>ul>li:nth-child(1){
        font-size:2vw;
        font-weight:300;
    }
    .xrs4thInFrame .contBox>ul>li>span>ul>li:nth-child(2){
        font-size:3vw;
        line-height:5vw;
    }
    .xrs4thInFrame .contBox>ul>li>span>ul>li:nth-child(3){
        font-size:2vw;
        line-height:3vw;
        font-weight:200;
    }
    .xrs4thInFrame .TitleBox{
        width:50%;
    }
    .xrs4thInFrame .TitleBox ul li.sectMenuText{
        font-size:2vw;
    }
    .xrs4thInFrame .TitleBox ul li.contTitleText{
        font-size:4.5vw;
        line-height:5.5vw;
        padding:1vw 0 3vw;
    }
    .xrs4thInFrame .TitleBox ul li.contentText{
        font-size:1.8vw;
        padding-bottom:5vw;
        line-height:2.5vw;
    }
    .xrs4thInFrame .TitleBox ul li.buttonText a{
        font-size:1.6vw;
        height:5vw;
        line-height:5vw;
        padding:0 3vw;
    }




}






@media (max-width:480px){
    
    .xrsOutFrame:nth-child(1){padding-top:20vw; padding-bottom:20vw;}
    .xrsOutFrame:nth-child(2){padding-bottom:20vw;}
    .xrsOutFrame:nth-child(3){padding-bottom:20vw;}
    .xrsOutFrame:nth-child(4){padding-bottom:20vw;}

    .xrsInFrame{
        height:auto;
        flex-direction: column-reverse;
    }
    .xrsInFrame .TitleBox{
        width:100%;
        padding-left:0;
    }
    .xrsInFrame .TitleBox ul li.sectMenuText{
        font-size:3.8vw;
    }
    .xrsInFrame .TitleBox ul li.contTitleText{
        font-size:8vw;
        line-height:10vw;
        padding:3vw 0 5vw;
    }
    
    .xrsInFrame .TitleBox ul li.contentText{
        font-size:4vw;
        padding-bottom:5vw;
        line-height:5vw;
    }
    .xrsInFrame .TitleBox ul li.buttonText a{
        font-size:4vw;
        height:10vw;
        line-height:10vw;
        padding:0 5vw;
    }

    .xrsInFrame .contBox{
        width:100%;
        height:40vh;
        display:inherit;
        flex-direction: inherit;
        position: relative;
    }
    .xrsInFrame .contBox .contImage{
        width:100%;
        padding-top:5vw;
    }
    
    .xrs1stInFrame .TitleBox ul li.contTitleText span:nth-child(1){
        font-size:5.5vw;
    }
    .xrs1stInFrame .TitleBox ul li.contTitleText span:nth-child(2){
        font-size:8vw;
    }
    .xrs1stInFrame .TitleBox ul li.contentText span:nth-child(3){
        width:100%;
    }
    .xrs1stInFrame .TitleBox ul li.contentText span:nth-last-child(1){
        font-size:5vw;
    }

    .xrs2ndInFrame .TitleBox{
        height:auto;
    }
    .xrs2ndInFrame .TitleBox ul li.contTitleText span:nth-child(1){
        font-size:5.5vw;
    }
    .xrs2ndInFrame .contBox{
        display:flex;
        flex-direction:row;
        align-items: flex-start;
        padding:0;
        height:auto;
        display:none !important;
    }
    .xrs2ndInFrame .contBox div{
        width:20vw;
        height:20vw;
        padding-top:0;
        display:none !important;
    }


    .xrs3thInFrame{
        height:100%;
    }
    
    .xrs3thInFrame .contBox{
        padding-top:15vw;
        height:50vh;
    }
    .xrs3thInFrame .contBox>ul>li>span>ul{
        padding:5vw;
    }
    .xrs3thInFrame .contBox>ul>li>span>ul>li:nth-child(1){
        font-size:4vw;
        font-weight:300;
    }
    .xrs3thInFrame .contBox>ul>li>span>ul>li:nth-child(2){
        font-size:6vw;
        line-height:8vw;
    }
    .xrs3thInFrame .contBox>ul>li>span>ul>li:nth-child(3){
        font-size:4vw;
        line-height:6vw;
        font-weight:200;
    }
    .xrs3thInFrame .TitleBox{
        padding-left:6vw;
        padding-top:4vw;
    }
    .xrs3thInFrame .TitleBox ul{
        width:100%;
    }
    .xrs3thInFrame .TitleBox ul li{
        width:100%;
    }

    .xrs4thInFrame{
        height:100%;
    }
    
    .xrs4thInFrame .contBox{
        height:50vh;
    }

    .xrs4thInFrame .contBox>ul>li>span>ul{
        padding:5vw;
    }
    .xrs4thInFrame .contBox>ul>li>span>ul>li:nth-child(1){
        font-size:4vw;
        font-weight:300;
    }
    .xrs4thInFrame .contBox>ul>li>span>ul>li:nth-child(2){
        font-size:6vw;
        line-height:8vw;
    }
    .xrs4thInFrame .contBox>ul>li>span>ul>li:nth-child(3){
        font-size:4vw;
        line-height:6vw;
        font-weight:200;
    }

}
/* @media (max-width:834px){
    .xrsOutFrame{
        
        padding-top:10vw;
    }
    .xrsInFrame{
        
        padding-left:0;
        padding-right:0;
        padding-bottom:0;
    }    
    .xrsTextBox{       
        height:auto;
        position: inherit;
        padding-top:0;
        z-index:0;
        padding:4.7vw 12.5vw;
    }
    .xrsTextBox h2{
        font-size:5vw;  
        padding-bottom:4vw;
    }
    .xrspagerGroup{
        padding-bottom:3vw;
    }

    .xrspagerGroup li{
        font-size:2vw;
        line-height:4vw;
    }      
    .xrsTextBox .btnGroup a{
        height:5vw;
        font-size:2vw;
        line-height:5vw;
    }
    

    .xrsContBox{
        height:auto;
        position:inherit;
        top:inherit;
        bottom:0;
    }
    .xrsContBox ul.slider li img.pcImage{
        display:none;
    }
    .xrsContBox ul.slider li img.mobileImage{
        display:block;
        width:100%;
    }

    
    
}
@media (max-width:480px){
    .xrsTextBox h2{
        font-size:10vw;  
        padding-bottom:6vw;
    }

    .xrspagerGroup li{
        font-size:3.6vw;
        line-height:7vw;
    }      
    
    .xrsTextBox .btnGroup a{
        height:10vw;
        font-size:3.5vw;
        line-height:10vw;
        padding: 0 6vw;
    }
} */