.landingBg{
    width:100%;
    height:100vh;
    padding:0 5.2vw;

    
}

.landingFrame{
    width:auto;
    height:100%;
    padding-top:15vw;
    margin-left:10vw;
    /* border:1px solid orange; */
}

.landingFrameMo{display:none;}

.landingFrame li{
    width:100%;
    height:2vw;
    /* border:1px solid blue; */
    line-height:2vw;
}

.landingFrame .pwResetTitle{
    font-size:1.8vw;
    margin-bottom:5vw;
    font-weight:600;
    word-break: keep-all;
}



.landingFrame .landingTxt{
    font-size:1.4vw; 
    line-height:3vw;
    word-break: keep-all;
}

.landingFrame .landingBtn{
    width:auto;
    height:3.2vw;
    /* border:1px solid #000000; */
    text-align:center;
    display:inline-block;
}

.landingFrame .landingBtn a{
    line-height:3.2vw;
    font-size:1.2vw;
    font-weight:600;
    text-decoration: none;
    color:#000000;  
    display:inline-block;
    padding:0 3vw;
    border:1px solid #000000;
}
.landingFrame .landingBtn a:nth-child(2){
    background:#000f1e;
    color:#ffffff;
}

.landingBtnGroup{
    display:flex;
    flex-direction: row;
}
.landingBtnGroup>a{
    border:1px solid #000000;
    text-align:center;
    font-size:1.2vw;
    font-weight:600;
    line-height:3.2vw;
    text-decoration: none;
    color:#000000;  
    height:3.2vw;
    padding:0 2vw;
}
.landingBtnGroup>a:nth-child(2){
    background:#000f1e;
    color:#ffffff;
}


.landingFrame ul.emailVeriBoxPC{
    display:block;
}
.landingFrame ul.emailVeriBoxMo{
    display:none;
}

.landingFrame .emailVeriTitle{
    font-size:1.8vw;
    margin-bottom:2vw;
    font-weight:600;
    word-break: keep-all;
}
.landingFrame .emailVeriTxt{
    font-size:1.4vw; 
    word-break: keep-all;
}

.emailVerBtnGroup{
    width:100%; 
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top:2vw;
}

.emailVerBtnGroup button{
    text-decoration: none;
    width:auto;
    height:3.2vw;
    border:1px solid #000000;
    background:#ffffff;
    
    font-size:1.2vw;
    font-weight:600;
    padding:0 2vw;
}
.emailVerBtnGroup button:nth-child(2){
    background:#000f1e;
    color:#ffffff;
}













