.termPoliBg{
    width:100vw;
    min-height:100vh;
    padding:0 5.2vw 15vw;
    /* border:1px solid red; */
}


.termPoliframe{
    width:100%;
    height:100%;
    
    padding:15vw 10vw 0;
    /* border:1px solid orange; */
}

.termPoliframe>h1{
    font-size:1.6vw;
    margin-bottom:3.1vw;
}
.termPoliframe>.underBar{
    width:100%;
    height:1px;
    background:#dedede;
    margin-bottom:2.5vw;
}

.termPoliframe>.ag{
    margin:2vw 0;
}

.termPoliframe>.ag>h4{
    font-size:1vw;
    font-weight:600;
    padding-top:1.5vw;
    margin-bottom:1.5vw;
}

.termPoliframe>.ag>h5{
    font-size:1vw;
    font-weight:600;
    margin-bottom:1vw;
}

.uaBox>p{
    font-size:0.8vw;
    line-height:1.2vw;
    margin-bottom:0.8vw; 

}
.uaBox>ul>li{
    font-size:0.8vw;
    line-height:1.2vw;
    margin-bottom:0.8vw;

}

.serviceGuideLink{
    margin-left:1vw;
    color:#555555;
    text-decoration:underline;
}

.detailBx{
    padding:0.5vw;
}
.detailBx>li{
    margin-bottom:0.5vw;
}
.detailBx>li:nth-last-child(1){
    margin-bottom:0;
}


.poliGraph1{
    border:1px solid #dedede; 
    margin:1vw 0 2vw;
    padding:0;
}
.poliGraph1 thead tr{
    background:#eeeeee;
    border-bottom:1px solid #dedede;
}
.poliGraph1 thead tr th{
    font-weight:normal;
    font-size:0.8vw;
    border:1px solid #dedede;
    padding:0 0.5vw;
    height:2vw;
}
.poliGraph1 thead tr th:nth-child(1){width:5%;}
.poliGraph1 thead tr th:nth-child(2){width:5%;}
.poliGraph1 thead tr th:nth-child(3){width:10%;}
.poliGraph1 thead tr th:nth-child(4){width:30%;}
.poliGraph1 thead tr th:nth-child(5){width:35%;}
.poliGraph1 thead tr th:nth-child(6){width:15%;}

.poliGraph1 tbody tr td{
    height:2vw;
    padding:0.5vw;
    font-size:0.8vw;
    border:1px solid #dedede;
}
.poliGraph1 tbody tr:nth-child(1) td:nth-child(1),
.poliGraph1 tbody tr:nth-last-child(3) td:nth-child(1){
    background:#eeeeee;
}




.poliGraph2{
    width:40%;
    height:auto;
    border:1px solid #dedede; 
    padding:0;
    margin-top:0.5vw;
    margin-bottom:1vw;
}

.poliGraph2>li{
    width:100%;
    height:2vw;
    border-bottom:1px solid #dedede;
    margin:0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.poliGraph2>li:last-child{
    border:none;
}
.poliGraph2>li>span{
    width:50%;
    height:100%;
    line-height:2vw;
    padding-left:0.5vw;
}
.poliGraph2>li>span.cate{
    border-right:1px solid #dedede;
    background:#eeeeee;
}
.poliGraph2>li:first-child>span{
    background:#eeeeee;
}



@media (max-width : 834px){
    .termPoliframe>h1{
        font-size:2.5vw;
    }
    .termPoliframe>.ag{
        margin:4vw 0;
    }
    .termPoliframe>.ag>h4{
        font-size: 2.5vw;
        padding: 4vw 0 2vw;
    }
    .termPoliframe>.ag>h5{
        font-size: 2vw;
    }
    .uaBox>p{
        font-size: 1.8vw;
        line-height: 2.8vw;
        margin-bottom:1vw; 
    }
    .uaBox>ul>li{
        font-size: 1.8vw;
        line-height: 2.8vw;
        margin-bottom:1.2vw;
    }
    .serviceGuideLink{
        margin-left:2vw;
    }
    .detailBx{
        padding:1vw 2vw;
    }
    .detailBx>li{
        margin-bottom:1vw;
    }



    .poliGraph1 thead tr th{
        font-size:1.6vw;
        padding:0 1vw;
        height:4vw;
    }
    .poliGraph1 thead tr th:nth-child(1){width:10%;}
    .poliGraph1 thead tr th:nth-child(2){width:10%;}
    .poliGraph1 thead tr th:nth-child(3){width:10%;}
    .poliGraph1 thead tr th:nth-child(4){width:25%;}
    .poliGraph1 thead tr th:nth-child(5){width:30%;}
    .poliGraph1 thead tr th:nth-child(6){width:15%;}
    .poliGraph1 tbody tr td{
        padding:1vw;
        font-size:1.6vw;
    
    }


    .poliGraph2{
        padding:0;
        margin:1vw 0 2vw;
        width:100%;
    }
    .poliGraph2>li{
        height:4vw;
        margin:0;
    }
    .poliGraph2>li>span{
        line-height:4vw;
        padding-left:1vw;
        font-size:1.6vw;
    }


}

@media (max-width : 430px){
    .termPoliframe{
        padding-top:25vw;
    }
    .termPoliframe>h1{
        font-size:5vw;
        margin-bottom:4vw;
    }
    .termPoliframe>.ag{
        margin:5vw 0;
    }
    .termPoliframe>.ag>h4{
        font-size: 4vw;
        padding: 4vw 0 2vw;
    }
    .termPoliframe>.ag>h5{
        font-size: 4.5vw;
        margin-bottom:2vw;
    }
    .uaBox>p{
        font-size: 3vw;
        line-height: 4.5vw;
        margin-bottom:1vw; 
    }
    .uaBox>ul>li{
        font-size: 3.8vw;
        line-height: 5vw;
        margin-bottom:1.2vw;
    }
    .serviceGuideLink{
        margin-left:3vw;
        font-size:3vw;
    }
    .detailBx{
        padding:1vw 3vw;
    }
    .detailBx>li{
        margin-bottom:2vw;
    }



    .poliGraph1{
        margin:3vw 0 4vw;
    }
    .poliGraph1 thead tr th{
        font-size:2.5vw;
        padding:0 2vw;
        height:4vw;
    }
    .poliGraph1 thead tr th:nth-child(1){width:10%;}
    .poliGraph1 thead tr th:nth-child(2){width:10%;}
    .poliGraph1 thead tr th:nth-child(3){width:10%;}
    .poliGraph1 thead tr th:nth-child(4){width:25%;}
    .poliGraph1 thead tr th:nth-child(5){width:30%;}
    .poliGraph1 thead tr th:nth-child(6){width:15%;}
    .poliGraph1 tbody tr td{
        padding:2vw;
        font-size:2.5vw;
    
    }

    .poliGraph2{
        padding:0;
        margin:2vw 0 4vw;
        width:100%;
    }
    .poliGraph2>li{
        width:100%;
        height:auto;
        margin:0;
        flex-direction: column;
        
    }
    .poliGraph2>li:nth-child(1){
        display:none;
    }
    .poliGraph2>li>span{
        width:100%;
        height:8vw;
        line-height:8vw;
        padding-left:2vw;
        font-size:2vw;
    }
    .poliGraph2>li>span:nth-child(1){
        border-right:none;
        border-bottom:1px solid #dedede;
    }

}



