/* res css */
@media (max-width:834px){

    .slOutFrame{
        height:auto;
    }
    .slInFrame{
        height:auto;
        padding-bottom:10vw;
        padding-top:10vw;
        position:relative;
    }
    .slInFrame .TitleBox{
        justify-content: center;
        padding-left:0;
    }
    .slInFrame .TitleBox ul li.sectMenuText{
        font-size:2vw;
    }
    .slInFrame .TitleBox ul li.contTitleText{
        font-size:4.5vw;
        line-height:5.5vw;
        padding:2vw 0 3vw;

    }
    .slInFrame .TitleBox ul li.contTitleText span:nth-child(2){
        font-size:3.5vw;
    }
    .slInFrame .TitleBox ul li.contentText{
        font-size:1.8vw;
        padding-bottom:5vw;
        line-height:2.5vw;
    }
    .slInFrame .TitleBox ul li.buttonText a{
        font-size:1.6vw;
        height:5vw;
        line-height:5vw;
        padding:0 2vw;
    }


    .slInFrame .contBox{
        padding:1vw 0 0;
        background-position:50% 50%;
        height:auto;
    
    }
    .slInFrame .contBox div{
        transform: scale(1.1);
    }
   
    .slInFrame .contBox .contText{
        padding-left:3vw;
    }
    
    .slInFrame .contBox .contText ul li ul li{
        font-size:1.5vw;
        line-height:2.5vw;
    }
    .slInFrame .contBox .contText ul li ul li:nth-child(1){
        font-size:2.5vw;
        line-height:3vw;
        padding-bottom:1vw;;
    }
    .sl1stInFrame .TitleBox ul li.contentText span:nth-child(3){
        width:80%;
    }
    .sl1stInFrame .TitleBox ul li.contentText span:nth-child(6){
        width:100%;
    }

    
    .slInFrame .contBox ul li:nth-child(1){
        font-size:2.5vw;
        padding-bottom:1vw;
    }
    .slInFrame .contBox ul li:nth-child(2){
        font-size:1.8vw;
    }
    .sl2ndInFrame .contBox ul{
        bottom:0;
    }
}





@media (max-width:480px){
    .slOutFrame{
        padding-bottom:20vw;
    }
    .slInFrame{
        height:auto;
        padding-top:0;
        
        flex-direction: column-reverse;
    }
    .slInFrame .TitleBox{
        width:100%;
        height:auto;
        padding-right:0;
        justify-content: flex-start;

    }
    .slInFrame .TitleBox ul li.sectMenuText{
        font-size:3.8vw;
    }
    .slInFrame .TitleBox ul li.contTitleText{
        font-size:8vw;
        line-height:10vw;
        padding:3vw 0 5vw;
    }
    .slInFrame .TitleBox ul li.contTitleText span:nth-child(2){
        font-size:6.5vw;
    }
    .slInFrame .TitleBox ul li.contentText{
        font-size:4vw;
        padding-bottom:5vw;
        line-height: 5vw;
    }
    .slInFrame .TitleBox ul li.buttonText a{
        font-size:4vw;
        height:10vw;
        line-height:10vw;
        padding:0 5vw;
    }
    .slInFrame .contBox{
        width:100%;
        height:40vh;
        -webkit-transform: scale(1);
        transform: scale(1);
        padding-bottom:10vh;
        overflow:inherit;
    }
    .sl1stInFrame .contBox{ margin-bottom:15vw;}
    .sl2ndInFrame .contBox{ margin-bottom:5vw;}
    .slInFrame .contBox ul{
        left:0;
    }
    .sl1stInFrame .contBox ul{bottom:-12vw;}
    .sl2ndInFrame .contBox ul{bottom:-1vw;}
    .slInFrame .contBox ul li:nth-child(1){
        font-size:5vw;
    }
    .slInFrame .contBox ul li:nth-child(2){
        font-size:4vw;
    }
}
/* @media (max-width:834px){
    .slOutFrame{
        height:auto;
        padding-top:10vw;
    }
    .slInFrame{
        background:#ffffff;
        
        padding-left:0;
        padding-right:0;
        padding-bottom:0;
    }    
    .slTextBox{       
        height:auto;
        position: inherit;
        padding-top:0;
       
        background:#ffffff;
        z-index:0;
        padding:4.7vw 12.5vw;
    }
    .slTextBox h2{
        font-size:5vw; 
        padding-bottom:4vw; 
    }
    .slpagerGroup{
        padding-bottom:3vw;
    }
    .slpagerGroup li{
        font-size:2vw;
        line-height:4vw;
    }      
    .slTextBox .btnGroup a{
        height:5vw;
        font-size:2vw;
        line-height:5vw;
    }
    

    .slContBox{
        height:auto;
        position:inherit;
        top:inherit;
        bottom:0;
        
    }
    .slContBox ul.slider li img.pcImage{
        display:none;
    }
    .slContBox ul.slider li img.mobileImage{
        display:block;
        width:100%;
    }
    
}
@media (max-width:480px){
    .slTextBox h2{
        font-size:10vw;  
        padding-bottom:6vw;
    }
    
    .slpagerGroup li{
        font-size:3.6vw;
        line-height:7vw;
    }      
    
    .slTextBox .btnGroup a{
        height:10vw;
        font-size:3.5vw;
        line-height:10vw;
        padding: 0 6vw;
    }
} */