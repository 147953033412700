.pwForgetBg{
    width:100vw;
    min-height:100vh;
    padding:0 5.2vw 15vw;
}
.pwForgetFrame{
    width:460px;
    height:100%;
    padding-top:15vw;
    margin-left:10vw;
    /* border:1px solid orange; */
}




.pwForgetFrame h1{
    font-size:1.6vw;
    padding-bottom:1.5vw;
    display:inline-block;
    margin-bottom:3vw;
    border-bottom:3px solid #000f1e
}

.inputGroup{
    display:flex;
    flex-direction: column;
}

.inputGroup>input{
    text-decoration: none;
    outline:none;
    width:100%;
    height:50px;
    margin-bottom:1.6vw;
    padding:0 1vw;
    border:none;
    border-bottom:1px solid #000000;
    font-size:0.8vw;
}
.pwForgetLink a{
    text-decoration: none;
    color:#000000;
    text-decoration: underline;
    font-size:1vw;
}



@media (max-width:834px){
    .pwForgetFrame{
        width:auto;
        height:100%;
        padding-top:15vw;
        margin-left:10vw;
        /* border:1px solid orange; */
    }
        
    .pwForgetFrame h1{
        font-size:4vw;
        margin-bottom:10vw;
    }
    .inputGroup{
        width:70%;
        
    }
    .inputGroup>input{
 
        font-size:3vw;    
    }
    .inputGroup>button{
        font-size:2.5vw;  
    }
    .linkGoup{
        width:70%;
        padding-top:5vw;
    }
    .pwForgetLink a{
        font-size:2.5vw;
    }
    
}

@media (max-width:480px){
    .pwForgetFrame{
        width:auto;
        padding-top:30vw;
        margin-left:0;
        
    }
    .pwForgetFrame h1{
        font-size:6vw;
    }
    .inputGroup{
        width:100%;
    }
    .inputGroup>input{
 
        font-size:4vw;    
    }
    .inputGroup>button{
        font-size:4vw;  
    }
    .linkGoup{
        width:100%;
        padding-top:5vw;
    }
    .pwForgetLink a{
        font-size:4vw;
    }
}
