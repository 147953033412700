.nonAuthLogoFrame{
    width:auto;
    height:5.2vw;
    position:absolute;
    top:0;
    left:5.2vw;
    padding:1vw 0;
}
.nonAuthLogoFrame .nonAuthLogo{
    width:auto;
    height:100%;
    display:inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-decoration:none;
}
.nonAuthLogoFrame .nonAuthLogo img{
    height:100%;
}
.nonAuthLogoFrame .nonAuthLogo span{
    padding-left:0.5vw;
    font-size:1vw;
    font-weight:900;
    line-height:1.3vw;
    color:#375c9a;
    display:inline-block;
    
}




.nonAuthSignFrame{
    width:100%;
    height:100%;
    padding:0 5.2vw;
}
.nonAuthSignFrame .signFrame{
    width:30vw;
    height:100%;
    margin-left:10vw;
    padding-top:15vw; 
}
.nonAuthSignFrame .signFrame h1{
    font-size:1.6vw;
    padding-bottom:1.5vw;
    display:inline-block;
    margin-bottom:3vw;
    border-bottom:3px solid #000f1e;
}
.nonAuthSignFrame .signFrame .inputGroup{
    width:100%;
    height:100%;
}
.nonAuthSignFrame .signFrame .inputGroup input{
    width:100%;
    height:3vw;
    margin-bottom:1.6vw;
    padding:0 1vw;
    font-size:1vw;
    border-radius: 0;
    outline: none;
}
.nonAuthSignFrame .signFrame .inputGroup div{
    width:100%;
    height:4vw;
    background:#000f1e;
    color:#ffffff;
    font-size:1.2vw;
    margin-top:3vw;
    line-height:4vw;
    text-align:center;
    cursor: pointer;
}
.nonAuthSignFrame .signFrame .inputGroup a{
    width:100%;
    height:4vw;
    border:1px solid #000f1e;
    color:#000f1e;
    font-size:1.2vw;
    margin-top:1vw;
    line-height:4vw;
    text-align:center;
    cursor: pointer;
}
.nonAuthSignFrame .signFrame .inputGroup div:active,
.nonAuthSignFrame .signFrame .inputGroup a:active{
    border:1px solid rgb(8, 46, 77) !important;
    background:rgb(8, 46, 77) !important;
    color:#ffffff !important;   
}




.nonAuthHomeFrame{
    width:100%;
    height:100%;
    padding-top:15vw;
    padding-left:10vw;
    padding-right:10vw;
}
.nonAuthHomeFrame>span{
    display:flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}
.nonAuthHomeFrame>span>a{
    font-size:2vw;
    color:#000000;
}
.nonAuthHomeFrame>span>a:hover{
    color:#375c9a;
}
.nonAuthHomeFrame>span>a:active{
    color:rgb(8, 46, 77);
}
.nonAuthHomeFrame>span>h3{
    font-size:2vw;
    margin-bottom:1vw;
}
.nonAuthHomeFrame>p{
    font-size:1.2vw;
    margin-bottom:6vw;
}

.nonAuthHomeFrame>div h5{
    width:100%;
    height:5.2vw;
    line-height:5.2vw;
    padding:0 2vw;
    font-size:1.2vw;
    font-weight:600;
    border-bottom:1px solid #000f1e;
    cursor: pointer;
}

.nonAuthHomeFrame>div ul{
    height:100%;
    padding:2vw;
    padding-bottom:3vw;
    display:inline-flex;
    flex-direction:column;
}
.nonAuthHomeFrame>div ul li{
    width:auto;
    height:2vw;
    line-height:2vw;
    font-size:1vw;
    cursor: pointer;
}
.nonAuthHomeFrame>div ul li:hover{
    font-size:1.2vw;
}
.nonAuthHomeFrame>div ul li:active{
    color:rgb(8, 46, 77);
    text-decoration: underline;
}
.nonAuthHomeFrame>div:nth-last-child(1){
    margin-top:10vw;
    margin-bottom:10vw;
    display:flex;
    flex-direction:row;
    justify-content: flex-end;
}
.nonAuthHomeFrame>div button{  
    width:auto;
    height:3vw;
    font-size:1.2vw;
    padding:0 3vw;
    background: #000f1e;
    border:1px solid #000f1e;
    color:#ffffff;
    border:none;
    cursor: pointer;
}
.nonAuthHomeFrame>div>a{
    width:auto;
    height:3vw;
    font-size:1.2vw;
    padding:0 3vw;
    border:1px solid #000f1e;
    color:#000f1e;
    line-height:3vw;
    text-decoration: none;
    cursor: pointer;
}
.nonAuthHomeFrame>div button:active,
.nonAuthHomeFrame>div>a:active{
    border:1px solid rgb(8, 46, 77) !important;
    background:rgb(8, 46, 77) !important;
    color:#ffffff !important;   
}

.nonAuthHomeFrame>div>p{
    padding:1vw 2vw 0;
    font-weight:normal;
    height:auto;
}
.nonAuthHomeFrame .myShareFrame{
    padding:0.5vw 0;
    margin:0 2vw;
}

.nonAuthHomeFrame .myShareFrame>li>button{
    padding:0;
    background:transparent;
    color:#000000;
    line-height:2vw;
    height:2vw;
}
.nonAuthHomeFrame .myShareFrame>li>button:hover{
    background:transparent !important;
    color:#c56e6e !important;
    border:none !important;
    cursor: pointer;
}



@media (max-width:834px){
    .nonAuthLogoFrame{
        height:10vw;
        padding:1.5vw 0;
    }
    .nonAuthLogoFrame .nonAuthLogo span{
        padding-left:2vw;
        font-size:2.5vw;
        line-height:3vw;
    }





    .nonAuthSignFrame .signFrame{
        width:55vw;
    }
    .nonAuthSignFrame .signFrame h1{
        font-size:4vw;
        padding-bottom:5vw;
        margin-bottom:10vw;
    }
    .nonAuthSignFrame .signFrame .inputGroup{
        width:100%;
    }
    .nonAuthSignFrame .signFrame .inputGroup input{
        width:100%;
        height:8vw;
        margin-bottom:2.5vw;
        padding:0 2vw;
        font-size:3vw;
        line-height:8vw;
    }
    .nonAuthSignFrame .signFrame .inputGroup div,
    .nonAuthSignFrame .signFrame .inputGroup a{
        height:8vw;
        font-size:3vw;
        margin-top:5vw;
        line-height:8vw;
    }
    .nonAuthSignFrame .signFrame .inputGroup a{
        margin-top:2vw;
    }



    
    .nonAuthHomeFrame{
        padding:20vw 9vw 0;
    }
    .nonAuthHomeFrame>span>h3{
        font-size:3vw;
    }
    .nonAuthHomeFrame>span>a{
        font-size:3.5vw;
    }
    .nonAuthHomeFrame>p{
        font-size:2.5vw;
    }

    .nonAuthHomeFrame>div h5{
        height:8vw;
        line-height:8vw;
        font-size:2.6vw;
    }
    .nonAuthHomeFrame>div ul li{
        height:4vw;
        line-height:4vw;
        font-size:2vw;
    }
    .nonAuthHomeFrame>div:nth-last-child(1){
        margin-top:20vw;
    }
    .nonAuthHomeFrame>div button,
    .nonAuthHomeFrame>div>a{  
        height:6vw;
        font-size:2.5vw;
        padding:0 6vw;
        line-height:6vw;
    }
    
    .nonAuthHomeFrame>div>p{
        padding:3vw 2vw 2vw;

    }
}
@media (max-width:480px){
    .nonAuthLogoFrame{
        height:15vw;
        padding:2.5vw 0;
    }
    .nonAuthLogoFrame .nonAuthLogo span{
        font-size:4vw;
        line-height:5vw;
    }







    .nonAuthSignFrame .signFrame{
        width:100%;
        padding-top:30vw;
        margin-left:0;
    }
    .nonAuthSignFrame .signFrame h1{
        font-size:6vw;
    }
    .nonAuthSignFrame .signFrame .inputGroup input{
        height:12vw;
        font-size:4vw;
        line-height:12vw;
    }
    .nonAuthSignFrame .signFrame .inputGroup div,
    .nonAuthSignFrame .signFrame .inputGroup a{
        height:12vw;
        font-size:4vw;
        line-height:12vw;
    }
    .nonAuthSignFrame .signFrame .inputGroup a{
        margin-top:3vw;
    }







    .nonAuthHomeFrame{
        padding:30vw 2vw 0;
    }
    .nonAuthHomeFrame>span>h3{
        font-size:5vw;
        margin-bottom:2vw;
    }
    .nonAuthHomeFrame>span>a{
        font-size:5.5vw;
    }
    .nonAuthHomeFrame>p{
        font-size:4vw;
        margin-bottom:10vw;
    }

    .nonAuthHomeFrame>div h5{
        height:15vw;
        line-height:15vw;
        font-size:4vw;
        padding:0 2vw;
    }
    .nonAuthHomeFrame>div ul{
        padding:3vw;
    }
    .nonAuthHomeFrame>div ul li{
        height:6vw;
        line-height:6vw;
        font-size:3.5vw;
    }
    .nonAuthHomeFrame>div:nth-last-child(1){
        margin-top:25vw;
    }
    .nonAuthHomeFrame>div button,
    .nonAuthHomeFrame>div>a{  
        height:10vw;
        font-size:4vw;
        padding:0 10vw;
        line-height:10vw;
    }
    .nonAuthHomeFrame>div>p{
        padding:4vw 2vw 3vw;

    }
}