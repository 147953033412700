.navbar-wrapper {
    position: fixed;
    width: 100%;
    z-index:100;
  }
.scroll-up{
    top: 0;
    -moz-transition: top 1.2s ease;
    -webkit-transition: top 1.2s ease;
    -o-transition: top 1.2s ease;
    transition: top 1.2s ease;
  }
.scroll-down{
    top: -160px;
    -moz-transition: top 1.2s ease;
    -webkit-transition: top 1.2s ease;
    -o-transition: top 1.2s ease;
    transition: top 1.2s ease;
  }
  
.navbar{
  height : 5.2vw;
  width: 100%;
  padding:0;
}
.nav-withBanner{
  top: -160px;
  -moz-transition: top 1s ease;
  -webkit-transition: top 1s ease;
  -o-transition: top 1s ease;
  transition: top 1s ease;
}
.nav-withoutBanner{
  top: 0;
  -moz-transition: top 1s ease;
  -webkit-transition: top 1s ease;
  -o-transition: top 1s ease;
  transition: top 1s ease
}




.navbar>ul{width:100%;}

.navbarFrame{
  width:100%; 
  height:100%; 
  padding:0 5.2vw;
  background:#ffffff;/*intro 영상에서는 색상 제거, 그 외 영역에서 색상 표시 필요!!*/
  display:flex;
  flex-direction: row;
  align-content: space-around;
  justify-content: space-between; 
  position:relative;
  z-index:998;
}

.navbarResFrame{display:none;}
.darkBg{display:none;}




.navbarFrame .logo_img{
  width:auto; 
  /* height:80%;  */
  height:100%;
  /* margin:0.5vw 0; */
  position:absolute;
  top:0; 
  left:5.2vw;
  /* border:1px solid red; */
  padding:1vw 0;
}
.navbarFrame .logo_img a{
  width:100%; 
  height:100%;
  /* border:1px solid blue; */
  display:flex;
  flex-direction:row;
  justify-content: flex-start;
  align-items:center;
  text-decoration: none;
}
.navbarFrame .logo_img a img{
  height:100%; 
  /* border:1px solid green; */
} 
.navbarFrame .logo_img a span{
  /* border:1px solid yellow; */
  padding-left:0.5vw;
  font-size:1vw;
  line-height:1.3vw;
  font-weight:900;
  color:#375c9a;
  display:inline-block;
  text-decoration:none;
  /* margin-bottom:0.5vw; */
}


.headerRight{
  width:80%;
  height:100%;
  display:flex; 
  flex-direction: row;
  justify-content:flex-end; 
  margin-left:auto;
}

.resGnbBtn{
  display:none;
}




.gnb{
  /* width:70%; */
  height:100%; 
  margin-left:auto;
  
}
.gnb>ul.gnbMenu{
  width:100%; 
  font-size:0;
  display:flex; 
  flex-direction: row;
  justify-content:space-around;
}

.gnb>ul.gnbMenu>li{display:inline-block; }
.gnb>ul.gnbMenu>li>a{
  display:block; 
  color:#000000;
  font-size:1.1vw; 
  font-weight:600; 
  line-height:5.2vw;
  text-decoration: none;
  padding:0 1vw;
}
.gnb>ul.gnbMenu>li>a:hover{
  background:#053657;
  color:#ffffff;
}



.auth{
  width:5.2vw; 
  height:5.2vw; 
  padding:1vw; 
  display:flex; 
  position:relative;
  cursor: pointer;
}


.selectedBg{
  background:rgba(0,0,0,0.5);
}
.unSelectedBg {
  background:rgba(0,0,0,0);
}
.selectedIcon{
  background-image: url("../../../../src/images/auth_w_btn.png");
}
.unSelectedIcon{
  background-image: url("../../../../src/images/auth_b_btn.png");
}
.auth>ul{width:100%; height:100%;}
.auth>ul>li.authIcon{
  width:100%;
  height:100%;
  
  background-size: 100% 100%; 
  background-position: 50% 50%; 
  background-repeat: no-repeat;
  
  
}





.authMenu{
  width:15vw; 
  position: absolute;
  bottom:-10.2vh;
  right : 0;
}

.authMenuBox{
  width:100%; 
  height:10.2vh;
  
}
.authMenuBox>li{
  width:100%; 
  height:6vh;
}
.authMenuBox>li>.noDeco{   
  width:100%; height:100%;  
  display:block;
  padding-right:1vw;
  line-height:6vh; 
  text-align:right;
  font-size:1vw;
  font-weight: 300;
  color:#ffffff;
  background:rgba(0,0,0,0.5);
  text-decoration:none;
}
.authMenuBox>li>.noDeco:hover{
  background:rgba(0,0,0,1);
  color:#ffffff;
}

.noDeco {
  text-decoration: none;

  /* color:black */
}
.is-current {
font-weight: 900;
}
.nonAuthMenu{
width:11vw; 
position: absolute;
bottom:-10.2vh;
right : 0;
}

.nonAuthMenuShow{
  display:block;
  height:auto;
}
.nonAuthMenuHidden{
  display:none;
}

.userAuthBox{
  width:7vw;
  bottom:-12vh;
}

.authMenuShow{
  display:block;
  height:auto;
}
.authMenuHidden{
  display:none;
}




