.adminMypageFrame{
    padding:15vw 5.2vw 15vw;
    
}
.adminMypageFrame>h3{
    font-size:2vw;
    margin-bottom:1vw;
    padding-left:5vw;
}
.adminMypageFrame>p{
    font-size:1.2vw;
    margin-bottom:6vw;
    padding-left:5vw;
}


@media (max-width:834px){
    .adminMypageFrame{
        padding:0;
        padding-top:20vw;
    }
    .adminMypageFrame>h3{
        font-size:3vw;
        margin-bottom:1vw;
        padding-left:10vw;
    }
    .adminMypageFrame>p{
        font-size:2.5vw;
        margin-bottom:6vw;
    }    
}



@media (max-width:430px){
    .adminMypageFrame{
        padding-top:30vw;
    }
    .adminMypageFrame>h3{
        font-size:5vw;
        margin-bottom:2vw;
    }
    .adminMypageFrame>p{
        font-size:4vw;
        margin-bottom:10vw;
    }    
}