
@charset "utf-8";
/* @import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&family=Poor+Story&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap'); */


*{-webkit-box-sizing:border-box; -moz-box-sizing:border-box; box-sizing:border-box;}
html{overflow-x: hidden; font-family: 'NanumSquare', 'Nanum Gothic', sans-serif; font-size:10px;}
body{margin:0; padding:0; font-family: 'NanumSquare', 'Nanum Gothic', sans-serif;}
header, section, article, nav, aside, footer{display:block;}
header:after, section:after, article:after, nav:after, aside:after, footer:after{content:""; display:block; clear:both;}
ul, ol{margin:0; padding:0; list-style:none; }
h1, h2, h3, h4, h5, h6, p{margin:0;  font-family: 'Noto Sans KR', sans-serif;}
p, textarea{ font-family: 'NanumSquare', 'Nanum Gothic', sans-serif;}
h2{font-weight:600;}

a{text-decoration:none;}

input[type=password]{font-family:'Nanum Gothic', sans-serif;}
input, textarea, button{
  -webkit-appearance:none;
  -moz-appearance: none;
  appearance: none;
}
input, textarea, button, select{
  -webkit-border-radius: 0; 
  -moz-border-radius: 0; 
  -o-border-radius: 0; 
  border-radius: 0; 
}
input[type=radio],
input[type=checkbox]{
  -webkit-appearance:radio;
  -moz-appearance: radio;
  appearance: radio;
  -webkit-border: initial; 
  -moz-border: initial; 
  -o-border: initial; 
  border: initial;
}
button:hover{
  border:1px solid rgb(8, 46, 77) !important;
  background:rgb(8, 46, 77) !important;
  color:#ffffff !important;
}
button:active{
  border:1px solid rgb(8, 46, 77) !important;
  background:rgb(8, 46, 77) !important;
  color:#ffffff !important;
}
button:disabled{
  border:1px solid #bdbdbd !important;
  background:#dddddd !important;
  color:#bdbdbd !important;
  cursor:default !important;
}
button:disabled:hover{
  border:1px solid #bdbdbd !important;
  background:#dddddd !important;
  color:#bdbdbd !important;
  cursor:default !important;
}

table{border-spacing:0;}

.clear{clear:both;}

.fontNg{font-family: 'Nanum Gothic', sans-serif;}
.fontPs{font-family: 'Poor Story', cursive;}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.bookContentFrame .makingPaperFrame::-webkit-scrollbar{
  width:5px;
}
.bookContentFrame .makingPaperFrame::-webkit-scrollbar-thumb{
  background-color:#b3c7db;
  border-radius:5px;
  background-clip:padding-box;
  border:1px solid transparent;
}
.bookContentFrame .makingPaperFrame::-webkit-scrollbar-track{
  background:transparent;
  border-radius:5px;
  box-shadow:inset 0px 0px 3px #ffffff;
}
/* 
.bookContentFrame .makingPaperFrame::-webkit-scrollbar{
  display:none;
}
.bookContentFrame .makingPaperFrame{
  -ms-overflow-style:none;
  scrollbar-width:none;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pageContainer{
  position: relative;
  min-height: 100vh;
}
/* .contentWrapper{
  padding-bottom: 20.3vw;
}
 */
 .disabledDrag {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
   }
