.clOutFrame{
    width:100%; 
    height:56.25vw;
}
.clInFrame{
    width:100%;
    height:100%;
    padding:4.7vw 5.2vw;
    display:flex;
    flex-direction: row;
    padding-top:10vw;
}

.cl1stInFrame .TitleBox{
    /* border:1px solid yellowgreen; */
    width:40%;
    height:100%;
    padding-right:3vw;
    
}

.cl1stInFrame .TitleBox ul{
    font-size:0;
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height:100%;
}
.cl1stInFrame .TitleBox ul li{
    text-align:right;
    width:100%;
}


.cl1stInFrame .TitleBox ul li.contTitleText{
    font-size:4vw;
    font-weight:600;
    line-height:4.5vw;
    display:flex;
    flex-direction: column;
    padding:2vw 0 2vw;
}
.cl1stInFrame .TitleBox ul li.contentText{
    font-size:1.1vw;
    line-height:1.8vw;
    display:flex;
    flex-direction: column;
    padding-bottom:3vw;
    word-break: keep-all;
}
.cl1stInFrame .TitleBox ul li.contentText span:nth-child(1),
.cl1stInFrame .TitleBox ul li.contentText span:nth-child(2){
    margin-bottom:0.6vw;
}
.cl1stInFrame .TitleBox ul li.contentText span:nth-last-child(1),
.cl1stInFrame .TitleBox ul li.contentText span:nth-last-child(2){
    font-weight:600;
}
.cl1stInFrame .TitleBox ul li.buttonText a{
    font-size:1.2vw;
    height:4vw;
    line-height:4vw;
    text-decoration: none;
    color:#000000;
    padding:0 2vw;
    display:inline-block;
    border:1px solid #000000;
}
.cl1stInFrame .TitleBox ul li.buttonText a:hover{
    border:1px solid rgb(8, 46, 77);
    background:rgb(8, 46, 77);
    color:#ffffff;
}
.cl1stInFrame .TitleBox ul li.buttonText a:active{
    border:1px solid rgb(8, 46, 77) !important;
    background:rgb(8, 46, 77) !important;
    color:#ffffff !important;    
}
.cl1stInFrame .contBox{
    width:60%;
    height:100%;
    display:flex;
    flex-direction: row;
    /* padding:5vw 0 0; */
}
.cl1stInFrame .contBox .contImage{
    overflow: hidden;
    width:45%;
    height:100%;
    /* padding:2vw 0 0; */
}
.cl1stInFrame .contBox .contImage span{
    /* border:1px solid red; */
    display:block;
    width:100%;
    height:100%;
    background:url("../../../../images/tlpSectionImage.png") no-repeat 50% 50%;
    background-size:cover;
}
.cl1stInFrame .contBox .contText{
    width:65%;
    height:100%;
    padding:2vw 0 0 2vw;
}
.cl1stInFrame .contBox .contText>ul{
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.cl1stInFrame .contBox .contText>ul>li>ul>li{
    font-size:1.2vw;
    line-height:2vw;
    font-weight:600;
    color:#000000;
    word-break: keep-all;
}
.cl1stInFrame .contBox .contText>ul>li>ul>li:nth-child(1){
    font-size:1.5vw;
    display:flex;
    flex-direction: column;
    padding-bottom:0.5vw;
    color:#2260b1;
    font-weight:600;
}
.cl1stInFrame .contBox .contText>ul>li:nth-last-child(1)>ul>li{
    font-size:1.5vw;
    color:#000000;
    font-weight:600;
    padding-bottom:0;
}
/* .clOutFrame{
    width:100%; 
    height:100%;
    font-size:0;
    display:flex;
    
  
}
.clInFrame{
    width:100%;
    height:100%;
    background:rgba(0, 15,30, 0.5);
    padding:4.7vw 7.8vw;
    position:relative;
}
.clTextBox{
    width:100%;
    height:100%;
    position: relative;
    padding-top:15vw;
    z-index:1;
}
.clTextBox h2{
    font-size:4.2vw;
    color:#000f1e;
    line-height:1;
    padding-bottom:3vw;
}
.clpagerGroup{
    padding-bottom:2vw;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
}
.clpagerGroup li{
    font-size:1.1vw;
    color:#000f1e;
    line-height:2vw;
    cursor: pointer;
}
.clpagerGroup li.active{
    color:#60c0bf;
}
.clpagerGroup li:hover{
    font-weight:600;
}

.clTextBox .btnGroup a{
    text-decoration:none;
    width:auto;
    height:3vw;
    padding:0 2vw;
    display:inline-block;
    font-size:1.1vw;
    font-weight:500;
    line-height:3vw;
    text-align:center;
}
.clTextBox .btnGroup a.douwmloadBtn{
    border:1px solid #000f1e;
    color:#000f1e;
    cursor: pointer;
    font-weight:600;
}
.clTextBox .btnGroup a:hover{
    color:#ffffff;
    background:#000f1e;
    
}





.clContBox{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    background:#ffffff;
   
    overflow:hidden;
}
.clContBox ul.slider{
    width:300%; 
    height:100%;
    font-size:0;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
}
.clContBox ul.slider li{
    width:100%;
    height:100%;
}
.clContBox ul.slider li img.pcImage{
    width:100%;
    margin-left:auto;
    display:block;
}
.clContBox ul.slider li img.mobileImage{
    display:none;
}


.none{
    opacity: 0.0;
    height: 0px;
}
.leftShow{
    transition: height 0.5s, opacity 0.5s;
    opacity: 1;
    height: 10vw;
    border: 1px solid blue;
}
.rightShow{
    transition: height 0.5s, opacity 0.5s;
    opacity: 1;
    height: 10vw;
    border: 1px solid red;
}

 */
