.FacilitatorPage{
  margin-top: 10vh;
}
.FacilitatorWrapper{
  min-height:80vh
}
.FacilitatorWrapper>.spinnerFrame{
  padding: 30vh 0;
}
.FacilitatorWrapper>.spinnerFrame>.spinnerMsg{
  font-size: 2vw;
}
.FacilitatorWrapper>.FacilitatorHeadTitle{
  padding: 30vh 0;
}
.FacilitatorHeadTitle{
  display: block;
  font-size: 2vw;
  text-align: center;
  margin: auto 0;
}




.facilitatorVideoOutFrame {
    width: 100%;
    padding: 1vw;
    /* height:100vh; */
    /* border:1px solid orange; */
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .facilitatorVideoOutFrame:after {
    display: block;
    content: "";
    clear: both;
  }
  .facilitatorVideoBlock{
    width: 25%;
    display: flex;
    flex-direction: column;
    padding-right: 2vw;
    border : 1px solid lightgrey;
  }
  .facilitatorVideoIcon{
    padding-left: 1vw;
  }
  .facilitatorVideoIcon:hover{
    color:darkslategray
  }
  .facilitatorVideoTitle{
    padding: 1.5vw 1vw;
    font-size: 1.5vw;
  }
  .facilitatorVideoInFrame {
    width: 100%;
    height: 100%;
    padding: 0 1vw;
    /* position: relative; */
  }
  .facilitatorVideoInFrame > iframe {
    width: 100%;
    height: auto;
  }
  @media (max-width:834px){
    .FacilitatorHeadTitle{
      font-size: 3vw;
    }
    .FacilitatorWrapper>.spinnerFrame>.spinnerMsg{
      font-size: 3vw;
    }
    .facilitatorVideoBlock{
      width: 100%;
      margin: 7vw 0;
    }
    .facilitatorVideoOutFrame{
      flex-direction: column;
    }
    .facilitatorVideoTitle{
      font-size: 4vw;
      margin: 2vw 1vw;
    }
    .facilitatorVideoIcon{
      padding-left: 2vw;
    }
  }
  @media (max-width:480px){
    .FacilitatorHeadTitle{
      font-size: 5vw;
    }
    .FacilitatorWrapper>.spinnerFrame>.spinnerMsg{
      font-size: 5vw;
    }
    
  }