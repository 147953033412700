/* res css */

@media (max-width:870px){
    .gnb>ul.gnbMenu>li>a{
        padding:0 0.9vw;
    }
    .authMenuBox>li{
        height:5.1vh;
    }
    .authMenuBox>li>.noDeco{   
        line-height:5.1vh; 
    }
}
@media (max-width:856px){
    .gnb>ul.gnbMenu>li>a{
        padding:0 0.5vw;
    }
}

@media (max-width:834px){
    .navbar{
        height:10vw;
        width: 100%;
        padding:0;
    }
    .navbar>ul{
        position:relative;
        height:10vw;
    }
    .darkBg{
        width:100vw; 
        height:100vh; 
        position:fixed; 
        top:0; 
        left:0; 
        z-index:-1; 
        background:rgba(0, 0, 0, 0.8); 
        display:none;

    }


    .navbarFrame{
        display:none;
        /* height:5.2vw; */
    }

   
    .navbarResFrame{
        width:100%;
        height:100%;
        padding:0 5.2vw;
        background:rgba(255,255,255);/*intro 영상에서는 색상 제거, 그 외 영역에서 색상 표시 필요!!*/
        display:flex;
        flex-direction: column;
        justify-content: flex-start;
        position:relative;   
    }
    
    .resNavBar{
        display:flex;
        flex-direction: row;
        align-content: space-around;
        justify-content: space-between; 
        align-items: center;
        position:relative;
        ;
        /* border:1px solid red;  */
        width:100%;
        height:100%;
    }
    .navbarResFrame .logo_img{
        width:auto; 
        height:100%; 
        margin:0.5vw 0;
        display:inline-block;
        flex-direction: row;
        justify-content: flex-start;        
        /* border:1px solid yellow; */
    }
    .navbarResFrame .logo_img a{
        width:auto; 
        height:100%; 
        display:flex;
        flex-direction:row;
        justify-content: flex-start;
        align-items:center;
        text-decoration: none;
    }
    .navbarResFrame .logo_img a img{height:7vw; } 
    .navbarResFrame .logo_img a span{
        width:auto;
        margin-left:2vw;
        font-size:2.5vw;
        line-height:3vw;
        font-weight:900;
        color:#375c9a;
        display:inline-block;
        text-decoration:none;
        white-space: nowrap;
        /* border:1px solid red; */
        /* display:flex;
        flex-direction: column;
        justify-content: flex-start; */
    }

    .resRight{
        width:auto;
        /* height:100%; */
        /* border:1px solid red; */
      }
    .resGnbBtn{
        padding:1.3vw 0;
        display:flex;
        width:6vw;
        height:7vw;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        /* border:1px solid yellow; */
    }
    .resGnbBtn span{
        display:block;
        background:#000f1e;
        width:6vw;
        height:2px;
    } 



    .resNavMenu{
        /* width:86vw;  */
        width:100vw;
        height:100vh;
        background:#ffffff;
        /* border:1px solid orange; */
        position:fixed;
        top:0;
        right:-100vw;
        /* display:none; */
        display:flex;
        flex-direction: column;
        justify-content:flex-start;
        
    }



    .closeBtn{
        display:flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .closeBtn span{
        font-size:5vw;
        height:10vw;
        line-height:10vw;
        color:#000f1e;
        display:inline-block;
        margin-right:5vw;
        padding:0 2vw;
        cursor: pointer;

    }
    .menuGroup::-webkit-scrollbar{
        display:none;
    }
    .menuGroup{
        display:flex;
        flex-direction:column;
        justify-content: space-between;
        /* margin-top:auto; */
        height:100%;
        overflow:auto;
        -ms-overflow-style:none;
        scrollbar-width:none;
    }

    .gnbMenu{
        width:100%;
        padding:3vw 5.2vw;
        display:flex;
        flex-direction: column;
        align-content: flex-start;
    }
    .gnbMenu li{
        border-bottom:1px solid #000f1e;
        display:inline-block;
        text-align:right;  
    }

    .gnbMenu>li:last-child{
        border:none;
    }
    .gnbMenu li a{
        text-decoration: none;
        font-size:2vh;
        line-height:2vh;
        color:#000000;
        display: block;
        padding:3vh 3vw;
    }

    




    .resAuthMenuBox{
        width:100%;
        padding:3vw 5.2vw 5vw;
        display:flex;
        flex-direction: row;
        justify-content:flex-start;
        
    }
    .resAuthMenuBox>li{
        border:1px solid #000f1e;
        display:inline-block;
        text-align:left;
    }
    .resAuthMenuBox>li:nth-child(1){width:20%; margin-right:1%;}
    .resAuthMenuBox>li:nth-child(2){width:30%; margin-right:1%;}
    .resAuthMenuBox>li:nth-child(3){width:48%;}
    .resAuthMenuBox>li>a{
        text-decoration: none;
        font-size:2vh;
        line-height:6vh;
        color:#000000;
        display: block;
        word-break: keep-all;
        text-align:center;
        width:100%;
        height:100%;
    }
    
    .closeArrowBtn{
        display:none;
    }

    .resAuthMenu2Box{
        margin-bottom:2vw;
    }
    .resAuthMenu2Box>.authMenu{
        width:100%;
        position: inherit;
        bottom:inherit;
        right :inherit;
        height:100%;
    }
    .resAuthMenu2Box>.adminAuthBox>ul{
        width:100%;
        height:100%;
        padding:0 5.2vw;
        display:inherit;
        flex-direction: inherit;
        align-content: inherit;
        float:left;
    }
    .resAuthMenu2Box>.adminAuthBox>ul:after{
        content:"";
        display:block;
        clear:both;
    }
    .resAuthMenu2Box>.adminAuthBox>ul>li{
        display:inline-block;
        text-align:left;
        margin-bottom:1vw;
        word-break: keep-all;
        /* text-align:center; */
    }
    .resAuthMenu2Box>.adminAuthBox>ul>li>a{
        text-decoration:none;
        font-size:2vh;
        line-height:5vh;
        color:#000000;
        display: block;
        /* padding:0 4vw; */
        border:1px solid #000f1e;
        text-align:center;
    }
    .resAuthMenu2Box>.adminAuthBox>ul>li>a:hover{
        background:#000f1e;
        color:#ffffff;
    }
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(1){width:32.33%; margin-right:1.5%;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(2){width:32.33%; margin-right:1.5%;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(3){width:32.33%;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(4){width:25%; margin-right:1.5%;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(5){width:30%; margin-right:1.5%;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(6){width:42%; }
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(7){width:100%; }

    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(8){width:50%; margin-right:1.5%;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(9){width:48.5%;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(10){width:40%; margin-right:1.5%;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(11){width:20%; margin-right:1.5%;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(12){width:37%;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(13){width:50%; margin-right:1.5%;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(14){width:48.5%;}

    .resAuthMenu2Box>.userAuthBox>li{
        text-decoration:none;
        font-size:2vh;
        line-height:6vh;
        color:#000000;
        display: block;
        padding-left: 5vw;
        border:1px solid #000f1e;
        cursor: pointer;
        /* width: 44%; */
        /* display:inline-block; */
        text-align:left;
        margin: 0 5.2vw;
    }
    /* .resAuthMenu2Box>.userAuthBox>li:hover{
        background:#000f1e;
        color:#ffffff;
    } */

    .resAuthMenu2Box>.userAuthBox>ul{
        width:100%;
        /* height:100%; */
        padding:3vw 5.2vw 5vw;
        display:flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .resAuthMenu2Box>.userAuthBox>ul>li{
        display:inline-block;
        text-align:left;
        margin-left:2vw;
    }
    .resAuthMenu2Box>.userAuthBox>ul>li:nth-child(3){
        display:none;
    }
    
    .resAuthMenu2Box>.userAuthBox>ul>li>a{
        text-decoration:none;
        font-size:2vh;
        line-height:6vh;
        color:#000000;
        display: block;
        padding:0 5vw;
        border:1px solid #000f1e;
        cursor: pointer;
    }
    .resAuthMenu2Box>.userAuthBox>ul>li>a:hover{
        background:#000f1e;
        color:#ffffff;
    }


}


@media (max-width:480px){
    .navbar{
        height:15vw;
    }
    
    .navbar>ul{
        height:15vw;
    }
    .navbarResFrame .logo_img{
        width:auto;
    }
    .navbarResFrame .logo_img a img{height:10vw; }
    .navbarResFrame .logo_img a span{
        margin-left:2vw;
        font-size:4vw;
        line-height:5vw;
    } 
    /* .navbarResFrame .logo_img a>span{
       
        display:inline-block;
        flex-direction: inherit;
        justify-content: inherit;
    }
    */
    
    .resGnbBtn{
        padding:1.3vw;
        width:10vw;
        height:10vw;

    }
    .resGnbBtn span{
        width:10vw;
        height:2px;
    }
    .resNavMenu{
        /* width:83vw;  */
        width:100vw;
        display:inherit;
        flex-direction: inherit;
        justify-content:inherit;
        
    }
  
    .closeBtn {
        height:15vw;
    }
    .closeBtn span{
        font-size:12vw;
        font-weight:100;
        line-height:15vw;
        color:#000f1e;
        display:inline-block;
        margin-right:5vw;
        /* padding:0 5vw; */
        cursor: pointer;
    }


    .menuGroup{
        display:flex;
        flex-direction:column;
        justify-content: space-between;
        margin-top:0;
        height:100%;
    }
    


    .gnbMenu{
        width:100%;
        padding:3vw 5.2vw;
        display:inherit;
        flex-direction: inherit;
        align-content: inherit;
        
    }
    .gnbMenu li{
        display:block;
    }

    .gnbMenu>li:last-child{
        border:none;
    }
    .gnbMenu li a{
        text-align:right;
        text-decoration: none;
        font-size:4.5vw;
        line-height:1;
        color:#000000;
        display: block;
        padding:4vw 3vw;
    }

    




    .resAuthMenuBox{
        width:100%;
        padding:3vw 5.2vw;
        padding-bottom:4vw;     
    }
    .resAuthMenuBox>li{
        display:block;
        text-align:left;
    }
    .resAuthMenuBox>li:nth-child(1){width:25%; margin-right:1%;}
    .resAuthMenuBox>li:nth-child(2){width:28%; margin-right:1%;}
    .resAuthMenuBox>li:nth-child(3){width:45%;}
    .resAuthMenuBox>li>a{
        text-decoration: none;
        font-size:4.5vw;
        line-height:1;
        display: block;
        padding:2.8vw 1vw;
    }
    .closeArrowBtn{
        width:100%; 
        padding:0 5.2vw 5vw;
        display:block;
    }
    .closeArrowBtn span{
        border-top:1px solid #dedede;
        color:#cecece;
        padding:0 3vw;
        display:block;
        text-align:right;
        font-size:2.5vh;
        line-height:6vh;
    }


    .resAuthMenu2Box{
        width:100%;
        display:inherit;
        flex-direction: inherit;
        align-content: inherit;
    }
    .resAuthMenu2Box>.userAuthBox>li{
        display:block;
        margin-left: 7vw;
    }
    .resAuthMenu2Box>.userAuthBox>ul{
        padding:3vw 5.2vw 15vw;
    }
    .resAuthMenu2Box>.userAuthBox>ul>li{
        display:block;
        
    }
    .resAuthMenu2Box>.userAuthBox>ul>li:nth-child(3){
        text-align:right;
        line-height:1;
        padding:2vw 5vw;
        color:#dedede;
        font-size:3vh;
        margin-right:0;
        margin-left:auto;
        border-right:1px solid #dedede;
        display:inline-flex;
        flex-direction:row;
        align-items: center;
    }

    .resAuthMenu2Box>.userAuthBox>ul>li>a{
        text-decoration: none;
        font-size:2.5vh;
        line-height:1;
        display: block;
        text-align:left;
        padding:2.8vw 5vw;
    }
    .resAuthMenu2Box>.adminAuthBox>ul>li{
        margin-bottom:2.5vw;
        margin-right:0;
    }
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(1){width:30%; margin-right:2%;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(2){width:33%; margin-right:2%;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(3){width:33%; }
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(4){width:48%; margin-right:2%;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(5){width:50%; margin-right:0;}

    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(6){width:48%; margin-right:2%;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(7){width:50%; margin-right:0;}

    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(8){width:100%; margin-right:0;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(9){width:100%;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(10){width:100%; margin-right:0;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(11){width:39%; margin-right:2%;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(12){width:59%; margin-right: 0;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(13){width:59%; margin-right:2%;}
    .resAuthMenu2Box>.adminAuthBox>ul>li:nth-child(14){width:39%;}
    .resAuthMenu2Box>.adminAuthBox>ul>li>a{
        font-size:2vh;
        line-height:6vh;
    }

}