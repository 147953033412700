.signBg{
    width:100%;
    min-height:100vh;
    padding:0 5.2vw 15vw;
    
}


.signFrame{
    width:460px;
    height:100%;
    padding-top:15vw;
    margin-left:10vw;
    /* border:1px solid orange; */
}

.signFrame h1{
    font-size:1.6vw;
    padding-bottom:1.5vw;
    display:inline-block;
    margin-bottom:3vw;
    border-bottom:3px solid #000f1e;
}

.inputGroup{
    display:flex;
    flex-direction: column;

}

.inputGroup>input{
    text-decoration: none;
    outline:none;
    width:100%;
    height:50px;
    margin-bottom:1.6vw;
    padding:0 1vw;
    border-bottom:1px solid #000f1e;
    border-radius: 0;
    /* border-bottom:1px solid #000000; */
    font-size:1vw;
    /* -webkit-apearance:none;
    -webkit-box-shadow: inset -2px -2px 5px rgb(241, 244, 255),
              inset 3px 3px 5px rgba(0, 15, 30, 0.1);
    box-shadow: inset -2px -2px 5px rgb(241, 244, 255),
              inset 3px 3px 5px rgba(0, 15, 30, 0.1);
    border-radius: 5px; */
}

.inputGroup>button{
    border-radius:0;
}


.linkGoup{
    padding-top:3vw;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}






@media (max-width:834px){
    .signFrame{
        width:auto;
        height:100%;
        padding-top:15vw;
        margin-left:10vw;
        /* border:1px solid orange; */
    }
        
    .signFrame h1{
        font-size:4vw;
        padding-bottom:5vw;
        margin-bottom:10vw;
    }
    .inputGroup{
        width:70%;
    }
    .inputGroup>input{
        padding:0 2vw;
        font-size:3vw;    
    }
    .inputGroup>button{
        font-size:2.5vw;  
    }
    .linkGoup{
        width:70%;
        padding-top:5vw;
    }

    
}

@media (max-width:480px){
    .signFrame{
        width:auto;
        padding-top:30vw;
        margin-left:0;
        
    }
    .signFrame h1{
        font-size:6vw;
    }
    .inputGroup{
        width:100%;
    }
    .inputGroup>input{
 
        font-size:4vw;    
    }
    .inputGroup>button{
        font-size:4vw;  
    }
    .linkGoup{
        width:100%;
        padding-top:5vw;
    }
}






