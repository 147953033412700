.adminReadingFrame {
    padding: 0 5vw;
  }
  .adminReadingFrame > h3 {
    font-size: 1.8vw;
    font-weight: 600;
    line-height: 5vw;
    margin-bottom: 2vw;
    border-bottom: 1px solid #000000;
  }
  
/* adminReadingTabBtnGroup */
.adminReadingFrame > div > .adminReadingTabBtnGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .adminReadingFrame > div > .adminReadingTabBtnGroup div {
    font-size: 1.2vw;
    text-align: center;
    border: 1px solid #dedede;
    width: 50%;
    height: 4vw;
    line-height: 4vw;
    cursor: pointer;
    color: #aaaaaa;
    border-right: 0;
  }
  .adminReadingFrame > div > .adminReadingTabBtnGroup div:hover {
    background: #eeeeee;
    color: #000000;
  }
  .adminReadingFrame > div > .adminReadingTabBtnGroup div:nth-child(1) {
    border-right: 0;
    border-bottom: 0;
    color: #000000;
  }
  .adminReadingFrame > div > .adminReadingTabBtnGroup div:nth-last-child(1) {
    border-right: 1px solid #dedede;
  }


/* adminReadingTabContGroup */
.adminReadingFrame > div > .adminReadingTabContGroup {
    padding: 3vw 2vw;
    border: 1px solid #dedede;
    border-top: none;
    font-size: 1vw;
    position: relative;
  }
  .adminReadingFrame > div > .adminReadingTabContGroup > p{
      margin-bottom:2vw;
  }
  .adminReadingFrame > div > .adminReadingTabContGroup > p:nth-child(1){
    font-size:1vw;
    position: absolute;
    top:3vw;
    right:2vw;
}

.adminReadingFrame > div > .adminReadingTabContGroup .adminReadingCate{
  display:flex;
  flex-direction: row;
  height:2.5vw;
  line-height:2.5vw;
  border-bottom:2px solid #dedede;
  margin-bottom:0.5vw;
  font-weight:600;
}
.adminReadingFrame > div > .adminReadingTabContGroup .adminReadingCate li:nth-child(1){width:5%;}
.adminReadingFrame > div > .adminReadingTabContGroup .adminReadingCate li:nth-child(2){width:50%;}
.adminReadingFrame > div > .adminReadingTabContGroup .adminReadingCate li:nth-child(3){width:35%;}
.adminReadingFrame > div > .adminReadingTabContGroup .adminReadingCate li:nth-child(4){width:10%;}


.adminReadingFrame > div > .adminReadingTabContGroup >div{
  text-align:center;
  height:3vw;
  line-height:3vw;
  color:#959595;
}


.adminReadingFrame > div > .adminReadingTabContGroup .adminReadingList{
    margin-bottom:0.5vw;
    padding-bottom:0.5vw;
    border-bottom:1px solid #dedede;
    display:flex;
    flex-direction: row;
    align-items: center;
}
.adminReadingFrame > div > .adminReadingTabContGroup .adminReadingList:nth-last-of-type(1){
    border-bottom:0;
}
.adminReadingFrame > div > .adminReadingTabContGroup .adminReadingList li:nth-child(1){width:5%;}
.adminReadingFrame > div > .adminReadingTabContGroup .adminReadingList li:nth-child(2){width:50%;}
.adminReadingFrame > div > .adminReadingTabContGroup .adminReadingList li:nth-child(3){width:35%;}
.adminReadingFrame > div > .adminReadingTabContGroup .adminReadingList li:nth-child(4){width:10%;}
.adminReadingFrame > div > .adminReadingTabContGroup .adminReadingList li:nth-child(1)>span{
    margin-right:1vw;
}
.adminReadingFrame > div > .adminReadingTabContGroup .adminReadingList  li a{
    text-decoration: none;
    color:#000000;
}
.adminReadingFrame > div > .adminReadingTabContGroup .adminReadingList  li a:hover{
    color:orange;
}
.adminReadingFrame > div > .adminReadingTabContGroup .adminReadingList  li a span:nth-child(1){
  width:30vw;
  display:inline-block;
}