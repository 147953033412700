.userCoverFrame{
    padding:0 5.2vw;
}
.bookInfoGroup{
    width:100%;
    height:100%;
    display:flex;
    flex-direction: row;
    align-items:center;
    margin-top:2vw;
}
.bookInfoGroup .iconFrame{
    width:3vw;
    height:3vw;
    border:1px solid #000000;
    overflow:hidden;
}
.bookInfoGroup .iconFrame img{
    height:100%;
    margin-left:50%;
    margin-top:50%;
    transform:translate(-50%, -50%);
}

.bookInfoGroup span{
    font-size: 1.2vw;
    padding: 0 2vw 0 1vw;
    word-break: keep-all;
}






.bookCoverGroup{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    width:100%;
    height:100%;
    margin:10vw auto;
    /* border:1px solid olivedrab; */

}
.coverApplyFrame{
    width:35vw;
    height:45vw;
    border:1px solid #000000;
}


.coverSelFrame{
    /* border:1px solid pink; */
    width:45.4vw;;
    height:100%;
    padding:0 2vw;
}
.coverSelFrame h5{
    font-size:1.2vw;
    padding-bottom:1.5vw;
    font-weight:600;
    word-break: keep-all;;
}
.coverSelFrame p{
    /* width:45.4vw; */
    font-size:1vw;
    line-height:1.8vw;
    margin-bottom:2vw;
    word-break: keep-all;
}
.coverSelFrame h6{
    font-size:1vw;
    padding-bottom:1vw;
}
.coverSelFrame ul.coverIllust{
    width:100%;
    height:auto;
    padding-bottom:1.5vw;
    padding-left:1vw;
}
.coverSelFrame ul.coverIllust:nth-last-of-type(2){
    padding-bottom:2vw;
}
.coverSelFrame ul.coverIllust:after{
    content:"";
    display:block;
    clear:both;
}
.coverSelFrame ul.coverIllust li{
    width:5vw;
    height:5vw;
    display:inline-block;
    border:1px solid #000f1e;
    overflow:hidden;
    margin-right:0.5vw;
    cursor: pointer;
}
.coverSelFrame ul.coverIllust li.active{
    border:5px solid #000f1e;
}
.coverSelFrame ul.coverIllust li:hover{
    border:5px solid #000f1e;
}
.coverSelFrame ul.coverIllust li>div>div{
    border:none !important;
}
.coverSelFrame ul.coverIllust li>div>div>div{
    border:none !important;
}
.coverSelFrame ul.coverIllust li img{
    width:200%;
    margin-top:50%;
    margin-left:50%;
    transform:translate(-50%, -50%);
}
.coverSelFrame div.coverTitleColor,
.coverSelFrame div.coverBgColor{
    padding-bottom:1.5vw;
    padding-left:1vw;
}
.coverSelFrame div.coverTitleColor>div,
.coverSelFrame div.coverBgColor>div{
    box-shadow: inherit!important;
    border: 1px solid #000!important;
    border-radius: 0!important;
}
.coverSelFrame div.coverTitleColor>div>div,
.coverSelFrame div.coverBgColor>div>div{
    padding:0.5vw 1vw !important;
}
.coverSelFrame div.coverTitleColor>div>div>span:nth-child(1)>div,
.coverSelFrame div.coverBgColor>div>div>span:nth-child(1)>div{
    border:1px solid #eeeeee;
}
.coverSelFrame div.coverTitleColor>div>div>span>div,
.coverSelFrame div.coverBgColor>div>div>span>div{
    width:2vw !important;
    height:2vw !important;
    margin:0 0.5vw 0 0 !important;
}
.coverSelFrame div.coverTitleColor>div>div>div:nth-last-child(3),
.coverSelFrame div.coverBgColor>div>div>div:nth-last-child(3){
    width:2vw !important;
    height:2vw !important;
}
.coverSelFrame div.coverTitleColor>div>div>div:nth-last-child(2),
.coverSelFrame div.coverBgColor>div>div>div:nth-last-child(2){
    height:2vw !important;
    position:inherit !important;
}
.coverSelFrame div.coverTitleColor>div>div>div>input,
.coverSelFrame div.coverBgColor>div>div>div>input{
    height:2vw !important;
    width:5.5vw !important;
    font-size:1vw !important;
    box-sizing:inherit !important;
}
.coverSelFrame ul.coverAddress li{
    font-size:1vw;
    line-height:1.8vw;
    color:#c56e6e;
    word-break: keep-all;
}
.coverSelFrame ul.coverAddress li span{
    font-size:1vw;
    color:#000000;
    word-break: keep-all;
}
.coverSelFrame ul.coverAddress li:nth-child(1){
    display:flex;
    flex-direction:row;
    justify-content: space-between;   
    align-items: center;
    margin-bottom:2vw;
}
.coverSelFrame ul.coverAddress li:nth-child(1) span:nth-child(2){
    border:1px solid #000000;
    padding:0 1vw;
    height:2vw;
    line-height:2vw;
    cursor: pointer;
}
.coverSelFrame ul.coverAddress li:nth-child(1) span:nth-child(2):hover{
    background:#000f1e;
    color:#ffffff;
}
.coverSelFrame ul.coverAddress li span.coverSelBtn{
    padding:0 2vw;
    height:3vw;
    line-height:3vw;
    font-size:1.2vw;
    background:#000f1e;
    color:#ffffff;
    display:inline-block;
    margin-top:5vw;
    cursor: pointer;
}
.coverSelFrame ul.coverAddress li:nth-last-child(1){
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
}





.coverAdrsInputFrame{
    width:100vw;
    height:100vh;
    position: fixed;
    top:0;
    left:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
.coverAdrsInputBg{
    width:100vw;
    height:100vh;
    background:rgba(0,0,0,0.9);
    position:absolute;
    top:0;
    left:0;
}
.coverAdrsInputBox{
    width:50%;
    height:auto;
    border:1px solid #000000;
    background:#ffffff;
    padding:3vw;
    position:relative;
    top:0;
    left:0;
}
.coverAdrsInputBox>div.delBtn{
    position:absolute;
    top:-3vw;
    right:-3vw;
    font-size:2vw;
    color:#ffffff;
    cursor: pointer;
}
.coverAdrsInputBox ul{
    width:100%;
    /* border:1px solid pink; */
}
.coverAdrsInputBox ul li{
    width:100%;
    height:3vw;
    display:flex;
    flex-direction: row;
    align-items: center;
    margin-bottom:0.5vw;
}
.coverAdrsInputBox ul li input{
    width:100%;
    height:100%;
    padding:0 0.5vw;
    font-size:1vw;
    border:1px solid #000000;
    border-radius: 0;
}
.coverAdrsInputBox ul li:nth-child(1) input{
    width:59%;
}
.coverAdrsInputBox ul li:nth-child(1) span{
    font-size:1.2vw;
    width:40%;
    margin-left:1%;
    height:100%;
    text-align:center;
    line-height:3vw;
    border:1px solid #000000;
    background:#eeeeee;
    display:inline-block;
}
.coverAdrsInputBox ul li:nth-child(4){
    margin-top:2vw;
}
.coverAdrsInputBox ul li:nth-child(4) span{
    background:#000f1e;
    color:#ffffff;
    padding:0 5vw;
    height:3vw;
    line-height:3vw;
    font-size:1.2vw;
}







@media (max-width:1400px){
    .coverApplyFrame{
        width:40vw;
        height:55vw;
    }
    /* .coverSelFrame p{
        width:49.6vw;
    } */
}
@media (max-width:1024px){
    .coverApplyFrame{
        width:43vw;
        height:60vw;
    }
    /* .coverSelFrame p{
        width:46.6vw;
    } */
}
@media (max-width:834px){
    .bookInfoGroup{
        margin-top:3vw;
    }
    .bookInfoGroup .iconFrame{
        width:6vw;
        height:6vw;
    }
    .bookInfoGroup span{
        font-size:2.5vw;
    }

    .bookCoverGroup{
        flex-direction:column;   
    }
    .coverApplyFrame{
        width:100%;
        height:86vh;
    }
    .coverSelFrame{
        width:100%;
    }
    .coverSelFrame h5{
        font-size:2.5vw;
        padding-bottom:2.5vw;
        padding-top:4vw;
    }
    .coverSelFrame p{
        width:100%;
        font-size:2vw;
        line-height:3vw;
        margin-bottom:3vw;
    }
    .coverSelFrame h6{
        font-size:2vw;
        padding-bottom:2vw;
    }
    .coverSelFrame ul.coverIllust{
        padding-bottom:2vw;
        padding-left:2vw;
    }
    .coverSelFrame ul.coverIllust li{
        width:10vw;
        height:10vw;
        margin-right:1vw;
    }
    .coverSelFrame div.coverTitleColor,
    .coverSelFrame div.coverBgColor{
        padding-bottom:2vw;
        padding-left:2vw;
    }
    .coverSelFrame div.coverTitleColor>div>div,
    .coverSelFrame div.coverBgColor>div>div{
        padding:1vw 1.5vw !important;
    }
    .coverSelFrame div.coverTitleColor>div>div>span>div,
    .coverSelFrame div.coverBgColor>div>div>span>div{
        width:4vw !important;
        height:4vw !important;
        margin:0 1vw 0 0 !important;
    }
    .coverSelFrame div.coverTitleColor>div>div>div:nth-last-child(3),
    .coverSelFrame div.coverBgColor>div>div>div:nth-last-child(3){
        width:4vw !important;
        height:4vw !important;
    }
    .coverSelFrame div.coverTitleColor>div>div>div:nth-last-child(2),
    .coverSelFrame div.coverBgColor>div>div>div:nth-last-child(2){
        height:4vw !important;
    }
    .coverSelFrame div.coverTitleColor>div>div>div>input,
    .coverSelFrame div.coverBgColor>div>div>div>input{
        height:4vw !important;
        width:10vw !important;
        font-size:1.8vw !important;
    }

    .coverSelFrame ul.coverAddress li{
        font-size:2vw;
        line-height:3vw;
    }
    .coverSelFrame ul.coverAddress li span{
        font-size:2vw;
    }
    .coverSelFrame ul.coverAddress li:nth-child(1){
        margin-bottom:3vw;
    }
    .coverSelFrame ul.coverAddress li:nth-child(1) span:nth-child(2){
        padding:0 3vw;
        height:5vw;
        line-height:5vw;
    }
    .coverSelFrame ul.coverAddress li span.coverSelBtn{
        padding:0 5vw;
        height:6vw;
        line-height:6vw;
        font-size:2vw;
    }





    .coverAdrsInputBox{
        width:70%;
        padding:5vw;
    }
    .coverAdrsInputBox>div.delBtn{
        top:-8vw;
        right:-5vw;
        font-size:5vw;
    }
    .coverAdrsInputBox ul li{
        height:6vw;
        margin-bottom:1vw;
    }
    .coverAdrsInputBox ul li input{
        padding:0 1vw;
        font-size:2.5vw;
    }
    .coverAdrsInputBox ul li:nth-child(1) input{
        width:59%;
    }
    .coverAdrsInputBox ul li:nth-child(1) span{
        font-size:2.5vw;
        line-height:6vw;
    }
    .coverAdrsInputBox ul li:nth-child(4){
        margin-top:3vw;
    }
    .coverAdrsInputBox ul li:nth-child(4) span{
        padding:0 8vw;
        height:6vw;
        line-height:6vw;
        font-size:2.5vw;
    }

}






@media (max-width:480px){
    .bookInfoGroup{
        margin-top:5vw;
    }
    .bookInfoGroup .iconFrame{
        width:10vw;
        height:10vw;
    }
    .bookInfoGroup span{
        font-size:3.2vw;
        margin-left:1.5vw;
    }
    .coverApplyFrame{
        height:80vh;
    }
    .coverSelFrame p{
        font-size:4vw;
        line-height:6vw;
        margin-bottom:5vw;
    }

    .coverSelFrame h5{
        font-size:4.5vw;
        line-height:6vw;
        padding-bottom:4vw;
        padding-top:8vw;
    }
    .coverSelFrame h6{
        font-size:4vw;
        padding-bottom:3vw;
    }
    .coverSelFrame ul.coverIllust{
        padding-bottom:4vw;
        padding-left:0;
    }
    .coverSelFrame ul.coverIllust li{
        width:15vw;
        height:15vw;
        margin-right:1.5vw;
    }
    

    .coverSelFrame div.coverTitleColor,
    .coverSelFrame div.coverBgColor{
        padding-bottom:4vw;
        padding-left:0;
    }
    .coverSelFrame div.coverTitleColor>div>div,
    .coverSelFrame div.coverBgColor>div>div{
        padding:1.5vw 2vw !important;
    }
    .coverSelFrame div.coverTitleColor>div>div>span>div,
    .coverSelFrame div.coverBgColor>div>div>span>div{
        width:8vw !important;
        height:8vw !important;
        margin:0 2vw 0 0 !important;
    }
    .coverSelFrame div.coverTitleColor>div>div>div:nth-last-child(3),
    .coverSelFrame div.coverBgColor>div>div>div:nth-last-child(3){
        width:8vw !important;
        height:8vw !important;
        margin-top:2vw;
    }
    .coverSelFrame div.coverTitleColor>div>div>div:nth-last-child(2),
    .coverSelFrame div.coverBgColor>div>div>div:nth-last-child(2){
        height:8vw !important;
    }
    .coverSelFrame div.coverTitleColor>div>div>div>input,
    .coverSelFrame div.coverBgColor>div>div>div>input{
        height:8vw !important;
        width:25vw !important;
        font-size:3.5vw !important;
        margin-top:2vw;
    }





    .coverSelFrame ul.coverAddress li{
        font-size:4vw;
        line-height:6vw;
    }
    .coverSelFrame ul.coverAddress li span{
        font-size:4vw;
    }
    .coverSelFrame ul.coverAddress li:nth-child(1){
        margin-bottom:3vw;
        flex-direction: column;
        align-items: inherit;

    }
    .coverSelFrame ul.coverAddress li:nth-child(1) span:nth-child(2){
        width:auto;
        padding:0 6vw;
        height:12vw;
        line-height:12vw;
        margin-top:2vw;
        margin-bottom:3vw;
        text-align:center;
    }
    .coverSelFrame ul.coverAddress li span.coverSelBtn{
        padding:0 8vw;
        width:100%;
        height:12vw;
        line-height:12vw;
        font-size:4vw;
        text-align:center;
    }








    .coverAdrsInputBox{
        width:90%;
        padding:6vw;
    }
    .coverAdrsInputBox>div.delBtn{
        top:-14vw;
        right:0;
        font-size:10vw;
    }
    .coverAdrsInputBox ul li{
        height:12vw;
        margin-bottom:2vw;
    }
    .coverAdrsInputBox ul li input{
        padding:0 1vw;
        font-size:4vw;
    }
    .coverAdrsInputBox ul li:nth-child(1) input{
        width:58%;
    }
    .coverAdrsInputBox ul li:nth-child(1) span{
        font-size:4vw;
        line-height:12vw;
        margin-left:2%;
    }
    .coverAdrsInputBox ul li:nth-child(4){
        margin-top:3vw;
    }
    .coverAdrsInputBox ul li:nth-child(4) span{
        padding:0;
        width:100%;
        text-align:center;
        height:12vw;
        line-height:12vw;
        font-size:4vw;
    }
}