.galleryOutFrame{
    width:100%;
    height:56.25vw;
    background:#ffffff;
}
.galleryInFrame{
    width:100%;
    height:100%;
    display:flex;
    flex-direction: row;
    padding-left:5.2vw;
    padding-top:10vw;
    padding-bottom:10vw;
}
.galleryInFrame .galleryTitleBox{
    width:40%;
    padding-left:5%;
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content:flex-end;
}
.galleryInFrame .galleryTitleBox ul{
    font-size:0;
}
.galleryInFrame .galleryTitleBox ul li{
    text-align:right;
}
.galleryInFrame .galleryTitleBox ul li.sectMenuText{
    font-size:1.8vw;
    color:#dedede;
}
.galleryInFrame .galleryTitleBox ul li.contTitleText{
    font-size:4vw;
    font-weight:600;
    line-height:4.5vw;
    display:flex;
    flex-direction: column;
    padding:0.5vw 0 2vw;
}
.galleryInFrame .galleryTitleBox ul li.contentText{
    font-size:1.2vw;
    line-height:3.5vw;
    display:inline-flex;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom:3vw;
    width:100%;
    
}
.galleryInFrame .galleryTitleBox ul li.contentText span{
    display:inline-block;
    cursor: pointer;
    border-bottom:1px solid #dedede;
    width:100%;
    padding:0 1vw;
}
/* .galleryInFrame .galleryTitleBox ul li.contentText span:nth-last-child(1){
    border:none;
} */
.galleryInFrame .galleryTitleBox ul li.contentText span:hover{
    font-weight:600;
    background:rgba(238,238,238,0.2);
}


.galleryInFrame .galleryContBox{
    width:60%;
    height:100%;
    padding-left:5vw;
}
.galleryInFrame .galleryContBox .albumFrame{
    width:100%;
    height:100%;
    float:left;
    overflow:hidden;
    font-size:0;
}

.galleryInFrame .galleryContBox .albumFrame li{
    width:25%;
    height:33.33%;
    display:inline-block;
    overflow:hidden;
}

.galleryInFrame .galleryContBox .albumFrame li:nth-child(1){width:25%;}
.galleryInFrame .galleryContBox .albumFrame li:nth-child(2){width:25%;}
.galleryInFrame .galleryContBox .albumFrame li:nth-child(3){width:50%;}
.galleryInFrame .galleryContBox .albumFrame li:nth-child(4){width:25%;}
.galleryInFrame .galleryContBox .albumFrame li:nth-child(5){width:50%;}
.galleryInFrame .galleryContBox .albumFrame li:nth-child(6){height:66.66%; float: right;}
.galleryInFrame .galleryContBox .albumFrame li:nth-child(7){width:50%;}
.galleryInFrame .galleryContBox .albumFrame li:nth-child(8){width:25%;}
.galleryInFrame .galleryContBox .albumFrame li span{
    width:100%;
    height:100%;
    display:block;
    background-repeat:no-repeat;
    background-position:50% 50%;
    background-size:cover;
    cursor: pointer;
    transition:transform 1s;
}
.galleryInFrame .galleryContBox .albumFrame li:hover span{
    -webkit-transform:scale(1.2);
    transform:scale(1.2);
}


.galleryPopFrame{
    width:100vw;
    height:100vh;
    position: fixed;
    top:0;
    left:0;
    z-index:1000;
}
.galleryPopBg{
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.8);
    position: fixed;
    top:0;
    left:0;
    z-index:1001;
}
.galleryPopBox{
    width:100%;
    height:100%;
    position: relative;
    z-index:1002;
}
.arActivityPopImage{
    min-width:60vw;
    min-height:40vw;
    max-height:80vh;
    background-repeat:no-repeat;
    background-position:50% 50%;
    background-size:contain;
    position: absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%, -50%);
    z-index:11;
    transition:background-image 0.5s;
    /* overflow:auto;
    overflow-x:hidden; */
}
/*collage용**********************************/
.galleryPopImage{
    min-width:60vw;
    min-height:40vw;
    max-height:80vh;
    background-repeat:no-repeat;
    background-position:50% 50%;
    background-size:contain;
    position: absolute;
    top:50%;
    left:50%;
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%, -50%);
    /* border:1px solid #dedede */
}
.galleryPopImage2{
    min-width:80vw;
}
.galleryPopImage3{
    top:57%;
    /* max-width:50vw; */
    align-items: center;
}
.galleryPoptitle{
    position: absolute;
    top:-17%;
    left:0;
    font-size: 2.3vw;
    z-index: 12;
    color: white;
    width:100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* max-height:15vh; */
    /* border : 1px solid red */
}
.galleryPoptitle .letcureTitle{
    word-break: keep-all;
}
.galleryPoptitle .letcureSubTitle{
    font-size: 2vw;
    margin-top: 1%;
}
.galleryPopImage2>img{
    /* max-width:40%; */
    max-height: 95%;
    object-fit: contain;
    max-width: 95%;
}
/* .galleryPopImage::-webkit-scrollbar{
    width:5px;
  }
  .galleryPopImage::-webkit-scrollbar-thumb{
    background-color:#b3c7db;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
  }
  .galleryPopImage::-webkit-scrollbar-track{
    background:transparent;
    border-radius:5px;
    box-shadow:inset 0px 0px 3px #ffffff;
  } */


.galleryPopBox>span{
    position:absolute;
    z-index:11;
    display:block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding:0 1vw;
}
.galleryPopBox>span:hover{
    color:#ffffff;
}
.galleryPopDel{
    top:2%;
    right:2%;
    font-size:4vw;
    color:rgba(255,255,255,0.5);
}
.galleryPopPrev{
    top:45%;
    left:2%;
    font-size:4vw;
    color:rgba(255,255,255,0.8);
}
.galleryPopNext{
    top:45%;
    right:2%;
    font-size:4vw;
    color:rgba(255,255,255,0.8);
}
/* .userGallPopPrev{
    left:-10%;
}
.userGallPopNext{
    right:-10%;
} */
/* .galleryInFrame .galleryContBox .albumFrame .albumLine:nth-child(1) div:nth-child(1) span{background-image:url(../../../../images/gallerySectionImage1.png);}
.galleryInFrame .galleryContBox .albumFrame .albumLine:nth-child(1) div:nth-child(2) span{background-image:url(../../../../images/gallerySectionImage2.png);}
.galleryInFrame .galleryContBox .albumFrame .albumLine:nth-child(1) div:nth-child(3) span{background-image:url(../../../../images/gallerySectionImage3.png);}
.galleryInFrame .galleryContBox .albumFrame .albumLine:nth-child(2) div:nth-child(1) span{background-image:url(../../../../images/gallerySectionImage4.png);}
.galleryInFrame .galleryContBox .albumFrame .albumLine:nth-child(2) div:nth-child(2) span{background-image:url(../../../../images/gallerySectionImage5.png);}
.galleryInFrame .galleryContBox .albumFrame .albumLine:nth-child(2) div:nth-child(3) span{background-image:url(../../../../images/gallerySectionImage6.png);}
.galleryInFrame .galleryContBox .albumFrame .albumLine:nth-child(2) div:nth-child(4) span{background-image:url(../../../../images/gallerySectionImage7.png);}
.galleryInFrame .galleryContBox .albumFrame .albumLine:nth-child(3) div:nth-child(1) span{background-image:url(../../../../images/gallerySectionImage8.png);}
.galleryInFrame .galleryContBox .albumFrame .albumLine:nth-child(3) div:nth-child(2) span{background-image:url(../../../../images/gallerySectionImage9.png);}
.galleryInFrame .galleryContBox .albumFrame .albumLine:nth-child(3) div:nth-child(3) span{background-image:url(../../../../images/gallerySectionImage10.png);} */

/* .galleryOutFrame{
    width:100%;
    height:100%;
    background:#ffffff;
    display:flex;
}

.galleryInFrame{
    width:100%;
    height:100%;
    background:rgba(255,255,255, 0.5);
    padding-left:5.2vw;
    position: relative;
}

.galleryTextBox{
    width:100%;
    position:relative;
    padding-top:15vw;
    z-index:1;
}
.galleryTextBox h2{
    font-size:4vw;
    color:#000f1e;
    line-height:4.5vw;
    padding-bottom:3vw;
}
.gallerypagerGroup{
    padding-bottom:2vw;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
}
.gallerypagerGroup li{
    font-size:1.1vw;
    color:#000f1e;
    line-height:2vw;
    cursor: pointer;
    
}
.gallerypagerGroup li.active{
    color:#60c0bf;
}
.gallerypagerGroup li:hover{
    font-weight:600;
}

.galleryContBox{
    width:50%;
    height:80%;
    position:absolute;
    top:20%;
    right:0;
    overflow:hidden;
}
.galleryContBox>div{
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
}
.galleryContBox>div>div{border:none; height:12vw;}
.galleryContBox>div>div>div{margin:0;} */










