
.scrollOff{
    position:fixed;
    overflow:hidden;
    height:100%;
}


.drawFrame{
    width:100%;
    /* display:flex;
    flex-direction: column;
    justify-content: stretch; */
    position:relative;
}




.drawToolBx{
    height:auto;
    padding:0.5vw;
    /* border:1px solid yellow; */
    position: absolute;
    top:0;
    right:0;
    z-index:1;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;

}
.drawToolBx>div{
    display:flex;
    /* margin-bottom:0.5vw; */
    /* height:2vw; */
    /* border:1px solid blue; */
}
.drawToolBx div.propertyBx{
    flex-direction:row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom:0.3vw;

}
.drawToolBx div.propertyBx span{
    display:inline-block;
    border:1px solid #000000;
    background:#ffffff;
    width:2vw;
    height:2vw;
    margin-left:0.3vw;
    text-align: center;
    font-size:1vw;
    cursor: pointer;
    line-height:2vw;
}
.drawToolBx div.propertyBx span.saveBtn{
    background:#f1f1f1;
}

.drawToolBx div.propertyBx span:active{
    background:#000000 !important;
    color:#ffffff !important;
}

.colorGroup{
    display: flex;
    flex-direction: column;
    /* justify-content:space-between; */
    /* height:2vw; */
    
}
.colorGroup div.penColorBx{
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
    margin-bottom:0.2vw;
}
.colorGroup div.penColorBx span{
    display:inline-block;
    width:1.3vw;
    height:1.3vw;
    border-radius: 2vw;
    padding:0 0.5vw;
    /* margin-right:0.5vw; */
    font-size:1vw;
    cursor: pointer;
}
.colorGroup div.penColorBx span:nth-child(1){
    border:none;
    font-size:1vw;
    display:inline-block;
    padding:0 0.5vw;
    width:auto;
    height:100%;
    cursor:default;
}

.colorGroup div.bgColorBx{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.colorGroup div.bgColorBx span{
    display:inline-block;
    width:1.5vw;
    height:1.5vw;
    font-size:1vw;
    cursor: pointer;
    
}
.colorGroup div.bgColorBx span:nth-child(2){
    border:1px solid #dedede;
}

.colorGroup div.bgColorBx span:nth-child(1){
    border:none;
    font-size:1vw;
    display:inline-block;
    padding:0 0.5vw;
    width:auto;
    height:100%;
    cursor:default;
}





.drawPaperBx{
    display:block;
    position:relative;
    border:1px solid #000000;
}
.drawPaperBx>div{
    width:100%;
    height:100%;
    position: absolute;
    top:0;
    left:0;
    /* border:1px solid red; */
}
.drawPaperBx>canvas{
    /* width:100%;
    height:100%; */
    position: absolute;
    top:0;
    left:0;
    /* border:1px solid green; */
}
.drawPaperCoverBx{
    display:block;
    position:relative;
    /* border:1px solid #000000; */
}
.drawPaperCoverBx>div{
    width:100%;
    height:100%;
    position: absolute;
    top:0;
    left:0;
    /* border:1px solid red; */
}
.drawPaperCoverBx>canvas{
    /* width:100%;
    height:100%; */
    position: absolute;
    top:0;
    left:0;
    /* border:1px solid green; */
}

@media (max-width:834px){
    .drawToolBx{
        top:1vw;
        right:1vw;
    }
    .drawToolBx div.propertyBx{
        margin-bottom:1vw;
    }
    .drawToolBx div.propertyBx span{
        width:6vw;
        height:6vw;
        margin-left:0.8vw;
        font-size:2.8vw;
        line-height:6vw;
    }
    .drawToolBx div.propertyBx span:nth-child(1){
        margin-left:0;
    }
    .colorGroup div.penColorBx{
        margin-bottom:0.5vw;
    }
    .colorGroup div.penColorBx span{
        width:4vw;
        height:4vw;
        border-radius:5vw;        
    }
    .colorGroup div.penColorBx span:nth-last-child(1){
        margin-right:0.8vw;
    }
    .colorGroup div.penColorBx span:nth-child(1){
        font-size:2.8vw;
    }
    .colorGroup div.bgColorBx span{
        width:3.5vw;
        height:3.5vw;        
    }

    .colorGroup div.bgColorBx span:nth-child(1){
        font-size:2.8vw;
    }
    .colorGroup div.bgColorBx span:nth-last-child(1){
        margin-right:0.8vw;
    }
}

@media (max-width:430px){
    .drawToolBx{
        top:1.5vw;
        right:1.5vw;
    }
    .drawToolBx div.propertyBx{
        margin-bottom:2vw;
    }
    .drawToolBx div.propertyBx span{
        width:8vw;
        height:8vw;
        margin-left:1vw;
        font-size:4.5vw;
        line-height:8vw;
    }
    .colorGroup div.penColorBx{
        margin-bottom:2vw;
    }
    .colorGroup div.penColorBx span:nth-child(1){
        font-size:3.5vw;
    }
    .colorGroup div.penColorBx span{
        width:6vw;
        height:6vw;
        border-radius:5vw;        
    }
    .colorGroup div.bgColorBx{
        margin-bottom:3vw;
    }
    .colorGroup div.bgColorBx span:nth-child(1){
        font-size:3.5vw;
    }
    .colorGroup div.bgColorBx span{
        width:6vw;
        height:6vw;        
    }
}