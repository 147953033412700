.authReaderFrame{
    padding:0 5.2vw 0;
    height:auto;
    width:100%;
}


.bookGuideBg{
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    background:rgba(0,0,0,0.9);
    z-index:101;
    display:none;
}
.bookGuideFrame{
    width:65vw;
    height:auto;
    position:absolute;
    top:50%;
    left:50%;
    -webkit-transform:translate(-50%, -50%);
    transform:translate(-50%, -50%);
    overflow:hidden;
    /* border:1px solid yellow; */
}
.bookGuideFrame>ul{
    display:flex;
    flex-direction: row;
    width:500%;
    font-size:0;
    margin-left:-100%;
    /* -webkit-transition-timing-function:ease-in-out;
    transition-timing-function:ease-in-out; */
}
.bookGuideFrame>ul>li{
    width:20%;
    height:auto;
    padding:0 10vw;
}

.bookGuideFrame>ul>li .bookGuideGroup{
    /* border:1px solid yellowgreen; */
    display:flex;
    flex-direction: column;
    width:100%;
    font-size:0;
}
.bookGuideFrame>ul>li .bookGuideGroup li{
    color:rgba(255,255,255,0.5);
    width:100%;
    height:auto;
    word-break: keep-all;
}
.bookGuideFrame>ul>li .bookGuideGroup li:nth-child(1){
    border:1px solid #ffffff;
    padding:2vw;
    width:100%;
    height:50vh;
    display:flex;
    flex-direction: row;
    justify-content: center;
}
.bookGuideFrame>ul>li .bookGuideGroup li:nth-child(2){
    font-size:1.5vw;
    color:#ffffff;
    padding:2vw 0 1vw;
}
.bookGuideFrame>ul>li .bookGuideGroup li:nth-child(3){
    font-size:1vw;
    color:#ffffff;
    line-height:2.5vw;
    font-weight:500;
}
.bookGuideFrame>ul>li .bookGuideGroup li:nth-child(1) img.dbookPcImage{
    height:100%;
}
.bookGuideFrame>ul>li .bookGuideGroup li:nth-child(1) img.dbookTaMoImage{
    display:none;
}
.bookGuideFrame>ul>li .bookGuideGroup li:nth-child(3) span.textBorder{
    border:1px solid #ffffff;
    padding:0 1vw;
    height:2.3vw;
    line-height:2.3vw;
    margin-right:0.5vw;
    display:inline-block;
    cursor:default;
}
.bookGuideFrame>ul>li.bookGuide1 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(2),
.bookGuideFrame>ul>li.bookGuide1 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(3),
.bookGuideFrame>ul>li.bookGuide2 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(2),
.bookGuideFrame>ul>li.bookGuide3 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(2),
.bookGuideFrame>ul>li.bookGuide4 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(1){margin:0 0.5vw;}

.bookGuideFrame>ul>li.bookGuide1 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(1):hover{background:rgba(23,94,206,0.5);}
.bookGuideFrame>ul>li.bookGuide1 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(2):hover{background:rgba(10,176,221,0.5);}
.bookGuideFrame>ul>li.bookGuide1 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(3):hover{background:rgba(10,176,221,0.5);}
.bookGuideFrame>ul>li.bookGuide2 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(1):hover{background:rgba(255,206,0,0.5);}
.bookGuideFrame>ul>li.bookGuide2 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(2):hover{background:rgba(239,165,0,0.5);}
.bookGuideFrame>ul>li.bookGuide3 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(1):hover{background:rgba(16,229,102,0.5);}
.bookGuideFrame>ul>li.bookGuide3 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(2):hover{background:rgba(63,234,0,0.5);}
.bookGuideFrame>ul>li.bookGuide4 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(1):hover{background:rgba(10,176,221,0.5);}
.bookGuideFrame>ul>li.bookGuide5 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(1):hover{background:rgba(255,0,207,0.5);}
.bookGuideFrame>ul>li.bookGuide5 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(2):hover{background:rgba(255,23,95,0.5);}
.bookGuideFrame>ul>li.bookGuide5 .bookGuideGroup li:nth-child(3) span.textBorder:nth-of-type(3):hover{background:rgba(158,47,237,0.5);}








.bookGuidePager{
    width:100%;
    position:absolute;
    top:40%;
    left:0;
    padding:0 15vw;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
.bookGuidePager div{
    font-size:4vw;
    font-weight:300;
    color:rgba(255,255,255,0.7);
    cursor: pointer;
}
.bookGuidePager div:hover{
    color:rgba(255,255,255,1);
}

.bookGuideBtn{
    display:flex;
    flex-direction: row;
    justify-content: center;
    width:100%;
    position:absolute;
    bottom:5%;
    left:0;
    margin:0 auto;
}
.bookGuideBtn div{
    text-align:center;
    font-size:1vw;
    color:#ffffff;
    padding:0 2vw;
    height:2.3vw;
    line-height:2.3vw;
    border:1px solid #ffffff;
    cursor: pointer;
}
.bookGuideBtn div:nth-child(2){
    background:#ffffff;
    color:#000f1e;
    font-weight:600;
}



.manualBg{
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.9);
    position:fixed;
    top:0;
    left:0;
    z-index:101;
}
.manualBg .deviceMenualGuideFrame{
    width:70vw;
    height:80vh;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    overflow:auto;
    background:#ffffff;
    padding:3vw;
}
.manualBg .deviceMenualGuideFrame::-webkit-scrollbar{
    width:5px;
  }
  .manualBg .deviceMenualGuideFrame::-webkit-scrollbar-thumb{
    background-color:#b3c7db;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
  }
  .manualBg .deviceMenualGuideFrame::-webkit-scrollbar-track{
    background:transparent;
    border-radius:5px;
    box-shadow:inset 0px 0px 3px #ffffff;
  }
.manualBg .manualDelBtn{
    color:rgba(255,255,255,0.8);
    position:absolute;
    top:3%;
    right:5%;
    font-size:2.5vw;
    cursor: pointer;
}
.manualBg .manualDelBtn:hover{
    color:rgba(255,255,255,1);
}







.bookTop{
    width:100%;
    height:100%;
    /* padding:0 5.2vw; */
    margin:0 auto;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    margin-top:2vw;
}

.booInfoGroup{
    display:flex;
    flex-direction:row;
    align-items: center;
}
.booInfoGroup .iconFrame{
    width:3vw;
    height:3vw;
    border:1px solid #000000;
    /* border-radius:2vw */
    overflow:hidden;
}
.booInfoGroup .iconFrame img{
    height:100%;
    margin-left:50%;
    margin-top:50%;
    transform:translate(-50%, -50%);
}
.booInfoGroup span{
    font-size:1.2vw;
    padding:0 2vw 0 1vw;
    word-break: keep-all;
}



.linkGroup div{
    display:inline-block;
    font-size:1.2vw;
    height:3vw;
    line-height:3vw;
    padding:0 1.5vw;
    color:#c56e6e;
    cursor: pointer;
}
.linkGroup div.infoLinkBtnMobile{
    display:none;
}
.linkGroup span{
    display:inline-block;
    font-size:1.2vw;
    text-decoration: none;
    color:#000f1e;
    padding:0 1.5vw;
    border:1px solid #000f1e;
    height:3vw;
    line-height:3vw;
    cursor: pointer;
}
.linkGroup span:nth-of-type(1){
    background:#000f1e;
    color:#ffffff;
}
.linkGroup span:active{
    border:1px solid rgb(8, 46, 77) !important;
    background:rgb(8, 46, 77) !important;
    color:#ffffff !important;  
}




.bookMiddle{
    width:auto;
    height:auto;
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    position:absolute;
    top:8.5vw;
    left:9.2vw;
}
.bookMiddle p{
    font-size:2vw;
    color:#dedede;
}




.bookBottom{
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    height:3vw;
}
.bookBottom button{
    font-size:1.2vw;
    padding:0 2vw;
    line-height:3vw;
    height:100%;
    border:1px solid #000f1e;
    background:#ffffff;
}
.bookBottom button:hover{
    background:#000f1e;
    color:#ffffff;
}
.bookBottom a,
.bookBottom span{
    font-size:1.2vw;
    padding:0 2vw;
    line-height:3vw;
    border:1px solid #000f1e;
    color:#ffffff;
    text-decoration: none;
    height:100%;
    background:#000f1e;
}

/* 
@media (max-width:480px){
    #landscape{
        display:none;
    }
    @media screen and (orientation:landscape){
        #landscape{
            display:block;
            position:absolute;
            top:0;
            left:0;
            z-index:3000;
            width:100%;
            height:100%;
            background:#000000;
            color:#ffffff;
            font-size:2vw;
        }
    }
} */
