.eventIcon{
    position:fixed;
    right:5.2vw;
    bottom:5.2vw;
    z-index:2;
    width:6vw;
}
.eventIcon>a{
    width:100%;
    height:100%;
}
.eventIcon>a>img{
    width:100%;
}


@media (max-width:834px){
    .eventIcon{
     width:12vw;
    }
}
@media (max-width:480px){
    .eventIcon{
        bottom:10vw;
        z-index:100;
        width:20vw;
    }
       
}