.adminProgramFrame {
  padding: 0 5vw;
}
.adminProgramFrame > h3 {
  font-size: 1.8vw;
  font-weight: 600;
  line-height: 5vw;
  margin-bottom: 2vw;
  border-bottom: 1px solid #000000;
}

/* clpTabBtnGroup */
.adminProgramFrame > div > .clpTabBtnGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.adminProgramFrame > div > .clpTabBtnGroup div {
  font-size: 1.2vw;
  text-align: center;
  border: 1px solid #dedede;
  width: 34%;
  height: 4vw;
  line-height: 4vw;
  cursor: pointer;
  color: #aaaaaa;
  border-right: 0;
}
.adminProgramFrame > div > .clpTabBtnGroup div:hover {
  background: #eeeeee;
  color: #000000;
}
.adminProgramFrame > div > .clpTabBtnGroup div:nth-child(1) {
  border-right: 0;
  border-bottom: 0;
  color: #000000;
}
.adminProgramFrame > div > .clpTabBtnGroup div:nth-last-child(1) {
  border-right: 1px solid #dedede;
}
/* clpTabContGroup */
.adminProgramFrame > div > .clpTabContGroup {
  padding: 3vw 2vw;
  border: 1px solid #dedede;
  border-top: none;
  font-size: 1vw;
}

.programListCate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid #dedede;
  padding-top: 2vw;
}
.programListCate li {
  display: inline-block;
  font-size: 1vw;
  font-weight: 600;
  height: 2.5vw;
  line-height: 2.5vw;
}
.programListCate li:nth-child(1) {
  width: 10%;
}
.programListCate li:nth-child(2) {
  width: 10%;
}
.programListCate li:nth-child(3) {
  width: 8%;
}
.programListCate li:nth-child(4) {
  width: 8%;
}
.programListCate li:nth-child(5) {
  width: 25%;
}
.programListCate li:nth-child(6) {
  width: 12%;
}
.programListCate li:nth-child(7) {
  width: 8%;
}
.programListCate li:nth-child(8) {
  width: 17%;
}
.programListFrame {
  border-top: 1px solid #dedede;
}
.programListFrame:nth-child(1) {
  border: none;
}
.programListCont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.programListCont li {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1vw;
  /* line-height: 3vw; */
  word-break: normal;
  padding: 0.2vw 0;
}
.programListCont li:nth-child(1) {
  width: 10%;
}
.programListCont li:nth-child(2) {
  width: 10%;
}
.programListCont li:nth-child(3) {
  width: 8%;
}
.programListCont li:nth-child(4) {
  width: 8%;
}
.programListCont li:nth-child(5) {
  width: 25%;
}
.programListCont li:nth-child(6) {
  width: 12%;
}
.programListCont li:nth-child(7) {
  width: 8%;
}
.programListCont li:nth-child(8) {
  width: 17%;
}
.programListCont li select {
  width: 90%;
  height: 2.5vw;
  line-height: 2.5vw;
  cursor: pointer;
}
.programListCont li div {
  height: 2.5vw;
  line-height: 2.5vw;
  cursor: pointer;
  border: 1px solid #000000;
  padding: 0 1vw;
  margin-right: 0.5vw;
}
.programListCont li button {
  height: 2.5vw;
  line-height: 2.5vw;
  cursor: pointer;
  border: 1px solid #000000;
  padding: 0 1vw;
}
.instituteCate li:nth-child(1) {
  width: 10%;
}
.instituteCate li:nth-child(2) {
  width: 7%;
}
.instituteCate li:nth-child(3) {
  width: 10%;
}
.instituteCate li:nth-child(4) {
  width: 8%;
}
.instituteCate li:nth-child(5) {
  width: 25%;
}
.instituteCate li:nth-child(6) {
  width: 12%;
}
.instituteCate li:nth-child(7) {
  width: 8%;
}
.instituteCate li:nth-child(8) {
  width: 17%;
}
.instituteCont li:nth-child(1) {
  width: 10%;
}
.instituteCont li:nth-child(2) {
  width: 7%;
}
.instituteCont li:nth-child(3) {
  width: 10%;
}
.instituteCont li:nth-child(4) {
  width: 8%;
}
.instituteCont li:nth-child(5) {
  width: 25%;
}
.instituteCont li:nth-child(6) {
  width: 12%;
}
.instituteCont li:nth-child(7) {
  width: 8%;
}
.instituteCont li:nth-child(8) {
  width: 17%;
}

.detailBox {
  width: 100%;
  height: 100%;
  padding: 1vw;
}
.detailBox {
  width: 100%;
  height: 100%;
}
.detailBox > li {
  height: 100%;
  display: block;
  padding-bottom: 0.5vw;
}
.detailBox > li > span {
  font-size: 1vw;
  margin-right: 1vw;
  line-height: 1.5vw;
  word-break: keep-all;
  display: inline-block;
}
.detailBox > li > span:nth-child(1) {
  font-weight: 600;
  width: 10%;
  margin-right: 0;
}
.detailBox li input[type="text"] {
  border: 1px solid #000000;
  font-size: 1vw;
  padding: 0 0.5vw;
  width: 15vw;
  height: 2vw;
  line-height: 2vw;
}
.detailBox li input[type="checkbox"] {
  border: 1px solid #000000;
  font-size: 3vw;
  padding: 0 0.5vw;
  width: 1.5vw;
  height: 1.5vw;
  line-height: 2vw;
}
.detailBox li.ReguireText {
  margin-left: 10%;
  margin-bottom:0.5vw;
  height:15vw;
  overflow:auto;
  border:1px solid #dedede;
  padding:1vw;
}
.detailBox li.ReguireText::-webkit-scrollbar{
  width:5px;
}
.detailBox li.ReguireText::-webkit-scrollbar-thumb{
  background-color:#b3c7db;
  border-radius:5px;
  background-clip:padding-box;
  border:1px solid transparent;
}
.detailBox li.ReguireText::-webkit-scrollbar-track{
  background:transparent;
  border-radius:5px;
  box-shadow:inset 0px 0px 3px #ffffff;
}

.detailBox li.ReguireText div {
  font-weight: normal;
  width: auto;
}
.detailBox li.ReguireText span{
  font-weight:normal;
  width:auto;
  display:block;
}
.detailBox li.manegeText {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.detailBox li.manegeText textarea {
  padding: 0.5vw;
  width: 90%;
  height: 10vw;
  resize: none;
  overflow: auto;
}
.detailBox li.xrbookSelect {
  max-width: 90%;
  width: auto;
}
.detailBox li.xrbookSelect select {
  height: 2vw;
  width: 100%;
  overflow: hidden;
  line-height: 2vw;
}
.detailBox li.xrbookSelect2 select {
  height: 2vw;
  width: 70%;
  overflow: hidden;
  line-height: 2vw;
  margin-left: 2%;
}
.detailBox li.periodSelect {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.detailBox li.userInfoUpload {
  display: flex;
  flex-direction: row;
}
.detailBox li.periodSelect div{
  font-size:1vw;
}
.detailBox li.userInfoUpload div .userInfoBtnGroup {
  display: flex;
  flex-direction: column;
}
.detailBox li.userInfoUpload div .userInfoBtnGroup > li {
  margin-bottom: 0.5vw;
}
.detailBox li.warning {
  color: #c56e6e;
}
.detailBox li.userInfoUpload div .userInfoBtnGroup .infoUploadBtn {
  border: 1px solid #000000;
  padding: 0 0.5vw;
  height: 2.5vw;
  line-height: 2.5vw;
  display: inline-block;
  cursor: pointer;
}
.detailBox li.userInfoUpload div .userInfoBtnGroup .infoUploadBtn:hover {
  background: #eeeeee;
}
.detailBox li.userInfoUpload div .userInfoBtnGroup .fileDownloadBtn a {
  padding: 0 0.5vw;
  height: 2.5vw;
  line-height: 2.5vw;
  display: inline-block;
  cursor: pointer;
  color: #000000;
  text-decoration: none;
}
.detailBox li.userInfoUpload div .userInfoBtnGroup .fileDownloadBtn a:hover {
  color: #4676ac;
  text-decoration: underline;
}
.detailBox li.userInfoUpload div .userInfoBtnGroup .fileDeleteBtn {
  border: 1px solid #000000;
  padding: 0 0.5vw;
  height: 2vw;
  line-height: 2vw;
  display: inline-block;
  cursor: pointer;
}

.detailBox li.userInfoUpload div .userInfoBtnGroup .fileDeleteBtn:hover {
  color: #a30d0d;
  border: 1px solid #a30d0d;
}
.detailBox li.userInfoUpload div .userInfoContGroup button {
  height: 2vw;
  padding: 0 2vw;
  border-width: 1px;
  margin: 0.5vw 0;
}
.detailBox li.userInfoUpload div .userInfoContGroup input {
  margin-right: 0.5vw;
}
