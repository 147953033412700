.userMypageBg{
    width:100%;
    min-height:100vh;
    padding:0 5.2vw 15vw;
}

.userMypageFrame{
    width:100%;
    height:100%;
    padding-top:15vw;
    padding-left:10vw;
    padding-right:10vw;
    /* border:1px solid orange; */
}

.userMypageFrame>h3{
    font-size:2vw;
    margin-bottom:1vw;
}
.userMypageFrame>p{
    font-size:1.2vw;
    margin-bottom:6vw;
}


.tab{
    border-bottom: 1px solid black;
    width: 100%;
    height:5.2vw;
    line-height:5.2vw;
    padding:0 2vw;
    cursor: pointer;
    font-size:1.2vw;
    font-weight:600;
}
.tab:nth-child(3){
    border-top:1px solid black;
}


.contents{
    border: 1px solid #f0f0f0;
    border-bottom:1px solid #000000;
    width: 100%;
    height:auto;
    padding: 2vw;
    background:#f0f0f0;
}
.contents h6{
    margin-bottom:1vw;
    font-size:1.5vw;
    color:#000f1e;
}

.show {
    min-height: 20vw;
    height: auto;
    transition: height 0.5s ease-out;
}
.hidden {
    display: none;
}



.libraryBg{
    position: relative;
    min-height: 15vw;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.libraryBg > p{
    font-size:1vw;
    line-height:1.5vw;
    color:#c56e6e;
    word-break: keep-all;
    display:inline-flex;
    flex-direction: row;
}
.libraryBg>p>span{
    margin: 1vw 0;
}
.libraryFrame>div{
    width:100%;
    padding-bottom:1vw;
    border-bottom:1px solid #dedede;
    margin-bottom:1vw;
    font-size:1vw;
    word-break: keep-all;
}

.libraryFrame div a{
    display:inline-flex;
    flex-direction:row;
    align-items:center;
    width: 65vw;
    text-decoration: none;
}
.libraryFrame div a span{
    width:3vw;
    height:3vw;
    border:1px solid #000000;
    overflow:hidden;
    margin-right:0.5vw;
    position: relative;
}
.libraryFrame div a span img{
    height:100%;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
}
.libraryFrame div a p{
    font-size:1vw;
    color:#000000;
    text-decoration: none;
    word-break: keep-all;
}

.libraryFrame div a:hover p{
    /* font-size:1.2vw; */
    text-decoration: underline;
    color:#000000;
}
.libraryFrame div a:active span{
    border:2px solid rgb(8, 46, 77);
}
.libraryFrame div a:active p{
    color:rgb(8, 46, 77);
    text-decoration: underline;
}
.libraryFrame .btnArea{
    align-self: center;
}
.libraryFrame div .extensionBtn, .libraryFrame div .oculusVrAppBtn{
    padding: 0.1vw 0.3vw;
    font-size:0.9vw;
    line-height: 2vw;
    width: 10vw;
    outline: none;
    cursor: pointer;
    text-align: center;
    border:none;
}
.libraryFrame div .extensionBtn{
    border: 1px solid #000f1e;
}
.libraryFrame div .oculusVrAppBtn{
    color:#Ffffff;
    background:rgb(8, 46, 77);
}
.bookRegistBtn{
    width:12vw;
    height:2.9vw;
    line-height:2.9vw;
    background:#000f1e;
    display: inline-block;
    color:#ffffff;
    padding:0 2vw;
    cursor: pointer;
    font-size:1vw;
    text-align: center;
}
.bookRegistBtn:hover{
    background:rgb(8, 46, 77);
    color:#ffffff;
}




.codeRegiBg{
    width:100vw;
    height:100vh;
    background:rgba(0,0,0,0.9);
    position:fixed;
    top:0;
    left:0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
.codeRegiBx{
    width:50%;
    height:auto;
    background:#ffffff;
    padding:3vw;
}

.codeRegiBx .titleBox{
    border-bottom: 2px solid #000000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-bottom: 2vw;
    height:3vw;
}

.codeRegiBx .titleBox>h5{
    font-size: 1.5vw;
    font-weight: 600;
    height: 4vw;
    line-height: 3vw;
    display: inline-block;
}
.codeRegiBx .titleBox>div{
    font-size: 2.5vw;
    height: 4vw;
    line-height: 3vw;
}
.codeRegiBx .subTextBox>h6{
    font-size:1.4vw;
    margin-bottom:1vw;
}
.codeRegiBx .subTextBox>ul.codeRegiBxinfoPc>li{
    font-size:1.2vw;
    margin-bottom:1vw;
}
.codeRegiBx .subTextBox>ul.codeRegiBxinfoMobile>li{
    display: none;
}
.codeRegiBx .codeRegiBxoculusCode{
    display: inline-block;
    padding: 0.5vw;
    background-color: rgba(0,0,0,0.9);
    color: #fff;
    cursor: pointer;
    font-size: 1.4vw;
    cursor: pointer;
    margin-bottom: 1vw;
}
.codeRegiBx .codeRegiBxoculusCodeCopy{
    font-size: 1vw;
    margin-bottom: 0.3vw;
}
.codeRegiBx .codeRegiBxoculusCodeCopy1{
    display: inline;
    font-size: 1vw;
}
.codeRegiBx .codeRegiBxoculusCodeNone p{
    display: inline-block;
    height: 2.9vw;
    margin: 2vw 0;
    padding: 0.6vw 1vw;
    font-size: 1.4vw;
    /* border: 2px solid #999; */
    color: #999;
}

.codeRegiBx .codeRegiBxoculusCodeNone button{   
    display: inline-block;
    width:12vw;
    height:2.9vw;
    background:#000f1e;
    color:#ffffff;
    padding:0 2vw;
    cursor: pointer;
    border: none;
    font-size:1vw;
}
.codeInputFrame{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom:1vw;
}
.codeInputFrame p{
    /* margin-right:0.5vw; */
    height:2.6vw;
    line-height:2.6vw;
    width:auto;
    font-size:1.2vw;
}
.codeInputFrame span span{
    position: relative;
    top: 0.4vw;
    font-size: 2vw;
}
.codeInputFrame input{
    height:2.6vw;
    line-height:2.6vw;
    width:auto;
    font-size:1.2vw;
    margin: 0 0.5vw;
}
.codeInputFrame input{
    width:10vw;
}
.codeRegiBx form{
    width:100%;
}
.codeRegiBx form>p{
    font-size:1.2vw;
    word-break: keep-all;
}
.codeRegiBx form p.errMsg{
    font-size:1vw;
    color:#c56e6e;
    line-height:2.6vw;
}
.codeRegiBx form .codeRegiBtn{
    font-size:1vw;
    padding:0 2vw;
    line-height:2.9vw;
    background:#000000;
    color:#ffffff;
    margin:2vw 0 1vw;
    cursor: pointer;
    display:inline-block;
}


.addressRegiBx{
    width:50%;
    height:auto;
    background:#ffffff;
    padding:3vw;
}
.addressRegiBx .titleBox{
    border-bottom: 2px solid #000000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 3vw;
    margin-bottom: 2vw;
}

.addressRegiBx .titleBox>h5{
    font-size: 1.5vw;
    font-weight: 600;
    height: 4vw;
    line-height: 3vw;
    display: inline-block;
}
.addressRegiCont p{
    font-size:1.2vw;
    word-break: keep-all;
    line-height:2vw;
    padding-bottom:2vw;
}

.addressInputFrame{
    font-size:0;
}
.addressInputFrame input{
    width:100%;
    height:2.6vw;
    margin-bottom:0.5vw;
    padding:1vw;
    font-size:1.2vw;
    line-height:2.6vw;
    border:1px solid #000000;
}
.addressInputFrame input:nth-child(1){width:70%;}
.addressInputFrame span{
    width:29%;
    height:2.6vw;
    margin-left:1%;
    border:1px solid #000000;
    line-height:2.6vw;
    text-align: center;
    display:inline-block;
    background:#dedede;
    cursor: pointer;
    font-size:1.2vw;
}
.addressRegiCont .addressRegiBtn{
    font-size:1vw;
    border:none;
    padding: 0 2vw;
    line-height: 2.9vw;
    background: #000000;
    color: #ffffff;
    margin: 2vw 0 1vw;
    cursor: pointer;
    display: inline-block;
}

.noDataText{
    width:100%;
    height:100%;
    font-size:1vw;
    line-height:2vw;
    padding:0 2vw;
    padding-bottom:3vw;
    
    color:#999999;
    word-break: keep-all;
    /* text-align:center; */
}

.myProgramFrame{
    height:auto;
    line-height:2.5vw;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    font-size:1vw;
    padding:1vw;
    border-bottom:1px solid #dedede;
}
.myProgramFrame:nth-last-child(1){
    border-bottom:0;
}
/* .myProgramFrame:nth-child(1){
    font-weight:600;
    border-bottom:1px solid #000000;
} */
.myProgramFrame>li{height:auto;}
/* .myProgramFrame>li:nth-child(1){width:15%;}
.myProgramFrame>li:nth-child(2){width:8%;}
.myProgramFrame>li:nth-child(3){width:15%;}
.myProgramFrame>li:nth-child(4){width:8%}
.myProgramFrame>li:nth-child(5){width:13%}
.myProgramFrame>li:nth-child(6){width:13%}
.myProgramFrame>li:nth-child(7){width:11%}
.myProgramFrame>li:nth-child(8){width:10%}
.myProgramFrame>li:nth-child(9){width:5%} */
.myProgramFrame>li span{
    font-size:1.1vw;
    font-weight:600;
    display:inline-block;
    width:7vw;
    text-align:right;
    margin-right:1vw;
}
.myProgramFrame>li span:nth-child(2){
    width:auto;
    margin-left:1vw;
    font-weight:normal;
    border-left:1px solid #999999;
    line-height:1;
    padding-left:1vw;
}


.myBookFrame{
    width:100%;
    height:auto;
    line-height:2.5vw;
    /* background:rgba(255,255,255,0.5); */
    display:flex;
    flex-direction:column;
    justify-content: space-around;
    font-size:1vw;
    border-bottom:1px solid #dedede;
    padding:1vw;
}
.myBookFrame:nth-last-child(1){
    border-bottom:0;
}
.myBookFrame>li{
    height:auto;
}
.myBookFrame>li span{
    font-size:1.1vw;
    font-weight:600;
    display:inline-block;
    width:6vw;
    text-align:right;
    margin-right:1vw;
}

.bookBtnGroup{text-align: center;
    display:flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-between;
    padding:0.3vw 0;
}
.bookBtnGroup>button{
    height:100%;
    line-height:2vw;
    border-radius:none;
    border:none;
    border:1px solid #000000;
    background:#ffffff;
}








.payHistoryFrame, 
.slpStatusFrame{
    height:auto;
    line-height:2.5vw;
    /* background:rgba(255,255,255,0.5); */
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    font-size:1.1vw;
    border-bottom:1px solid #dedede;
    padding:1vw;
}
.payHistoryFrame:nth-last-child(1){
    margin-bottom:3vw;
}
.payHistoryFrame:nth-last-child(1),
.slpStatusFrame:nth-last-child(1){
    border-bottom:0;
}
.payHistoryFrame>li, 
.slpStatusFrame>li{
    height:auto;
    display:flex;
    flex-direction:row;
    align-items:center;
}

.payHistoryFrame>li:nth-last-child(1), 
.slpStatusFrame>li:nth-last-child(1){
    display:flex;
    flex-direction:row;
    align-items:center;
}
.payHistoryFrame>li span, 
.slpStatusFrame>li span{
    display:inline-block;
    width:8vw;
    margin-right:1vw;
}
.payHistoryFrame>li span:nth-child(1), 
.slpStatusFrame>li span:nth-child(1){
    font-weight:600;
    font-size:1.1vw;
}
.payHistoryFrame>li span:nth-child(2), 
.slpStatusFrame>li span:nth-child(2){
    font-size:1vw;
    width:auto;
    display:flex;
    flex-direction:row;
    align-items:center;
    margin-right:0;
    word-break: keep-all;
}
.payHistoryFrame>li span button, 
.slpStatusFrame>li span button{
    border:1px solid #000f1e;
    background:#ffffff;
    padding:0 1vw;
    font-size:1vw;
    line-height:2vw;
    outline:none;
}
.payHistoryFrame>li span button:nth-child(1), 
.slpStatusFrame>li span button:nth-child(1){
    background:#000f1e;
    color:#ffffff;
}
.payHistoryFrame>li span button:nth-child(2),
.payHistoryFrame>li span button:nth-child(3), 
.slpStatusFrame>li span button:nth-child(2),
.slpStatusFrame>li span button:nth-child(3){
    border-left-width:0 !important;
}






/* orderCancle */
.orderCancleBg{
    width:100vw;
    height:100vh;
    position:fixed;
    top:0;
    left:0;
    background:rgba(0,0,0,0.9);
    z-index:1000;
}
.orderCancleFrame{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    background:#ffffff;
    border:1px solid #000000;
    width:65vw;
    height:80vh;
    padding:2vw 3vw;
    overflow:auto;
}
.orderCancleFrame::-webkit-scrollbar{
    width:5px;
}
.orderCancleFrame::-webkit-scrollbar-thumb{
    background-color:#dde7f1;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
}
.orderCancleFrame::-webkit-scrollbar-track{
    background:transparent;
    border-radius:5px;
    box-shadow:inset 0px 0px 3px #ffffff;
}
.orderCancleFrame .canclePopTitle{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: space-between;
    padding-bottom:1vw;
    border-bottom:1px solid #000000;
    margin-bottom:3vw;
}

.orderCancleFrame .canclePopTitle h5{
    font-size:1.5vw;
    font-weight:600;
}
.orderCancleFrame .canclePopTitle div{
    font-size:2vw;
    /* font-weight:600; */
    cursor: pointer;
}
.orderCancleFrame .canclePopTitle div:hover{font-weight:600;}

.orderCancleFrame .canclePopList h6{
    font-size:1.2vw;
    color:#000000;
    font-weight:600;
}
/*다시 잡아야할 부분**********************************************/
.orderCancleFrame .canclePopList .payHistoryFrame{
    display:flex;
    flex-direction:row;
    align-items: center;
    padding:0;
    padding:0 0.5vw;
}
.orderCancleFrame .canclePopList .payHistoryFrame:nth-child(2){
    background:#eeeeee;
    border-bottom:0;
}
.orderCancleFrame .canclePopList .payHistoryFrame li{
    font-size:1vw;
    line-height:2.5vw;
    word-break: break-all;
    padding-right:1vw;
}
.orderCancleFrame .canclePopList .payHistoryFrame li:nth-child(1){width:31%;word-break: keep-all;}
.orderCancleFrame .canclePopList .payHistoryFrame li:nth-child(2){width:20%;}
.orderCancleFrame .canclePopList .payHistoryFrame li:nth-child(3){width:12%;}
.orderCancleFrame .canclePopList .payHistoryFrame li:nth-child(4){width:12%;}
.orderCancleFrame .canclePopList .payHistoryFrame li:nth-child(5){width:15%;word-break: keep-all;}
.orderCancleFrame .canclePopList .payHistoryFrame li:nth-child(6){width:10%;}
.orderCancleFrame .canclePopList .payHistoryFrame li.payCancleBtn{
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
}
.orderCancleFrame .canclePopList .payHistoryFrame li.payCancleBtn button{
    background:#ffffff;
    border:1px solid #000f1e;
    padding:0 0.5vw;
    height:2vw;
    line-height:2vw;
    border-radius: 0;
    outline: none;
}
/*********************************************************/
.orderCancleFrame .canclePopReason h6{
    font-size:1.2vw;
    color:#000000;
    font-weight:600;
}
.orderCancleFrame .canclePopReason ul{
    width:100%;
    height:auto;
    display:flex;
    flex-direction:row;
}
.orderCancleFrame .canclePopReason ul li{
    display:flex;
    flex-direction:row;
    align-items: center;;
}
.orderCancleFrame .canclePopReason ul li input{
    cursor: pointer;
}
.orderCancleFrame .canclePopReason ul li span{
    font-size:1vw;
    margin:0 1.5vw 0 0.5vw;
}

.orderCancleFrame .canclePopAccount{
    margin-top:3vw;
}
.orderCancleFrame .canclePopAccount h6{
    font-size:1.2vw;
    color:#000000;
    font-weight:600;
}
.orderCancleFrame .cancleAccounInput{
    display:flex;
    flex-direction: row;
    align-items: center;
    margin-bottom:1vw;
}
.orderCancleFrame .cancleAccounInput select{
    height:2vw;
    margin-right:0.5vw;
    font-size:1vw;
}
.orderCancleFrame .cancleAccounInput input{
    padding:0 0.5vw;
    height:2vw;
    line-height:2vw;
    font-size:1vw;
    width:20vw;
    border:1px solid #000000;
}
.orderCancleFrame .canclePopAccount p{
    font-size:1vw;
    word-break: keep-all;
}



.orderCancleFrame .canclePopGuide{
    margin-bottom:3vw;
    margin-top:5vw;
}
.orderCancleFrame .canclePopGuide p{
    color:#c56e6e;
    font-size:1vw;
    line-height:1.5vw;
}
.orderCancleFrame .canclePopBtn{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.orderCancleFrame .canclePopBtn span{
    font-size:1vw;
    color: #000000;
    text-decoration:underline;
    cursor: pointer;
}
.orderCancleFrame .canclePopBtn span:hover{
    color:rgb(8, 46, 77);
}
.orderCancleFrame .canclePopBtn button{
    font-size:1vw;
    color:#000000;
    padding:0 1vw;
    line-height:2.5vw;
    border-width:1px;
}
/* bookPublishBg */
.bookPublishBg{
    width:100vw;
    height:100vh;
    position:fixed;
    top:0;
    left:0;
    background:rgba(0,0,0,0.9);
    z-index:2000;
}
.bookPublishFrame{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    background:#ffffff;
    border:1px solid #000000;
    width:50vw;
    height:80vh;
    padding:2vw 3vw;
    overflow:auto;
}
.bookPublishFrame::-webkit-scrollbar{
    width:5px;
}
.bookPublishFrame::-webkit-scrollbar-thumb{
    background-color:#b3c7db;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
}
.bookPublishFrame::-webkit-scrollbar-track{
    background:transparent;
    border-radius:5px;
    box-shadow:inset 0px 0px 3px #ffffff;
}
.bookPublishFrame .publPopTitle{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: space-between;
    padding-bottom:1vw;
    border-bottom:1px solid #000000;
    margin-bottom:3vw;
}

.bookPublishFrame .publPopTitle h5{
    font-size:1.5vw;
    font-weight:600;
}
.bookPublishFrame .publPopTitle div{
    font-size:2vw;
    cursor: pointer;
}
.bookPublishFrame .publPopTitle div:hover{font-weight:600;}
.bookPublishFrame>p{
    font-size:1vw;
    padding-bottom:2vw;
}
.bookPublishFrame>p span{
    display:block;
    line-height:1.5;
}
.bookPublishFrame .publPopInfo{
    margin-bottom:2vw;
    display:flex;
    flex-direction: row;
    font-size:0;
}

.bookPublishFrame .publPopInfo li{
    display:flex;
    flex-direction: column;
}
.bookPublishFrame .publPopInfo li:nth-child(1){width:20%}
.bookPublishFrame .publPopInfo li:nth-child(2){width:65%;}
.bookPublishFrame .publPopInfo li:nth-child(3){width:15%;}
.bookPublishFrame .publPopInfo li span{
    font-size:1vw;
    line-height:2.5vw;
    padding:0 1vw;
    text-overflow: ellipsis;
}
.bookPublishFrame .publPopInfo li span:nth-child(1){
    background:#eeeeee;
}

.bookPublishFrame .publPopAddress{
    margin-bottom:1vw;
}
.bookPublishFrame .publPopAddress p{
    font-size:1vw;
    font-weight:600;
}
.bookPublishFrame .publPopAddress>div{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
}

.bookPublishFrame .publPopAddress>div>p{
    display:block;
    line-height:2.5vw;
    padding-bottom:0;
    font-weight:300;
}
.bookPublishFrame .publPopAddress>div>span{
    font-size:1vw;
    padding:0 1vw;
    background:#000f1e;
    color:#ffffff;
    display:inline-block;
    height:2.5vw;
    line-height:2.5vw;
    text-align:right;
    cursor: pointer;
} 
.bookPublishFrame .publPopAddress>div>span:hover{
    background:rgb(8, 46, 77) ;
    color:#ffffff ;
}
.bookPublishFrame .PublPopChangeAddress{
    width:100%;    
    background:#ffffff;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    border-top:1px solid #dedede;
    padding-top:2vw;
}
.bookPublishFrame .PublPopChangeAddress .PublPopChAddrBtn{
    display:flex;
    flex-direction: row;
    align-items: center;
}
.bookPublishFrame .PublPopChangeAddress .PublPopChAddrBtn button{
    border:1px solid #000f1e;
    background:#ffffff;
    font-size:1vw;
    padding:0 3vw;
    height:2.5vw;
    line-height:2.5vw;
}
.bookPublishFrame .PublPopChangeAddress .PublPopChAddrBtn button:nth-child(1){
    background:#000f1e;
    color:#ffffff;
}

.addressPopupOut{
    position: absolute;
    width:50%;
    height:50%;
    min-height: 500px;
    z-index: 1100;
    left: 0; right: 0; top: 0; bottom: 0; margin: auto;
    background:#ffffff;
}
.addressPopupOut .addressPopupIn{
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items:center;
    padding:2vw;
}
.addressPopupOut .addressPopupIn>button{
    border:1px solid #000f1e;
    background:#ffffff;
    font-size:1vw;
    padding:0 3vw;
    height:2.5vw;
    line-height:2.5vw;
    margin-bottom:2vw;
}


/* deviceRental */
.deviceRentalBg{
    width:100vw;
    height:100vh;
    position:fixed;
    top:0;
    left:0;
    background:rgba(0,0,0,0.9);
    z-index:1000;
}
.deviceRentFrame{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    background:#ffffff;
    border:1px solid #000000;
    width:50vw;
    height:80vh;
    padding:2vw 3vw;
    overflow:auto;
}
.deviceRentFrame::-webkit-scrollbar{
    width:5px;
}
.deviceRentFrame::-webkit-scrollbar-thumb{
    background-color:#dde7f1;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
}
.deviceRentFrame::-webkit-scrollbar-track{
    background:transparent;
    border-radius:5px;
    box-shadow:inset 0px 0px 3px #ffffff;
}
.deviceRentFrame .rentPopTitle{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: space-between;
    padding-bottom:1vw;
    border-bottom:1px solid #000000;
    margin-bottom:3vw;
}

.deviceRentFrame .rentPopTitle h5{
    font-size:1.5vw;
    font-weight:600;
}
.deviceRentFrame .rentPopTitle div{
    font-size:2vw;
    cursor: pointer;
}
.deviceRentFrame .rentPopTitle div:hover{font-weight:600;}
.deviceRentFrame .rentPopList p{
    display:flex;
    flex-direction:column;
    margin-bottom:2vw;
    font-size:1vw;
    word-break: keep-all;
    line-height:1.5;
}
.deviceRentFrame .rentPopList h6{
    font-size:1.2vw;
    color:#000000;
    font-weight:600;
    margin-bottom:2vw;
}
.deviceRentFrame .rentPopList .payHistoryFrame{
    display:flex;
    flex-direction:row;
    padding:0;
}
.deviceRentFrame .rentPopList .payHistoryFrame:nth-child(2){
    background:#eeeeee;
}
.deviceRentFrame .rentPopList .payHistoryFrame li{
    line-height:2.5vw;
    font-size:1vw;
    padding:0 1vw;
    text-overflow: ellipsis;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
}
.deviceRentFrame .rentPopList .payHistoryFrame li:nth-child(1){width:10%;}
.deviceRentFrame .rentPopList .payHistoryFrame li:nth-child(2){width:40%;}
.deviceRentFrame .rentPopList .payHistoryFrame li:nth-child(3){width:30%;}
.deviceRentFrame .rentPopList .payHistoryFrame li:nth-child(4){width:20%;}
.deviceRentFrame>p{
    font-size:1vw;
    padding-bottom:2vw;
    margin-top:auto;
}
.deviceRentFrame>p span{
    display:block;
}
.deviceRentFrame .rentPopInfo{
    margin-bottom:2vw;
}
.deviceRentFrame .rentPopInfo li{
    display:flex;
    flex-direction: row;
    align-items: center;
    margin-bottom:0.5vw;
}
.deviceRentFrame .rentPopInfo li span{
    font-size:1.1vw;
}
.deviceRentFrame .rentPopInfo li>span:nth-child(1){
    font-size:1.2vw;
    font-weight:600;
    width:6vw;
}
.deviceRentFrame .rentPopGuide{
    font-size:1vw;
    line-height:1.6vw;
    margin-bottom:2vw;
    word-break:keep-all;
} 
.deviceRentFrame .rentPopBtn{
    text-align:right;
} 
.deviceRentFrame .rentPopBtn button{
    font-size:1vw;
    padding:0 1vw;
    border:none;
    line-height:2.5vw;
    background:#000f1e;
    color:#ffffff;
}



/* programUserManage */

.deviceRentFrame .programUserManage h6 {
    font-size: 1.2vw;
    color: #000000;
    font-weight: 600;
}
.deviceRentFrame .programUserManage .payHistoryFrame {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* padding: 0; */
    padding: 0 0.5vw;
}
.deviceRentFrame .programUserManage .payHistoryFrame:nth-child(2) {
    background: #eeeeee;
    border-bottom: 0;
    margin-bottom:0.5vw;
}
.deviceRentFrame .programUserManage .payHistoryFrame li {
    font-size: 1vw;
    line-height: 2.5vw;
    word-break: break-all;
    padding:0.2vw 0;
}
.deviceRentFrame .programUserManage .payHistoryFrame li:nth-child(1) {width: 15%;}
.deviceRentFrame .programUserManage .payHistoryFrame li:nth-child(2) {width: 25%;}
.deviceRentFrame .programUserManage .payHistoryFrame li:nth-child(3) {width: 30%;}
.deviceRentFrame .programUserManage .payHistoryFrame li:nth-child(4) {width: 30%;}
.deviceRentFrame .programUserManage .payHistoryFrame li input{
    border:1px solid #000000;
    width:90%;
    line-height:2vw;
    height:2vw;
    font-size:1vw;
    padding:0 0.5vw;
}
.deviceRentFrame .programUserManage .payHistoryFrame li button{
    border-width:1px;
    line-height:2vw;
    height:2vw;
    width:100%;
    font-size:1vw;
}



/* payCancleGuideBg */
.payCancleGuideBg{
    width:100vw;
    height:100vh;
    position:fixed;
    top:0;
    left:0;
    background:rgba(0,0,0,0.9);
    z-index:1000;
}
.payCancleGuideFrame{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    background:#ffffff;
    border:1px solid #000000;
    width:50vw;
    height:80vh;
    padding:2vw 3vw;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
}
.payCancleGuideFrame .payCanGuidePopTitle{
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content: space-between;
    padding-bottom:1vw;
    border-bottom:1px solid #000000;
    margin-bottom:3vw;
}

.payCancleGuideFrame .payCanGuidePopTitle h5{
    font-size:1.5vw;
    font-weight:600;
}
.payCancleGuideFrame .payCanGuidePopTitle div{
    font-size:2vw;
    font-weight:600;
    cursor: pointer;
}
.payCancleGuideFrame ul{
    height:90%;
    overflow:auto;
}
.payCancleGuideFrame ul::-webkit-scrollbar{
    width:5px;
}
.payCancleGuideFrame ul::-webkit-scrollbar-thumb{
    background-color:#d8e0e9;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
}
.payCancleGuideFrame ul::-webkit-scrollbar-track{
    background:transparent;
    border-radius:5px;
    box-shadow:inset 0px 0px 3px #ffffff;
}






p.myShareTxt{
    height:3vw;
    line-height:3vw;
    font-size:1vw;
    font-weight:600;
    /* border-bottom:1px solid #000000; */
}
.myShareFrame:nth-last-child(1){
    border-bottom:0;
}
.myShareFrame{
    height:3vw;
    line-height:3vw;
    /* background:rgba(255,255,255,0.5); */
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    
    font-size:1vw;
    border-bottom:1px solid #dedede;
}

.myShareFrame>li:nth-child(1){width:60%; text-overflow: ellipsis; cursor: pointer;}
.myShareFrame>li:nth-child(1):hover{font-weight:600;}
.myShareFrame>li:nth-child(2){width:25%;}
.myShareFrame>li:nth-child(3){width:15%; text-align:right;}
.myShareFrame>li>button{
    width:4vw;
    height:100%;
    font-size:1vw;
    line-height:3vw;
    border:none;
    outline:none;
    text-decoration:underline ;  
    background:transparent;
}
.myShareFrame>li>button:hover{
    color:#c56e6e !important;
    background:transparent !important;
    border:none !important;
}
.userGallPopBox{
    position: inherit !important;
    z-index:50;
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
}
.userGallPopImage{
    width:575px !important;
    height:780px !important;
    min-width:inherit !important;
    /* padding:10vw 0; */
    margin:0 auto;
    position:relative !important;
    top:inherit !important;
    left:inherit !important;
    transform: inherit !important;
    
}
.userGallPopImage .PageGalleryItem{
    width:100%;
    height:100%;
    overflow:hidden;
    position: absolute;
    top:0;
    left:0;
}
.userGallPopImage .PageGalleryItem .userCheckFrame{
    width:100%;
    height:100%;
}
.userGallPopImage .PageGalleryItem .userCheckFrame .bookContentFrame{
    width:100%;
    height:100%;
}
.userGallPopImage .transparentCover{
    width:100%;
    height:100%;
    background:transparent;
    position: absolute;
    top:0;
    left:0;
    z-index:99;
}






p.myReviewTxt{
    height:3vw;
    line-height:3vw;
    font-size:1vw;
    font-weight:600;
    border-bottom:1px solid #000000;
}

.myReviewFrame{
    height:3vw;
    line-height:3vw;
    /* background:rgba(255,255,255,0.5); */
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    font-size:1vw;
    border-bottom:1px solid #dedede;
}

/* .myReviewFrame>li{height:auto;border:1px solid red; } */
.myReviewFrame>li:nth-child(1){width:60%;}
.myReviewFrame>li:nth-child(2){width:30%;}
.myReviewFrame>li:nth-child(3){width:10%; display:flex; align-items: center;}
.myReviewFrame>li>button{
    width:7vw;
    height:2vw;
    font-size:1vw;
    line-height:2vw;
    border:none;
    outline:none;
    background:#000000;
    color:#ffffff;
}









.myInfoFrame{
    width:100%;
    height:auto;
    /* background:rgba(255,255,255,0.5); */
}
.myInfoFrame>ul>li{
    height:2.6vw;
    display:flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: center;
    margin-bottom:0.5vw;
    /* border:1px solid red; */
}
.myInfoFrame>ul>li>span{
    font-size:1vw;
}
.myInfoFrame>ul>li>span.infoCate{
    width:8vw;
    /* border:1px solid red; */
    line-height:2.6vw;
    text-align: right;
    padding:0 1vw;
    font-weight:600;
    font-size:1vw;
    margin-right:1vw;
}

.myInfoFrame>ul>li>input{
    height:100%;
    font-size:1vw;
    padding:0 1vw;
    margin-right:0.5vw;
    border:1px solid #000f1e;
}
.myInfoFrame>ul>li>button{
    border:1px solid #000f1e;
    font-size:1vw;
    width:10vw;
    height:100%;
    outline:none;
}
.myInfoFrame>ul>li>span:nth-child(2){
    display:flex;
    flex-direction: row;
    align-items: center;
    height:2.6vw;
}
.myInfoFrame>ul>li>span>input{
    height:100%;
    font-size:1vw;
    padding:0 1vw;
    margin-right:0.5vw;
    border:1px solid #000f1e;
}
.myInfoFrame>ul>li>span>button{
    border:1px solid #000f1e;
    font-size:1vw;
    width:10vw;
    height:100%;
    outline:none;
}

.myInfoFrame>ul>li>button.infoChangeBtn{
    margin-left:9vw;
    width:10vw;
    height:100%;
    font-size:1vw;
    border-radius:0;
    outline:none;
    border:none;
    color:#ffffff;
    background:#000f1e;
}
.pwChangeGroup{ 
    height:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.pwChangeGroup>input{
    width:10vw;
    height:100%;
    font-size:1vw;
    padding:0 1vw;
    margin-right:0.5vw;  
    border:1px solid #000f1e;
}
.pwChangeGroup>button{
    width:10vw;
    height:100%;
    font-size:1vw;
    border-radius:0;
    outline:none;
    border-width:1px;
}






.myInfoFrame>ul>li>span.infoDelLink{
    margin-left:auto;
    font-size:1vw;
    text-decoration: underline;
    cursor: pointer;
}
.infoDelBtnGroup{
    width:100%;
    height:100%;
    display:inline-flex;
    flex-direction: row;
    justify-content: center;
    margin-top:8%;
}
.infoDelBtnGroup>button{
    width:auto;
    height:2.6vw;
    padding:0 3vw;
    border:1px solid #000f1e;
    margin-right:1vw;
    background:#ffffff;
    font-size:1vw;
}
.infoDelBtnGroup>button:nth-child(1){
    background:#000f1e;
    color:#ffffff;
    border:none;
}


.secessionAlertBg{
    padding:2vw;
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background:rgba(0,0,0,0.9);
    display:flex;
    justify-content: center;
    align-items: center;
    z-index:999;
}
.secessionAlertBx{
    width:50%;
    height:auto;
    padding:3vw;
    
    background:#ffffff;
    /* margin:0 auto;
     */
}
.secessionAlertBx>.secessionAlertTitle{
    border-bottom:2px solid #000000;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
   
    /* border:1px solid red; */
    text-align:center;
    height:3vw;
    margin-bottom:2vw;
}
.secessionAlertBx>.secessionAlertTitle>h5{
    font-size:1.5vw;
    font-weight: 600;
    height:4vw;
    line-height:3vw;
    /* border:1px solid yellow; */
    display:inline-block;
}
.secessionAlertBx>.secessionAlertTitle>span{
    border:none;
    font-size:2.2vw;
    background:none;
    line-height:1;
    /* border:1px solid yellow; */
    /* height:3vw; */
    width:4vw;
    display:block;
    margin-bottom:1vw;
    outline:none;
    cursor: pointer;
}
.secessionAlertBx>.secessionAlertTitle>span:hover{
    font-weight:600;
}
.secessionAlertCont{
    /* border:1px solid yellow; */
    margin-bottom:2vw;
}

.secessionAlertCont>h6{
    font-size:1.2vw;
    color:#c56e6e;
    padding-bottom:1vw;
    word-break: keep-all;
}
.secessionAlertCont>ul>li{
    font-size:1vw;
    line-height:1.8vw;
    word-break: keep-all;
}
