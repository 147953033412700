.intstituteLogoFrame{
    width:89.6vw;
    height:5.5vw;
    position:absolute;
    top:0;
    left: 0;
    padding:1vw 5.2vw;
}
.intstituteLogoFrame .nonAuthLogoFrame{
    width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-decoration:none;
    height: 100%;
}
.intstituteLogoFrame .nonAuthLogo a{
    text-decoration:none;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.intstituteLogoFrame .intstituteLogoSignOut{
    text-decoration:none;
    height: 100%;

}
.intstituteLogoFrame .intstituteLogoSignOut svg{
    text-decoration:none;
    margin-left: 0.5vw;
    font-size: 1.5vw;
    color: #000000;
}
.intstituteLogoFrame img{
    height: 100%;
}
.intstituteLogoFrame img.scnuLogo{
    height: 80%;
    margin-right: 1vw;
}
.intstituteLogoFrame .intstituteLogo span{
    padding-left:0.5vw;
    font-size:1.5vw;
    font-weight:900;
    line-height:1.3vw;
    display:inline-block;
    text-decoration:none;
}

.nonAuthLogo2>span, .nonAuthLogo2>a{
    margin-top: 0.4vw;
    color: #000000 !important;
}
.nonAuthLogoFrame .nonAuthLogo2 span{
    font-size: 1.3vw;
}
.InstituteHomeFrame>.statisticsTabContGroup>.FacilitatorPage{
    margin-top: 0;
}
.InstituteHomeFrame>.statisticsTabContGroup>.FacilitatorPage .facilitatorVideoBlock{
    margin-top: 0;
    /* border:none; */
}

.nonAuthSignFrame{
    width:100%;
    height:100vh;
    padding:0 5.2vw 0;
}
.nonAuthSignFrame .signFrame{
    width:30vw;
    height:100%;
    margin-left:10vw;
    padding-top:15vw; 
}
.nonAuthSignFrame .signFrame h1{
    font-size:1.6vw;
    padding-bottom:1.5vw;
    display:inline-block;
    margin-bottom:3vw;
    border-bottom:3px solid #000f1e;
}
.nonAuthSignFrame .signFrame .inputGroup{
    width:100%;
    height:100%;
}
.nonAuthSignFrame .signFrame .inputGroup input{
    width:100%;
    height:3vw;
    margin-bottom:1.6vw;
    padding:0 1vw;
    font-size:1vw;
    border-radius: 0;
    outline: none;
}
.nonAuthSignFrame .signFrame .inputGroup div{
    width:100%;
    height:4vw;
    background:#000f1e;
    color:#ffffff;
    font-size:1.2vw;
    margin-top:3vw;
    line-height:4vw;
    text-align:center;
    cursor: pointer;
}
.nonAuthSignFrame .signFrame .inputGroup a{
    width:100%;
    height:4vw;
    border:1px solid #000f1e;
    color:#000f1e;
    font-size:1.2vw;
    margin-top:1vw;
    line-height:4vw;
    text-align:center;
    cursor: pointer;
}
.nonAuthSignFrame .signFrame .inputGroup div:active,
.nonAuthSignFrame .signFrame .inputGroup a:active{
    border:1px solid rgb(8, 46, 77) !important;
    background:hsl(207, 81%, 17%) !important;
    color:#ffffff !important;   
}




.InstituteHomeFrame{
    width:100%;
    height:100%;
    padding-top:10vw;
    padding-left:5vw;
    padding-right:5vw;
}
.InstituteHomeFrame>span{
    display:flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}
.InstituteHomeFrame>span>a{
    font-size:2vw;
    color:#000000;
}
.InstituteHomeFrame>span>a:hover{
    color:#375c9a;
}
.InstituteHomeFrame>span>a:active{
    color:rgb(8, 46, 77);
}
.InstituteHomeFrame>span>h3{
    font-size:2vw;
    margin-bottom:1vw;
}
.InstituteHomeFrame>p{
    font-size:1.2vw;
    /* margin-bottom:6vw; */
}

.InstituteHomeFrame>div h5{
    width:100%;
    height:5.2vw;
    line-height:5.2vw;
    padding:0 2vw;
    font-size:1.2vw;
    font-weight:600;
    border-bottom:1px solid #000f1e;
    cursor: pointer;
}

.InstituteHomeFrame>div ul{
    /* height:100%; */
    padding:2vw;
    padding-bottom:3vw;
    display:inline-flex;
    flex-direction:column;
}
.InstituteHomeFrame>div ul li{
    width:auto;
    /* height:2vw; */
    line-height:2vw;
    font-size:1vw;
    cursor: pointer;
}
.InstituteHomeFrame>div ul li:hover{
    font-size:1.2vw;
}
.InstituteHomeFrame>div ul li:active{
    color:rgb(8, 46, 77);
    text-decoration: underline;
}
/* .InstituteHomeFrame>div:nth-last-child(0){
    margin-top:15vw;
    display:flex;
    flex-direction:row;
    justify-content: flex-end;
} */
.InstituteHomeFrame>div button{  
    width:auto;
    height:3vw;
    font-size:1.2vw;
    padding:0 3vw;
    background: #000f1e;
    border:1px solid #000f1e;
    color:#ffffff;
    border:none;
    cursor: pointer;
}
.InstituteHomeFrame>div>a{
    width:auto;
    height:3vw;
    font-size:1.2vw;
    padding:0 3vw;
    border:1px solid #000f1e;
    color:#000f1e;
    line-height:3vw;
    text-decoration: none;
    cursor: pointer;
}
.InstituteHomeFrame>div button:active,
.InstituteHomeFrame>div>a:active{
    border:1px solid rgb(8, 46, 77) !important;
    background:rgb(8, 46, 77) !important;
    color:#ffffff !important;   
}

.InstituteHomeFrame>div>p{
    padding:1vw 2vw 0;
    font-weight:normal;
    height:auto;
}
.InstituteHomeFrame .myShareFrame{
    padding:0.5vw 0;
    margin:0 2vw;
}

.InstituteHomeFrame .myShareFrame>li>button{
    padding:0;
    background:transparent;
    color:#000000;
    line-height:2vw;
    height:2vw;
}
.InstituteHomeFrame .myShareFrame>li>button:hover{
    background:transparent !important;
    color:#c56e6e !important;
    border:none !important;
    cursor: pointer;
}
/* .InstituteHomeFrame>.statisticsTabBtnGourp>div:nth-last-child(1){
    border-right: none;
    border-top: none;
}
.InstituteHomeFrame>.statisticsTabBtnGourp>div:nth-last-child(1):hover{
    background: #ffffff;
    color: #000000;
} */

@media (max-width:834px){
    .intstituteLogoFrame{
        height:11vw;
        padding:1.5vw 3vw;
    }
    
    .intstituteLogoFrame .nonAuthLogoFrame .nonAuthLogo{
        margin-bottom: 2vw;
    }
    
    .intstituteLogoFrame .nonAuthLogoFrame .nonAuthLogo2 .scnuLogo{
        margin-right: 15%;
    }
    .intstituteLogoFrame .intstituteLogoSignOut svg{
        font-size: 2.5vw;
        margin-left: 1vw;
    }
    .intstituteLogoFrame .intstituteLogo span{
        padding-left:2vw;
        font-size:2.5vw;
        line-height:3vw;
    }
    .nonAuthLogoFrame .nonAuthLogo2 span{
        font-size:4vw;
    }
    .nonAuthSignFrame .signFrame{
        width:55vw;
    }
    .nonAuthSignFrame .signFrame h1{
        font-size:4vw;
        padding-bottom:5vw;
        margin-bottom:10vw;
    }
    .nonAuthSignFrame .signFrame .inputGroup{
        width:100%;
    }
    .nonAuthSignFrame .signFrame .inputGroup input{
        width:100%;
        height:8vw;
        margin-bottom:2.5vw;
        padding:0 2vw;
        font-size:3vw;
        line-height:8vw;
    }
    .nonAuthSignFrame .signFrame .inputGroup div,
    .nonAuthSignFrame .signFrame .inputGroup a{
        height:8vw;
        font-size:3vw;
        margin-top:5vw;
        line-height:8vw;
    }
    .nonAuthSignFrame .signFrame .inputGroup a{
        margin-top:2vw;
    }



    
    .InstituteHomeFrame{
        padding-top: 15vw;
    }
    .InstituteHomeFrame>.statisticsTabBtnGourp>div{
        font-size: 3vw;
        padding: 1vw;
        height: 6vw;
    }
    

    .InstituteHomeFrame>span>h3{
        font-size:3vw;
    }
    .InstituteHomeFrame>span>a{
        font-size:3.5vw;
    }
    .InstituteHomeFrame>p{
        font-size:2.5vw;
    }

    .InstituteHomeFrame>div h5{
        height:8vw;
        line-height:8vw;
        font-size:2.6vw;
    }
    .InstituteHomeFrame>div ul li{
        /* height:4vw; */
        line-height:4vw;
        font-size:2vw;
    }
    /* .InstituteHomeFrame>div:nth-last-child(0){
        margin-top:20vw;
    } */
    .InstituteHomeFrame>div button,
    .InstituteHomeFrame>div>a{  
        height:6vw;
        font-size:2.5vw;
        padding:0 6vw;
        line-height:6vw;
    }
    
    .InstituteHomeFrame>div>p{
        padding:3vw 2vw 2vw;

    }
}
@media (max-width:480px){
    .intstituteLogoFrame{
        height:15vw;
        padding:2.5vw 7vw;
    }
    .intstituteLogoFrame .intstituteLogo span{
        font-size:6vw;
        line-height:5vw;
    }
    .intstituteLogoFrame .intstituteLogoSignOut svg{
        font-size: 4vw;
        margin-left: 1vw;
    }
    .intstituteLogoFrame .nonAuthLogoFrame .nonAuthLogo2 {
        width: 100%;
        /* border:1px solid red; */
        display: flex;
        justify-content: flex-end;
        margin-left: 1vw;
    }
    .intstituteLogoFrame .nonAuthLogoFrame{
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
    .nonAuthLogo2>span, .nonAuthLogo2>a{
        margin-top: 2vw;
        color: #000000 !important;
    }
    .InstituteHomeFrame>.statisticsTabBtnGourp>div{
        font-size: 4.5vw;
        padding: 2.5vw;
        height: 10vw;
    }


    .nonAuthSignFrame .signFrame{
        width:100%;
        padding-top:30vw;
        margin-left:0;
    }
    .nonAuthSignFrame .signFrame h1{
        font-size:6vw;
    }
    .nonAuthSignFrame .signFrame .inputGroup input{
        height:12vw;
        font-size:4vw;
        line-height:12vw;
    }
    .nonAuthSignFrame .signFrame .inputGroup div,
    .nonAuthSignFrame .signFrame .inputGroup a{
        height:12vw;
        font-size:4vw;
        line-height:12vw;
    }
    .nonAuthSignFrame .signFrame .inputGroup a{
        margin-top:3vw;
    }







    .InstituteHomeFrame{
        padding:25vw 2vw 0;
    }
    .InstituteHomeFrame>span>h3{
        font-size:5vw;
        margin-bottom:2vw;
    }
    .InstituteHomeFrame>span>a{
        font-size:5.5vw;
    }
    .InstituteHomeFrame>p{
        font-size:4vw;
        margin-bottom:10vw;
    }

    .InstituteHomeFrame>div h5{
        height:15vw;
        line-height:15vw;
        font-size:4vw;
        padding:0 2vw;
    }
    .InstituteHomeFrame>div ul{
        padding:3vw;
    }
    .InstituteHomeFrame>div ul li{
        /* height:6vw; */
        line-height:6vw;
        font-size:3.5vw;
    }
    /* .InstituteHomeFrame>div:nth-last-child(0){
        margin-top:25vw;
    } */
    .InstituteHomeFrame>div button,
    .InstituteHomeFrame>div>a{  
        height:10vw;
        font-size:4vw;
        padding:0 10vw;
        line-height:10vw;
    }
    .InstituteHomeFrame>div>p{
        padding:4vw 2vw 3vw;

    }
}