@media (max-width:834px){
    .reviewBg{
        width:100%;
        height:65vw;
    }
    .reviewBgWarp{
        clip:rect(0, 100vw, 65vw, 0);
    }
    .reviewImage{
        position:absolute;
    }
    .reviewInFrame .reviewTextBox{
        width:30%;
    }
    .reviewInFrame .reviewTextBox li.contTitleText{
        font-size:4.5vw;
        line-height:5.5vw;
    }
    .reviewInFrame .reviewTextBox li.sectMenuText{
        font-size:2vw;
        line-height:3vw;
    }
    .reviewInFrame .ReviewContBox{
        width:70%;
        padding-left:4vw;
    }
    p.reviewTitle{font-size:2vw; padding-bottom:3vw; font-weight:600;}
    p.content{font-size:1.6vw; padding-bottom:2vw; line-height:2.3vw;}
    p.contentMobile{display:none;}
    p.writer{font-size:1.5vw;}



/*    
    .reviewOutFrame{
        min-height:auto;
        padding-top:10vw;
    }
    .reviewInFrame{
        padding-left:0;
        padding-right:0;
        padding-bottom:0;
    }    
    .reviewTextBox{       
        height:auto;
        position: inherit;
        padding-top:0;
        z-index:0;
        padding:4.7vw 7.8vw;
    }
    .reviewTextBox h2{
        font-size:5vw;  
        padding-bottom:4vw;
    }
  

    .ReviewContBox{
        height:auto;
        position:inherit;
        top:inherit;
        bottom:0;
        
        
    }
    .ReviewContBox .sectInFrame .swiperFrame{
        min-height:inherit;
    }
    .ReviewContBox .sectInFrame .swiperFrame .swiper-button-prev{
        left:4.2vw !important;
        top:50% !important;
    }
    .ReviewContBox .sectInFrame .swiperFrame .swiper-button-next{
        right:4.2vw !important;
        top:50% !important;
    }

    .reviewContFrame{
        padding:7.8vw 3vw 7.8vw;
        width:100%;
        
    }
    .reviewTextGroup{
        width:100%;
        margin-left:0;
        padding:10vw 12.5vw;
    }
    p.reviewTitle{font-size:2.1vw; padding-bottom:3vw; font-weight:600;}
    p.content{font-size:1.6vw; padding-bottom:2vw; line-height:2.5vw;word-break:keep-all;}
    p.contentPc{display:none;}
    p.contentMobile{display:block; }
    p.writer{font-size:1.4vw;}
  */



}



@media (max-width:480px){
    .reviewBg{
        width:100%;
        height:100vh;
    }
    .reviewBgWarp{
        clip:rect(0, 100vw, 100vh, 0);
    }
   .reviewInFrame{
        width:100%;
        height:100%;
        flex-direction: column;
        justify-content: flex-start;
        padding:0 5.2vw;
    }
    .reviewInFrame .reviewTextBox{
        width:100%;
        height:15%;
        
    }
    .reviewInFrame .reviewTextBox li.contTitleText{
        font-size:8vw;
        line-height:10vw;
    }
    .reviewInFrame .reviewTextBox li.sectMenuText{
        font-size:4vw;
        line-height:5vw;
        padding-top:2vw;
    }
    .reviewInFrame .ReviewContBox{
        width:100%;
        height:90%;
        padding-left:0;
    }
    /* .reviewContFrame{
        width:100% !important;
    } */
    .reviewContFrame{
        padding:15vw 0;
    
    }
    .reviewTextGroup{
        padding:7vw 5vw;
    }
    p.reviewTitle{font-size:4.5vw; padding-bottom:5vw; }
    p.content{font-size:3.5vw; padding-bottom:8vw; line-height:5.5vw;}
    p.writer{font-size:3.5vw;}
    /* .reviewOutFrame{
        width:100%;
        height:100%;
        background-size:cover;
        background-attachment:fixed;
        display:flex;
        
    }
    .reviewContFrame{
        padding:7.8vw 3vw 0;
        
    }
    .reviewTextBox h2, .galleryTextBox h2{
        font-size:10vw;  
        padding-bottom:6vw;
    }
    
    .reviewpagerGroup li, .gallerypagerGroup li{
        font-size:5vw;
        line-height:8vw;
    }      
    
    p.reviewTitle{font-size:4.2vw; padding-bottom:5vw; font-weight:600; word-break: keep-all;}
    p.content{font-size:4vw; padding-bottom:4vw; line-height:6.5vw; word-break: keep-all; }
    p.writer{font-size:3.7vw; word-break: keep-all;}
  */

}