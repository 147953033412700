/* res css */

@media (max-width:834px){
    .clOutFrame{
        height:auto;
        padding-top:10vw;
        padding-bottom:10vw;
    }
    .clInFrame{
        height:50vh;
        padding-bottom:0;
        padding-top:8vw;
    }
    .cl1stInFrame{
        height:auto;
    }
    .cl1stInFrame .TitleBox{
        padding-right:2vw;
    }
    .cl1stInFrame .TitleBox ul li.sectMenuText{
        font-size:2vw;
    }
    .cl1stInFrame .TitleBox ul li.contTitleText{
        font-size:4.5vw;
        line-height:5.5vw;
        padding:2vw 0 3vw;
    }
    .cl1stInFrame .TitleBox ul li.contentText{
        font-size:1.8vw;
        padding-bottom:5vw;
    }
    .cl1stInFrame .TitleBox ul li.contentText span{
        line-height:2.5vw;
    }
    .cl1stInFrame .TitleBox ul li.contentText span:nth-child(1),
    .cl1stInFrame .TitleBox ul li.contentText span:nth-child(2){
        margin-bottom:1.5vw;
    }
    .cl1stInFrame .TitleBox ul li.buttonText a{
        font-size:1.6vw;
        height:5vw;
        line-height:5vw;
        padding:0 3vw;
    }


    .cl1stInFrame .contBox{
        padding:0;
    }
    .cl1stInFrame .contBox .contImage{
        height:67vw;
    }

    .cl1stInFrame .contBox .contText{
        padding-left:2vw;
    }
    .cl1stInFrame .contBox .contText>ul>li>ul{
        margin-bottom:2vw;
    }
    .cl1stInFrame .contBox .contText>ul>li>ul>li{
        font-size:1.5vw;
        line-height:2.5vw;
    }
    .cl1stInFrame .contBox .contText>ul>li>ul>li:nth-child(1){
        font-size:2.5vw;
        line-height:3vw;
    }
    .cl1stInFrame .contBox .contText>ul>li:nth-last-child(1)>ul>li{
        font-size:2vw;
        padding-bottom:0;
        padding-top:1vw;
    }
    /* .clOutFrame{
        height:auto;
        padding-top:10vw;
    }
    .clInFrame{
        height:auto;
        background:#ffffff;
        padding-left:0;
        padding-right:0;
        padding-bottom:0;
    }    
    .clTextBox{       
        height:auto;
        position: inherit;
        padding-top:0;
       
        background:#ffffff;
        z-index:0;
        padding:4.7vw 12.5vw;
    }
    .clTextBox h2{
        font-size:5vw;  
        padding-bottom:4vw;
    }
    .clpagerGroup{
        padding-bottom:3vw;
    }
    .clpagerGroup li{
        font-size:2vw;
        line-height:4vw;
    }      
    .clTextBox .btnGroup a{
        height:5vw;
        font-size:2vw;
        line-height:5vw;
        padding: 0 3vw;
    }
    

    .clContBox{
        height:auto;
        position:inherit;
        top:inherit;
        bottom:0;
       
    }
    .clContBox ul.slider li img.pcImage{
        display:none;
    }
    .clContBox ul.slider li img.mobileImage{
        display:block;
        width:100%;
    } */


    
}
@media (max-width:480px){
    .clOutFrame{
        padding-top:20vw;
        padding-bottom:30vw;
    }
    .clInFrame{
        height:auto;
        flex-direction: column;
    }
    
    .cl1stInFrame .TitleBox{
        width:100%;
        padding-right:0;
    }

    .cl1stInFrame .TitleBox ul li.sectMenuText{
        font-size:3.8vw;
    }
    .cl1stInFrame .TitleBox ul li.contTitleText{
        font-size:8vw;
        line-height:10vw;
        padding:3vw 0 5vw;
    }
    .cl1stInFrame .TitleBox ul li.contentText{
        font-size:4vw;
        padding-bottom:5vw;
    }
    .cl1stInFrame .TitleBox ul li.contentText span{
        line-height:5vw;
    }
    .cl1stInFrame .TitleBox ul li.contentText span:nth-child(1), 
    .cl1stInFrame .TitleBox ul li.contentText span:nth-child(2){
        margin-bottom:2vw;
    }
    .cl1stInFrame .TitleBox ul li.buttonText a{
        font-size:4vw;
        height:10vw;
        line-height:10vw;
        padding:0 5vw;
    }

    .cl1stInFrame .contBox{
        width:100%;
        display:inherit;
        flex-direction: inherit;
        position: relative;
    }
    .cl1stInFrame .contBox .contImage{
        width:100%;
        height:80vh;
        padding-top:5vw;
    }
    .cl1stInFrame .contBox .contImage span{
        background-position:37% 50%;
    }
    .cl1stInFrame .contBox .contText{
        width:100%;
        padding:10vw 6vw 5vw;
        position: absolute;
        top:0;
        left:0;
    }
    .cl1stInFrame .contBox .contText>ul>li>ul>li{
        font-size:4vw;
        line-height:5.5vw;
        color:#ffffff;
        font-weight:500;
    }
    .cl1stInFrame .contBox .contText>ul>li>ul>li:nth-child(1){
        font-size:5.5vw;
        line-height:6.5vw;
        padding-bottom:1vw;
        color:#1d4f81;
        font-weight:600;
    }
    .cl1stInFrame .contBox .contText>ul>li:nth-last-child(1)>ul>li{
        font-size:5.3vw;
        line-height:6vw;
        padding-bottom:0;
        padding-top:1vw;
        color:#ffffff;
    }
    /* .clTextBox h2{
        font-size:10vw;  
        padding-bottom:6vw;
    }
    
    .clpagerGroup li{
        font-size:3.6vw;
        line-height:7vw;
    }      
    
    .clTextBox .btnGroup a{
        height:10vw;
        font-size:3.5vw;
        line-height:10vw;
        padding: 0 6vw;
    } */
}