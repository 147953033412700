.invalidBtn{
    color:gray;
    border : 1px solid gray
}
.validBtn{
    color : black;
    border: 1px solid black
}




/* adminDigitalBookFrame */
.adminDigitalBookFrame{
    width:100%;
    height:auto;
}
.adminDigitalBookFrame h3{
    font-size:1.8vw;
    font-weight:600;
    margin-left:5vw;
    margin-right:5vw;
    line-height:5vw;
    margin-bottom:2vw;
    border-bottom:1px solid #000000;
}
.adminDigitalBookFrame h5{
    font-size:1.5vw;
    font-weight:600;
    margin-left:5vw;
    margin-right:5vw;
    padding-bottom:1vw;
}

.bookIDFrame{
    display:flex;
    flex-direction: column;
    margin-left:5vw;
    margin-bottom:2vw;
}
.bookIDFrame>div{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items:center;
    margin-bottom:0.5vw;
}
.bookIDFrame>div>p{
    font-size:1.2vw;
    margin-right:1vw;
}
.bookIDFrame>div>input{
    width:30vw;
    height:2.5vw;
    line-height:2.5vw;
    font-size:1.2vw;
    padding:0 0.5vw;
    border:1px solid #000000;
    background:#ffffff;
}
.bookIDFrame>div>div{
    width:auto;
    height:100%;
}
.bookIDFrame>div>div>form{
    width:100%;
    height:100%;
    padding:0.5vw 0;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.bookIDFrame>div>div>form>input{
    font-size:1vw;
    line-height:2vw;
    border:1px solid #eeeeee;
}
.bookIDFrame>div>div>form>button{
    height:2.5vw;
    line-height:2.5vw;
    font-size:1vw;
    padding:0 1vw;
    border-width:1px;
}
.bookIDFrame>div>div:nth-last-child(1){
    height:2.5vw;
    display:flex;
    flex-direction:row;
    align-items: center;
}
.bookIDFrame>div>div>div{
    width:2.5vw;
    height:2.5vw;
    overflow:hidden;
    border:1px solid #000000;
    position: relative;
}
.bookIDFrame>div>div>div>img{
    height:2.5vw;
    position: absolute;
    top:0;
    left:50%;
    transform: translate(-50%, 0%);
}
.bookIDFrame>div>div>span{
    font-size:1vw;
    height:2.5vw;
    line-height:2.5vw;
    padding:0 1vw;
    margin-left:0.5vw;
    border:1px solid #000000;
    cursor: pointer;
}
.bookIDFrame>div>div>span:hover{
    border:1px solid rgb(8, 46, 77);
    background:rgb(8, 46, 77);
    color:#ffffff;
}
/* DigitalbookPageTool */
.makingPageFrame .setPageTitle{
    margin-left:5vw;
    margin-right:5vw;
    margin-bottom:1vw;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.makingPageFrame .setPageTitle p{
    width:10%;
    height:2vw;
    line-height:2vw;
    font-size:1.2vw;
    padding-right:1vw;
}
.makingPageFrame .setPageTitle input{
    width:90%;
    height:2.5vw;
    line-height:2.5vw;
    padding:0 0.5vw;
    font-size:1.2vw;
    border:1px solid #000000;
}
/* .makingPageFrame .setIndexFrame{
    display: flex;
    justify-content: flex-start;
} */
.makingPageFrame .setIndex,
.makingPageFrame .setIndex2{
    margin-left:5vw;
    margin-right:5vw;
    margin-bottom:2vw;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* width:45%; */
}

.makingPageFrame .setIndex p,
.makingPageFrame .setIndex2 p{
    width:10%;
    height:2vw;
    line-height:2vw;
    font-size:1.2vw;
    margin-right:0.5vw;
}

.makingPageFrame .setIndex>div,
.makingPageFrame .setIndex2>div{
    display:flex;
    flex-direction: row;
}
.makingPageFrame .setIndex>div>div,
.makingPageFrame .setIndex2>div>div{
    display:flex;
    flex-direction: row;
    align-items:center;
    margin-right:1.5vw;
}
.makingPageFrame .setIndex>div>div input,
.makingPageFrame .setIndex2>div>div input{
    height:1.5vw;
    width:1.5vw;
    margin-right:0.5vw;
}
.makingPageFrame .setIndex>div>div span,
.makingPageFrame .setIndex2>div>div span{
    font-size:1vw;
    /* margin-right:1vw; */
}


.makingPageFrame .setBg{
    margin-left:5vw;
    margin-right:5vw;
    margin-bottom:2vw;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.makingPageFrame .setBg p{
    width:10%;
    font-size:1.2vw;
    padding-right:1vw;
}
.makingPageFrame .setBg div.setBgContent{
    width:90%;
    display:flex;
    flex-direction:row;
    align-items: center;
}
.makingPageFrame .setBg div.setBgContent div.bgColorFrame{
    width:30vw;
    margin-right:1vw;
}
.makingPageFrame .setBg div.setBgContent div.bgColorFrame>div{
    box-shadow:inherit !important;
    border:1px solid #000000 !important;
    border-radius: 0 !important;
}
.makingPageFrame .setBg div.setBgContent div.bgColorFrame>div>div{
    padding:0.5vw 1vw !important;
}
.makingPageFrame .setBg div.setBgContent div.bgColorFrame>div>div>span:nth-child(1)>div{
    border:1px solid #eeeeee;
}
.makingPageFrame .setBg div.setBgContent div.bgColorFrame>div>div>span>div{
    width:2vw !important;
    height:2vw !important;
    margin:0 0.5vw 0 0 !important;
}
.makingPageFrame .setBg div.setBgContent div.bgColorFrame>div>div>div:nth-last-child(3){
    width:2vw !important;
    height:2vw !important;
}
.makingPageFrame .setBg div.setBgContent div.bgColorFrame>div>div>div:nth-last-child(2){
    height:2vw !important;
    position:inherit !important;
}
.makingPageFrame .setBg div.setBgContent div.bgColorFrame>div>div>div>input{
    height:2vw !important;
    width:5.5vw !important;
    font-size:1vw !important;
    box-sizing:inherit !important;
}


.makingPageFrame .setBg div.setBgContent div.bgImageFrame{
    display:flex;
    flex-direction:row;
    align-items: center; 
}
.makingPageFrame .setBg div.setBgContent div.bgImageFrame div form{
    display:flex;
    flex-direction:row;
    align-items: center;
}
.makingPageFrame .setBg div.setBgContent div.bgImageFrame div form input{
    width:10vw;
    line-height:2vw;
    font-size:1vw;
}
.makingPageFrame .setBg div.setBgContent div.bgImageFrame div form button{
    font-size:1vw;

}
.makingPageFrame .setBg div.setBgContent div.bgImageFrame button{
    height:2.5vw;
    line-height:2.5vw;
    font-size:1vw;
    padding:0 1vw;
}
.bgUploadBx button{
    outline: none;
    padding:0 1vw;
    line-height:3vw;
    margin-right:0.5vw;
    background:#e6e6e6;
    border-width:1px;
}
.makingPageFrame button{
    outline: none;
    padding:0 1vw;
    line-height:3vw;
    margin-right:0.5vw;
    background:#e6e6e6;
    border-width:1px;
}




.makingPageFrame .setCont{
    margin-left:5vw;
    margin-right:5vw;
    margin-bottom:2vw;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.makingPageFrame .setCont p{
    width:10%;
    font-size:1.2vw;
    padding-right:1vw;
}
/* .makingPageFrame .setCont div{
    width:90%;
} */
.makingPageFrame .setCont div button{
    height:2.5vw;
    line-height:2.5vw;
    border:none;
    outline: none;
    border:1px solid #000000;
    padding:0 1.5vw;
    margin-right:0.5vw;
    background:#f7faff;
    font-size:1vw;
}
.makingPageFrame .setCont button:hover{
    background:#0f1322;
    color:#ffffff;
}

.makingPageFrame .setPage{
    margin-left:5vw;
    margin-right:5vw;
    margin-bottom:2vw;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.makingPageFrame .setPage p{
    width:10%;
    font-size:1.2vw;
    padding-right:1vw;
}
.makingPageFrame .setPage .setPageFrame{
    width:90%;
    height:2.5vw;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
}
.makingPageFrame .setPage .setPageFrame div{
    height:100%;
}
.makingPageFrame .setPage .setPageContent button{
    border:none;
    outline: none;
    border:1px solid #000000;
    padding:0 0.8vw;
    margin-right:0.5vw;
    background:#f9f6ff;
    font-size:1vw;
    line-height:2.5vw;
}
.makingPageFrame .setPage .setPageContent button:hover{
    background:#0f1322;
    color:#ffffff;
}
.makingPageFrame .setPage .setPagePager{
    display:flex;
    flex-direction: row;
    align-items: center;
}
.makingPageFrame .setPage .setPagePager span{
    font-size:1.2vw;
    display:inline-block;
    height:2.5vw;
    line-height:2.5vw;
    padding:0 0.5vw;
}
.makingPageFrame .setPage .setPagePager button{
    border:none;
    outline: none;
    padding:0 0.5vw;
    margin:0;
    background:#ffffff;
    color:#959595;
    font-size:2.5vw;
    line-height:2.5vw;
    cursor: pointer;
}

.makingPageFrame .setCont .setContFrame{
    width:90%;
    height:2.5vw;
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
}
.makingPageFrame .setCont .setContFrame div{
    height:100%;
}
.makingPageFrame .setCont .setPagePager button:hover{
    color:#000000;
}

.makingPageFrame .setCont .setPagePager{
    display:flex;
    flex-direction: row;
    align-items: center;
}
.makingPageFrame .setCont .setPagePager span{
    font-size:1.2vw;
    display:inline-block;
    height:2.5vw;
    line-height:2.5vw;
    padding:0 0.5vw;
}
.makingPageFrame .setCont .setPagePager button{
    border:none;
    outline: none;
    padding:0 0.5vw;
    margin:0;
    background:#ffffff;
    color:#959595;
    font-size:2.5vw;
    line-height:2.5vw;
    cursor: pointer;
}

.makingPageFrame .setPage .setPagePager button:hover{
    color:#000000;
}


/*output*/
.makingPageFrame .setOutput1{
    display:flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
}
.makingPageFrame .setOutput1 div.output1Frame{
    width:551px;
    height:780px;
    border:1px solid #dedede;
    overflow:auto;
    overflow-x:hidden;
    /* position:relative; */
    /* padding:1vw; */
    margin-right:1vw;
}

.makingPageFrame .setOutput1 div.output1Frame.scrollbarHide{
    -ms-overflow-style:none;
    scrollbar-width:none;
  }
.makingPageFrame .setOutput1 div.output1Frame.scrollbarHide::-webkit-scrollbar{
  display:none;
}
.makingPageFrame .setOutput1 div.output1Frame.scrollbarShow::-webkit-scrollbar{
    width:5px;
}
.makingPageFrame .setOutput1 div.output1Frame.scrollbarShow::-webkit-scrollbar-thumb{
    background-color:#b3c7db;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
  }
.makingPageFrame .setOutput1 div.output1Frame.scrollbarShow::-webkit-scrollbar-track{
    background:transparent;
    border-radius:5px;
    box-shadow:inset 0px 0px 3px #ffffff;
}
.makingPageFrame .setOutput1 div.output1Frame>div:nth-child(1){
    /* position: absolute;
    top:0;
    left:0; */
    padding:1vw;
    width:100%;
    height:auto;
}
/* .makingPageFrame .setOutput1>div.output1Frame>div:nth-child(1) .bookContFrame{
    position:inherit;
} */

.makingPageFrame .output2Frame>form>div{
    display:flex;
    flex-direction: row;
}


.adminPreviewBg{
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.9);
    position:fixed;
    top:0;
    left:0;
    z-index:1000;
}
.adminPreviewBg h5{
    display:none;
}

.makingPreviewBtn{
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-left:5vw;
    position: absolute;
    top:2vw;
    left:0;
    z-index:1001;
}

.makingPreviewBtn div{
    padding:0 1vw;
    border:1px solid #000000;
    background:#ffffff;
    height:2.5vw;
    line-height:2.5vw;
    font-size:1vw;
    cursor: pointer;
}
.makingPreviewBtn div:nth-child(1){
    background:#000000;
    color:#ffffff;
}
.makingPreviewBtn div:hover{
    background:#0d3258;
    color:#ffffff;
}

.adminPreviewBg .pcPreviewFrame .bookPageFrame .headingTextBox{
    margin:2vw 0 5vw !important;
    border:1px solid #000000;
    overflow:auto;
}
.adminPreviewBg .pcPreviewFrame .bookPageFrame .headingTextBox::-webkit-scrollbar{
    width:5px;
}
.adminPreviewBg .pcPreviewFrame .bookPageFrame .headingTextBox::-webkit-scrollbar-thumb{
    background-color:#b3c7db;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
}
.adminPreviewBg .pcPreviewFrame .bookPageFrame .headingTextBox::-webkit-scrollbar-track{
    background:transparent;
    border-radius:5px;
    box-shadow:inset 0px 0px 3px #ffffff;
}


.adminPreviewBg .moPreviewFrame .bookPageFrame .headingTextBox{
    width:100% !important;
    margin:2vw 0 5vw !important;
    border:1px solid #000000;
    overflow:auto;
}
.adminPreviewBg .moPreviewFrame .bookPageFrame .headingTextBox::-webkit-scrollbar{
    width:5px;
}
.adminPreviewBg .moPreviewFrame .bookPageFrame .headingTextBox::-webkit-scrollbar-thumb{
    background-color:#b3c7db;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
}
.adminPreviewBg .moPreviewFrame .bookPageFrame .headingTextBox::-webkit-scrollbar-track{
    background:transparent;
    border-radius:5px;
    box-shadow:inset 0px 0px 3px #ffffff;
}


.adminPreviewBg .pcPreviewFrame .bookPageFrame .scene{
    margin:2vw auto 5vw;
}
.adminPreviewBg .moPreviewFrame .bookPageFrame .mobileScene{
    margin:2vw auto 5vw;
    /* width:575px; */
    height:780px;
}
.adminPreviewBg .utilityBtnGroup{
    bottom:0;
    margin-bottom:0;
}








/*output1Frame contents*/
.output1Frame .bookContFrame{
    padding:1vw;
    margin-bottom:0.5vw;
    border-bottom:1px dashed #dedede;
}
.output1Frame .bookContFrame:nth-last-child(1){
    border-bottom:0;
}

.output1Frame .textEditorFrame{
    flex-direction:column;
}
.output1Frame .textEditorFrame .textEditorBg{
    margin-bottom:0.5vw;
    display:flex;
    flex-direction:column;
    width:100%;
}
.output1Frame .textEditorFrame .textEditorBg p{
    font-size:1.5rem;
    line-height:1;
    padding-bottom:0.5vw;
    color:#cccccc;
    text-align:right;
}
.output1Frame .textEditorFrame .textEditorBg>div{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
}
.output1Frame .textEditorFrame .textEditorBg>div>div{
    display:flex;
    flex-direction:row;
    align-items: center;
}
.output1Frame .textEditorFrame .textEditorBg>div>div>span{
    font-size:1.5rem;
    margin:0 1vw 0 0.5vw;
    cursor: pointer;
}
.output1Frame .textEditorFrame .textEditorBg>div>input{
    width:100%;
    height:2vw;
    font-size:1.5rem;
    padding:0 0.5vw;
    border:1px solid #000000;
    border-radius: 0;
}

.output1Frame .textareaFrame{
    flex-direction:column;
    width:100%;
}
.output1Frame .textareaFrame input{
    width:100%;
    height:2vw;
    font-size:1.2rem;
    border:1px solid #000000;
    border-bottom:0;
    font-size:1.4rem;
    padding:0 0.5vw;
}
.output1Frame .textareaFrame select{
    width:100%;
    height:2vw;
    padding:0 0.5vw;
    border:1px solid #000000;
    font-size:0.9vw;
    cursor: pointer;
}

.output1Frame .imageFrame{
    flex-direction:column;
}
.output1Frame .imageFrame>div{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    height:2vw;
    margin-bottom:0.3vw;
}
.output1Frame .imageFrame>div>div.bgUploadBx{
    height:100%;
    width:100%;
    /* margin-right:1%; */
}
.output1Frame .imageFrame>div>div.bgUploadBx>form{
    height:100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
.output1Frame .imageFrame>div>div.bgUploadBx>form>input{
    line-height:1.5vw;
    width:65%;
    height:2vw;
    font-size:1vw;
}
.output1Frame .imageFrame>div>div.bgUploadBx>form>button{
    width:35%;
    height:2vw;
    line-height:2vw;
    font-size:1vw;
    border-width:1px;
    margin-right:0;
}
.output1Frame .imageFrame>div>button{
    width:30%;
    margin:0;
    line-height:2vw;
    border:1px solid #000000;
    background:#ffffff;
    font-size:1vw;
}
.output1Frame .imageFrame>div:nth-child(2){
    justify-content: space-between;
}

.output1Frame .imageFrame>div:nth-child(2)>div>input{
    height:2vw;
    width:3vw;
    font-size:1vw;
    padding:0 0.5vw;
    margin:0 0.5vw;
    border:1px solid #000000;
}
.output1Frame .imageFrame>div:nth-child(2)>div>input::-webkit-outer-spin-button,
.output1Frame .imageFrame>div:nth-child(2)>div>input::-webkit-inner-spin-button{
    -webkit-appearance:none;
    margin:0;
}
.output1Frame .imageFrame>div:nth-child(2)>div>p{
    display:inline-block;
    font-size:1vw;
}
.output1Frame .imageFrame>div:nth-child(2)>select{
    width:40%;
    margin-left:1%;
    font-size:1vw;
    padding:0 0.5vw;
}
.output1Frame .imageFrame>div:nth-child(2)>select:nth-of-type(1){
    width:30%;
}
.output1Frame .imageFrame>textarea::-webkit-scrollbar{
    display:none;
}
.output1Frame .imageFrame>textarea{
    resize:none;
    overflow:auto;
    -ms-overflow-style:none;
    scrollbar-width:none;
}

.output1Frame .videoFrame{
    flex-direction:column;
    width:100%;
}
.output1Frame .videoFrame div{
    display:flex;
    flex-direction:row;
    width:100%;
    height:2vw;
}
.output1Frame .videoFrame div input:nth-child(1){
    width:16vw;
    border:1px solid #000000;
    font-size:1vw;
    padding:0 0.5vw;
    margin-right:0.5vw;
}
.output1Frame .videoFrame div input:nth-child(2){
    border:1px solid #000000;
    font-size:1vw;
    padding:0 0.5vw;
}
.output1Frame .videoFrame div input::-webkit-outer-spin-button,
.output1Frame .videoFrame div input::-webkit-inner-spin-button{
    -webkit-appearance:none;
    margin:0;
}
.output1Frame .videoFrame div span{
    font-size:1vw;
    line-height:2vw;
    padding:0 0.5vw;
}
.output1Frame .videoFrame div select{
    width:10vw;
    font-size:1vw;
    padding:0 0.5vw;
    cursor: pointer;
}
.output1Frame .videoFrame p{
    height:auto;
    line-height:1.5vw;
    font-size:1vw;
    word-break: keep-all;
}

.output1Frame .emptySpaceFrame{
    font-size:1vw;
    height:2vw;
    line-height:2vw;
    margin-bottom:0;
    color:#959595; 
    padding:0 1vw;
}

.output1Frame .drawingToolFrame{
    flex-direction:column;
    width:100%;
}
.output1Frame .drawingToolFrame input{
    width:100%;
    height:2vw;
    font-size:1.2rem;
    border:1px solid #000000;
    font-size:1.4rem;
    padding:0 0.5vw;
}
.output1Frame .drawingToolFrame div{
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top:0.3vw;
}
.output1Frame .drawingToolFrame div span{
    font-size:1vw;
    line-height:2vw;
    padding-right:1vw;
}
.output1Frame .drawingToolFrame select{
    height:2vw;
    padding:0 0.5vw;
    border:1px solid #000000;
    font-size:1.3rem;
    cursor: pointer;
}

.output1Frame .inputTextFrame{
    flex-direction:column;
    justify-content: flex-start;
    width:100%;
    height:auto;
}
.output1Frame .inputTextFrame input{
    width:100%;
    height:2vw;
    font-size:1.2rem;
    border:1px solid #000000;
    border-bottom:0;
    padding:0 0.5vw;
}
.output1Frame .inputTextFrame div{
    width:100%;
    height:2vw;
    display:block;
    padding:0 1vw;
    border:1px solid #000000;
    font-size:.9vw;
    line-height:2vw;
    color:#959595;
}










.headingTextOutFrame{
    position:absolute;
    top:0;
    left:-100%;
    display:flex;
    flex-direction:row;
    justify-content: center;
    width:100%;
    height:100%;
    transition:left 1s;
    z-index:99;
}
.headingTextOutFrame.showHeader{
    left:0;
}
.headingTextOutFrame.hideHeader{
    left:-100%;
}


/* headingTextFrame */
.headingTextFrame{
    width:551px;
    height:780px;
    border:1px solid #eeeeee;
    background:#ffffff;
    font-size:0.9vw;
    padding:2vw;
    color:#000000;
    overflow:auto;
    -webkit-margin-right:calc(551px + 1vw);
    margin-right:calc(551px + 1vw);
    /* position:absolute;
    top:0;
    left:-100%; */
    /* transition:left 1s;
    z-index:999; */
}

.headingTextFrame::-webkit-scrollbar{
    width:5px;
}
.headingTextFrame::-webkit-scrollbar-thumb{
    background-color:#b3c7db;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
}
.headingTextFrame::-webkit-scrollbar-track{
    background:transparent;
    border-radius:5px;
    box-shadow:inset 0px 0px 3px #ffffff;
}
.headingTextFrame>div{
    height:auto;
    margin-bottom:0.5vw;
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;

}
.headingTextFrame>div:hover{
    border:1px dashed #959595;
}



.headingTextFrame span{
    cursor: pointer;
    height:auto;
    /* word-break: keep-all; */
    height:100%;
    border:1px solid #ffffff;
    font-size:1.2vw;
}
.headingTextFrame .heading1{
    /* font-size:1.2vw; */
    font-weight:600;
    color:#0d3258;
    line-height:1;
}
.headingTextFrame .heading2{
    /* font-size:1vw; */
    font-weight:600;
    color:#0d3258;
    line-height:1;
}
.headingTextFrame .heading3{
    /* font-size:0.9vw; */
    font-weight:600;
    line-height:1;
}
.headingTextFrame .noHeading{
    color:#000000;
    line-height:1;
    font-size:1vw;
}


.btnGroup{
    height:100%;
}
.btnGroup>span{  
    /* border-left:1px solid #dedede; */
    padding:0 0.5vw;
    font-size:1vw;
    line-height:1vw;
    height:100%;
    color:#dedede;
}
.btnGroup>span:hover{
    color:#000000;
    font-weight:600;
}



/* DigitalbookPageList */
.output2Frame{
    width:551px;
    height:780px;
    border:1px solid #000000;
}
.output2Frame>form{width:100%; height:100%;}
.output2Frame>form>div{width:100%; height:100%;}
.output2Frame>form>div>div{width:100%; height:100%;}
.makingPaperFrame{
    padding:1vw;
    overflow:auto;
    overflow-x: hidden;
}
.makingPaperFrame::-webkit-scrollbar{
    width:5px;
  }
.makingPaperFrame::-webkit-scrollbar-thumb{
    background-color:#b3c7db;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
}
.makingPaperFrame::-webkit-scrollbar-track{
    background:transparent;
    border-radius:5px;
    box-shadow:inset 0px 0px 3px #ffffff;
}
.bookContFrame{
    display:flex;
    position:relative;  
    width:100%;
    height:auto;
}
.bookContFrame>p{
    font-size:1.3rem;
    height:auto;
    line-height:1.8rem;
    padding:0.5vw 0;
    word-break: keep-all;
}
.bookContFrame>textarea{
    font-size:1.4rem;
    line-height:2.4rem;
    padding:0.5vw;
    word-break: keep-all;
    border-radius:0;
    border:1px solid #000000;
    word-break: keep-all;
}
.bookContFrame>span{
    position: absolute;
    right:0;
    top:0; 
}
.bookContFrame>span>span{
    background:rgba(255,255,255,0.9);
    border:1px solid #dedede;
    font-size:1.8rem;
    width:2vw;
    height:2vw;
    line-height:2vw;
    text-align: center;
    cursor: pointer;
    z-index:4;
}
.bookContFrame>span>span:hover{
    background:#000000;
    color:#ffffff;
    border:#000000;
}
.bookContFrame>span>span:nth-child(1):hover{
    background:#a01414;
    color:#ffffff;
    border:#a01414;
}
.bookContFrame>input{
    height:2.5vw;
    outline: none;
    font-size:1.4rem;
    line-height:3rem;
    padding:0 0.5vw;
    border:1px solid #000000;
    word-break: keep-all;
}
.inputTextFrame{flex-direction:column;}
.textareaFrame{flex-direction:column;}
.drawingToolFrame{flex-direction:column;}
.textEditorFrame{flex-direction:row;}
.sun-editor{
    word-break: keep-all;
    border:none;
}
.sun-editor .se-wrapper .se-wrapper-inner{
    min-height: inherit;
    padding:0;
    overflow:hidden;
}
.sun-editor-editable hr{
    margin:1vw 0;
}
.sun-editor-editable h1,
.sun-editor-editable h2,
.sun-editor-editable h3,
.sun-editor-editable h4,
.sun-editor-editable h5,
.sun-editor-editable h6{
    margin-right:1vw;
    margin-left:1vw;
    word-break: keep-all;
}
.sun-editor-editable p{
    margin-right:1vw;
    margin-left:1vw;
    margin-bottom:0;
    word-break: keep-all;
    /* line-height:1.5; */
}
.sun-editor-editable div{
    margin-right:1vw;
    margin-left:1vw;
}
.imageFrame{flex-direction:row;}
.imageFrame>p{
    font-size:1.2rem;
    width:100%;
    height:2vw;
    color:#c56e6e;
}
.imageFrame>div>span{
    font-size:1.2rem;
    line-height:1.5vw;
}

.videoFrame{flex-direction:row;}
.videoFrame>p{
    font-size:1.2rem;
    width:100%;
    height:2vw;
    color:#c56e6e;
}
.emptySpaceFrame{
    height:2vw;
}






.managementFrameTitle{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin:0 5vw 2vw;
    line-height:5vw;
    border-bottom :1px solid #000000;
}
.managementFrameTitle h5{
    font-size:1.8vw;
    font-weight:600;
    line-height:5vw;
}
.managementFrameTitle>span{
    cursor: pointer;
    font-size:1.5vw;
    line-height:5vw;
}
.managementFrame{
    margin:0 5vw;
    display:flex;
    flex-direction:column;
    justify-content:flex-start;

}
.managementFrame>div{
    margin-bottom:1vw;
    width:100%;
    position: relative;
}
.managementFrame>div>span{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.managementFrame>div>span>span{
    width:5vw;
    height:5vw;
    /* padding:0.5vw; */
    margin-right:1vw;
    overflow:hidden;
}
.managementFrame>div>span>span>img{
    height:100%;
    cursor: pointer;
    margin-left:50%;
    margin-top:50%;
    -webkit-transform:translate(-50%, -50%);
    transform:translate(-50%, -50%);
}


.managementFrame>div>span>p{
    width:72vw;
    font-size:1.2vw;
    line-height:2vw;
    word-break: keep-all;
    cursor: pointer;
    border-bottom:1px solid #dedede;
}

.managementFrame div .manageBtnGroup{
    position:absolute;
    bottom:1vw;
    right:0;
    display:inline-flex;
    flex-direction: row;
    align-items:center
}
.managementFrame div .manageBtnGroup .bookCopyBtn,
.managementFrame div .manageBtnGroup .bookDelBtn{
    font-size:1.5vw;
    line-height:1;
    cursor: pointer;
    color:#dedede;
   
    margin-left:1vw;
}
.managementFrame div .manageBtnGroup .bookCopyBtn:hover{color:#0110e0;}
.managementFrame div .manageBtnGroup .bookDelBtn:hover{color:#e00101;}

