.adminSalesFrame {
  padding: 0 5vw;
}
.adminSalesFrame > h3 {
  font-size: 1.8vw;
  font-weight: 600;
  line-height: 5vw;
  margin-bottom: 2vw;
  border-bottom: 1px solid #000000;
}

/* slpTabBtnGroup */
.adminSalesFrame > div > .slpTabBtnGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.adminSalesFrame > div > .slpTabBtnGroup div {
  font-size: 1.2vw;
  text-align: center;
  border: 1px solid #dedede;
  width: 34%;
  height: 4vw;
  line-height: 4vw;
  cursor: pointer;
  color: #aaaaaa;
  border-right: 0;
}
.adminSalesFrame > div > .slpTabBtnGroup div:hover {
  background: #eeeeee;
  color: #000000;
}
.adminSalesFrame > div > .slpTabBtnGroup div:nth-child(1) {
  border-right: 0;
  border-bottom: 0;
  color: #000000;
}
.adminSalesFrame > div > .slpTabBtnGroup div:nth-last-child(1) {
  border-right: 1px solid #dedede;
}


/* slpTabContGroup */
.adminSalesFrame > div > .slpTabContGroup {
  padding: 3vw 2vw;
  border: 1px solid #dedede;
  border-top: none;
  font-size: 1vw;
}

.salesListCate {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  border-bottom: 2px solid #dedede;
  padding-top: 2vw;
}
.salesListCate li {
  display: inline-block;
  font-size: 1vw;
  font-weight: 600;
  height: 2.5vw;
  line-height: 2.5vw;
  padding: 0 0.2vw;
}
.salesList1Cate li:nth-child(1){width: 8%;}
.salesList1Cate li:nth-child(2){width: 10%;}
.salesList1Cate li:nth-child(3){width: 20%;}
.salesList1Cate li:nth-child(4){width: 6%;}
.salesList1Cate li:nth-child(5){width: 5%;}
.salesList1Cate li:nth-child(6){width: 8%;}
.salesList1Cate li:nth-child(7){width: 8%;}
.salesList1Cate li:nth-child(8){width: 10%;}
.salesList1Cate li:nth-child(9){width: 9%;}
.salesList1Cate li:nth-child(10){width: 16%;}

.salesList2Cate li:nth-child(1){width: 8%;}
.salesList2Cate li:nth-child(2){width: 12%;}
.salesList2Cate li:nth-child(3){width: 15%;}
.salesList2Cate li:nth-child(4){width: 33%;}
.salesList2Cate li:nth-child(5){width: 10%;}
.salesList2Cate li:nth-child(6){width: 8%;}
.salesList2Cate li:nth-child(7){width: 7%;}
.salesList2Cate li:nth-child(8){width: 7%;}

.salesList3Cate li:nth-child(1){width: 10%;}
.salesList3Cate li:nth-child(2){width: 10%;}
.salesList3Cate li:nth-child(3){width: 10%;}
.salesList3Cate li:nth-child(4){width: 13%;}
.salesList3Cate li:nth-child(5){width: 27%;}
.salesList3Cate li:nth-child(6){width: 10%;}
.salesList3Cate li:nth-child(7){width: 10%;}
.salesList3Cate li:nth-child(8){width: 10%;}

.salesList4Cate li:nth-child(1){width: 15%;}
.salesList4Cate li:nth-child(2){width: 30%;}
.salesList4Cate li:nth-child(3){width: 5%;}
.salesList4Cate li:nth-child(4){width: 10%;}
.salesList4Cate li:nth-child(5){width: 10%;}
.salesList4Cate li:nth-child(6){width: 30%;}

.salesList5Cate li:nth-child(1){width: 8%; }
.salesList5Cate li:nth-child(2){width: 12%; }
.salesList5Cate li:nth-child(3){width: 16%;}
.salesList5Cate li:nth-child(4){width: 4%;}
.salesList5Cate li:nth-child(5){width: 10%;}
.salesList5Cate li:nth-child(6){width: 10%;}
.salesList5Cate li:nth-child(7){width: 12%;}
.salesList5Cate li:nth-child(8){width: 27%;}
.salesList5Cate li:nth-child(9){width: 15%;}

.salesList6Cate li:nth-child(1){width: 8%; }
.salesList6Cate li:nth-child(2){width: 12%; }
.salesList6Cate li:nth-child(3){width: 16%;}
.salesList6Cate li:nth-child(4){width: 10%;}
.salesList6Cate li:nth-child(5){width: 34%;}
.salesList6Cate li:nth-child(6){width: 20%;}

.salesList7Cate li:nth-child(1){width: 7%; }
.salesList7Cate li:nth-child(2){width: 40%; }
.salesList7Cate li:nth-child(3){width: 11%;}
.salesList7Cate li:nth-child(4){width: 11%;}
.salesList7Cate li:nth-child(5){width: 11%;}
.salesList7Cate li:nth-child(6){width: 20%;}



.viewMoreListBtn{
  border:1px solid #dedede;
  color:#959595;
  font-size:1vw;
  width:100%;
  height:2.5vw;
  line-height:2.5vw;
  text-align:center;
  margin-top:1vw;
  outline:0;
}
.viewMoreListBtn:focus{
  outline:0;
}
.viewMoreListBtn:hover{
  background:#aaaaaa;
  color:#ffffff;
}






.salesListFrame {
  border-top: 1px solid #dedede;
}
.salesListFrame:nth-child(1) {
  border: none;
}
.salesListFrame>.detailBox{
  min-height:5vw;
}
.salesListFrame>div>p{
  border:1px solid #dedede;
  height:2vw;
  line-height:2vw;
  font-size:0.9vw;
  text-align: center;
  margin-top:1vw;
  margin-bottom:2vw;
  background:#f8f8f8;
  color:#959595;
}
.salesListCont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.salesListCont li {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.9vw;
  min-height: 2.5vw;
  /* word-break: normal; */
  word-break:break-all;
  padding: 0.2vw 0.2vw;
}
.salesList1Cont li:nth-child(1){width: 8%;}
.salesList1Cont li:nth-child(2){width: 10%;}
.salesList1Cont li:nth-child(3){width: 20%;}
.salesList1Cont li:nth-child(4){width: 6%;}
.salesList1Cont li:nth-child(5){width: 5%;}
.salesList1Cont li:nth-child(6){width: 8%;}
.salesList1Cont li:nth-child(7){width: 8%;}
.salesList1Cont li:nth-child(8){width: 10%;}
.salesList1Cont li:nth-child(9){width: 9%;}
.salesList1Cont li:nth-child(10){width: 16%;}

.salesList2Cont li:nth-child(1){width: 8%;}
.salesList2Cont li:nth-child(2){width: 12%;}
.salesList2Cont li:nth-child(3){width: 15%;}
.salesList2Cont li:nth-child(4){width: 33%;}
.salesList2Cont li:nth-child(4):hover{color:orange; cursor: pointer;}
.salesList2Cont li:nth-child(5){width: 10%;}
.salesList2Cont li:nth-child(6){width: 8%;}
.salesList2Cont li:nth-child(7){width: 7%;}
.salesList2Cont li:nth-child(8){width: 7%;}

.salesList3Cont li:nth-child(1){width: 10%; align-items: inherit;}
.salesList3Cont li:nth-child(2){width: 10%;}
.salesList3Cont li:nth-child(3){width: 10%;}
.salesList3Cont li:nth-child(4){width: 13%;}
.salesList3Cont li:nth-child(5){width: 27%;}
.salesList3Cont li:nth-child(6){width: 10%;}
.salesList3Cont li:nth-child(7){width: 10%;}
.salesList3Cont li:nth-child(8){width: 10%;}


.salesList4Cont li:nth-child(1){width: 15%;  align-items: inherit;}
.salesList4Cont li:nth-child(2){width: 30%; }
.salesList4Cont li:nth-child(3){width: 5%;}
.salesList4Cont li:nth-child(4){width: 10%;}
.salesList4Cont li:nth-child(5){width: 10%;}
.salesList4Cont li:nth-child(6){width: 30%;}
.salesList4Cont li:nth-child(6) div{
  margin-left:auto;
}

.salesList5Cont li:nth-child(1){width: 8%; }
.salesList5Cont li:nth-child(2){width: 12%; }
.salesList5Cont li:nth-child(3){width: 16%;}
.salesList5Cont li:nth-child(4){width: 4%;}
.salesList5Cont li:nth-child(5){width: 10%;}
.salesList5Cont li:nth-child(6){width: 10%;}
.salesList5Cont li:nth-child(7){width: 12%;}
.salesList5Cont li:nth-child(8){width: 27%;}
.salesList5Cont li:nth-child(9){width: 15%;}

.salesList6Cont li:nth-child(1){width: 8%; }
.salesList6Cont li:nth-child(2){width: 12%; }
.salesList6Cont li:nth-child(3){width: 16%;}
.salesList6Cont li:nth-child(4){width: 10%;}
.salesList6Cont li:nth-child(5){width: 34%;}
.salesList6Cont li:nth-child(6){width: 20%;}

.salesList7Cont li:nth-child(1){width: 7%; }
.salesList7Cont li:nth-child(2){width: 40%; }
.salesList7Cont li:nth-child(3){width: 11%;}
.salesList7Cont li:nth-child(4){width: 11%;}
.salesList7Cont li:nth-child(5){width: 11%;}
.salesList7Cont li:nth-child(6){width: 20%;}

.salesListCont li select {
  width: 90%;
  height: 2.5vw;
  line-height: 2.5vw;
  cursor: pointer;
}
.salesList4Cont li select{
  width:40%;
  margin-right:0.5vw;
}
.salesListCont li div {
  height: 2.5vw;
  line-height: 2.5vw;
  cursor: pointer;
  border: 1px solid #000000;
  padding: 0 1vw;
  margin-left:auto;
}
.salesList6Cont li div {
  margin-right: 0;
}
.salesListCont li span {
  height: 2.5vw;
  line-height: 2.5vw;
}
.salesList6Cont li span {
  padding: 0 1vw;
  margin-right:0.5vw;
}
.salesListCont li div:hover{
  background:#000000;
  color:#ffffff;
}
.salesListCont li button {
  height: 2.5vw;
  line-height: 2.5vw;
  cursor: pointer;
  border: 1px solid #000000;
  padding: 0 1vw;
}
.salesList6Cont li button {
  margin-right:0.5vw;
}
.salesListCont li button:hover{
  background:#000000;
  color:#ffffff;
}
.salesList5Cont>li>input {
  width: 20% !important;
}
.salesList5Cont li button {
  height: 2.5vw;
  line-height: 2.5vw;
  cursor: pointer;
  border: 1px solid #000000;
  padding:0 0.5vw;
}
.salesList5Cont li button:nth-last-child(2){
  background:#000000;
  color:#ffffff;
}
.salesList5Cont li button:nth-last-child(2):hover{
  background:#c56e6e;
  border:1px solid #c56e6e;
}
.salesList5Cont li button:nth-last-child(1){
  margin-left:0.5vw;
  
}
.salesListCont li div.sendMsgBtn{
  width:100%;
  margin-right:0;
  padding:0;
  text-align:center;
}
.salesListCont>li>input{
  width:100%;
  height:2.5vw;
  border:1px solid #000000;
  padding:0 0.5vw;
}




/* salesListDetailGroup */
.salesListDetailGroup{
  width:100%;
  height:auto;
  padding:1vw 0 2vw;
}
.salesListDetailGroup table{
  width:100%;
  height:auto;
  font-size:0.8vw;
  
}
.salesListDetailGroup table th{
  height:2vw;
  line-height:2vw;
  padding:0 0.5vw;
  border:1px solid #dedede;
  background:#f8f8f8;
  font-size:0.9vw;
  font-weight:normal;
}
.salesListDetailGroup table td{
  padding:0 0.5vw;
  height:2.5vw;
  border:1px solid #dedede;
}
.salesListDetailGroup table td>span{cursor: pointer;}
.salesListDetailGroup table td>span span:nth-last-child(1){
  margin-left:0.5vw;
}
.salesListDetailGroup table td>span:hover{
  color:orange;
}
.salesListDetailGroup table td select{height:1.8vw; line-height:1.8vw; cursor: pointer;}
.salesListDetailGroup table td input{height:1.8vw; line-height:1.8vw; border:1px solid #aaaaaa; padding:0 0.5vw;}
.salesListDetailGroup table td button{ 
  border:1px solid #aaaaaa;
  width:100%;
  height:1.8vw;
  line-height:1.8vw;
  background:#f5f5f5;
}
.salesListDetailGroup table td button:hover{
  background:rgba(0,0,0,0.8);
  color:#ffffff;
  border:1px solid rgba(0,0,0,0.8);
}



.salesDetailPopBg{
  width:100vw;
  height:100vh;
  background:rgba(0,0,0,0.9);
  position:fixed;
  top:0;
  left:0;
  z-index:99;
}
.salesDetailPopFrame{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  background:#ffffff;
  border:1px solid #000000;
  width:50vw;
  padding:2vw 3vw;
}
.salesDetailPopFrame .salesDetailPopTitle{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content: space-between;
  padding-bottom:1vw;
  border-bottom:1px solid #000000;
  margin-bottom:2vw;
}

.salesDetailPopFrame .salesDetailPopTitle h5{
  font-size:1.5vw;
  font-weight:600;
}
.salesDetailPopFrame .salesDetailPopTitle div{
  font-size:2vw;
  font-weight:600;
  cursor: pointer;
}
.salesDetailPopFrame>p{
  font-size:1.2vw;
  padding-bottom:1vw;
  display:flex;
  flex-direction:row;
}
.salesDetailPopFrame>p>span:nth-child(1){
  font-weight:600;
  margin-right:1vw;
}
.salesDetailPopFrame>div:nth-last-child(1){
  font-size:1vw;
  width:100%;
}




/*sendMsgContFrame*/
.sendMsgContBg{
  width:100vw;
  height:100vh;
  background:rgba(0,0,0,0.9);
  position:fixed;
  top:0;
  left:0;
  z-index:2000;
}
.sendMsgContFrame{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  background:#ffffff;
  border:1px solid #000000;
  width:50vw;
  padding:2vw 3vw;
}
.sendMsgContFrame .sendMsgPopTitle{
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content: space-between;
  padding-bottom:1vw;
  border-bottom:1px solid #000000;
  margin-bottom:2vw;
}

.sendMsgContFrame .sendMsgPopTitle h5{
  font-size:1.5vw;
  font-weight:600;
}
.sendMsgContFrame .sendMsgPopTitle div{
  font-size:2vw;
  font-weight:600;
  cursor: pointer;
}
.sendMsgContFrame>p{
  font-size:1vw;
  padding-bottom:1vw;
}


.selectMsgGroup{
  display:flex;
  flex-direction: row;
  align-items: center;
  margin-bottom:1vw;
  padding:1vw 0;
  border-top:1px solid #dedede;
  border-bottom:1px solid #dedede;
}
.selectMsgGroup li{
  margin-right:1.5vw;
  display:flex;
  flex-direction: row;
  align-items: center;
}
.selectMsgGroup li input{
  width:1.1vw;
  height:1.1vw;
  margin-right:0.5vw;
  cursor: pointer;
}
.selectMsgGroup li span{
  font-size:0.8vw;
  cursor: pointer;
}



.longSentenceTitle{
  display:flex;
  flex-direction: row;
  margin-bottom:0.2vw;
}
.longSentenceTitle>span{
  width:20%;
  line-height:2vw;
  font-weight:600;
}
.longSentenceTitle input{
  border:1px solid #000000;
  font-size:1vw;
  line-height:2vw;
  padding:0 0.5vw;
  width:80%;
}



.longSentencesBx textarea{
  width:100%;
  height:6vw;
  line-height:2vw;
  resize:none;
  padding:0 0.5vw;
  overflow:hidden;
}

.paragraphBx{
  margin-bottom:0.2vw;
  border-bottom:1px solid #dedede;
  padding-bottom:1vw;
  margin-bottom:0.5vw;
}


.wordLimitText{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
}
.sendMsgPopBtn{
  padding-top:1vw;
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
}
.sendMsgPopBtn button{
  border-width:1px;
  line-height:2.5vw;
  padding:0 2vw;
}















