@media (max-width:1024px){
    .arActivityPopImage{
        width:80vw;
        height:80vh;
    }
    .galleryPopImage2{
        min-width:80vw;
        min-height:50vw;
        max-height:80vh;
    }
    /* .galleryPopImage2>img{
        width:80%
    } */
    .galleryPoptitle{
        font-size: 4vw;
        top:-12%;
    }
    .galleryPoptitle .letcureSubTitle{
        font-size: 3vw;
        margin-top: 1%;
    }
}
@media (max-width:834px){
    .galleryOutFrame{
        /* height:100vh; */
        height:auto;
        padding-top:10vw;
        padding-bottom:10vw;
    }
    .galleryInFrame{
        height:70vw;
    }
    .galleryInFrame .galleryTitleBox{
        width:32%;
        padding-left:0;
    }
    .galleryInFrame .galleryContBox{
        width:68%;
        padding-left:4vw;
    }
    .galleryInFrame .galleryTitleBox ul li.sectMenuText{
        font-size:2vw;
    }
    .galleryInFrame .galleryTitleBox ul li.contTitleText{
        font-size:4.5vw;
        line-height:5.5vw;
        padding:2vw 0 3vw;
    }
    .galleryInFrame .galleryTitleBox ul li.contentText{
        font-size:1.8vw;
        padding-bottom:5vw;
        line-height:5vw;
    }
    .galleryInFrame .galleryTitleBox ul li.contentText span{
        padding:0;
    }


    .arActivityPopImage{
        width:85vw;
        min-height:50vw;
    }
    .galleryPopDel{
        /* top:-12%;
        right:-5%; */
        font-size:6vw;
        line-height:1;
    }
    .galleryPopBox>span{
        padding:0;
    }
    .galleryPopPrev{
        /* top:42%;
        left:-9%; */
        left:1vw;
        font-size:6vw;
    }
    .galleryPopNext{
        /* top:42%;
        right:-9%; */
        right:1vw;
        font-size:6vw;
    }
    /* .userGallPopPrev{
        left:-5%;
    }
    .userGallPopNext{
        right:-5%;
    } */
    /* .galleryOutFrame{
        height:100vh;
        padding-top:10vw;
        overflow: hidden;
        
    }
    .galleryInFrame{
        background:#ffffff;
        
        padding-left:0;
        padding-right:0;
        padding-bottom:0;
    }    
    .galleryTextBox{       
        height:auto;
        position: inherit;
        padding-top:0;
       
        background:#ffffff;
        z-index:0;
        padding:4.7vw 7.8vw;
    }
    .galleryTextBox h2{
        font-size:5vw; 
        padding-bottom:4vw;
    }
    .gallerypagerGroup{
        padding-bottom:3vw;
    }
    .gallerypagerGroup li{
        font-size:2vw;
        line-height:4vw;
    }      
    .galleryTextBox .btnGroup a{
        height:5vw;
        font-size:2vw;
        line-height:5vw;
    }
    

    .galleryContBox{
        width:100%;
        height:auto;
        position:inherit;
        top:inherit;
        bottom:0;
        padding-left:7.8vw !important;
    }

    .galleryContBox>div>div{border:none; height:15vh;} */
    
    .galleryPopImage2{
        min-width:80vw;
        min-height:50vw;
        max-height:80vh;
    }
    .galleryPopImage2>img{
        width:100%
    }
    .galleryPoptitle{
        top:-13%;
        font-size: 5vw;
    }
    .galleryPoptitle .letcureSubTitle{
        font-size: 4vw;
        margin-top: 1%;
    }
}
@media (max-width:480px){
    .galleryOutFrame{
        padding-top:20vw;
        padding-bottom:20vw;
    }
    .galleryInFrame {
        height:100%;
        flex-direction: column;
        padding-top:0;
    }
    .galleryInFrame .galleryTitleBox{
        width:100%;
        padding-right:5.2vw;
        height:auto;
    }
    .galleryInFrame .galleryTitleBox ul li.sectMenuText{
        font-size:4vw;
    }
    .galleryInFrame .galleryTitleBox ul li.contTitleText{
        font-size:8vw;
        line-height:10vw;
        padding:1vw 0 5vw;
    }
    .galleryInFrame .galleryTitleBox ul li.contentText{
        font-size:4vw;
        padding-bottom:10vw;
        line-height:10vw;
    }
    .galleryInFrame .galleryTitleBox ul li.contentText span{
        padding:0 1vw;
    }
    .galleryInFrame .galleryContBox{
        width:100%;
        padding-left:0;
        height:70vw;
    }
    .galleryPopImage{
        width:80vw;
        min-height:60vw;
        max-height:70vh;
    }
    .galleryPopImage2>img{
        width:100%
    }
    .galleryPoptitle{
        top:-22%;
        font-size: 6vw;
    }
    .galleryPoptitle .letcureSubTitle{
        font-size: 5vw;
        margin-top: 4%;
    }
    .galleryPopDel{
        /* top:-25%;
        right:0; */
        line-height:1;
        font-size:10vw;
        top:1vw;
        right:5vw;
        
    }
    .galleryPopImage>span{
        padding:0 2vw;
    }
    .galleryPopPrev{
        /* top:40%;
        left:5%; */
        font-size:10vw;
        left:0;
    }
    .galleryPopNext{
        /* top:40%;
        right:5%; */
        font-size:10vw;
        right:0;
    }
    
    .userGallPopPrev{
        bottom:0;
        top:inherit;
        left:0;
        padding:0 5vw;
        height:16vw;
        line-height:16vw;
        font-size:12vw;
        z-index:101 !important;
        background:rgba(0,0,0,0.1);
    }
    .userGallPopNext{
        bottom:0;
        top:inherit;
        right:0;
        padding:0 5vw;
        height:16vw;
        line-height:16vw;
        font-size:12vw;
        z-index:101 !important;
        background:rgba(0,0,0,0.1);
    }
    /* .galleryOutFrame{
        height:100vh;
        padding-top:10vw;
        background-size:15%, 50%;
    }
    .galleryTextBox{       
        padding-bottom:10vw;
    }
    .galleryContBox{
        height:auto;
    } */

  
}