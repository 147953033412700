.footerPadding{
    padding-bottom: 20.3vw;
}
.footerBg{
    position: absolute;
    bottom: 0;
    width:100%; 
    min-height:20.3vw;
    background:#000f1e;
    
}
.footerFrame{
    width:100%; 
    height:100%;
    padding:4.7vw 5.2vw 3.6vw;
    margin:0 auto;
}



.footerTitle{
    padding-bottom:1.5vw;
}
.footerTitle a{
    font-size:1.6vw;
    color:#ffffff;
    font-weight:600;
    text-decoration:none;
}
.footerTitle a span{
    font-size:0.9vw;
    font-weight:300;
    padding-left:0.5vw;
}




.footerCont{
    width:100%;
    display:flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}


.footerRight{
    display:flex;
    flex-direction: column;   
}
.missionText{
    font-size:0.8vw;
    color:#ffffff;
    text-align:right;
    line-height:1.6vw;
}
.copyText{
    padding-top:2.1vw;
    font-size:0.7vw;
    color:#ffffff;
    text-align:right;
}





.utilityBx{
    padding-bottom:1.3vw;
}
.utilityBx li{
    display:inline-block; 
    padding:0 0.5vw;
    border-right:1.5px solid #ffffff;
    color:#ffffff;
    cursor: pointer;
}
.utilityBx li:nth-child(1){
    padding-left:0;
}
.utilityBx li:last-child{
    border:none;
}

.utilityBx li:hover a, .utilityBx li:hover span{
    color:#dedede;
}

.utilityBx li a{
    text-decoration:none;
    color:#ffffff;
    font-size:0.9vw;
    line-height:1;
    text-align:center;
    display:block;
}
.utilityBx li span{
    font-size:0.9vw;
    line-height:1;
    display:block;
}



.corpTextBx ul{
    font-size:0;
}
.corpTextBx p{
    font-size:0.7vw;
    color:#ffffff;
    line-height:1;
    margin-bottom:0.5vw;
}
.corpTextBx ul li{
    padding:0 0.3vw;
    /* border-right:1px solid #ffffff; */
    display:inline-block;
    margin-bottom:0.5vw;
}
.corpTextBx ul li:first-child{
    padding-left:0;
}
.corpTextBx li:last-child{
    border:none
}
.corpTextBx ul li span{
    display:block;
    font-size:0.8vw;
    color:#ffffff;
    line-height:1;
}


















