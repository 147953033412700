.borderRed {
    border : 1px solid red;
}
.borderBlue {
    border : 1px solid Blue;
}
.borderGreen {
    border : 1px solid Green;
}
.borderOrange {
    border : 1px solid Orange;
}

.arDownloadWrapper{
    margin-top: 160px;
}

.arDownloadContainerRow{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.arDownloadContainerFlexible{
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    margin: 2vw auto 7vw auto;
}
.arDownloadText{
    width: 100vw;
    margin: 0 auto;
    text-align: center;
    display: block;
    margin-bottom: 1vw;
}
.arDownloadHead{
    color: #38639a;
    font-size: 3vw;
    font-weight: bold;
}
.arDownloadBody{
    font-size: 2vw;
    font-weight: bold;
    margin: 2vw 0;
}
.arDownloadTag{
    color: #38639a;
    font-size: 1.5vw;
    font-weight: bold;
}
.arDownloadImg{
    width: 20vw;
    height: 20vw;
    margin: 2vw;
}
.arDownloadIcon{
    width: 2vw;
    height: 2.3vw;
    margin-left: 2vw;
}
.arDownloadBtnWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin: 0 2vw; */
}
.arDownloadBtnContainer{
    /* display: flex; */
    box-shadow: 2px 2px 2px #999;
    padding: 1vw 1.2vw;
    margin: 2vw;
    background-color: #efefef;
    cursor: pointer;
    text-decoration: none;
    color:black
}
.arDownloadIconText{
    font-size: 1.5vw;
    font-weight: bold;
}
@media screen and (max-width: 1024px){
    .arDownloadWrapper{
        margin-top: 30vw
    }
    .arDownloadWrapper{
        min-height: 70vh;
    }
    .arDownloadContainerFlexible{
        flex-direction: column;
    }
    .arDownloadBtnContainer{
        width: 50vw;
        margin: 2vw auto;
        padding: 2vw;
    }
    .arDownloadHead{
        font-size: 4vw;
    }
    .arDownloadBody{
        font-size: 3vw;
    }
    .arDownloadTag{
        font-size: 2vw;
    }
    .arDownloadIconText{
        font-size: 3vw;
    }
    .arDownloadIcon{
        width: 3vw;
        height: 3.2vw;
    }
}

@media screen and (max-width: 480px){
    .arDownloadWrapper{
        margin: 17vh 0 10vh 0;
    }
    .arDownloadBtnContainer{
        width: 70vw;
        margin: 4vw auto;
        padding: 4vw;
    }
    .arDownloadHead{
        font-size: 8vw;
    }
    .arDownloadBody{
        font-size: 6vw;
        word-break: keep-all;
        line-height: 8vw;
    }
    .arDownloadTag{
        font-size: 5vw;
    }
    .arDownloadIconText{
        font-size: 6vw;
    }
    .arDownloadIcon{
        width: 5vw;
        height: 5.2vw;
    }
    .arDownloadImg{
        width: 50vw;
        height: 50vw;
        margin: 2vw;
    }
} 
@media screen and (max-width: 280px){
    
    .arDownloadWrapper{
        margin-top: 30vw
    }
    .arDownloadBtnWrapper{
        margin: 6vw auto;
    }
    .arDownloadBtnContainer{
        width: 70vw;
        margin: 4vw auto;
        padding: 3vw;
        
    }
    .arDownloadContainerRow{
        justify-content: space-around;
    }
    .arDownloadHead{
        font-size: 8vw;
    }
    .arDownloadBody{
        font-size: 7vw;
        
        line-height: 8vw;
    }
    .arDownloadTag{
        font-size: 5vw;
    }
    .arDownloadImg{
        width: 50vw;
        height: 50vw;
        margin: 7vw;
    }
    .arDownloadIconText{
        font-size: 7vw;
        word-break: keep-all;
        padding-left: 3vw;
    }
    .arDownloadIcon{
        width: 10vw;
        height: 9vw;
        padding-right: 3vw;
    }
}

@media (orientation: landscape) { /* 가로형 pixel */
    
}





.downloadheight.experienceheigh{
    display: flex;
    flex-direction: column;
}
.downloadheight.experienceheight h3.experiencetitle{
    width: 100%;
    text-align: center;
    padding-left: 0;
    margin-bottom: 1vw;
}
.downloadheight.experienceheight h3.experiencetitle>span{
    font-weight: normal;
    font-size: 1.5vw;
    color:#999;
}
.experienceAR{
    width: 100%;
}
.experienceAR .container{
    margin: 0 auto;
    width: 50%;
}
.experienceAR .container .downloadleft{
    height: 20vw;
    border-radius: 2vw;
    display: flex;
}
.experienceAR .container .downloadleft img{
    align-self: center;
}
.experienceAR  .downloadright{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
}
.experienceAR .downloadright>a.experience{
    display: flex;
    box-shadow: 2px 2px 2px #999;
    padding: 1vw 1.2vw;
    background-color: #efefef;
    cursor: pointer;
    text-decoration: none;
    color:black
}
.experienceAR .downloadright>a.experience p{
    margin-top: 0;
    font-weight: bold;
    align-self: center;
    word-break: keep-all;
    margin-bottom: 0;
}
.experienceAR .downloadright>a.experience img.experienceImg{
    width: 2vw;
    margin-left: 1vw;
}
@media screen and (max-width: 1024px){
    .downloadheight.experienceheight{
       /* height: 130vw; */
        padding-top: 0vw;
    }
    .downloadheight.experienceheight h3.experiencetitle span, .downloadheight h3.experiencetitle .pc{font-size: 3vw;}
    .downloadheight.experienceheight h3.experiencetitle{
        width: 80%; font-size: 5vw; margin: 0 auto 11vw;
    }
    .downloadheight.experienceheight h3.experiencetitle span{
        font-size: 4vw; word-break: keep-all;
    }
    .downloadheight.experienceheight .experienceAR .container{
        flex-direction: column;  
        width: 80%;
    }
    .downloadheight.experienceheight .experienceAR .container .downloadleft{
        justify-content: center;
        margin-bottom: 10vw;
    }
    .downloadheight.experienceheight .experienceAR .container .downloadleft img{
        width: 50%;
    }
    .downloadheight.experienceheight .experienceAR .container .downloadright a{
        width: 100%;
        margin: 3vw 0;
        padding: 3vw 5vw;
        justify-content: center;
    }
    .downloadheight.experienceheight .experienceAR .container .downloadright p{
        margin-left: 2vw;
        font-size: 6vw;
    }
    .experienceAR .downloadright>a.experience img.experienceImg{width: 6vw; margin-left: 5vw;}
}
@media screen and (max-width: 1024px) and (orientation: landscape){
   /* .downloadheight.experienceheight{height: 130vw;}*/
}
@media screen and (max-width: 823px) and (orientation: landscape){
   /* .downloadheight.experienceheight{height: 130vw;}*/
}
@media screen and (max-width: 767px){
    .downloadheight.experienceheight{/*height: 130vw;*/ padding-top: 5vw;}
    .downloadheight.experienceheight h3.experiencetitle{font-size: 6vw; margin-bottom: 10vw;}
}
@media screen and (max-width: 480px){
    .downloadheight.experienceheight{padding-top: 13vw;}
} 
@media screen and (max-width: 280px){
    .downloadheight.experienceheight{height: 180vw; padding-top: 20vw;}
    .downloadheight h3.experiencetitle{margin-bottom: 10vw;}
    .downloadheight .experienceAR .container{margin-top: 20vw;}
    .downloadheight .experienceAR .container .downloadleft{margin-bottom: 10vw;}
    .downloadheight .experienceAR .container .downloadright p{ margin-left: 5vw;}
}