/* dataManageGroup */
.adminFacilitatorFrame{
    padding:0 5vw;
}
.adminFacilitatorFrame h3{
    font-size:1.8vw;
    font-weight:600;
    line-height:5vw;
    margin-bottom:2vw;
    border-bottom:1px solid #000000;
}
.adminFacilitatorFrame>div>.FacilitatorBtnGroup{
    display:flex;
    flex-direction: row;
    align-items: center;
}
.adminFacilitatorFrame>div>.FacilitatorBtnGroup div{
    font-size:1.2vw;
    text-align:center;
    border:1px solid #dedede;
    width:34%;
    height:4vw;
    line-height:4vw;
    cursor: pointer;
    color:#aaaaaa;
}
.adminFacilitatorFrame>div>.FacilitatorBtnGroup div:hover{
    background:#eeeeee;
    color:#000000;
}
.adminFacilitatorFrame>div>.FacilitatorBtnGroup div:nth-child(1){
    border-right:0;
    border-bottom:0;
    color:#000000;
}
.adminFacilitatorFrame>div>.FacilitatorBtnGroup div:nth-child(2){
    border-right:none;
}
.adminFacilitatorFrame>div>.FacilitatorContGroup{
    padding:3vw 2vw;
    border:1px solid #dedede;
    border-top:none;
    font-size:1vw;
}
.adminFacilitatorFrame>div>.FacilitatorContGroup>span>select{
    margin:1vw;
    margin-left: 2vw;
    padding: 1vw;
    width: 50%;
    border:1px solid #dedede;
    font-size:1.5vw;
}
.adminFacilitatorFrame>div>.FacilitatorContGroup>span>a
{
    margin-left: 2vw;
    font-size:1.5vw;
    text-align:right;
    /* margin-left:auto; */
    color:#c56e6e;
}

.rolesChangeBg{
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    display:block;
    background:rgba(0,0,0,0.8);
    z-index:999;
}

.rolesChangeBg .rolesChangeBx{
    border:1px solid #000000;
    background:#ffffff;
    width:auto;
    height:auto;
    padding:3vw;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
}
.rolesChangeBg .rolesChangeBx>button{
    position:absolute;
    top:-4vw;
    right:-3vw;
    background:transparent;
    border:0;
    font-size:2vw;
    color:rgba(255,255,255,0.8);
    outline: none;
    cursor: pointer;
}

.rolesChangeBg .rolesChangeBx>button:hover{
    color:rgba(255,255,255,1);
    background:transparent !important;
    border:none !important;
}
.rolesChangeBg .rolesChangeBx .rolesChangeInfo>.DayPicker{
    width: 100%;
}
.rolesChangeInfo>.DayPicker{
    width:100%;
}
.rolesChangeInfo>.DayPicker>div{
    width:100%;
}
.rolesChangeInfo>.DayPicker>div>.DayPicker-NavBar span{
    width:2vw;
    height:2vw;
    outline: none;
}
.rolesChangeInfo>.DayPicker>div>.DayPicker-NavBar span.DayPicker-NavButton--prev{
    right:2vw;
    bottom:1vw;
    top:inherit;
}
.rolesChangeInfo>.DayPicker>div>.DayPicker-NavBar span.DayPicker-NavButton--next{
    right:1vw;
    bottom:1vw;
    top:inherit;
}
.rolesChangeInfo>.DayPicker>div>.DayPicker-Months{
    display:flex;
    flex-direction:row;
    justify-content:center;
    font-size:1.2vw;
    margin-top:1vw;
    margin-bottom:2vw;
}
.rolesChangeInfo .setCpPeriodText{
    height:2.5vw;
    line-height:2.vw;
    font-size:1.2vw;
    color:#c56e6e;
    margin-left:4vw;
    word-break: keep-all;
}
.rolesChangeInfo .setCpPeriodText button{
    height:2.5vw;
    padding:0 2vw;
    margin-left:0.5vw;
    border:1px solid #000000;
    background:#ffffff;
}
.rolesChangeInfo .setCpPeriodText button:hover{
    background:#c56e6e;
    color:#ffffff;
    border:1px solid #c56e6e;
}
.rolesChangeBg .rolesChangeBx .btnGroup{
    text-align:right;
}
.rolesChangeBg .rolesChangeBx .btnGroup button{
    border-width:1px;
    height:2.5vw;
    padding:0 2vw;
    font-size:1vw;
    background:#ffffff;
}
.rolesChangeBg .rolesChangeBx .btnGroup button:nth-child(1){
    border-right:0;
}

/* .userListFrame */
.userListFrame{
    border-width:1px;
}
.userListFrame table{
    width:100%;
    height:auto;
    font-size:1vw;
}
.userListFrame table th{
    height:2.5vw;
    line-height:2.5vw;
    padding:0 0.5vw;
    border:1px solid #dedede;
    background:#eeeeee;
    border-bottom:2px solid #aaaaaa;
    font-size:1vw;
}
.userListFrame table td{
    height:2.5vw;
    border:1px solid #dedede;
}
.userListFrame table td>button
{
    border-width:1px;
    height:2.5vw;
    padding:0 2vw;
    font-size:1vw;
    background:#ffffff;
}
.userListFrame table td>select{
    width:50%;
    height:2.5vw;
    font-size:1vw;  
    cursor: pointer; 
}
.userListFrame table tr td:nth-child(1){
    /* background:#eeeeee; */
    font-weight:600;
}
.userListFrame table tr td:nth-child(2){
    background:rgba(94,114,228,0.1);
}



.userListFrame table td span{
    display:block;
    text-align:center;
}
.userListFrame table td a{
    width:100%;
    height:100%;
    line-height:2.5vw;
    text-align:center;
    display:block;
    color:#000000;
    display:inline-block;
    border:1px solire red;
}
.userListFrame table td a:hover{
    color:#ffffff;
    background:rgba(94,114,228,1);
}
.userListFrame .dataAnalysisGuide{
    padding-top:2vw;
}
.userListFrame .dataAnalysisGuide p{
    font-size:1vw;
    padding-bottom:0.5vw;
}
.userListFrame .dataAnalysisGuide ul{
    font-size:0;
}
.userListFrame .dataAnalysisGuide ul li{
    font-size:1vw;
    display:inline-block;
    margin-right:1vw;
}
.userListFrame .dataAnalysisGuide ul li span{
    font-weight:600;
}

@media (max-width:834px){
.adminFacilitatorFrame>div>.FacilitatorContGroup>span>select{
    font-size:3vw;
}
.adminFacilitatorFrame>div>.FacilitatorContGroup>span>a
{
    font-size:3vw;
    /* margin-top: 10vw; */
}
.adminFacilitatorFrame h3{
    font-size:3.5vw;
    padding: 1vw;
}
.adminFacilitatorFrame>div>.FacilitatorBtnGroup div{
    font-size:3vw;
    width:34%;
    height:7vw;
    line-height:7vw;
    margin-bottom: 3vw;

}
}