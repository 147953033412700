.adminAppImagesFrame {
    padding: 0 5vw;
  }
  .adminAppImagesFrame > h3 {
    font-size: 1.8vw;
    font-weight: 600;
    line-height: 5vw;
    margin-bottom: 2vw;
    border-bottom: 1px solid #000000;
  }
  .adminAppImagesFrame button{
    font-size:1.2vw;
    width:50%;
    height:3vw;
    margin-bottom: 3vw;
    border-width:1px;
}
.adminAppImagesFrame .fullBtn{
    width:100%;
}

  
/* appImagesTabBtnGroup */
.adminAppImagesFrame > div > .appImagesTabBtnGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.adminAppImagesFrame > div > .appImagesTabBtnGroup div {
    font-size: 1.2vw;
    text-align: center;
    border: 1px solid #dedede;
    width: 34%;
    height: 4vw;
    line-height: 4vw;
    cursor: pointer;
    color: #aaaaaa;
    border-right: 0;
}
.adminAppImagesFrame > div > .appImagesTabBtnGroup div:hover {
    background: #eeeeee;
    color: #000000;
}
.adminAppImagesFrame > div > .appImagesTabBtnGroup div:nth-child(1) {
    border-right: 0;
    border-bottom: 0;
    color: #000000;
}
.adminAppImagesFrame > div > .appImagesTabBtnGroup div:nth-last-child(1) {
    border-right: 1px solid #dedede;
}

/* appImagesTabContGroup */
.adminAppImagesFrame > div > .appImagesTabContGroup {
    padding: 3vw 2vw;
    border: 1px solid #dedede;
    border-top: none;
    font-size: 1vw;
}


.appImagesGroup{
    margin-top:2vw;
    width:100%;
    border:1px solid #aaaaaa;
    /* float:left; */
}
/* .appImagesGroup:after{
    content:"";
    display:block;
    clear:both;
} */
.appImagesGroup .appImageBx{
    width:20%;
    height:10vw;
    display:inline-block;
    background:#dedede;
    border:1px solid #aaaaaa;
}
.appImagesGroup .appImageBx .appImgInfoBtn{
    text-align:center;
    padding:2vw;
    height:7vw;
    cursor: pointer;
}
.appImagesGroup .appImageBx .appImgInfoBtn:hover{
    font-weight:600;
    font-size:1.2vw;
}



.appImagesGroup .appImageBx .appImgManageBtn{
    display:flex;
    flex-direction:row;
    margin:0 1vw;
}
.appImagesGroup .appImageBx .appImgManageBtn span{
    border:1px solid #000000;
    width:50%;
    height:2vw;    
    line-height:2vw;
    text-align:center;
    cursor: pointer;
}
.appImagesGroup .appImageBx .appImgManageBtn span:hover{
    background:#000000;
    color:#ffffff;
}
.appImagesGroup .appImageBx .appImgManageBtn span.appImgDel{
    border-left:0;
}
.appImagesGroup .appImageBx .appImgManageBtn span.appImgDel:hover{
    background:#a02121;
    color:#ffffff;
}

.appImagesGroup>span{
    padding:1vw;
    display:block;
    color:#aaaaaa;
    text-align: center;
}






.ChapterPopImage{
    width:575px !important;
    height:780px !important;
    min-width: inherit !important;
    /* width:auto !important;
    height:auto !important; */
    margin:0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.ChapterPopImage .PageGalleryItem{
    width:100%;
    height:100%;
    overflow:hidden;
}
.arActivityPopImage{
    width:60vw;
    height:40vw;
    background-repeat:no-repeat;
    background-position:50% 50%;
    background-size:cover;
}

.adminGallPopImage span:hover{
    color:#ffffff;
}
.adminGallPopDel{
    position:absolute;
    top:2vw !important;
    right:4vw !important;
    z-index:100;
    cursor: pointer;
    display:flex !important;
    flex-direction: row;
    align-items: center;
}
.adminGallPopDel>span{
    line-height:1;
}
.adminGallPopDel>span:nth-child(1){
    font-size:2vw;
    margin-right:1vw;
}
.adminGallPopDel:hover{
    color:#ffffff;
}

@media (max-width:1600px){
    .ChapterPopImage{
        width:35vw !important;
        height:50vw !important;
    }
    .ChapterPopImage .bookContentFrame{
        width:100%;
        /* height:100%; */
    }
}
@media (max-width:1400px){
    .ChapterPopImage{
        width:40vw !important;
        height:55vw !important;
    }
}
@media (max-width:1024px){
    .ChapterPopImage{
        width:80vw !important;
        height:80vh !important;
    }
    .ChapterPopImage .bookContentFrame{
        height:80vh;
    }
}
@media (max-width:834px){
    .ChapterPopImage{
        width:80vw !important;
        height:auto !important;
    }
    .adminGallPopDel{
        top:-6% !important;
    }
    .adminGallPopDel{
        top:2vw !important;
        right:4vw !important;
    }
}

@media (max-width:480px){
    .ChapterPopImage{
        width:90vw !important;
        height:auto !important;
    }
    .adminGallPopDel{
        top:-10% !important;
    }
    .adminGallPopDel{
        top:2vw !important;
        right:4vw !important;
    }
}

