.slOutFrame{
    width:100%; 
    /* height:100%; */
    height:56.25vw;
}
.slInFrame{
    width:100%;
    height:100%;
    padding:4.7vw 5.2vw;
    display:flex;
    flex-direction: row;
}

.sl1stInFrame .TitleBox{
    /* border:1px solid yellowgreen; */
    width:50%;
    height:100%;
    /* padding-left:2vw; */
    display:flex;
    flex-direction: column;
    justify-content: flex-end;
}

.sl1stInFrame .TitleBox ul{
    font-size:0;
}
.sl1stInFrame .TitleBox ul li{
    text-align:right;
}
.sl1stInFrame .TitleBox ul li.contTitleText{
    font-size:4vw;
    font-weight:600;
    line-height:4vw;
    display:flex;
    flex-direction: column;
    padding:1vw 0 2vw;
}
.sl1stInFrame .TitleBox ul li.contTitleText span:nth-child(2){
    font-size:2.5vw;
}
.sl1stInFrame .TitleBox ul li.contentText{
    font-size:1.1vw;
    line-height:1.8vw;
    display:flex;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom:3vw;
    word-break: keep-all;
}
.sl1stInFrame .TitleBox ul li.contentText span:nth-child(6){
    width:75%;
}

.sl1stInFrame .TitleBox ul li.buttonText a{
    font-size:1.2vw;
    height:4vw;
    line-height:4vw;
    text-decoration: none;
    color:#000000;
    padding:0 2vw;
    display:inline-block;
    border:1px solid #000000;
}
.sl1stInFrame .TitleBox ul li.buttonText a:nth-child(2){
    background:#000f1e;
    color:#ffffff;
}
.sl1stInFrame .TitleBox ul li.buttonText a:hover{
    border:1px solid rgb(8, 46, 77);
    background:rgb(8, 46, 77);
    color:#ffffff;
}
.sl1stInFrame .TitleBox ul li.buttonText a:active{
    border:1px solid rgb(8, 46, 77);
    background:rgb(8, 46, 77);
    color:#ffffff;
}
.sl1stInFrame .contBox{
    width:50%;
    height:100%;
    position: relative;
    overflow:hidden;
}
.sl1stInFrame .contBox div{
    width:100%;
    height:100%;
    background:url("../../../../images/CLPimage.png") no-repeat 50% 50%;
    background-size:contain;
    position:absolute;
    top:0;
    left:0;
    transition:transform 2s;
}
.sl1stInFrame .contBox div:hover{
    transform: scale(1.1);
}
.sl1stInFrame .contBox ul{
    position: absolute;
    bottom:0;
    left:6vw;
}
.sl1stInFrame .contBox ul li:nth-child(1){
    font-size:2vw;
    font-weight:600;
    padding-bottom:0.5vw;
    color:#1d4f81;
}
.sl1stInFrame .contBox ul li:nth-child(2){
    font-size:1.3vw;
    color:#a1a1a1;
}
.sl1stInFrame .contBox ul li span{
    display:inline-block;
    margin-right:0.5vw;
}

.sl2ndInFrame .TitleBox{
    /* border:1px solid yellowgreen; */
    width:45%;
    height:100%;
    padding-left:2vw;
    display:flex;
    flex-direction: column;
    justify-content: center;
}

.sl2ndInFrame .TitleBox ul{
    font-size:0;
}
.sl2ndInFrame .TitleBox ul li{
    text-align:right;
}
/* .sl2ndInFrame .TitleBox ul li.sectMenuText{
    font-size:1.5vw;
    font-weight:600;
} */
.sl2ndInFrame .TitleBox ul li.contTitleText{
    font-size:4vw;
    font-weight:600;
    line-height:4vw;
    display:flex;
    flex-direction: column;
    padding:1vw 0 2vw;
}
.sl2ndInFrame .TitleBox ul li.contTitleText span:nth-child(2){
    font-size:2.5vw;
}
.sl2ndInFrame .TitleBox ul li.contentText{
    font-size:1.1vw;
    line-height:1.8vw;
    display:flex;
    flex-direction: column;
    padding-bottom:3vw;
}
.sl2ndInFrame .TitleBox ul li.buttonText a{
    font-size:1.2vw;
    height:4vw;
    line-height:4vw;
    text-decoration: none;
    color:#000000;
    padding:0 2vw;
    display:inline-block;
    border:1px solid #000000;
}
.sl2ndInFrame .TitleBox ul li.buttonText a:nth-child(2){
    background:#000f1e;
    color:#ffffff;
}
.sl2ndInFrame .TitleBox ul li.buttonText a:hover{
    border:1px solid rgb(8, 46, 77);
    background:rgb(8, 46, 77);
    color:#ffffff;
}
.sl2ndInFrame .TitleBox ul li.buttonText a:active{
    border:1px solid rgb(8, 46, 77);
    background:rgb(8, 46, 77);
    color:#ffffff;
}

.sl2ndInFrame .contBox{
    width:55%;
    height:100%;
    position:relative;
    overflow:hidden;
}
.sl2ndInFrame .contBox div{
    width:100%;
    height:100%;
    background:url("../../../../images/SLPimage.png") no-repeat 50% 50%;
    background-size:contain;
    position:absolute;
    top:0;
    left:0;
    transition:transform 2s;
}
.sl2ndInFrame .contBox div:hover{
    transform: scale(1.1);
}
.sl2ndInFrame .contBox ul{
    position: absolute;
    bottom:7vw;
    left:6vw;
}
.sl2ndInFrame .contBox ul li:nth-child(1){
    font-size:2vw;
    font-weight:600;
    padding-bottom:0.5vw;
    color:#1d4f81;
}
.sl2ndInFrame .contBox ul li:nth-child(2){
    font-size:1.3vw;
    color:#a1a1a1;
}
.sl2ndInFrame .contBox ul li span{
    display:inline-block;
    margin-right:0.5vw;
}
/* .slOutFrame{
    width:100%;
    height:100%;
    display:flex;
}

.slInFrame{
    width:100%;
    height:100%;
    background:rgba(255,255,255, 0.5);
    padding:4.7vw 7.8vw;
    position: relative;
}

.slTextBox{
    width:100%;
    height:100%;
    position:relative;
    padding-top:15vw;
    z-index:1;
}
.slTextBox h2{
    font-size:4.2vw;
    color:#000f1e;
    line-height:1;
    padding-bottom:3vw;
}
.slpagerGroup{
    padding-bottom:2vw;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
}
.slpagerGroup li{
    font-size:1.1vw;
    color:#000f1e;
    line-height:2vw;
    cursor: pointer;    
}
.slpagerGroup li.active{
    color:#60c0bf;
}
.slpagerGroup li:hover{
    font-weight:600;
}

.slTextBox .btnGroup a{
    text-decoration:none;
    width:auto;
    height:3vw;
    padding:0 2vw;
    display:inline-block;
    font-size:1.1vw;
    font-weight:500;
    line-height:3vw;
    text-align:center;
}
.slTextBox .btnGroup a.orderBtn{
    border:1px solid #000f1e;
    color:#000f1e;
    cursor: pointer;
    font-weight:600;
}
.slTextBox .btnGroup a:hover{
    color:#ffffff;
    background:#000f1e;
    
}



.slContBox{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    background:#ffffff;
    overflow:hidden;
 
}
.slContBox ul.slider{

    width:300%; 
    height:100%;
    font-size:0;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
}
.slContBox ul.slider li{
    width:100%;
    height:100%;
}
.slContBox ul.slider li img.pcImage{
    width:100%;
    margin-left:auto;
    display:block;
}
.slContBox ul.slider li img.mobileImage{
    display:none;
}




 */
