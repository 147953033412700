.adminSiteManagementFrame{
    padding:0 5vw;
}
.adminSiteManagementFrame h3{
    font-size:1.8vw;
    font-weight:600;
    line-height:5vw;
    margin-bottom:2vw;
    border-bottom:1px solid #000000;
}
.adminSiteManagementFrame>div{
    margin-bottom:8vw;
    font-size:1vw;
}


.adminSiteManagementFrame>div>div:nth-last-child(1),
.adminSiteManagementFrame>div>div:nth-last-child(2){
    padding:2vw;
    border:1px solid #dedede;
}
.adminSiteManagementFrame>div>div:nth-last-child(2){border-bottom:none;}
.adminSiteManagementFrame>div:nth-last-child(1)>div:nth-child(1){border:none; padding:0;}
.adminSiteManagementFrame>div>div:nth-last-child(1){border-top:none;}
.adminSiteManagementFrame>div:nth-last-child(1)>div:nth-child(2){border:1px solid #dedede;}
.adminSiteManagementFrame>div>.manageTitle{
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items:center;
    height:2.5vw;
    margin-bottom:0.5vw;
}
.adminSiteManagementFrame>div>.manageTitle h5{
    font-size: 1.5vw;
    font-weight: 600;
    padding-bottom: 1vw;
    line-height:2.5vw;
    height:2.5vw;
}
.adminSiteManagementFrame>div>.manageTitle button{
    height:2.5vw;
    padding:0 2vw;
    background:#ffffff;
    border:1px solid #dedede;
}
.adminSiteManagementFrame>div>.manageTitle button:hover{
    background:#000000;
    color:#ffffff;
}

/* adminIntroManage */
.adminIntroManage .videoUploadGroup{
    display:flex;
    flex-direction:row;
    align-items:center;
}
.adminIntroManage .videoUploadGroup p{
    font-size:1.2vw;
    line-height:2.5vw;
    margin-right:0.5vw;
}
.adminIntroManage .videoUploadGroup input{
    font-size:1.2vw;
    line-height:2.5vw;
    padding:0 0.5vw;
    width:50vw;
    border:1px solid #000000;
}
.adminIntroManage>p{
    font-size:1vw;
    color:#c56e6e;
    padding:1vw 0;
}
.adminIntroManage .videoPreviewGroup>div{
    width:auto !important;
    height:auto !important;
}


/* adminClassManage, adminSelfManage, adminXrMakingManage*/
.makingSectContent{
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    padding:0;
    margin-bottom:2vw;
}
.makingSectContent .sectTextBx{
    width:40%;
    padding-right:1vw;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
}
.makingSectContent .sectTextBx div{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.makingSectContent .sectTextBx div p{
    font-size:1.2vw;
    padding-bottom:0.5vw;
    font-weight:600;
}
.makingSectContent .sectTextBx div input{
    width:100%;
    height:2.5vw;
    line-height:2.5vw;
    font-size:1vw;
    padding:0 0.5vw;
    border:1px solid #000000;
    margin-bottom:1vw;
}
.makingSectContent .sectTextBx div textarea{
    width:100%;
    height:15vw;
    line-height:2.5vw;
    font-size:1vw;
    padding:0 0.5vw;
    border:1px solid #000000;
    resize:none;
    word-break: keep-all;
}

.makingSectContent .sectImageBx{
    display:flex;
    flex-direction:row;
    width:60%;
}
.makingSectContent .sectImageBx>div{
    width:50%;
    padding-left:1vw;
}
.makingSectContent .sectImageBx>div p{
    font-size:1.2vw;
    padding-bottom:0.5vw;
    font-weight:600;
}
.makingSectContent .sectImageBx>div>div{
    width:100%;
    overflow:hidden;
}
.makingSectContent .sectImageBx>div>div>form{
    width:100%;
    margin-bottom:0.5vw;
}
.makingSectContent .sectImageBx>div>div>form input{
    width:100%;
    line-height:2vw;
    font-size:0.9vw;
    border:1px solid #eeeeee;
    margin-bottom:0.5vw;
}
.makingSectContent .sectImageBx>div>div>form button{
    width:100%;
    height:2.5vw;
    font-size:1vw;
    line-height:2.5vw;
    border-width:1px;
}
.makingSectContent .sectImageBx>div>div img{
    width:100%;
    height:15vw;
}

.addContentBtn{
    width:100%;
    padding-bottom:5vw;
}
.addContentBtn button{
    width:100%;
    height:2.8vw;
    border-width:1px;
    font-size:1.2vw;
}

.addedContentPreview{
    display:flex;
    flex-direction:row;
    justify-content: space-between;
}

/*slideImageItem*/
.addedContentPreview>div{
    display:flex;
    flex-direction:row;
    justify-content: space-between;
}



.PreviewForConfirm{
    width:95%;
}
.PreviewForConfirm .sectImageBx{
    display:flex;
    flex-direction: row;
    width:30%;
}

.PreviewForConfirm .sectImageBx>div{
    width:50%;
    padding-right:1vw;
}
.PreviewForConfirm .sectImageBx>div>p{
    font-size:1.2vw;
    padding-bottom:0.5vw;
    font-weight:600;
    color:#dedede;
}
.PreviewForConfirm .sectImageBx>div>img{
    width:100%;
}
.PreviewForConfirm .sectTextBx{
    width:59%;
}
.PreviewForConfirm button{
    border-width:1px;
    width:5%;
    margin-right:1%;
}
.PreviewForConfirm button:hover{
    background:#000000;
    color:#ffffff;
}



.previewForModify{
    border:1px solid #000000;
    border-right:0;
    padding:2vw;
    width:95%;
}
.previewForModify .sectImageBx{
    display:flex;
    flex-direction: row;
    width:50%;
}
.previewForModify .sectImageBx>div>p{
    font-size:1.2vw;
    padding-bottom:0.5vw;
    font-weight:600;
}

.previewForModify .sectImageBx>div{
    padding-right:1vw;
    width:50%;
}
.previewForModify .sectImageBx>div>div>form{
    width:100%;
    margin-bottom:0.5vw;
}
.previewForModify .sectImageBx>div>div>form input{
    width:100%;
    line-height:2vw;
    font-size:0.9vw;
    border:1px solid #eeeeee;
    margin-bottom:0.5vw;
}
.previewForModify .sectImageBx>div>div>form button{
    width:100%;
    height:2.5vw;
    font-size:1vw;
    line-height:2.5vw;
    border-width:1px;
}
.previewForModify .sectImageBx>div>div img{
    width:100%;
    height:15vw;
}
.previewForModify .sectTextBx{
    width:55%;
    display:flex;
    flex-direction:column;
    padding:0 1vw;
}
.previewForModify .sectTextBx input{
    margin-bottom:0.5vw;
    border:1px solid #000000;
    height:2vw;
    padding:0 0.5vw;
    font-size:1vw;
}
.previewForModify .sectTextBx textarea{
    padding:0.5vw;
    font-size:1vw;
    overflow:auto;
    height:20vw;
    resize:none;
}
.previewForModify .sectTextBx textarea::-webkit-scrollbar{
    width:5px;
}
.previewForModify .sectTextBx textarea::-webkit-scrollbar-thumb{
    background-color:#b3c7db;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
}
.previewForModify .sectTextBx textarea::-webkit-scrollbar-track{
    background:transparent;
  border-radius:5px;
  box-shadow:inset 0px 0px 3px #ffffff;
}

.previewForModify button{
    border-width:1px;
    width:5%;
}
.previewForModify button:hover{
    background:#000000;
    color:#ffffff;
}
.addedContentPreview button.contentDelBtn{
    background:#ffffff;
    border:1px solid #000000;
    width:5%;
}
.addedContentPreview button.contentDelBtn:hover{
    background:#a30505;
    color:#ffffff;
    border:1px solid #a30505;
}


