.adminInquiriesFrame{
    padding:0 5vw;
}
.adminInquiriesFrame h3{
    font-size:1.8vw;
    font-weight:600;
    line-height:5vw;
    margin-bottom:2vw;
    border-bottom:1px solid #000000;

}
.adminInquiriesFrame>p {
    font-size: 1vw;
    display: block;
    margin-bottom: 1vw;
}
.inquiriesBxGroup{
    width:100%;
    height:auto;
}
.inquiriesBxGroup table{
    width:100%;
    height:auto;
    font-size:1vw;
}
.inquiriesBxGroup table th{
    height:2.5vw;
    line-height:2.5vw;
    padding:0 0.5vw;
    border:1px solid #dedede;
    border-bottom:2px solid #aaaaaa;
    font-size:1.2vw;
}
.inquiriesBxGroup table td{
    padding:0 0.5vw;
    height:2.5vw;
    border:1px solid #dedede;
}
.inquiriesBxGroup table td span.inquireContText{
    border-width:1px;
    display:block;
    width:15vw;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    cursor: pointer;
}
.inquiriesBxGroup table td span.inquireContText:hover{
    color:orange;
}






.inquireDetailsBg{
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    display:block;
    background:rgba(0,0,0,0.8);
    z-index:999;
}

.inquireDetailsBg .inquireDetailsBx{
    border:1px solid #000000;
    background:#ffffff;
    width:60vw;
    height:auto;
    padding:3vw;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
}
.inquireDetailsBg .inquireDetailsBx>button{
    position:absolute;
    top:-4vw;
    right:-3vw;
    background:transparent;
    border:0;
    font-size:3vw;
    color:rgba(255,255,255,0.8);
    outline: none;
    cursor: pointer;
}
.inquireDetailsBg .inquireDetailsBx>button:hover{
    color:rgba(255,255,255,1);
    background:transparent !important;
    border:none !important;
}
.inquireDetailsBg .inquireDetailsBx .inqirerInfo{
    display:flex;
    flex-direction: row;
    align-items:flex-end;
    font-size:1vw;
    margin-bottom:2vw;
}
.inquireDetailsBg .inquireDetailsBx .inqirerInfo div{
    line-height:1;
}
.inquireDetailsBg .inquireDetailsBx .inqirerInfo div:nth-child(1){
    font-size:1.5vw;
    font-weight:600;
    margin-right:1vw;
}
.inquireDetailsBg .inquireDetailsBx .inqirerInfo div:nth-child(2),
.inquireDetailsBg .inquireDetailsBx .inqirerInfo div:nth-child(3){
    padding-right:0.5vw;
    border-right:1px solid #959595;
    margin-right:0.5vw;
}
.inquireDetailsBg .inquireDetailsBx .inquireContents{
    display:flex;
    flex-direction: column;
    border-bottom:1px solid #dedede;
    font-size:1.2vw;
    padding:1vw 0;    
}
.inquireDetailsBg .inquireDetailsBx .inquireContents div:nth-child(1){
    margin-bottom:1vw;
    color:orange;
    opacity: 0.8;
}
.inquireDetailsBg .inquireDetailsBx .inquireContents div:nth-child(2){
    max-height:30vh;
    overflow:auto;
    overflow-x:hidden;
}
.inquireDetailsBg .inquireDetailsBx .inquireContents div:nth-child(2)::-webkit-scrollbar{
    width:5px;
}
.inquireDetailsBg .inquireDetailsBx .inquireContents div:nth-child(2)::-webkit-scrollbar-thumb{
    background-color:#b3c7db;
    border-radius:5px;
    background-clip:padding-box;
    border:1px solid transparent;
}
.inquireDetailsBg .inquireDetailsBx .inquireContents div:nth-child(2)::-webkit-scrollbar-track{
    background:transparent;
    border-radius:5px;
    box-shadow:inset 0px 0px 3px #ffffff;
}
.inquireDetailsBg .inquireDetailsBx .inquireContents div:nth-child(2) span{
    word-break: keep-all;
}

.inquireDetailsBg .inquireDetailsBx .inquireContents div:nth-child(3){
    text-align:right;
    margin-top:0.5vw;
    font-size:1vw;
}
.inquireDetailsBg .inquireDetailsBx textarea{
    width:100%;
    height:20vh;
    resize:none;
    overflow:auto;
    margin:2vw 0;
    color:#000000;
    padding:0.5vw;
    font-size:1vw;
    line-height:1.5vw;
    word-break: keep-all;
}
.inquireDetailsBg .inquireDetailsBx .btnGroup{
    text-align:right;
}
.inquireDetailsBg .inquireDetailsBx .btnGroup button{
    border-width:1px;
    height:2.5vw;
    padding:0 2vw;
    font-size:1vw;
    background:#ffffff;
}
.inquireDetailsBg .inquireDetailsBx .btnGroup button:nth-child(1),
.inquireDetailsBg .inquireDetailsBx .btnGroup button:nth-child(2){
    border-right:0;
}







