/* res css */

@media (max-width:834px){
    .footerPadding{
        padding-bottom: 44vw;
    }
    .footerFrame{
        display:flex;
        flex-direction: column;
    }

    .footerTitle a{
        font-size:3vw;
    }
    .footerTitle a span{
        font-size:2vw;

    }
    
    .footerCont{
        display:block;
         
    }
        
    .footerRight{
        display:block;
        padding-bottom:1vw; 
    }
    .missionText{
        font-size:1.9vw;
        text-align:left;
        line-height:3vw;
    }
    .copyText{
        font-size:1.5vw;
        text-align:left;
    }

    
    .utilityBx{
        padding-top:1vw;
        padding-bottom:2.5vw;
    }
    .utilityBx li{
        padding:0 1.5vw;
    }
    .utilityBx li a{
        font-size:2vw;
    }
    .utilityBx li span{
        font-size:2vw;
    }
    .corpTextBx p{
        font-size:0.5vw;
        line-height:1;
        margin-bottom:1vw;
    }
    .corpTextBx p{
        font-size:1.6vw;
    }
    .corpTextBx ul li{
        margin-bottom:1vw;
    }
    .corpTextBx ul li span{
        font-size:1.6vw;
        line-height:1vw;
        margin-bottom:0.5vw;
    }
}




@media (max-width:480px){
    .footerPadding{
        padding-bottom: 80vw;
    }
    .footerFrame{
        display:flex;
        flex-direction: column;
    }

    .footerTitle a{
        font-size:6vw;
    }
    .footerTitle a span{
        font-size:4vw;
        padding-left:2vw;
    }
 
        
    .footerRight{
        display:block;
        padding-bottom:1vw; 
    }
    .missionText{
        display:none;
    }
    .copyText{
        font-size:3vw;
        padding-bottom:1vw;
    }
    
    .utilityBx{
        padding-top:0;
        padding-bottom:2.5vw;
    }
    .utilityBx li{
        padding:0 1.5vw;
        
    }
    .utilityBx li a{
        font-size:3.5vw;
    }
    .utilityBx li span{
        font-size:3.5vw;
    }
    .corpTextBx p{
        font-size:2.5vw;
        line-height:1;
        margin-bottom:1.5vw;
    }
    .corpTextBx p{
        font-size:2.5vw;
    }
    .corpTextBx ul li{
        margin-bottom:0;
        padding-right:2vw;
        border:none;
    }
    .corpTextBx ul li:nth-last-child(1),
    .corpTextBx ul li:nth-last-child(2){
        padding-right:0;
    }
    .corpTextBx ul li span{
        font-size:2.5vw;
        line-height:1.6;
        margin-bottom:0;
    }
    .corpTextBx ul:nth-child(4) li:nth-child(1) span{
        letter-spacing: -0.6px;
    }
    .corpTextBx ul:nth-child(4) li:nth-child(1) span strong{
        letter-spacing:0;
    }
}
